import React from "react";
import {flexRender} from "@tanstack/react-table";
import {Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableProps, TableRow} from "@mui/material";

import {Pagination} from "components";

interface BasicTableProps {
  table: any
  page?: number
  totalPage?: number
  onChangePage?: (event: React.ChangeEvent<unknown>, value: number) => void
  isFetching?: boolean
  renderSubComponent?: (props: { row }) => React.ReactElement
  tableProps?: TableProps
  paginationStyle?: any
	containerProps?: any
}

export const BasicTable = (props: BasicTableProps) => {
  const { table, page, totalPage = 0, onChangePage, isFetching, renderSubComponent, tableProps, paginationStyle, containerProps } = props

  return (
    <Box {...containerProps}>
      <Table className="TableGrid" {...tableProps}>
        <TableHead>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <TableCell key={header.id} align="center">
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {!isFetching && table.getRowModel().rows.map(row => {
            const className = row.getIsExpanded() ? "expanded" : ""

            return (
              <React.Fragment key={row.id}>
                <TableRow className={className} sx={{height: '1px'}}>
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id} sx={{height: 'inherit'}}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
                {row.getIsExpanded() && renderSubComponent &&
                  <TableRow className="expand-row">
                    <TableCell colSpan={row.getVisibleCells().length}>
                      {renderSubComponent({ row })}
                    </TableCell>
                  </TableRow>
                }
              </React.Fragment>
            )
          })}
        </TableBody>
      </Table>

      {totalPage > 0 && !isFetching &&
        <Pagination
          page={page}
          count={totalPage}
          onChange={onChangePage}
          boxStyle={paginationStyle}
        />
      }

      {isFetching &&
        <Box m={3} textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      }
    </Box>
  )
}
