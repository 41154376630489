
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-14 23:27:39
 * @modify date 2022-04-13 17:48:38
 */

import React, { Suspense } from 'react';
import { CssBaseline } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { RootStore, RootStoreProvider, setupRootStore } from 'models/root-store'

import { Logo } from 'assets/images'
import {authenticationTheme, dashboardTheme} from 'assets'
import { AuthenticationNavigation, DashboardNavigation, PublicNavigation } from 'navigation'
import {InfoPage, GlobalNotification, Loading, CloseNotification, LoadingPage} from 'components'
import { useAppVersion } from "hooks/app-version"
import ErrorBoundary from "./pages/errors/error-boudary";
import './style.css'

const App: React.FC = () => {
  const [rootStore, setRootStore] = React.useState<RootStore | undefined>(undefined)

  useAppVersion(rootStore)

  React.useEffect(() => {
    (async () => setupRootStore().then(setRootStore))()
  }, [])

  if (!rootStore) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={dashboardTheme}>
          <CssBaseline />
          <InfoPage icon={Logo} />
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }

  return (
    <ErrorBoundary logout={rootStore.userStore.logout}>
      <Suspense fallback={<LoadingPage />}>
        <RootStoreProvider value={rootStore}>
          <StyledEngineProvider injectFirst>
            <SnackbarProvider
              action={key => <CloseNotification snackbarKey={key} />}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              autoHideDuration={5000}
              hideIconVariant
            >

              <ThemeProvider theme={authenticationTheme}>
                <CssBaseline />

                <AuthenticationNavigation />

                <ThemeProvider theme={dashboardTheme}>
                  <CssBaseline />
                  <DashboardNavigation />
                </ThemeProvider>

                <PublicNavigation />

                <GlobalNotification />
                <Loading />
              </ThemeProvider>
            </SnackbarProvider>
          </StyledEngineProvider>
        </RootStoreProvider>
      </Suspense>
    </ErrorBoundary>
  )
}

export default App
