import { orderBy } from 'lodash'
import { getData } from 'country-list'

export const countries = orderBy(getData(), 'name')

export const countryName = (code: string) => {
  const country = countries.find(country => country.code === code)
  return country?.name ?? ''
}

export interface countryProps {
	code: string
	name: string
}
