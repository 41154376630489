export const SampleShipmentApproval = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
  DECLINED: 'declined',
}

export const SampleShipmentFulfillmentStatus = {
  FULFILLED: 'fulfilled',
  UNFULFILLED: 'unfulfilled',
  NEW_REQUEST: 'new_request',
}

export const SampleFulfillmentType = {
  THIRD_PARTY: 'third_party',
  LOCAL: 'local'
}

export const CuppingProtocol = {
  ARABICA: 'arabica',
  ROBUSTA: 'robusta',
  CUP_OF_EXCELLENCE: 'cup_of_excellence',
  SCA_AFFECTIVE: 'sca_affective',
  SCA_DESCRIPTIVE: 'sca_descriptive',
  SCA: 'sca',
  SUCAFINA_TASTING: 'sucafina_tasting',
  COMMERCIAL: 'commercial',
  SOLUBLE: 'soluble',
	SIMPLIFIED_COMMON: 'simplified_common',
}
