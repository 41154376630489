import {applySnapshot, flow, getSnapshot, types} from "mobx-state-tree"
import {withEnvironment, withGeneralError, withSetValue} from "models/extensions"
import {ApiResult} from "services/api"
import {SampleReportStoreModel} from "models/sample-report-store";

export const CombinedReportStore = types
  .model("CombinedReportStore")
  .props({
    sampleReport: types.optional(SampleReportStoreModel, {})
  })
  .extend(withEnvironment)
  .extend(withGeneralError)
  .extend(withSetValue)
  .actions((self) => ({
    getReport: flow(function * (params) {
      try {
        self.setValue('sampleReport', {})
        const res: ApiResult = yield self.environment.combinedReportApi.all(params)

        if (res.kind === "ok") {
          if (res.data.scoreSummary) self.sampleReport.setSampleReport(res.data.scoreSummary)

          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    })
}))
