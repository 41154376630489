/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-16 22:28:06
 * @modify date 2022-02-16 22:28:06
 */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Container, Grid, Typography} from '@mui/material'

import { HeaderTitle } from 'components'
import { findRoute } from 'constants/routes'
import { onBoarding1, onBoarding2, onBoarding3, tastify1, tastify2, tastify3 } from "assets/images"

export const WatchVideo: React.FC = observer(() => {
  const { t } = useTranslation()
  const homeLink = findRoute('home')

  const onBoardingVideos = [
    {
      title: t('watchVideo.howToCupCoffeeWithTastify'),
      subtitle: t('watchVideo.learnToMakeCuppingSession'),
      videoUrl: "https://drive.google.com/uc?export=download&id=189hAsRyj2uWSKLFf5mW41oqFWSBjFV8G",
      poster: onBoarding1
    },
    {
      title: t('watchVideo.howToAdvanceSearch'),
      subtitle: t('watchVideo.searchCertainSample'),
      videoUrl: "https://drive.google.com/uc?export=download&id=1kDtd0PwAuBLtPeguKq9nvg2UBKXvgCFM",
      poster: onBoarding2
    },
    {
      title: t('watchVideo.howToSeeAndShare'),
      subtitle: t('watchVideo.youCanShareYourSample'),
      videoUrl: "https://drive.google.com/uc?export=download&id=1h3cw0Ps4FcJOtW9VLVUzzpJ32oSikdgZ",
      poster: onBoarding3
    }
  ]

  const tastifyVideos = [
    {
      title: t('watchVideo.simplifyYourCuppingSession'),
      subtitle: t('watchVideo.youCanShareYourSampleReport'),
      videoUrl: "https://drive.google.com/uc?export=download&id=1YvzpGupVrbNO4puR0xY7Cd6PYuU8Y8fe",
      poster: tastify1
    },
    {
      title: t('watchVideo.manageYourSamplingData'),
      subtitle: t('watchVideo.tastifyCollaborativeDigitalCuppingWorkspace'),
      videoUrl: "https://drive.google.com/uc?export=download&id=15xJLec9AsliY6M7IQ-LDH0IKahARfUZY",
      poster: tastify2
    },
    {
      title: t('watchVideo.remoteCupWithEveryOne'),
      subtitle: t('watchVideo.collaborativeCupAndScore'),
      videoUrl: "https://drive.google.com/uc?export=download&id=1YOms3wYevEeJDbDxQoungXmLTGaF1aHe",
      poster: tastify3
    }
  ]

  const playlist = (list: any, n: number) => {
    return (
      <Grid item xs={12} md={6} key={list.title}>
        <video width="100%" controls poster={list.poster}>
          <source src={list.videoUrl} type='video/mp4' />
        </video>

        <Typography variant='h6'>{n + 1}. {list.title}</Typography>
        <Typography variant='caption'>{list.subtitle}</Typography>
      </Grid>
    )
  }

  return (
    <>
      <HeaderTitle
        backUrl={homeLink}
        breadcrumb={t('common.backTo', { menu: t('menu.home')})}
        title={t('watchVideo.watchVideo')}
      />

      <Container sx={{mb: 4}}>
        <Typography variant='h4' paragraph>{t('watchVideo.onBoarding')}</Typography>
        <Grid container spacing={3} sx={{mb: 4}}>
          {onBoardingVideos.map((onBoarding, index) => playlist(onBoarding, index))}
        </Grid>
        <Typography variant='h4' paragraph>{t('watchVideo.aboutTastify')}</Typography>
        <Grid container spacing={3}>
          {tastifyVideos.map((tastify, index) => playlist(tastify, index))}
        </Grid>
      </Container>
    </>
  )
})

export default WatchVideo
