/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-16 22:28:06
 * @modify date 2022-02-16 22:28:06
 */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"
import { Container, Box, Card, Typography, Button } from '@mui/material'

import { useStores } from 'models'
import { HeaderTitle } from 'components'
import { findRoute } from 'constants/routes'
import { loadGuide } from 'utils'

import { useStyles } from './tutorial.styles'

export const Tutorial: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    userStore: { languagePreference },
    guideStore: { setValue: setValueGuide }
  } = useStores()

  const homeLink = findRoute('home')

  const guideId = (type: string) => {
    const es = languagePreference === 'es'
    const mobile = window.innerWidth < 900

    switch (type) {
      case 'knowYourDashboard':
        if (es && mobile) return 53582
        if (mobile) return 53558
        if (es) return 52483

        return 51137
      case 'addLicenses':
        // if (es && mobile) return 53576
        if (mobile) return 53559
        if (es) return 52786

        return 51138
      case 'addUserToMyTeam':
        // if (es && mobile) return 53575
        if (mobile) return 53571
        if (es) return 52787

        return 51139
      case 'howToCupAndScore':
        // if (es && mobile) return 53642
        if (mobile) return 53636
        if (es) return 52788

        return 51133
      case 'howToReviewAndGenerateReport':
        // if (es && mobile) return 53651
        if (mobile) return 53649
        if (es) return 52789

        return 51140
      case 'addSampleDetails':
        // if (es && mobile) return 53650
        if (mobile) return 53647
        if (es) return 52790

        return 51141
      default:
        break;
    }
  }

  const guides = [
    {
      label: t('tutorial.knowYourDashboard'),
      previewId: guideId('knowYourDashboard'),
      action: () => navigate(homeLink)
    },
    {
      label: t('tutorial.addLicenses'),
      previewId: guideId('addLicenses'),
      action: () => {
        setValueGuide('guideLicense', true)
        navigate(homeLink)
      }
    },
    {
      label: t('tutorial.addUserToMyTeam'),
      previewId: guideId('addUserToMyTeam'),
      action: () => {
        setValueGuide('guideCupper', true)
        navigate(homeLink)
      }
    },
    {
      label: t('tutorial.howToCupAndScore'),
      previewId: guideId('howToCupAndScore'),
      action: () => {
        setValueGuide('guideCupping', true)
        navigate(homeLink)
      }
    },
    {
      label: t('tutorial.howToReviewAndGenerateReport'),
      previewId: guideId('howToReviewAndGenerateReport'),
      action: () => {
        setValueGuide('guideSample', true)
        navigate(homeLink)
      }
    },
    {
      label: t('tutorial.addSampleDetails'),
      previewId: guideId('addSampleDetails'),
      action: () => {
        setValueGuide('guideScore', true)
        navigate(homeLink)
      }
    },
  ]

  const cardGuide = (index, guide) => {
    return (
      <Card key={index} className={classes.cardWrapper}>
        <Box sx={{display: 'flex'}}>
          <Typography variant='body2' component='div' sx={{mr: 1}}>{index + 1}.</Typography>
          <Typography variant='body2' component='div'>{guide.label}</Typography>
        </Box>

        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            guide.action && guide.action()
            loadGuide(() => (window as any).userGuiding.previewGuide(guide.previewId))
          }}
          className={classes.button}
        >
          {t('tutorial.seeTutorial')}
        </Button>
      </Card>
    )
  }

  return (
    <>
      <HeaderTitle
        backUrl={homeLink}
        breadcrumb={t('common.backTo', { menu: t('menu.home')})}
        title={t('tutorial.helpAndTutorials')}
      />

      <Container>
        <Box sx={{ width: { xs: '100%', md: '50%' }}}>
          {guides.map((guide, index) => cardGuide(index, guide))}
        </Box>

      </Container>
    </>
  )
})

export default Tutorial
