/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 20:03:48
 * @modify date 2022-02-06 20:03:48
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: 'white',
      padding: theme.spacing(4),
      borderRadius: 5,
      border: `1px solid ${colors.magnolia}`,
    },
    collapseWrapper: {
      padding: theme.spacing(2),
      backgroundColor: colors.magnolia,
      borderRadius: 4
    },
    approvalStatus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 148,
      color: 'white',
      fontWeight: 600,
      borderRadius: 4,
      '&.approved': {
        backgroundColor: '#94DF78'
      },
      '&.rejected': {
        backgroundColor: '#FF848A'
      }
    }
  })
)
