/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-20 17:11:59
 * @modify date 2022-04-20 17:11:59
 */

import { types } from 'mobx-state-tree'
import {
  withSetValue,
  withReset
} from "models/extensions"

export const filterProps = types.model({
  name: types.optional(types.string, ''),
  regionName: types.optional(types.string, ''),
  scoredByMe: types.optional(types.boolean, false),
  species: types.optional(types.string, ''),
  receivedOn: types.optional(types.string, ''),
  sampleTypes: types.optional(types.string, ''),
  cropYear: types.optional(types.string, ''),
  numberOfBags: types.optional(types.string, ''),
  reference: types.optional(types.string, ''),
  shippingInfo: types.optional(types.string, ''),
  waterActivity: types.optional(types.string, ''),
  moisture: types.optional(types.string, ''),
  mass: types.optional(types.string, ''),
  volume: types.optional(types.string, ''),
  countryCode: types.optional(types.string, ''),
  status: types.optional(types.string, ''),
  externalId: types.optional(types.string, ''),
  sampleId: types.optional(types.string, ''),
  producerName: types.optional(types.string, ''),
  bagWeight: types.optional(types.string, ''),
  grade: types.optional(types.string, ''),
  processName: types.optional(types.string, ''),
  supplierName: types.optional(types.string, ''),
  notesAndRemarks: types.optional(types.string, ''),
  averageScoreMin: types.optional(types.string, ''),
  averageScoreMax: types.optional(types.string, ''),
  acidityAndSaltDescriptors: types.optional(types.string, ''),
  bodyAndMouthfeelDescriptors: types.optional(types.string, ''),
  fragranceDescriptors: types.optional(types.string, ''),
  flavorAndAftertasteDescriptors: types.optional(types.string, ''),
  defectsDescriptors: types.optional(types.string, ''),
  generalSearch: types.optional(types.boolean, false),
  sampleUniqueNumber: types.optional(types.string, ''),
  customer: types.optional(types.string, ''),
  purchaseContractReference: types.optional(types.string, ''),
  salesContractReference: types.optional(types.string, ''),
  trackingNumber: types.optional(types.string, ''),
})
.extend(withSetValue)
.extend(withReset)
