import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { chain, isEmpty } from "lodash"

import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"

import { withReportsAnalyticsActions } from "./reports-analytics-action"
import {
  fulfilledProps,
  reportAnalyticsDailyArrivalsProps,
  reportAnalyticsDailyCuppingsProps,
  reportAnalyticsShipmentProps,
  descriptorProps
} from "./report-analytics.props"
import { filterModel } from "./filter-model"
import {dateFilterModel} from "./date-filter"

/**
 * Model description here for TypeScript hints.
 */


export const ReportsAnalyticsStoreModel = types
  .model("ReportsAnalyticsStore")
  .props({
    reportAnalyticsDailyArrivals: types.optional(
      types.array(types.model(reportAnalyticsDailyArrivalsProps)),
      []
    ),
    reportAnalyticsDailyCuppings: types.optional(
      types.array(types.model(reportAnalyticsDailyCuppingsProps)),
      []
    ),
    reportAnalyticsShipments: types.optional(
      types.array(types.model(reportAnalyticsShipmentProps)),
      []
    ),
    summaryLocalShipments: types.maybeNull(types.optional(fulfilledProps, {})),
    summaryThirdPartyShipments: types.maybeNull(types.optional(fulfilledProps, {})),
    totalShipments: types.optional(types.number, 0),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0),
    filter: types.optional(filterModel, {}),
    approvedSamples: types.optional(types.number, 0),
    cuppingSubmitted: types.optional(types.number, 0),
    rejectedSamples: types.optional(types.number, 0),
    sampleReceived: types.optional(types.number, 0),
    sampleShipmentCreated: types.optional(types.number, 0),
    topFlavorWheel: types.optional(types.string, '{}'),
    averageScore: types.optional(types.number, 0),
    maxScore: types.optional(types.number, 0),
    minScore: types.optional(types.number, 0),
    noStatusSamples: types.optional(types.number, 0),
    descriptors: types.optional(types.array(descriptorProps),[]),
    rejectedReasons: types.optional(types.string, '{}'),
    dateFilter: types.optional(dateFilterModel, {}),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withReportsAnalyticsActions)
  .views(self => ({
    get filterValues() {
      return chain(self.filter)
              .omitBy(isEmpty)
              .assign({
                ...(self.filter.generalSearch && {generalSearch: self.filter.generalSearch}),
                ...(self.filter.scoredByMe && {scoredByMe: self.filter.scoredByMe})
              })
              .value()
    }
  }))

type ReportsAnalyticsStoreType = Instance<typeof ReportsAnalyticsStoreModel>
export interface ReportsAnalyticsStore extends ReportsAnalyticsStoreType {}
type ReportsAnalyticsStoreSnapshotType = SnapshotOut<typeof ReportsAnalyticsStoreModel>
export interface ReportsAnalyticsStoreSnapshot extends ReportsAnalyticsStoreSnapshotType {}
