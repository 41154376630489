import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@mui/material";

export const SampleInformation = (props) => {
  const { t } = useTranslation()
  const { customer, recipient, requestDate, shipTo, tastifyOrderNumber } = props

  const information = [
    {
      title: t('shipmentOrderForm.customer'),
      value: customer ?? '-'
    },
    {
      title: t('shipmentOrderForm.recipient'),
      value: recipient ?? '-'
    },
    {
      title: t('shipmentOrderForm.requestDate'),
      value: requestDate ?? '-'
    },
    {
      title: t('shipmentOrderForm.shipTo'),
      value: shipTo ?? '-'
    },
    ...(tastifyOrderNumber ? [{
      title: t('shipmentOrderForm.tastifyOrderNumber'),
      value: tastifyOrderNumber
    }] : [])
  ]

  const renderInformation = (info) => (
    <Grid item key={info.title} xs={12} md={4}>
      <Typography variant='body1'>{info.title}</Typography>
      <Typography variant='body1' color="primary" fontWeight={600}>{info.value ?? '-'}</Typography>
    </Grid>
  )

  return (
    <Grid container spacing={2}>
      {information.map(renderInformation)}
    </Grid>
  )
}
