/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-28 18:31:58
 * @modify date 2022-02-01 16:38:32
 */

import React from 'react'
import { isEmpty , assign, has } from 'lodash'
import { observer } from "mobx-react-lite"
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Input, InputAdornment, IconButton,
  TextField, Container, Typography, FormHelperText
} from '@mui/material'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { showSignUpPage } from "config/setting"
import { useStores } from "models"
import { findRoute } from 'constants/routes'
import { validateEmail, errorField, errorMessage, errorStatus } from 'utils'
import { AuthenticationWrapper, StackAlert, FormInput } from 'components'

import { useStyles } from './sign-in.styles'
import { SignInProps } from './sign-in.props'

export const SignIn: React.FC<SignInProps> = observer ((props: SignInProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const homeLink = findRoute('home')
  const signUpLink = findRoute('signUp')
  const forgotPasswordLink = findRoute('forgotPassword')

  const {
    userStore: { email, password, setValue, login, isAuthenticated, registrationCompleted }
  } = useStores()

  const { image } = props
  const [isLoading, setIsLoading] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})

  const isPasswordError = errorStatus('password', password, errors)

  const signIn = async () => {
    setIsLoading(true)
    let validationErrors = {}

    try {
      const validEmail = validateEmail(email)
      if (!validEmail) {
        assign(validationErrors, {
          "email": [{ message: t('signIn.error.emailIsInvalid') }]
        })
      }

      if (!password) {
        assign(validationErrors, {
          "password": [{ message: t('signIn.error.passwordIsEmpty') }]
        })
      }

      if (!isEmpty(validationErrors)) {
        setErrors(validationErrors)
        return
      }

      if (!executeRecaptcha) return

      const token = await executeRecaptcha('public')
      setValue('captchaToken', token)
      await login()
      navigate(homeLink, { replace: true })
    } catch (e: any) {
      setErrors(e.errors)
      setValue('captchaToken', null)
    } finally {
      setIsLoading(false)
    }
  }

  const onEnter = async (e) => {
    if (e.key === 'Enter') signIn()
  }

  React.useEffect(() => {
    if (isAuthenticated && registrationCompleted) navigate(homeLink, { replace: true })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, registrationCompleted])

  return (
    <AuthenticationWrapper title={t('signIn.title')} image={image} >

      <Typography variant='h4' textAlign='center' sx={{mb: 2}}>
        {t('signIn.header')}
      </Typography>
      {has(errors, 'base') && <StackAlert alerts={errors['base']} sx={{mb: 2}} />}

      <TextField
        fullWidth
        autoFocus
        margin='normal'
        variant="standard"
        label={t('signIn.email')}
        value={email}
        onChange={event => setValue('email', event.target.value)}
        onKeyPress={onEnter}
        {...errorField('email', email, errors)}
      />

      <FormInput
        label={t('signIn.password')}
        formControlProps={{
          variant: 'standard',
          error: isPasswordError
        }}
      >
        <Input
          data-testid='password'
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={event => setValue('password', event.target.value)}
          onKeyPress={onEnter}
          error={isPasswordError}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {isPasswordError &&
          <FormHelperText error={true}>
            {errorMessage('password', password, errors)}
          </FormHelperText>
        }
      </FormInput>

      <Container className={classes.buttonWrapper}>
        <LoadingButton
          size="large"
          variant="contained"
          onClick={signIn}
          loading={isLoading}
          className={classes.button}
          sx={{ fontSize: 'h6.fontSize' }}
        >
          {t('signIn.signIn')}
        </LoadingButton>

        <Link to={forgotPasswordLink}>
          <Typography align="center" variant="h6">
            {t('signIn.forgotYourPassword')}
          </Typography>
        </Link>
      </Container>

      {showSignUpPage && (
        <Typography align="center" variant="h6">
          {t('signIn.dontHaveAccount')}
          <Link to={signUpLink}>{t('signIn.signUp')}</Link>
        </Typography>
      )}
    </AuthenticationWrapper>
  )
})

export default SignIn
