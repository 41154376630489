/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import {flow, Instance, SnapshotOut, types} from "mobx-state-tree"

import {withEnvironment, withGeneralError, withRootStore } from "models/extensions"
import {ApiResult} from "services/api";

/**
 * Model description here for TypeScript hints.
 */
export const RedeemCodeModel = types
  .model("RedeemCode")
  .extend(withRootStore)
  .extend(withEnvironment)
  .extend(withGeneralError)
  .actions(self => ({
    sendCode: flow(function * (code: string) {
      try {
        const res: ApiResult = yield self.environment.redeemCodeApi.save({ code })
        if (res.kind === "ok") {
          const { companyStore: { setCompany } } = self.rootStore
          setCompany(res.data)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }))

type RedeemCodeType = Instance<typeof RedeemCodeModel>
export interface RedeemCode extends RedeemCodeType {}
type RedeemCodeSnapshotType = SnapshotOut<typeof RedeemCodeModel>
export interface RedeemCodeSnapshot extends RedeemCodeSnapshotType {}
