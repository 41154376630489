import React from "react";
import {Chip} from "@mui/material";
import {useTranslation} from "react-i18next";

import {colors} from "assets";

export const ComboCuppingFlag = () => {
  const { t } = useTranslation()

  return (
    <Chip
      label={t(`cuppingSession.comboCupping`)}
      variant="filled"
      color='primary'
      size='small'
      sx={{
        backgroundColor: colors.primary.o25,
        color: colors.primary.main,
        fontWeight: 'bold',
      }}
    />
  )
}
