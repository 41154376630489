import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import {centered, colors} from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileWrapper: {
      position: 'relative',
      border: 'none',
      borderRadius: 0,
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
      borderBottom: `1px solid ${colors.border.primary}`,
      [theme.breakpoints.up('md')]: {
        borderRadius: 5,
        border: `1px solid ${colors.border.primary}`,
      },
    },
    mobileButtonWrapper: {
      position: 'absolute',
      top: -7,
      right: 20,
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        textAlign: 'right',
        top: 'unset',
        right: 'unset',
      }
    },
    mobileButtonWrapperExpand: {
      position: 'absolute',
      right: 20,
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        textAlign: 'right',
        top: 'unset',
        right: 'unset',
      }
    },
    iconButton: {
      margin: 0,
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark
      }
    },
    firstRow: {
      alignItems: 'center',
    },
    mobileFirstRow: {
      alignItems: 'center',
      marginTop: theme.spacing(-3),
      marginLeft: theme.spacing(-1)
    },
    mobileRowDetail: {
      alignItems: 'center',
      marginTop: theme.spacing(-3),
      marginBottom: theme.spacing(-3),
      marginLeft: theme.spacing(3),
    }
  })
)
