import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {colors} from "assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      padding: '8px 16px',
      height: '70px',
      borderRadius: 4,
      background: colors.primary.o25,

      '& button': {
        background: 'transparent',
        color: colors.primary.main,
        borderRadius: 4,
        border: 'none',
        fontWeight: 600,
        width: '24%',
        height: '50px',
        minHeight: 'unset',

        '&.Mui-selected': {
          background: 'white',
          color: colors.primary.main
        }
      },

      '& svg': {
        width: 20,
        height: 20,
        fill: colors.primary.main,
      },

      '& .MuiTabs-indicator': {
        display: 'none'
      }
    }
  })
)
