import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: theme.spacing(1),
      minWidth: 'fit-content'
    },
    table: {
      '& .MuiTableHead-root': {
        backgroundColor: '#E0D1EA',
      },
      '& .MuiTableCell-root': {
        padding: theme.spacing(1),

        '&:first-child': {
          borderLeft: `1px solid ${theme.palette.divider}`,
        }
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '&:nth-of-type(even)': {
            backgroundColor: '#EBE8F2',
          }
        },

        '& .MuiTableCell-root': {
          borderRight: `1px solid ${theme.palette.divider}`,
        }
      }
    }
  })
)
