/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-01 17:30:06
 * @modify date 2022-03-01 17:30:06
 */

import React from 'react'
import { toNumber } from 'lodash'
import { useTranslation } from 'react-i18next'
import {Card, Typography, Box, Select, MenuItem, InputAdornment, Stack} from '@mui/material'
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'

import { Descriptor } from 'components'
import { cupOptions, intensityOptions, coeIntensityOptions, coeFactor } from 'constants/form'

import { useStyles } from './defect.styles'
import { DefectProps } from './defect.props'
import {isMobile} from "react-device-detect";

export const Defect: React.FC<DefectProps> = (props: DefectProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { cups, fiveCups, intensity, score, isCoe, onChange, setCup, setIntensity, ...rest } = props
  const intensityList = isCoe ? coeIntensityOptions : intensityOptions

  React.useEffect(() => {
    if (!fiveCups) return

    let result = cups * intensity
    if (isCoe) result *= coeFactor
    onChange(result)
  }, [cups, intensity])

  return (
    <Descriptor
      key='defect'
      {...rest}
    >
      {!fiveCups ?
        <Box className={classes.scoreText}>
          <Typography variant='body3' mr={1}>{t('descriptor.score')}</Typography>
          <CurrencyTextField
            fullWidth
            type="text"
            size="small"
            inputMode="numeric"
            variant="outlined"
            textAlign="left"
            currencySymbol=""
            value={score}
            outputFormat="number"
            decimalCharacter="."
            digitGroupSeparator=","
            minimumValue="0"
            decimalPlaces={0}
            onChange={(e, value) => onChange(value)}
            InputProps={{
              startAdornment: (<InputAdornment position="start">-</InputAdornment>)
            }}
          />
        </Box> :
        <Box className={classes.calculationWrapper}>
          <Stack direction={isCoe ? 'row' : 'column'} alignItems={isCoe ? 'center' : 'start'} spacing={1}>
            {isCoe ?
              <Box className={classes.coeTextWrapper}>
                <Typography variant='body3' component="p"># x i x {coeFactor} = {t('descriptor.score')}</Typography>
                <Typography variant='body3' component="p">{'i = <1> - <3>'}</Typography>
              </Box> :
              <Box>
                <Typography variant='body3' sx={{mr: 7}}>#{t('descriptor.cups')}</Typography>
                <Typography variant='body3'>{t('descriptor.intensity')}</Typography>
              </Box>
            }
            <Box>
              <Select
                size='small'
                value={cups}
                onChange={(e) => setCup(toNumber(e.target.value))}
                sx={{mr: 1}}
              >
                {cupOptions.map(n =>
                  <MenuItem key={n} value={n}>{n}</MenuItem>
                )}
              </Select>
              <Typography variant='body3' sx={{mr: 1}}>x</Typography>
              <Select
                size='small'
                value={intensity}
                onChange={(e) => setIntensity(toNumber(e.target.value))}
                sx={{mr: 1}}
              >
                {intensityList.map(n =>
                  <MenuItem key={n} value={n}>{n}</MenuItem>
                )}
              </Select>
              <Typography variant='body3' sx={{mr: 1}}>x</Typography>
              {isCoe &&
                <Card className={classes.score} sx={{mr: 1}}>{coeFactor}</Card>
              }
              <Typography variant='body3' sx={{mr: 1}}>=</Typography>
              <Card className={classes.score}>{score}</Card>
            </Box>
          </Stack>
          {!isCoe &&
            <Box className={classes.hintWrapper}>
              <Typography variant='body3' sx={{mr: 5}} component='span'>{t('descriptor.faint')} = 2</Typography>
              <Typography variant='body3' component='span'>{t('descriptor.taint')} = 4</Typography>
            </Box>
          }
        </Box>
      }
    </Descriptor>
  )
}
