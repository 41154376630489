/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withGeneralError, withReset, withRootStore, withEnvironment, withSetValue
} from "models/extensions"
import {
  ShipmentOrderFormModel,
  shipmentOrderFormPreProcessor,
  shipmentOrderFormProps
} from "models/shipment-order-form";

import {
  sampleProps,
  sampleTransactionItemProps,
  shipmentInformationProps,
} from "./sample-order.props"
import {withSampleOrderActions} from "./sample-order-action";
import {withSampleOrderViews} from "./sample-order.view";
import {filterModel} from "./filter-model";

/**
 * Model description here for TypeScript hints.
 */
export const SampleOrderStoreModel = types
  .model("SampleOrderStore")
  .props({
    samplesToBeShipment: types.optional(
      types.array(types.model(sampleProps)),
      []
    ),
    shipmentInformation: types.optional(types.model(shipmentInformationProps), shipmentOrderFormPreProcessor({})),
    sampleOrders: types.optional(types.array(types.model({
      ...shipmentOrderFormProps,
      tastifyOrderNumber: types.string,
      fulfillment: types.maybeNull(types.string),
      fulfillmentStatus: types.string,
      sampleTransactionItems: types.optional(
        types.array(types.model(sampleTransactionItemProps)),
        []
      ),
      numberOfSamples: types.number,
      uniqueToken: types.string,
      createdAt: types.string,
      isSubmitted: types.boolean,
      orderNumber: types.maybeNull(types.string),
      isRequestedNewSample: types.boolean,
      country: types.maybeNull(types.string),
      customer: types.maybeNull(types.string),
      purchaseContractReference: types.maybeNull(types.string),
      salesContractReference: types.maybeNull(types.string),
      sampleOrderNumber: types.maybeNull(types.string),
    })), []),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0),
    sort: types.optional(types.string, ''),
    filter: types.optional(filterModel, {}),
  })
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withEnvironment)
  .extend(withSampleOrderActions)
  .extend(withSampleOrderViews)

type SampleOrderStoreType = Instance<typeof SampleOrderStoreModel>
export interface SampleOrderStore extends SampleOrderStoreType {}
type SampleOrderStoreSnapshotType = SnapshotOut<typeof SampleOrderStoreModel>
export interface SampleOrderStoreSnapshot extends SampleOrderStoreSnapshotType {}
