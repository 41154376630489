/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-01 00:50:29
 * @modify date 2022-03-01 00:50:29
 */
import React from 'react'
import {
  Box,
  Typography,
  Slider,
  Card
} from '@mui/material'

import { useStyles } from './descriptor-slide.styles'
import { DescriptorSlideProps } from './descriptor-slide.props'

export const DescriptorSlide: React.FC<DescriptorSlideProps> = (props: DescriptorSlideProps) => {
  const classes = useStyles()
  const { label, value, onChange, step = 1, min = 0, max = 5 } = props

  return (
    <Box>
      <Box className={classes.wrapper}>
        <Typography variant='body2'>{label}</Typography>
        <Card className={classes.value}>
          {value}
        </Card>
      </Box>
      <Slider
        value={value}
        onChange={onChange}
        step={step}
        min={min}
        max={max}
        className={classes.slider}
      />
    </Box>
  )
}
