import React from 'react'
import {filter, includes, isNull} from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from "react-i18next"
import { isMobile } from "react-device-detect"
import {Card, Grid, TextField, Typography, Box, Button, Stack, FormControl, FormHelperText} from '@mui/material'
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'

import {useStores} from "models"
import { flavorDescriptorList } from 'constants/flavor-descriptors'
import { sucafinaTastingForm } from 'constants/form'
import { colors } from 'assets'
import { Descriptor } from 'components'

export const SucafinaTasting: React.FC = observer(() => {
  const { t } = useTranslation()

  const { sampleStore: {
    selectedSample: { id, sampleId, selectedScore: {
      emoticons, interestStatus, notes,
      tastingDescriptors, tastingScore,
      setScoreValue,
    }}
  },
  } = useStores()

  const addOrRemoveEmoticon = (value) => {
    const newEmoticons = includes(emoticons, value)
      ? filter(emoticons, emo => emo !== value)
      : [...emoticons, value]

    setScoreValue('emoticons', newEmoticons)
  }

  return (
    <Grid container spacing={isMobile ? 0 : 2}>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`descriptor${tastingDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.tasting')}
          descriptors={tastingDescriptors}
          descriptorList={flavorDescriptorList}
          addDescriptor={(values) => setScoreValue('tastingDescriptors', values)}
        >
          <Box display="flex">
            <Typography variant='h6' fontSize={isMobile ? '12px': '14px'} mt={2}>{t('descriptor.score')}</Typography>
            <FormControl size="small" sx={{ m: 1, width: isMobile ? 175 : 225 }}>
              <CurrencyTextField
                key={`tastingScore${id}`}
                fullWidth
                size='small'
                type='text'
                inputMode='numeric'
                variant='outlined'
                textAlign='left'
                currencySymbol=''
                value={tastingScore}
                outputFormat="number"
                decimalCharacter="."
                digitGroupSeparator=","
                minimumValue="0"
                maximumValue="100"
                decimalPlaces={2}
                onChange={(event, value)=> setScoreValue('tastingScore', value)}
              />
              <FormHelperText sx={{color: colors.primary.main}}>{t('descriptor.minMaxScoreDescription')}</FormHelperText>
            </FormControl>
          </Box>
        </Descriptor>
      </Grid>
      <Grid item xs={12} md={8}>
        <Card sx={{px: isMobile ? 2 : 4, py: isMobile ? 1 : 3}}>
          <Typography variant='h6' sx={{mb: 2}}>{t('sampleScore.howDidYourFeel')}</Typography>
          <Typography variant='body2' sx={{mb: 2}}>{t('sampleScore.selectEmojis')}</Typography>

          <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" mb={3}>
            {sucafinaTastingForm.map(st => {
              const include = includes(emoticons, st.value)

              return (
                <Button
                  variant='outlined'
                  color={include ? 'secondary' : 'primary' }
                  onClick={() => addOrRemoveEmoticon(st.value)}
                  sx={{borderColor: include ? 'secondary.main' : 'primary.main'}}
                >
                  <st.emoticon width={24} height={24} opacity={include ? 1 : 0.5} />
                </Button>
              )
            })}
          </Stack>

          <Box>
            <Typography variant='h6' sx={{mb: 2}}>{t('sampleScore.IdBeInterested')}</Typography>
            <Stack direction="row" spacing={2}>
              <Button
                variant={interestStatus ? 'contained' : 'outlined'}
                onClick={() => setScoreValue('interestStatus', true)}
              >
                {t('options.boolean.true')}
              </Button>
              <Button
                variant={!isNull(interestStatus) && !interestStatus ? 'contained' : 'outlined'}
                onClick={() => setScoreValue('interestStatus', false)}
              >
                {t('options.boolean.false')}
              </Button>
            </Stack>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{px: isMobile ? 2 : 4, py: isMobile ? 1 : 3}}>
          <Typography variant='h6' sx={{mb: 2}}>{t('descriptor.notes')}</Typography>
          <TextField
            fullWidth
            multiline
            minRows={isMobile ? 2 : 4}
            value={notes}
            onChange={(e) => setScoreValue('notes', e.target.value)}
          />
        </Card>
      </Grid>
    </Grid>
  )
})
