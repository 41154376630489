import React from "react";
import {FormControlProps, MenuItem, Select} from "@mui/material";

import {courierNames} from "constants/form";
import {FormInput} from "components";

interface CourierSelectionProps {
  label?: string;
  value?: string;
  onChange: (value: string) => void;
  formControlProps?: FormControlProps
}

export const CourierSelection = (props: CourierSelectionProps) => {
  const { label, value, onChange, formControlProps } = props;

  return (
    <FormInput label={label} formControlProps={formControlProps}>
      <Select
        size='small'
        defaultValue=""
        value={value ?? ''}
        onChange={(e) => onChange(e.target.value)}
      >
        <MenuItem value='' sx={{ height: 34 }} />
        {courierNames.map(item =>
          <MenuItem key={item} value={item}>{item}</MenuItem>
        )}
      </Select>
    </FormInput>
  );
}
