import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {colors} from "assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 3,
      marginBottom: 24,
      marginTop: 24,
    },
    date: {
      '& svg': {
        fill: colors.body.primary
      },
      '& .MuiOutlinedInput-root': {
        color: 'white',
        '& fieldset': {
          borderColor: colors.primary.main,
        },
        '&:hover fieldset': {
          borderColor: colors.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.primary.main,
        }
      }
    }
  })
)
