import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {colors} from "assets";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dataGrid: {
			'& .MuiDataGrid-columnHeader': {
				borderRight: `1px solid ${colors.magnolia}`,
				backgroundColor: colors.magnolia,
				fontWeight: 'bold',
			},
			'& .MuiDataGrid-cell': {
				borderRight: `1px solid ${colors.magnolia}`,
					backgroundColor: 'white',
			},
		},
		buttonWrapper: {
			display: 'flex',
			justifyContent: 'flex-end',
			gap: theme.spacing(2),
			marginTop: theme.spacing(2),
		}
	})
)
