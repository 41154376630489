/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-09 20:50:30
 * @modify date 2022-02-09 20:50:30
 */

import React from 'react'
import clsx from 'clsx'
import { Helmet } from "react-helmet"
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {
  debounce,
  includes,
  toNumber,
  chain,
  indexOf,
  isNaN,
  isNull,
  isEmpty,
  every,
  filter,
  find,
  clone,
  pull,
  remove,
  camelCase
} from 'lodash'
import { Clear as ClearIcon, AddCircleOutline, ExpandMore, ExpandLess } from '@mui/icons-material'
import {
  Container, Button, FormControlLabel, Checkbox, Grid, Typography, Box, Card,
  IconButton, Collapse, SvgIcon, CircularProgress,
  AppBar,
  SwipeableDrawer, Stack
} from '@mui/material'
import moment from "moment";

import { useStores } from 'models'
import { findRoute } from 'constants/routes'
import { HeaderTitle, ModalWrapper, FormInput, Pagination } from 'components'
import {
  IconSampleName,
  IconSampleProccess,
  IconCropYear,
  IconCountryOfOrigin,
  IconBean,
  IconFragrance,
  IconStartCupping,
  IconAftertaste,
  IconAcidity,
  IconBody,
  IconBalance,
  IconOverall,
  IconDefects,
  IconUniformity,
  IconCleanCup,
  IconSweetness,
  IconDefect,
  IconWeight,
  Logo,
  IconCup,
  IconSampleType, IconRoastLevel, IconAverageScore, IconScoreRange
} from 'assets/images'
import { DEBOUNCE_TIME } from 'config/env'
import {printPage, flavorWheel, globalAction, allDescriptorTranslation, countryName} from 'utils'
import { centered, colors } from 'assets'
import {defects as defectsList} from "constants/form";

import { useStyles } from './compare.styles'
import { SampleItemMobile } from './components/sample-item-mobile/sample-item-mobile'
import { AdvanceSearch } from './components/advance-search/advance-search'

export const Compare: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const reviewSamplesLink = findRoute('reviewSamples')
  const {
    userStore: { languagePreference },
    compareStore: { ids, cuppingProtocol, samples, sampleNames, sampleCountries, sampleTypes, sampleCropYears,
      fragrance, fragranceDescriptors, aroma, salt, saltDescriptors, bittersweet,
      flavor, flavorDescriptors, aftertaste, aftertasteDescriptors, acidity, acidityDescriptors,
      body, bodyDescriptors, balance, balanceDescriptors, mouthfeel, mouthfeelDescriptors,
      overall, uniformity, cleanCup, sweetness, cleanCupScore, sweetnessScore, nonUniform,
      defects, greenGradingColor, greenGradingWeight, greenGradingSmell, greenGradingDefects, processingMethods,
      roastLevels, averageScores, scoreRanges, getSample, clearSample, setValue: setCompareValue, reset: resetCompare },
    reviewSampleStore: { searchValues,samples: sampleList, getSamples, search: { name, setValue: setSearchValue }, setValue: setReviewSampleValue, totalPage, page,},
    companyStore: { base64Logo, isEnterprisePlus },
    notificationStore
  } = useStores()

  const [openSampleInfo, setOpenSampleInfo] = React.useState(false)
  const [openDescriptorInfo, setOpenDescriptorInfo] = React.useState(false)
  const [openGreenGradingInfo, setOpenGreenGradingInfo] = React.useState(false)
  const [openDefectsInfo, setOpenDefectsInfo] = React.useState(false)
  const [isPrinting, setIsPrinting] = React.useState(false)
  const printElement = React.createRef<Element | undefined>()
  const [isSearch, setIsSearch] = React.useState(false)
  const [openSearch, setOpenSearch] = React.useState(false)
  const [loadingSample, setLoadingSample] = React.useState(false)
  const [highlightDifference, setHighlightDifference] = React.useState(false)
  const [column, setColumn] = React.useState(4)
  const [openWheel, setOpenWheel] = React.useState(false)
  const [wheelData, setWheelData] = React.useState('')
  const [titleSample, setTitleSample] = React.useState('')
  const [tempIds, setTempIds] = React.useState<any[]>([])
  const [isMobile, setIsMobile] = React.useState(false)
  const [idSample, setIdSample] = React.useState<String[]>([])
  const [temporaryAcidityAndSaltDescriptors, setTemporaryAcidityAndSaltDescriptors] = React.useState<any[]>([])
  const [temporaryBodyAndMouthfeelDescriptors, setTemporaryBodyAndMouthfeelDescriptors] = React.useState<any[]>([])
  const [temporaryFragranceDescriptors, setTemporaryFragranceDescriptors] = React.useState<any[]>([])
  const [temporaryFlavorAndAftertasteDescriptors, setTemporaryFlavorAndAftertasteDescriptors] = React.useState<any[]>([])
  const [temporaryDefectsDescriptors, setTemporaryDefectsDescriptors] = React.useState<any[]>([])
  const [temporaryListSearch, setTemporaryListSearch] = React.useState<any[]>([])
  const [expanded, setExpanded] = React.useState(false)
  const [searchAdvanceData,setSearchAdvanceData] = React.useState<any[]>([])
  const [cuppingProtocols, setCuppingProtocols] = React.useState<String[]>([])
  const [reservedSamples, setReservedSamples] = React.useState<any[]>([])

  const sampleInformations = [
    {
      icon: IconSampleName,
      name: t('sample.sampleName'),
      values: sampleNames
    },
    {
      icon: IconCountryOfOrigin,
      name: t('sample.countryOfOrigin'),
      values: sampleCountries
    },
    {
      icon: IconSampleType,
      name: t('sample.sampleType'),
      values: sampleTypes
    },
    {
      icon: IconSampleProccess,
      name: t('sample.processingMethod'),
      values: processingMethods
    },
    {
      icon: IconCropYear,
      name: t('sample.harvestYear'),
      values: sampleCropYears
    }
  ]

  const descriptors = [
    {
      icon: IconRoastLevel,
      name: t('descriptor.roastLevel'),
      values: roastLevels,
    },
    {
      icon: IconAverageScore,
      name: t('descriptor.averageScore'),
      values: averageScores,
    },
    {
      icon: IconScoreRange,
      name: t('descriptor.scoreRange'),
      values: scoreRanges,
    },
    {
      icon: IconFragrance,
      name: t('descriptor.fragranceOrAroma'),
      values: fragrance,
      descriptors: fragranceDescriptors
    },
    {
      icon: IconAcidity,
      name: t('descriptor.saltOrAcid'),
      values: salt,
      descriptors: saltDescriptors
    },
    {
      icon: IconBean,
      name: t('descriptor.bitterOrSweet'),
      values: bittersweet,
    },
    {
      icon: IconStartCupping,
      name: t('descriptor.flavor'),
      values: flavor,
      descriptors: flavorDescriptors
    },
    {
      icon: IconAftertaste,
      name: t('descriptor.aftertaste'),
      values: aftertaste,
      descriptors: aftertasteDescriptors
    },
    {
      icon: IconAcidity,
      name: t('descriptor.acidity'),
      values: acidity,
      descriptors: acidityDescriptors
    },
    {
      icon: IconBody,
      name: t('descriptor.body'),
      values: body,
      descriptors: bodyDescriptors
    },
    {
      icon: IconBalance,
      name: t('descriptor.balance'),
      values: balance,
      descriptors: balanceDescriptors
    },
    {
      icon: IconCup,
      name: t('descriptor.mouthfeel'),
      values: mouthfeel,
      descriptors: mouthfeelDescriptors
    },
    {
      icon: IconOverall,
      name: t('descriptor.overall'),
      values: overall
    },
    {
      icon: IconUniformity,
      name: t('descriptor.uniformity'),
      values: uniformity
    },
    {
      icon: IconCleanCup,
      name: t('descriptor.cleanCup'),
      values: cleanCup || cleanCupScore
    },
    {
      icon: IconSweetness,
      name: t('descriptor.sweetness'),
      values: sweetness || sweetnessScore
    },
    {
      icon: IconDefects,
      name: t('descriptor.defects'),
      values: defects
    },
  ]

  const scaDescriptor = [
    {
      icon: IconFragrance,
      name: t('descriptor.fragrance'),
      values: fragrance,
    },
    {
      icon: IconFragrance,
      name: t('descriptor.aroma'),
      values: aroma,
    },
    {
      icon: IconStartCupping,
      name: t('descriptor.flavor'),
      values: flavor,
    },
    {
      icon: IconAftertaste,
      name: t('descriptor.aftertaste'),
      values: aftertaste,
    },
    {
      icon: IconAcidity,
      name: t('descriptor.acidity'),
      values: acidity,
    },
    {
      icon: IconSweetness,
      name: t('descriptor.sweetness'),
      values: sweetnessScore
    },
    {
      icon: IconCup,
      name: t('descriptor.mouthfeel'),
      values: mouthfeel,
    },
    {
      icon: IconOverall,
      name: t('descriptor.overall'),
      values: overall
    },
    {
      icon: IconDefects,
      name: t('descriptor.nonUniformCups'),
      values: nonUniform
    },
    {
      icon: IconDefects,
      name: t('descriptor.defects'),
      values: defects
    },
  ]

  const greenGradings = [
    {
      icon: IconBean,
      name: t('greenGrading.color'),
      values: greenGradingColor
    },
    {
      icon: IconWeight,
      name: t('greenGrading.weight'),
      values: greenGradingWeight
    },
    {
      icon: IconFragrance,
      name: t('greenGrading.smell'),
      values: greenGradingSmell
    }
  ]

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenSearch(newOpen);
    setReviewSampleValue('page', 1)
  };

  const renderMobile = () =>  {

    return(
      <SwipeableDrawer
       anchor="bottom"
       open={openSearch}
       onClose={toggleDrawer(false)}
       onOpen={toggleDrawer(true)}
       swipeAreaWidth={56}
       disableSwipeToOpen={false}
       ModalProps={{
         keepMounted: true,
       }}
     >
      <Box sx={{
        height: '80vh', 
        mt: 3,
        }}>
      <Box sx={{
        width: 30,
        height: 6,
        backgroundColor: colors.primary.main,
        borderRadius: 3,
        position: 'absolute',
        top: 8,
        left: 'calc(50% - 15px)',
      }}/>
         {sampleList.map((sample, index) => { 
           return(
             <SampleItemMobile
               {...sample}
               ids={idSample}
               onCheck={() => {
                setCompareValue('ids', [...ids, sample.id])
                onCheckMobile(sample.id)
              }}
               onGetSamples={onGetSample}
             />
           )}
           
         )}
         </Box>
          <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0}}>
           
              <Box
               sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 1
              }}
              >
               {totalPage > 0 && <Pagination page={page} count={totalPage} siblingCount={1} onChange={(e, p) => setReviewSampleValue('page', p)} /> }
               <Button
                  size="medium"
                  variant="contained"
                  sx={{ backgroundColor: colors.primary.main }}
                  fullWidth
                  onClick={() => {
                    setExpanded(!expanded)
                    handleChangeCategory()
                  }}
                >
                   {t('sample.advanceSearch')}
                </Button>
               <Box
                sx={{
                  display: "flex",
                  justifyContent: 'space-between',
                  flexDirection: "row",
                  mb: 1,
                  mt: 1,
                  width: '100%',
                }}
              >
                <Button
                  size="medium"
                  variant="contained"
                  sx={{ backgroundColor: colors.primary.main, mr: 0.5}}
                  onClick={toggleDrawer(false)}
                  fullWidth
                >
                  {t("common.submit")}
                </Button>
                <Button
                  size="medium"
                  variant="outlined"
                  sx={{ ml: 0.5}}
                  fullWidth
                  onClick={toggleDrawer(false)}
                >
                  {t("common.cancel")}
                </Button>
            </Box>
            </Box>

           
           </AppBar>
       </SwipeableDrawer>
     
    )
  }

  const onSetLayout = () => {
    if(window.innerWidth < 900) {
      setIsMobile(true)
    
      return
    }

    setIsMobile(false)
  }

  const onPrint = () => {
    setColumn(ids.length)
    setOpenSampleInfo(true)
    setOpenDescriptorInfo(true)
    setOpenGreenGradingInfo(true)
    setOpenDefectsInfo(true)
    setIsPrinting(true)
  }

  const onGetSample = (id: string) => globalAction(notificationStore, { action: getSample(id) })

  const loadSamples = () => {
    setCompareValue('samples', [])
    ids.map((id) => onGetSample(id))
  }

  const onSearch = React.useCallback(
    debounce(async (action) => {
      setLoadingSample(true)
      setReviewSampleValue('page', 1)

      try{
        await action()
      } finally {
        setIsSearch(true)
        setLoadingSample(false)
      }
    }, DEBOUNCE_TIME), []
  )

  const addSamples = () => {
    setTempIds([])
    setIsSearch(false)
    setOpenSearch(true)
    setSearchValue('name', '')
    setReviewSampleValue('samples', [])
  }

  const checkSample = (id) => {
    if (includes(tempIds, id)) {
      const newIds = filter(tempIds, i => { return i !== id})
      setTempIds(newIds)
      return
    }

    setTempIds([...tempIds, id])
  }

  const handleChangeCategory = () => {
    temporaryListSearch.map(item =>
      Object.keys(searchValues).forEach(k => {
        if(k !== item )
        setTemporaryListSearch(current =>
          current.filter(data => {
            return data !== item;
          }),
        );
      })
    )
  }

  const headerCard = (sample, index = -1) => {
    if (sample) {
      return (
        <>
          <Card className={classes.wheelInfo}>
            {sample.sampleInfo.name}
          </Card>
          <Card className={classes.headerCard}>
            {!isPrinting &&
              <Box sx={{textAlign: 'right', display: {sm: 'none', md: 'block'}}}>
                <IconButton size="small" onClick={() => clearSample(index)}>
                  <ClearIcon color='error'/>
                </IconButton>
              </Box>
            }
            <Box className={classes.imageWrapper} onClick={() => {
              setOpenWheel(true)
              setWheelData(sample.wheelData)
              setTitleSample(sample.sampleInfo.name)
              }}
            >
              <Box id={`tastify-wheel-${index}`} className={classes.wheel}></Box>
            </Box>
            <Typography variant='h4' align='center'>{sample.cachedAverageScore || '-'}</Typography>
          </Card>
        </>
      )
    }

    return (
      <Card className={classes.headerCard}>
        <Box sx={{textAlign: 'center'}}>
          <IconButton onClick={addSamples}>
            <AddCircleOutline color='primary' sx={{width: 50, height: 50}}/>
          </IconButton>
        </Box>
      </Card>
    )
  }

  const renderInfoItem = (item: any, index: number) => {
    const { icon, name, values, descriptors } = item
    const numberValues = chain(values).map(value => { return toNumber(value)}).sortBy().value()
    const n = column - ids.length

    const nullValue = every(values, v => isNull(v) || isEmpty(v.toString()))

    const defectCheck = isEmpty(defectsList.find(x => x.id === item.id))

    if (nullValue) return

    return (
      <Grid container key={index}>
        <Grid item xs={12} md className={classes.infoItemHeader}>
          <SvgIcon component={icon} color={!defectCheck ? item.type : "primary"} inheritViewBox />
          <Typography sx={{ml: 1, fontWeight: 600}}>{name}</Typography>
        </Grid>
        {[...Array(column)].map((_, i) =>
          <Grid
            item
            key={i}
            xs={12}
            md
            className={clsx(classes.infoItem, highlightDifference && !isNaN(toNumber(values[i])) && values[i] && `color-${indexOf(numberValues, toNumber(values[i])) + n}`, !values[i]?.toString() && 'no-content')}
          >
            <Box className={classes.infoItemName}>
              <Typography variant='body1'>{samples[i]?.sampleInfo?.name}</Typography>
            </Box>
            <Box className={classes.infoItemValue}>
              <Typography variant='h6' paragraph>{values[i]}</Typography>
              {descriptors && allDescriptorTranslation(languagePreference, descriptors[i])}
            </Box>
          </Grid>
        )}
      </Grid>
    )
  }

  const renderSearchSampleModal = (
    <ModalWrapper
      open={openSearch}
      onClose={() => {
        setOpenSearch(false)
        setExpanded(false)
      }}
    >
      <Typography variant='h6'>{t('compare.addSamplesToCompare')}</Typography>
      <Typography variant='body2'>{t('compare.maxSamplesToCompare')}</Typography>
      <Stack direction="row" spacing={1} justifyContent="end" sx={{mb: 3}}>
        <Button
          size='large'
          variant='contained'
          onClick={() => {
            setExpanded(!expanded)
            handleChangeCategory()
          }}
        >
          {t('sample.advanceSearch')}
        </Button>
      </Stack>
      <Container sx={{mb: 3}}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <AdvanceSearch
          isMobile={isMobile}
          expanded={expanded}
          temporaryDefectsDescriptors = {temporaryDefectsDescriptors}
          temporarySearchAdvanceData = {searchAdvanceData}
          temporaryFlavorAndAftertasteDescriptors = {temporaryFlavorAndAftertasteDescriptors}
          temporaryFragranceDescriptors = {temporaryFragranceDescriptors}
          temporaryBodyAndMouthfeelDescriptors = {temporaryBodyAndMouthfeelDescriptors}
          temporaryAcidityAndSaltDescriptors = {temporaryAcidityAndSaltDescriptors}
          temporaryListSearch = {temporaryListSearch}
          addTemporarySearchAdvanceData={setSearchAdvanceData}
          addTemporaryDefectsDescriptors={setTemporaryDefectsDescriptors}
          addTemporaryFlavorAndAftertasteDescriptors={setTemporaryFlavorAndAftertasteDescriptors}
          addTemporaryFragranceDescriptors={setTemporaryFragranceDescriptors}
          addTemporaryBodyAndMouthfeelDescriptors={setTemporaryBodyAndMouthfeelDescriptors}
          addTemporaryAcidityAndSaltDescriptors={setTemporaryAcidityAndSaltDescriptors}
          addTemporaryListSearch={setTemporaryListSearch}
          onSearch={() => {
            setSearchValue('generalSearch', false)
            setReviewSampleValue('page', 1)
            handleChangeCategory()
            getDataSample()
          }}
          onCancel={() => {
            getDataSample()
            setExpanded(false)
            window.scrollTo(0, 0)
            setTemporaryDefectsDescriptors([])
            setTemporaryFlavorAndAftertasteDescriptors([])
            setTemporaryFragranceDescriptors([])
            setTemporaryBodyAndMouthfeelDescriptors([])
            setTemporaryAcidityAndSaltDescriptors([])
            setTemporaryListSearch([])
          }}
        />
      </Collapse>
      </Container>
      {isEmpty(sampleList) && isSearch &&
        <Box sx={{...centered, p: 3}}>
          <Typography variant='h6'>{t('sample.sampleNotFound')}</Typography>
        </Box>
      }
      <Grid container sx={{mb: 3, alignItems: 'center'}}>
        <Grid container alignItems='center'>
          <Grid item md={2} display="flex" className={classes.textCenter}>
            {t('sample.samples.id')}
          </Grid>
          <Grid item md={10}>
            <Grid container>
              <Grid item xs className={classes.textCenter}>{t('sample.samples.name')}</Grid>
              <Grid item xs className={classes.textCenter}>{t('sample.samples.createdOn')}</Grid>
              <Grid item xs className={classes.textCenter}>{t('sample.samples.species')}</Grid>
              <Grid item xs className={classes.textCenter}>{t('sample.samples.type')}</Grid>
              <Grid item xs className={classes.textCenter}>{t('sample.samples.origin')}</Grid>
              <Grid item xs className={classes.textCenter}>{t('sample.samples.process')}</Grid>
              <Grid item xs className={classes.textBold}>{t('sample.samples.salesContract')}</Grid>
              <Grid item xs className={classes.textBold}>{t('sample.samples.averageScore')}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {sampleList.map((sample) => {
        const disableIds = includes(ids, sample.id) || (ids.length + tempIds.length) >= column
        return(
          <Card className={classes.wrapperTable}>
          <Grid container alignItems='center'>
            <Grid item md={2} display="flex">
              <Checkbox
                disabled={disableIds && !includes(tempIds, sample.id)}
                onClick={() => checkSample(sample.id)}
              />
              {isEmpty(sample.sampleUniqueNumber) ? "-" : sample.sampleUniqueNumber}
            </Grid>
            <Grid item md={10}>
              <Grid container>
                <Grid item xs className={classes.textCenter}>{sample.name}</Grid>
                <Grid item xs className={classes.textCenter}>{moment(sample.createdAt).format('DD/MM/YYYY')}</Grid>
                <Grid item xs className={classes.textCenter}>{sample.species && t(`options.cuppingProtocol.${camelCase(sample.species)}`)}</Grid>
                <Grid item xs className={classes.textCenter}>{!isEmpty(sample.sampleType) && t(`options.sampleType.${camelCase(sample.sampleType)}`)}</Grid>
                <Grid item xs className={classes.textCenter}>{countryName(sample.countryCode)}</Grid>
                <Grid item xs className={classes.textCenter}>{sample.processName}</Grid>
                <Grid item xs className={classes.textBold}>{sample.salesContractReference}</Grid>
                <Grid item xs className={classes.textBold}>{(sample.averageScore === 0 || isNull(sample.averageScore)) ? "-" : sample.averageScore}</Grid>
              </Grid>
            </Grid>
          </Grid>
          </Card>
        )}
      )}
      {totalPage > 0 && <Pagination page={page} count={totalPage} siblingCount={1} onChange={(e, p) => setReviewSampleValue('page', p)} /> }
      <Box
        sx={{
          display: "flex",
          justifyContent: 'flex-end',
          flexDirection: "row",
          mb: 1,
          mt: 1,
          width: '100%',
        }}
      >
        <Button
          size="medium"
          variant="contained"
          sx={{ backgroundColor: colors.primary.main, mr: 0.5}}
          onClick={() => {
            setOpenSearch(false)
            setCompareValue('ids', [...ids, ...tempIds])
            loadSamples()
          }}
        >
          {t("common.submit")}
        </Button>
        <Button
          size="medium"
          variant="outlined"
          sx={{ ml: 0.5}}
          onClick={() => {
            setOpenSearch(false)
            setExpanded(false)
          }}
        >
          {t("common.cancel")}
        </Button>
      </Box>
    </ModalWrapper>
  )

  const renderWheelModal = (
    <ModalWrapper
      maxWidth='md'
      open={openWheel}
      onClose={() => setOpenWheel(false)}
  
    >
 
      <Box sx={{width: '100%', height: '50vh'}}>
        <Box id='tastify-wheel' className={classes.wheel}></Box>
      </Box>
      <Typography variant='h3' align='center' sx={{my: 2}}>{titleSample}</Typography>
    </ModalWrapper>
  )

  const getDataSample = () => {
    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        await getSamples()
      },
    })
  }

  const onCheckMobile = (id : string) => {
    const { cuppingProtocol } = find(sampleList, ['id', id])

    const newIds = clone(idSample)
    const newCuppingProtocols = clone(cuppingProtocols)

    if (includes(idSample, id)) {
      pull(newIds, id)

      const index = newCuppingProtocols.indexOf(cuppingProtocol)
      if (index >= 0) newCuppingProtocols.splice(index, 1)

      remove(reservedSamples,(data) => data.id === id)

    } else {
      newIds.push(id)
      newCuppingProtocols.push(cuppingProtocol)
      reservedSamples.push(find(sampleList, ['id', id]))
    }

    setIdSample(newIds)
    setCuppingProtocols(newCuppingProtocols)
  }

  React.useEffect(() => {
    loadSamples()
  }, [languagePreference])

  React.useEffect(() => {
    if (!name) return

    onSearch(getSamples)
  }, [name])

  React.useEffect(() => {
    if (!isPrinting || !printElement.current) return

    notificationStore.setLoading(true)
    debounce(async () => {
      try {
        await printPage(printElement.current)
      } finally {
        setIsPrinting(false)
        setColumn(4)
        notificationStore.setLoading(false)
      }
    }, 3000)()

  }, [isPrinting])

  React.useEffect(() => {
    samples.map((sample, index) => flavorWheel(JSON.parse(sample.wheelData), isEnterprisePlus && base64Logo, `tastify-wheel-${index}`))
  }, [samples.length])

  React.useEffect(() => {
    if (!openWheel) return

    debounce(() => {
      flavorWheel(JSON.parse(wheelData), isEnterprisePlus && base64Logo)
    }, 300)()
  }, [openWheel])

  React.useLayoutEffect(() => {
   
    window.addEventListener('resize', onSetLayout)

    onSetLayout()
    return () => window.removeEventListener("resize", onSetLayout)
  }, [])

  React.useEffect(() => {
    getDataSample()
  }, [page, openSearch])

  return (
    <>
      {isPrinting &&
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=0.1, maximum-scale=0.1, user-scalable=0" />
          <body className="print-out" />
        </Helmet>
      }
      <HeaderTitle
        backUrl={reviewSamplesLink}
        breadcrumb={t('common.backTo', { menu: t('sample.samples.title') })}
        title={t('menu.compareSamples')}
      />

      <Container className={classes.buttonWrapper}>
        <Box className={classes.headerButtons}>
          <Typography variant='h4' sx={{display: { md: 'none' }}}>{ids.length} {t('sample.samples.title')}</Typography>
          <FormControlLabel
            control={<Checkbox checked={highlightDifference} onClick={() => setHighlightDifference(!highlightDifference)} />}
            label={`${t('compare.highlightDifferences')}`}
            sx={{ml: 2}}
          />
        </Box>
        <Box className={classes.headerButtons}>
          <Button
            fullWidth
            size='large'
            variant='contained'
            onClick={addSamples}
            startIcon={<AddCircleOutline />}
            sx={{ml: 2, display: { md: 'none' } }}
          >
            {t('compare.addSample')}
          </Button>
          <Button
            fullWidth
            size='large'
            sx={{ml: 2}}
            startIcon={<ClearIcon color='error' />}
            onClick={()=>{
              resetCompare()
              idSample.length = 0
            }}
          >
            {t('compare.clearSelection')}
          </Button>
          <Button
            fullWidth
            size='large'
            variant='contained'
            onClick={onPrint}
            sx={{ml: 2, display: { xs: 'none', md: 'inline-flex' } }}
          >
            {t('compare.exportComparison')}
          </Button>
        </Box>
      </Container>

      <Container className={classes.exportButton}>
        <Button
          fullWidth
          size='large'
          variant='contained'
          onClick={onPrint}
        >
          {t('compare.exportComparison')}
        </Button>
      </Container>

      <Box ref={printElement} data-name='sample-compare'>
        {isPrinting &&
          <Container sx={{textAlign: 'center'}}>
            <Logo width={200} height={75} />
            <Typography variant='h3' color='primary' sx={{mb: 3}}>
              {t('sample.sampleComparison')}
            </Typography>
          </Container>
        }
        <Container>
          <Box className={clsx(classes.infoHeader, classes.mobileOnly)}>
            <Typography variant="h6">{t('descriptor.flavorWheel')}</Typography>
          </Box>
          <Grid container className={classes.wheelWrapper}>
            <Grid item xs className={classes.headerWrapper}>
              <Box className={classes.headerContainer}>
                <Typography variant='h3'>{ids.length}</Typography>
                <Typography variant='h6'>{t('sample.samples.title')}</Typography>
              </Box>
            </Grid>
            {samples.map((sample, index) =>
              <Grid item xs key={index} className={classes.wheelItem}>
                {headerCard(sample, index)}
              </Grid>
            )}

            {[...Array(column - samples.length)].map((_, index) =>
              <Grid item xs key={index} className={classes.headerItem}>
                {headerCard('')}
              </Grid>
            )}
          </Grid>
        </Container>

        <Container className={classes.infoWrapper}>
          <Box
            className={classes.infoHeader}
            onClick={() => setOpenSampleInfo(!openSampleInfo)}
          >
            <Typography variant="h6">{t('sample.sampleInfo')}</Typography>
            {!isPrinting && (openSampleInfo ?
              <ExpandLess color='primary' fontSize='large' /> :
              <ExpandMore color='primary' fontSize='large' />
            )}
          </Box>

          <Box className={clsx(classes.infoItemWrapper, openSampleInfo && 'collapsed')}>
            <Collapse in={openSampleInfo} timeout="auto" unmountOnExit>
              {sampleInformations.map((item, index) => renderInfoItem(item, index))}
            </Collapse>
          </Box>
        </Container>

        <Container className={classes.infoWrapper}>
          <Box
            className={classes.infoHeader}
            onClick={() => setOpenDescriptorInfo(!openDescriptorInfo)}
          >
            <Typography variant="h6">{t('sample.sampleScore')}</Typography>
            {!isPrinting && (openDescriptorInfo ?
              <ExpandLess color='primary' fontSize='large' /> :
              <ExpandMore color='primary' fontSize='large' />
            )}
          </Box>

          <Box className={clsx(classes.infoItemWrapper, openDescriptorInfo && 'collapsed')}>
            <Collapse in={openDescriptorInfo} timeout="auto" unmountOnExit>
              {(cuppingProtocol === 'sca' ? scaDescriptor : descriptors).map((item, index) => renderInfoItem(item, index))}
            </Collapse>
          </Box>
        </Container>

        <Container className={classes.infoWrapper}>
          <Box
            className={classes.infoHeader}
            onClick={() => setOpenGreenGradingInfo(!openGreenGradingInfo)}
          >
            <Typography variant="h6">{t('compare.greenGrading')}</Typography>
            {!isPrinting && (openGreenGradingInfo ?
              <ExpandLess color='primary' fontSize='large' /> :
              <ExpandMore color='primary' fontSize='large' />
            )}
          </Box>

          <Box className={clsx(classes.infoItemWrapper, openGreenGradingInfo && 'collapsed')}>
            <Collapse in={openGreenGradingInfo} timeout="auto" unmountOnExit>
              {greenGradings.map((item, index) => renderInfoItem(item, index))}
            </Collapse>
          </Box>
        </Container>

        <Container className={classes.infoWrapper}>
          <Box
            className={classes.infoHeader}
            onClick={() => setOpenDefectsInfo(!openDefectsInfo)}
          >
            <Typography variant="h6">{t('descriptor.defects')}</Typography>
            {!isPrinting && (openDefectsInfo ?
              <ExpandLess color='primary' fontSize='large' /> :
              <ExpandMore color='primary' fontSize='large' />
            )}
          </Box>

          <Box className={clsx(classes.infoItemWrapper, openDefectsInfo && 'collapsed')}>
            <Collapse in={openDefectsInfo} timeout="auto" unmountOnExit>
              {greenGradingDefects.map((item, index) => {
                const defect = {...item, icon: IconDefect}
                return renderInfoItem(defect, index)
              })}
            </Collapse>
          </Box>
        </Container>
      </Box>

      {isMobile ? renderMobile() : renderSearchSampleModal}
      {renderWheelModal}
      
      <Container sx={{mb: 3}}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <AdvanceSearch
            isMobile={isMobile}
            expanded={expanded}
            temporaryDefectsDescriptors = {temporaryDefectsDescriptors}
            temporarySearchAdvanceData = {searchAdvanceData}
            temporaryFlavorAndAftertasteDescriptors = {temporaryFlavorAndAftertasteDescriptors}
            temporaryFragranceDescriptors = {temporaryFragranceDescriptors}
            temporaryBodyAndMouthfeelDescriptors = {temporaryBodyAndMouthfeelDescriptors}
            temporaryAcidityAndSaltDescriptors = {temporaryAcidityAndSaltDescriptors}
            temporaryListSearch = {temporaryListSearch}
            addTemporarySearchAdvanceData={setSearchAdvanceData}
            addTemporaryDefectsDescriptors={setTemporaryDefectsDescriptors}
            addTemporaryFlavorAndAftertasteDescriptors={setTemporaryFlavorAndAftertasteDescriptors}
            addTemporaryFragranceDescriptors={setTemporaryFragranceDescriptors}
            addTemporaryBodyAndMouthfeelDescriptors={setTemporaryBodyAndMouthfeelDescriptors}
            addTemporaryAcidityAndSaltDescriptors={setTemporaryAcidityAndSaltDescriptors}
            addTemporaryListSearch={setTemporaryListSearch}
            onSearch={() => {
              setSearchValue('generalSearch', false)
              setReviewSampleValue('page', 1)
              handleChangeCategory()
              getDataSample()
            }}
            onCancel={() => {
              getDataSample()
              setExpanded(false)
              window.scrollTo(0, 0)
              setTemporaryDefectsDescriptors([])
              setTemporaryFlavorAndAftertasteDescriptors([])
              setTemporaryFragranceDescriptors([])
              setTemporaryBodyAndMouthfeelDescriptors([])
              setTemporaryAcidityAndSaltDescriptors([])
              setTemporaryListSearch([])
            }}
          />
        </Collapse>
      </Container>

    </>
  )
})

export default Compare
