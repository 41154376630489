import React from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {
  Container, Typography, Box, CardContent, Card, Chip, Tabs, SvgIcon, IconButton, Grid, Paper, Avatar, Button,
  TableContainer, Table, TableRow, TableCell, TableBody,
} from '@mui/material'
import {  NavigateBefore, NavigateNext} from '@mui/icons-material'
import {isEmpty, upperFirst, flatMapDeep, debounce, orderBy, chunk, differenceBy} from 'lodash'
import { useParams, useNavigate } from 'react-router-dom'

import { HeaderTitle } from 'components'
import { useStores } from 'models'
import { findRoute } from 'constants/routes'
import { arabicaScoreTable, robustaScoreTable, coeScoreTable } from 'constants/form'
import { IconReportChartLine } from 'assets/images'
import {
  IconSampleName, IconTotalScore , IconComparison, IconReportShare
} from 'assets/images'
import { allDescriptor } from 'constants/all-descriptors'
import { printPage, flavorWheel } from 'utils'
import { DEBOUNCE_TIME } from 'config/env'

import { useStyles } from './sample-report-calibration.styles'
import { ScoreChart } from './components'
import { colors } from 'assets'


export const SampleReportCalibration: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const sampleLink = findRoute('reviewSamples')
  const printElement = React.createRef<Element | undefined>()

  const {
    sampleReportStore: {
      title, cuppingProtocol, getSampleReport, getAverageScoresForLineChart, getCuppingResult
    },
    companyStore: { base64Logo, isEnterprisePlus },
    notificationStore: { setLoading, setNotification, setValue: setNotificationValue },
    userStore: { isAuthenticated, languagePreference }
  } = useStores()

  const [valueTabs, setValueTabs] = React.useState(0);
  const [valueTabDescriptions, setValueTabDescriptions] = React.useState(0);
  const [isPrinting, setIsPrinting] = React.useState(false)
  const [graphPreviewByCuppers] = React.useState<any[]>([])
  const [isMobile, setIsMobile] = React.useState(false)

  const pickTextColorBasedOnBgColorSimple = (bgColor) => {
    if(bgColor === undefined) return

    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
      colors.text.primary : 'white';
  }


  const sampleInformations = [
    {
      icon: IconSampleName,
      name: t('sample.sampleName'),
      values: getCuppingResult.cupperName[0]['name']
    }
  ]

  const sampleInformationsDescriptor = [
    {
      icon: IconComparison,
      name: t('report.comparison'),
      values: getCuppingResult.cupperName[0]['name']
    }
  ]

  const scoreTable = () => {
    switch (cuppingProtocol) {
      case 'arabica':
        return arabicaScoreTable
      case 'robusta':
        return robustaScoreTable
      case 'cup_of_excellence':
        return coeScoreTable
      default:
        return []
    }
  }

  const handleChangeGraph = (data) => {

    graphPreviewByCuppers.map((item,i) => {
      if(item.id === data.id){
        if(item.active){
          graphPreviewByCuppers[i].active = false
          return
        }
        graphPreviewByCuppers[i].active = true
      }
    })
    onGetSampleReport(params.id)
  }

  const renderCuppers = (data) => {
    graphPreviewByCuppers.filter(x => x.id === data.id).length === 0 && graphPreviewByCuppers.push({'id': data.id, 'no': data.no,  'active': true })

    return (
          <Chip
            key={data.label}
            icon={<IconButton size='small' style={{ color: data.backgroundColor }}><SvgIcon component={IconReportChartLine} inheritViewBox sx={{ mr: 1, width: 15, height: 15 }} /></IconButton>}
            size="medium"
            variant={graphPreviewByCuppers.filter(x => x.id === data.id)[0]["active"] === true ? "outlined" : "filled"}
            color= "default"
            sx={{ mr: 1, mt: 1 }}
            label={data.no === 1 ? `${data.label} (You)`: data.label}
            onClick={() => {handleChangeGraph(data)}}
          />
    )
  }

  const descriptorTranslation = (language, data) => {
    const flatList = flatMapDeep(allDescriptor, getDescriptor)
    const label=`label_${language}`

    if (language === 'en') return upperFirst(data)

    return upperFirst(flatList.filter(x => upperFirst(x.label) === upperFirst(data))[0][label])
  }

  const descriptorColor = (data) => {
    const flatList = flatMapDeep(allDescriptor, getDescriptor)
    if(flatList.findIndex(x => upperFirst(x.label) === upperFirst(data)) > 0){
      return flatList.filter(x => upperFirst(x.label) === upperFirst(data))[0]['color']
    }
  }

  const getDescriptor = (descriptor) => {
    if(!descriptor.groups || !descriptor.groups.length) return descriptor

    return [descriptor, flatMapDeep(descriptor.groups, getDescriptor)]
  }

  const setFlavorWhell = () =>{
    orderBy(getCuppingResult.dataScoreWithId, "no", "asc").map((data, index)=> flavorWheel(data.flavorWhell, isEnterprisePlus && base64Logo, `tastify-wheel-${index}`))
  }

  const renderDescriptionScrollDetailWithScore = (data) => {
    return (
      <Chip
        key={data.label}
        avatar={<Avatar sx={{backgroundColor: 'white'}}><Typography sx={{ fontSize: 12,color: colors.primary.main }}>{t(data.qty)}</Typography></Avatar>}
        size="small"
        variant='outlined'
        sx={{ mr: 1, mt: 1 ,backgroundColor: descriptorColor(data.label), color: pickTextColorBasedOnBgColorSimple(descriptorColor(data.label)), borderColor: descriptorColor(data.label) }}
        label={descriptorTranslation(languagePreference, data.label)}

      />
    )
  }

  const renderDescriptionScroll = (data, index) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          '& > :not(style)': {
            m: 1,
            width: 420,
            height: 240,
          },
        }}
      >
        <Paper variant="outlined">
          <Box sx={{ mt: 1, ml: 2 }}>
            <Typography variant='body3' sx={{ mb: 1, fontWeight: 600, color: colors.text.o50 }}>Most used descriptor</Typography>
            <Typography sx={{ fontWeight: 600, mt: 0.5, color: colors.primary.main }}>{t(data.key)}</Typography>
          </Box>
          <Box  sx={{ ml: 2, mr: 2 }}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container justifyContent="left">
                  {data.descriptor.map((detail) => {
                    return renderDescriptionScrollDetailWithScore(detail)
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    )
  }

  const renderDescriptionItemTable = (isPrint = false,options: any = {}) => {
    return (
      <TableContainer sx={{ maxWidth: '100%' }}>
        <Table stickyHeader sx={{ my: 2 }} size="small" aria-label="a dense table">
          <TableBody>
            {getCuppingResult.scoreWithIcon.filter(data => data.name === 'sampleScore.totalScore').map((item, index) =>
              <TableRow key={index}>
                <TableCell className='sticky-cell' sx={{ maxWidth: 265, textAlign: 'center', border: 0 }}>
                  <Typography sx={{ ml: 1, fontWeight: 600, mt: 0.5, fontSize: 30 }}>{getCuppingResult.cupperName[0]['name'].length}</Typography>
                  <Typography sx={{ ml: 1, fontWeight: 600, mt: 1, fontSize: 20 }}>Participants</Typography>
                </TableCell>

                {[...Array(getCuppingResult.cupperName[0]['name'].length)].map((_, i) =>{
                  if(isPrint === true){
                    if(i < options?.end && i >= options?.start)
                    return (
                    <TableCell key={i} className={classes.tableCallDescriptors}>
                      <Box className={classes.wheel}>
                        <Card className={classes.headerCardWheel}>
                          <Box id={`tastify-wheel-${i}`} className={classes.wheel}></Box>
                        </Card>
                        <Button variant="contained" sx={{ mt: 1, borderRadius: 8, backgroundColor: getAverageScoresForLineChart.datasets[i].backgroundColor, width: 65, "&:hover": {backgroundColor: getAverageScoresForLineChart.datasets[i].backgroundColor} }}>
                          {item.values[i]}
                        </Button>
                      </Box>
                    </TableCell>
                    )
                  } else {
                    return (
                      <TableCell key={i} className={classes.tableCallDescriptors}>
                        <Box className={classes.wheel}>
                          <Card className={classes.headerCardWheel}>
                            <Box id={`tastify-wheel-${i}`} className={classes.wheel}></Box>
                          </Card>
                          <Button variant="contained" sx={{ mt: 1, borderRadius: 8, backgroundColor: getAverageScoresForLineChart.datasets[i].backgroundColor, width: 65, "&:hover": {backgroundColor: getAverageScoresForLineChart.datasets[i].backgroundColor} }}>
                            {item.values[i]}
                          </Button>
                        </Box>
                      </TableCell>
                      )
                  }
              })}
              </TableRow>
            )}
            {sampleInformationsDescriptor.map((item, index) =>
              <TableRow key={index}>
                <TableCell className='sticky-cell' sx={{ minWidth: 265, border: 1, borderColor: colors.text.o25 }}>
                  <Typography sx={{ fontWeight: 600 }}><IconButton size="small"><SvgIcon component={item.icon} sx={{ color: colors.secondary.main }} inheritViewBox /></IconButton>  {item.name}</Typography>
                </TableCell>

                {[...Array(getCuppingResult.cupperName[0]['name'].length)].map((_, i) =>{
                  if(isPrint === true){
                    if(i < options?.end && i >= options?.start)
                    return (
                    <TableCell key={i} className={classes.tableCallDescriptors}>
                      <Typography sx={{ ml: 1, fontWeight: 600, color: getAverageScoresForLineChart.datasets[i].backgroundColor }}>{item.values[i]}</Typography>
                    </TableCell>
                    )
                  } else {
                    return (
                      <TableCell key={i} className={classes.tableCallDescriptors}>
                        <Typography sx={{ ml: 1, fontWeight: 600, color: getAverageScoresForLineChart.datasets[i].backgroundColor }}>{item.values[i]}</Typography>
                      </TableCell>
                      )
                  }
                })}
              </TableRow>
            )}
            {getCuppingResult.descriptorsInfoDatasWithIcon.map((item, index) =>

              <TableRow key={index}>
                    <TableCell className='sticky-cell' sx={{ width: 265, border: 1, borderColor: colors.text.o25 }}>
                      <Typography sx={{ fontWeight: 600 }}> <IconButton size="small"><SvgIcon component={item.icon} sx={{ color: colors.secondary.main }} inheritViewBox /></IconButton> {t(item.name)}</Typography>
                    </TableCell>
                {[...Array(getCuppingResult.cupperName[0]['name'].length)].map((_, i) =>{
                  if(isPrint === true){
                    if(i < options?.end && i >= options?.start)
                    return (
                    <TableCell key={i} className={classes.tableCallDescriptors}>

                      {(isEmpty(item.descriptor) && item.name !== "Comparison") &&
                        <Box className={classes.infoItemValue}>
                          <Typography sx={{ fontWeight: 600, color: colors.text.o25 }}>-</Typography>
                        </Box>
                      }
                      {!isEmpty(item.descriptor[i]) &&
                        <Box className={classes.infoItemValue}>
                          {item.descriptor[i].map((detail) => {
                            return <Button variant="contained" sx={{ ml: 1, mt: 1, borderRadius: 8, color: pickTextColorBasedOnBgColorSimple(descriptorColor(detail)), backgroundColor: descriptorColor(detail), "&:hover": {backgroundColor: descriptorColor(detail)} }}>
                              <Typography variant='body3'>{descriptorTranslation(languagePreference, detail)}</Typography>
                            </Button>
                          })}
                        </Box>
                      }

                    </TableCell>
                    )
                  }  else {
                    return (
                      <TableCell key={i} className={classes.tableCallDescriptors}>

                        {(isEmpty(item.descriptor) && item.name !== "Comparison") &&
                          <Box className={classes.infoItemValue}>
                            <Typography sx={{ fontWeight: 600, color: colors.text.o25 }}>-</Typography>
                          </Box>
                        }
                        {!isEmpty(item.descriptor[i]) &&
                          <Box className={classes.infoItemValue}>
                            {item.descriptor[i].map((detail) => {
                              return <Button variant="contained" sx={{ ml: 1, mt: 1, borderRadius: 8, color: pickTextColorBasedOnBgColorSimple(descriptorColor(detail)), backgroundColor: descriptorColor(detail), "&:hover": {backgroundColor: descriptorColor(detail)} }}>
                                <Typography variant='body3'>{descriptorTranslation(languagePreference, detail)}</Typography>
                              </Button>
                            })}
                          </Box>
                        }

                      </TableCell>
                      )
                  }
              })}
              </TableRow>
            )}
          </TableBody>

        </Table>
      </TableContainer>
    )

  }

  const renderDescriptionItemTableMobile = () => {
    return (
      <>
      <Box sx={{ mt: -4, padding: 0.5, textAlign: 'end' }}>
        <Typography variant='h6'>{getCuppingResult.cupperName[0]['name'].length} Participants</Typography>
      </Box>
      <TableContainer sx={{ maxWidth: '100%' }}>
        <Table stickyHeader sx={{ my: 2 }} size="small" aria-label="a dense table">
          <TableBody>
            {getCuppingResult.scoreWithIcon.filter(data => data.name === 'sampleScore.totalScore').map((item, index) =>
              <TableRow key={index}>
                {[...Array(getCuppingResult.cupperName[0]['name'].length)].map((_, i) => {
                  return (
                    <TableCell key={i} className={classes.tableCallDescriptors}>
                      <Box className={classes.wheel}>
                        <Card className={classes.headerCardWheel}>
                          <Box id={`tastify-wheel-${i}`} className={classes.wheel}></Box>
                        </Card>
                        <Typography sx={{ ml: 1, fontWeight: 600, color: getAverageScoresForLineChart.datasets[i].backgroundColor }}>{sampleInformationsDescriptor[index].values[i]}</Typography>
                        <Button variant="contained" sx={{ mt: 1, borderRadius: 8, backgroundColor: getAverageScoresForLineChart.datasets[i].backgroundColor, width: 65, "&:hover": { backgroundColor: getAverageScoresForLineChart.datasets[i].backgroundColor } }}>
                          {item.values[i]}
                        </Button>
                      </Box>
                    </TableCell>
                  )
                })}
              </TableRow>
            )}

            {getCuppingResult.descriptorsInfoDatasWithIcon.map((item, index) =>
              <>
                <TableRow key={index} sx={{backgroundColor: "#FCF5FF"}}>
                  <TableCell colSpan={getCuppingResult.descriptorsInfoDatasWithIcon.length} sx={{ border: 1, borderColor: colors.text.o25 }}>
                    <Typography sx={{ fontWeight: 600, color: colors.primary.main }}> <IconButton size="small"><SvgIcon component={item.icon} sx={{ color: colors.primary.main }} inheritViewBox /></IconButton> {t(item.name)}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow key={index}>
                  {[...Array(getCuppingResult.cupperName[0]['name'].length)].map((_, i) => {
                    return (
                      <TableCell key={i} className={classes.tableCallDescriptors}>

                        {(isEmpty(item.descriptor) && item.name !== "Comparison") &&
                          <Box className={classes.infoItemValue}>
                            <Typography sx={{ fontWeight: 600, color: colors.text.o25 }}>-</Typography>
                          </Box>
                        }
                        {!isEmpty(item.descriptor[i]) &&
                          <Box className={classes.infoItemValue}>
                            {item.descriptor[i].map((detail) => {
                              return <Button variant="contained" sx={{ ml: 1, mt: 1, borderRadius: 8, color: pickTextColorBasedOnBgColorSimple(descriptorColor(detail)), backgroundColor: descriptorColor(detail), "&:hover": { backgroundColor: descriptorColor(detail) } }}>
                                <Typography variant='body3'>{descriptorTranslation(languagePreference, detail)}</Typography>
                              </Button>
                            })}
                          </Box>
                        }

                      </TableCell>
                    )
                  })}
                </TableRow>
              </>
            )}
          </TableBody>

        </Table>
      </TableContainer>
      </>
    )

  }

  const renderInfoItemTable = (isPrint = false,options: any = {}) => {

    return(
    <TableContainer sx={{ maxWidth: '100%' }}>
      <Table stickyHeader sx={{ my: 2 }} size="small" aria-label="a dense table">
        <TableBody>
        {sampleInformations.map((item, index) =>
            <TableRow key={index}>
              <TableCell className='sticky-cell' sx={{ minWidth: 265, borderBottom:"none" }}>
              </TableCell>
              {[...Array(getCuppingResult.cupperName[0]['name'].length)].map((_, i) =>{
                if(isPrint === true){
                  if(i < options?.end && i >= options?.start)
                  return(
                    <TableCell key={i} sx={{ minWidth: 265, borderBottom: "none", textAlign: 'center' }}>
                      <Typography variant='h6' color={getAverageScoresForLineChart.datasets[i].backgroundColor}>
                        {item.values[i]}
                      </Typography>
                    </TableCell>
                  )
                } else {
                  return(
                    <TableCell key={i} sx={{ minWidth: 265, borderBottom: "none", textAlign: 'center' }}>
                      <Typography variant='h6' color={getAverageScoresForLineChart.datasets[i].backgroundColor}>
                        {item.values[i]}
                      </Typography>
                    </TableCell>
                  )
                }
              })}
            </TableRow>

        )}
        {getCuppingResult.scoreWithIcon.filter(data => data.name === 'sampleScore.totalScore').map((item, index) =>
            <TableRow key={index}>
              <TableCell className='sticky-cell' sx={{ minWidth: 265 , borderBottom:"none"}}>
              </TableCell>
              {[...Array(getCuppingResult.cupperName[0]['name'].length)].map((_, i) =>{
                if(isPrint === true){
                  if(i < options?.end && i >= options?.start)
                  return (
                    <TableCell key={i} sx={{ minWidth: 265, borderBottom: "none", textAlign: 'center' }}>
                      <Typography variant='h6' color={getAverageScoresForLineChart.datasets[i].backgroundColor}>
                        <IconButton size='small' sx={{ mr: 0, color: getAverageScoresForLineChart.datasets[i].backgroundColor }}> <SvgIcon component={IconTotalScore} inheritViewBox /></IconButton>
                        {item.values[i]}
                      </Typography>
                    </TableCell>
                  )
                } else {
                  return (
                    <TableCell key={i} sx={{ minWidth: 265, borderBottom: "none", textAlign: 'center' }}>
                      <Typography variant='h6' color={getAverageScoresForLineChart.datasets[i].backgroundColor}>
                        <IconButton size='small' sx={{ mr: 0, color: getAverageScoresForLineChart.datasets[i].backgroundColor }}> <SvgIcon component={IconTotalScore} inheritViewBox /></IconButton>
                        {item.values[i]}
                      </Typography>
                    </TableCell>
                  )
                }
            })}
            </TableRow>
          )}
          {getCuppingResult.scoreWithIcon.filter(data => data.name !== 'sampleScore.totalScore').map((item, index) =>
            <TableRow key={index}>
              <TableCell className='sticky-cell' sx={{ width: 265 }}>
                <Typography sx={{ fontWeight: 600 }}><IconButton size="small"><SvgIcon component={item.icon} sx={{color: colors.secondary.main}} inheritViewBox /></IconButton>  {t(item.name)}</Typography>
              </TableCell>
              {[...Array(getCuppingResult.cupperName[0]['name'].length)].map((_, i) =>{
                if(isPrint === true){
                  if(i < options?.end && i >= options?.start)
                  return (
                    <TableCell key={i} sx={{ minWidth: 265, textAlign: 'center' }}>
                      <Button variant="contained" sx={{ borderRadius: 8, backgroundColor: getAverageScoresForLineChart.datasets[i].backgroundColor, width: 65, "&:hover": { backgroundColor: getAverageScoresForLineChart.datasets[i].backgroundColor } }}>
                        {item.values[i]}
                      </Button>
                    </TableCell>
                  )
                } else {
                  return (
                    <TableCell key={i} sx={{ minWidth: 265, textAlign: 'center' }}>
                      <Button variant="contained" sx={{ borderRadius: 8, backgroundColor: getAverageScoresForLineChart.datasets[i].backgroundColor, width: 65, "&:hover": { backgroundColor: getAverageScoresForLineChart.datasets[i].backgroundColor } }}>
                        {item.values[i]}
                      </Button>
                    </TableCell>
                  )
                }

            })}
            </TableRow>
        )}
        </TableBody>
      </Table>
    </TableContainer>
    )
  }

  const renderInfoItemTableMobile = () => {

    return(
        <TableContainer sx={{ maxWidth: '100%' }}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableBody>
            <TableRow >
              <TableCell className='sticky-cell' sx={{ width: 120 }}>
              </TableCell>
              {getCuppingResult.scoreWithIcon.filter(data => data.name !== 'sampleScore.totalScore').map((item, index) =>
                <>
                  <TableCell key={index} sx={{ width: 10, textAlign: 'center' }}>
                    <Typography sx={{ fontWeight: 600 }}>
                      <IconButton size="small"><SvgIcon component={item.icon} sx={{ color: colors.secondary.main }} inheritViewBox /></IconButton>
                      {getAverageScoresForLineChart.labels[index]}
                    </Typography>
                  </TableCell>
                </>
              )}
            </TableRow>
            {getCuppingResult.dataScoreWithId.map((item, i) => {

              return (
                <TableRow key={item.cupperId}>
                  <TableCell className='sticky-cell' sx={{ minWidth: 130 }}><Typography variant='h6'>{item.cupperName}</Typography>
                    <Typography variant='h6' color={getAverageScoresForLineChart.datasets[i].backgroundColor}>
                      <IconButton size='small' sx={{ mr: 0, color: getAverageScoresForLineChart.datasets[i].backgroundColor }}> <SvgIcon component={IconTotalScore} inheritViewBox /></IconButton>
                      {item['datasets'].filter(x => x.abbr === "TS")[0]['score']}
                    </Typography>
                  </TableCell>
                  {item.datasets.filter(x => x.abbr !== "TS").map((data, ii) =>
                    <TableCell key={i} sx={{ minWidth: 10, textAlign: 'center' }}>
                      <Button variant="contained" sx={{ borderRadius: 8, backgroundColor: getAverageScoresForLineChart.datasets[i].backgroundColor, width: 65, "&:hover": { backgroundColor: getAverageScoresForLineChart.datasets[i].backgroundColor } }}>
                        {data.score}
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const renderButtons = (
      <Button
        size='small'
        variant='contained'
        sx={{ml: 1}}
        onClick={() => setIsPrinting(true)}
      >
        <SvgIcon component={IconReportShare} inheritViewBox sx={{ mr: 1, width: 15, height: 15 }} />{t('compare.exportComparison')}
      </Button>
  )

  const renderToggleResult = () => {

    return (
      <Container sx={{ px: { xs: 0, md: 3 } }}>
        <Box sx={{ mt: 2 }}>
          <Typography variant='h6' sx={{ mb: 1 }}>{t('report.toggleResult')}</Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Tabs
            value={valueTabs}
            onChange={handleChangeTabs}
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
            style={{ flex: 1, display: 'flex' }}
            TabIndicatorProps={{
              sx: {
                display: "none"
              }

            }}
            ScrollButtonComponent={(props) => {

              if (
                props.direction === "left" &&
                !props.disabled
              ) {
                return (

                  <Box padding={1}>
                    <IconButton size="small" style={{ backgroundColor: "#EAE7F0" }} {...props}>
                      <NavigateBefore style={{ color: "#444F58" }} />

                    </IconButton>
                  </Box>
                );
              } else if (
                props.direction === "right" &&
                !props.disabled
              ) {
                return (
                  <Box padding={1}>
                    <IconButton size="small" style={{ backgroundColor: "#EAE7F0" }}{...props}>
                      <NavigateNext style={{ color: "#444F58" }} />

                    </IconButton>
                  </Box>
                );
              } else {
                return null;
              }
            }}
          >
            {orderBy(getAverageScoresForLineChart.datasets, "no", "asc").map(sc =>
              renderCuppers(sc)
            )}

          </Tabs>

        </Box>
      </Container>
    )
  }

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabs(newValue);
  };

  const handleChangeTabDescriptions = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabDescriptions(newValue);
  };

  const renderPrintData = () => {
    let end = 0;
    let start = 0;

    return (
      <>
        <Container sx={{ px: { xs: 0, md: 3 } }}>
          <Box sx={{ mt: 2, mb: 2 }}>
            {getAverageScoresForLineChart.datasets.map(sc =>
              renderCuppers(sc)
            )}
          </Box>
        </Container>
        <Container sx={{ px: { xs: 0, md: 3} }}>
          <Box className={classes.infoItemWrapper}>
            {getCuppingResult.mostUsedDescriptors.filter(detail => !isEmpty(detail.descriptor)).map((item, index) =>
              <>
                <Box sx={{ flex: 1, display: 'inline-flex'}}>
                  {renderDescriptionScroll(item, index)}
                </Box>
              </>
            )}
          </Box>
        </Container>

        {chunk(sampleInformations[0].values,3).map((data) => {
         start += end
         data.map(datass => {
          end++
         })

          return (
            <>
              <div className="html2pdf__page-break"></div>
              <Container sx={{ px: { xs: 0, md: 3 } }}>
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={6}>
                    <Typography variant='h6' sx={{ mb: 1 }}>{t('descriptor.descriptor')}</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <Typography variant='h6' sx={{ mb: 1 }}>{title}</Typography>
                  </Grid>
                </Grid>
                {renderDescriptionItemTable(true,{start: start,end: end})}
              </Container>
              <Container sx={{ px: { xs: 0, md: 3 } }}>
                {renderInfoItemTable(true,{start: start,end: end})}
              </Container>
            </>
          )

        })}

      </>
    )

  }

  const onGetSampleReport = React.useCallback(
    debounce(async (token) => {
      try{
        setNotificationValue('loading', true)
        await getSampleReport(token)

      } catch {
        navigate(-1)
      } finally {
        setNotificationValue('loading', false)
      }
    }, DEBOUNCE_TIME), []
  )

  const onSetLayout = () => {

    if (window.innerWidth < 900) {
        setIsMobile(true)
        setFlavorWhell()
        return
    }

    setIsMobile(false)
    setFlavorWhell()
}

React.useLayoutEffect(() => {
    window.addEventListener('resize', onSetLayout)

    onSetLayout()

    return () => window.removeEventListener("resize", onSetLayout)
}, [])

  React.useEffect(() => {
    if (!params.id) return

    onGetSampleReport(params.id)

  }, [])

  React.useEffect(() => {

    if (!isPrinting || !printElement.current) return

    setLoading(true)
    setFlavorWhell()
    debounce(async () => {
      try {
        await printPage(printElement.current,true)
      } finally {
        setIsPrinting(false)
        setLoading(false)
        setFlavorWhell()
      }
    }, 3000)()
  }, [isPrinting])

  return (
    <>
      {setFlavorWhell()}
      {!isPrinting &&
      <HeaderTitle
        title={getAverageScoresForLineChart.sampleRecord.name && t('report.yourCalibrationResults')+": " + getAverageScoresForLineChart.sampleRecord.name}
        rightContent={!isMobile && renderButtons}
        {...(isAuthenticated && {
          backUrl: sampleLink,
          breadcrumb: t('common.backTo', { menu: t('menu.reviewSamples') })
        })}
      />
      }

      <Box ref={printElement} data-name='sample-report-calibration'>

      <Container sx={{px: {xs: 0, md: 3}}}>
        <Card >
          {isPrinting &&
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Typography variant='h6' sx={{ mb: 1 }}>{title}</Typography>
            </Box>
          }
          <Box sx={{ mt: 1, p: 1 }}>
            <Typography variant='h6' sx={{ ml: 1 }}>{t('report.scoresOverview')}</Typography>
          </Box>
          <CardContent>
          {isMobile && renderToggleResult()}

              {getAverageScoresForLineChart.datasetChartScore[0]?.data?.length !== 0 &&
                <>
                  <Container sx={{ px: { xs: 0, md: 3 } }}>
                    <ScoreChart
                      datasets={differenceBy(getAverageScoresForLineChart.datasets, graphPreviewByCuppers.filter(x => x.active === false), "id")}
                      labels={getAverageScoresForLineChart.labels}
                      scores={getAverageScoresForLineChart.datasetChartScore}
                      isMobile={isMobile}
                      maxMinYaxis={getAverageScoresForLineChart.maxMinData}
                      labelsFullName={getAverageScoresForLineChart.labelsFullName}
                      key={Math.random()}
                    />
                  </Container>
                </>
              }

            <Container sx={{ px: { xs: 0, md: 3 } }}>
              <Box sx={{ mb: 2 , mt: 4}}>
                {scoreTable().filter(x => x.abbr !== 'TS').map((tc, index) =>
                  <React.Fragment key={index}>
                    <Typography variant='body3' className={clsx(classes.legend, 'bold')}>{tc.abbr}: </Typography>
                    <Typography variant='body3' className={classes.legend}>
                      {t(tc.translation)} {scoreTable().filter(x => x.abbr !== 'TS').length - 1 !== index && ' - '}
                    </Typography>
                  </React.Fragment>
                )}
              </Box>
            </Container>
            {!isPrinting ?
            <>
            {!isMobile && renderToggleResult()}
             <Container sx={{ px: { xs: 0, md: 3 } }}>
             <Box sx={{ mt: 3}}>
               <Typography variant='h6' sx={{ mb: 1 }}>{t('descriptor.descriptor')}</Typography>
             </Box>
             {isMobile ? renderDescriptionItemTableMobile():renderDescriptionItemTable()}
             <Box className={classes.infoItemWrapper}>
             <Tabs
               value={valueTabDescriptions}
               onChange={handleChangeTabDescriptions}
               variant="scrollable"
               scrollButtons="auto"
               style={{ flex: 1, display: 'flex' }}
               TabIndicatorProps={{
                 sx: {
                   display: "none"
                 }

               }}
               ScrollButtonComponent={(props) => {
                 if (
                   props.direction === "left" &&
                   !props.disabled
                 ) {
                   return (
                     <Box padding={1} marginTop={10}>
                       <IconButton size="small" style={{ backgroundColor: "#EAE7F0"}} {...props}>
                         <NavigateBefore style={{ color: "#444F58" }} />
                       </IconButton>
                     </Box>
                   );
                 } else if (
                   props.direction === "right" &&
                   !props.disabled
                 ) {
                   return (
                     <Box padding={1} marginTop={10}>
                       <IconButton size="small" style={{ backgroundColor: "#EAE7F0" }}{...props}>
                         <NavigateNext style={{ color: "#444F58" }} />
                       </IconButton>
                     </Box>
                   );
                 } else {
                   return null;
                 }
               }}
               >
                 {getCuppingResult.mostUsedDescriptors.filter(detail => !isEmpty(detail.descriptor)).map((item, index) =>
                   renderDescriptionScroll(item, index),
                 )}

               </Tabs>
             </Box>
           </Container>
           <Container sx={{ px: { xs: 0, md: 3 } }}>
             <Box sx={{ mt: 3}}>
               <Typography variant='h6' sx={{ mb: 1 }}>{t('report.cuppingResult')}</Typography>
             </Box>
             { isMobile ? renderInfoItemTableMobile() : renderInfoItemTable()}
           </Container>
           </>
            :
            <>
            {renderPrintData()}
            </>
            }

          </CardContent>
        </Card>

      </Container>
      </Box>
    </>
  )

})

export default SampleReportCalibration
