export const flavorDescriptorList = [{
	"id": 1,
	"label": "floral",
	"color": "#F5F058",
	"parent_id": null,
	"label_es": "floral",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "flavor",
	"positive": 1,
	"label_pt": "floral",
	"label_zh-CN": "花",
	"weight": 1.21,
	"label_fr-CA": "florale",
	"label_ko": "꽃향기",
	"label_id": "bunga",
	"label_ja": "フローラル、花のような",
	"groups": [{
		"id": 13,
		"label": "hibiscus",
		"color": "#E47089",
		"parent_id": 1,
		"label_es": "hibiscus",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "hibisco",
		"label_zh-CN": "芙蓉",
		"weight": 1.3,
		"label_fr-CA": "hibiscus",
		"label_ko": "히비스커스",
		"label_id": "hibiskus",
		"label_ja": "ハイビスカス",
		"groups": []
	}, {
		"id": 14,
		"label": "roses",
		"color": "#FAEAF3",
		"parent_id": 1,
		"label_es": "rosas",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "rosas",
		"label_zh-CN": "玫瑰",
		"weight": 1.29,
		"label_fr-CA": "roses",
		"label_ko": "장미",
		"label_id": "mawar",
		"label_ja": "バラ、ローズ",
		"groups": []
	}, {
		"id": 15,
		"label": "lavender",
		"color": "#9589A6",
		"parent_id": 1,
		"label_es": "lavanda",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "lavanda",
		"label_zh-CN": "薰衣草",
		"weight": 1.29,
		"label_fr-CA": "lavande",
		"label_ko": "라벤더",
		"label_id": "lavender",
		"label_ja": "ラベンダー",
		"groups": []
	}, {
		"id": 16,
		"label": "magnolia",
		"color": "#FDFDDB",
		"parent_id": 1,
		"label_es": "magnolia",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "magnólia",
		"label_zh-CN": "木兰",
		"weight": 1.0,
		"label_fr-CA": "magnolia",
		"label_ko": "목련",
		"label_id": "magnolia",
		"label_ja": "マグノリア、朴の木",
		"groups": []
	}, {
		"id": 17,
		"label": "jasmine honeysuckle",
		"color": "#FDFCE3",
		"parent_id": 1,
		"label_es": "jazmín",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "jasmim",
		"label_zh-CN": "茉莉／金银花",
		"weight": 1.27,
		"label_fr-CA": "jasmin chèvrefeuille",
		"label_id": "melati",
		"label_ja": "スイカズラジャスミン",
		"groups": []
	}, {
		"id": 18,
		"label": "orange blossom",
		"color": "#F8F295",
		"parent_id": 1,
		"label_es": "azahar",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "flor-de-laranjeira",
		"label_zh-CN": "橙花",
		"weight": 1.24,
		"label_fr-CA": "fleur d'oranger",
		"label_ko": "오렌지 꽃",
		"label_id": "bunga jeruk",
		"label_ja": "オレンジの花",
		"groups": []
	}, {
		"id": 19,
		"label": "lemongrass",
		"color": "#FCFCC9",
		"parent_id": 1,
		"label_es": "limoncillo",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "capim-limão",
		"label_zh-CN": "柠檬草",
		"weight": 1.25,
		"label_fr-CA": "citronnelle",
		"label_ko": "레몬그라스",
		"label_id": "serai",
		"label_ja": "レモングラス",
		"groups": []
	}]
}, {
	"id": 2,
	"label": "fruit",
	"color": "#AA274E",
	"parent_id": null,
	"label_es": "frutal",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "flavor",
	"positive": 1,
	"label_pt": "frutado",
	"label_zh-CN": "水果",
	"weight": 5.5,
	"label_fr-CA": "fruité",
	"label_ko": "과일",
	"label_id": "buah",
	"label_ja": "フルーツ、果物系",
	"groups": [{
		"id": 20,
		"label": "citrus",
		"color": "#F3EF4E",
		"parent_id": 2,
		"label_es": "cítrico",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "cítrico",
		"label_zh-CN": "柑橘",
		"weight": 1.99,
		"label_fr-CA": "agrumes",
		"label_ko": "감귤류",
		"label_id": "citrus",
		"label_ja": "シトラス、柑橘類",
		"groups": [{
			"id": 97,
			"label": "lemon",
			"color": "#F5F236",
			"parent_id": 20,
			"label_es": "lima",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "lima",
			"label_zh-CN": "柠檬",
			"weight": 1.22,
			"label_fr-CA": "citron",
			"label_ko": "레몬",
			"label_id": "limun",
			"label_ja": "レモン"
		}, {
			"id": 98,
			"label": "lime",
			"color": "#76BE43",
			"parent_id": 20,
			"label_es": "limón",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "limão",
			"label_zh-CN": "酸橙",
			"weight": 1.23,
			"label_fr-CA": "citron vert",
			"label_ko": "라임",
			"label_id": "jeruk nipis",
			"label_ja": "ライム"
		}, {
			"id": 99,
			"label": "grapefruit",
			"color": "#FBEB9C",
			"parent_id": 20,
			"label_es": "toronja",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "toranja",
			"label_zh-CN": "西柚",
			"weight": 1.2,
			"label_fr-CA": "pamplemousse",
			"label_ko": "자몽",
			"label_id": "jeruk bali",
			"label_ja": "グレープフルーツ"
		}, {
			"id": 100,
			"label": "clementine",
			"color": "#FBDC2B",
			"parent_id": 20,
			"label_es": "clementina",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "clementina",
			"label_zh-CN": "克莱门氏橘",
			"weight": 1.17,
			"label_fr-CA": "clémentine",
			"label_ko": "클레멘타인 오렌지",
			"label_id": "jeruk clementine",
			"label_ja": "クレメンタイン"
		}, {
			"id": 101,
			"label": "tangerine",
			"color": "#F99F7E",
			"parent_id": 20,
			"label_es": "tangerina",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "tangerina",
			"label_zh-CN": "蜜桔",
			"weight": 1.23,
			"label_fr-CA": "tangerine",
			"label_ko": "귤",
			"label_id": "tangerine/jeruk keprok",
			"label_ja": "タンジェリンオレンジ"
		}, {
			"id": 102,
			"label": "mandarin",
			"color": "#EF7F51",
			"parent_id": 20,
			"label_es": "mandarina",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "mandarina",
			"label_zh-CN": "柑橘",
			"weight": 1.21,
			"label_fr-CA": "tangerine",
			"label_ko": "감귤",
			"label_id": "jeruk mandarin",
			"label_ja": "マンダリンオレンジ"
		}, {
			"id": 103,
			"label": "orange",
			"color": "#EF645D",
			"parent_id": 20,
			"label_es": "naranja",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "laranja",
			"label_zh-CN": "橙子",
			"weight": 1.19,
			"label_fr-CA": "orange",
			"label_ko": "오렌지",
			"label_id": "jeruk",
			"label_ja": "オレンジ"
		}]
	}, {
		"id": 21,
		"label": "apple/pear",
		"color": "#B3CD31",
		"parent_id": 2,
		"label_es": "manzana/pera",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "maçã / pera",
		"label_zh-CN": "苹果／梨",
		"weight": 1.2,
		"label_fr-CA": "pomme/poire",
		"label_ko": "사과/배",
		"label_id": "apel/pir",
		"label_ja": "リンゴ・ナシ",
		"groups": [{
			"id": 104,
			"label": "green apple",
			"color": "#7BAA68",
			"parent_id": 21,
			"label_es": "manzana verde",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "maçã verde",
			"label_zh-CN": "青苹果",
			"weight": 1.19,
			"label_fr-CA": "pomme vert",
			"label_ko": "풋사과",
			"label_id": "apel hijau",
			"label_ja": "青リンゴ"
		}, {
			"id": 105,
			"label": "red apple",
			"color": "#EB3344",
			"parent_id": 21,
			"label_es": "manzana roja",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "maça vermelha",
			"label_zh-CN": "红苹果",
			"weight": 1.23,
			"label_fr-CA": "pomme rouge",
			"label_ko": "사과",
			"label_id": "apel merah",
			"label_ja": "赤リンゴ"
		}]
	}, {
		"id": 22,
		"label": "melon",
		"color": "#6EC140",
		"parent_id": 2,
		"label_es": "melón",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "melão",
		"label_zh-CN": "甜瓜",
		"weight": 1.21,
		"label_fr-CA": "melon",
		"label_ko": "멜론",
		"label_id": "melon",
		"label_ja": "メロン",
		"groups": [{
			"id": 106,
			"label": "watermelon",
			"color": "#50803A",
			"parent_id": 22,
			"label_es": "sandía",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "melancia",
			"label_zh-CN": "西瓜",
			"weight": 1.21,
			"label_fr-CA": "pastèque",
			"label_ko": "수박",
			"label_id": "semangkat",
			"label_ja": "スイカ"
		}, {
			"id": 107,
			"label": "honeydew",
			"color": "#B3CC2D",
			"parent_id": 22,
			"label_es": "mielada",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "melão",
			"label_zh-CN": "蜜瓜",
			"weight": 1.22,
			"label_fr-CA": "melon vert",
			"label_ko": "허니듀 멜론",
			"label_id": "honeydew/melon",
			"label_ja": "ハネデゥーメロン"
		}, {
			"id": 108,
			"label": "cantaloupe",
			"color": "#F4F254",
			"parent_id": 22,
			"label_es": "melon",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "melão cantalupe",
			"label_zh-CN": "哈密瓜",
			"weight": 1.27,
			"label_fr-CA": "cantaloup",
			"label_ko": "칸탈로푸 멜론",
			"label_id": "cantaloupe/blewah",
			"label_ja": "カンタロープメロン"
		}]
	}, {
		"id": 23,
		"label": "grape",
		"color": "#3C2048",
		"parent_id": 2,
		"label_es": "uva",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "uva",
		"label_zh-CN": "葡萄",
		"weight": 1.22,
		"label_fr-CA": "raisin",
		"label_ko": "포도",
		"label_id": "anggur",
		"label_ja": "グレープ、ぶどう",
		"groups": [{
			"id": 109,
			"label": "white grape",
			"color": "#FCFCCD",
			"parent_id": 23,
			"label_es": "uva blanca",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "uva branca",
			"label_zh-CN": "白葡萄",
			"weight": 1.19,
			"label_fr-CA": "raisin blanc",
			"label_ko": "백포도",
			"label_id": "anggur putih",
			"label_ja": "白ぶどう"
		}, {
			"id": 110,
			"label": "green grape",
			"color": "#F7FAC3",
			"parent_id": 23,
			"label_es": "uva verde",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "uva verde",
			"label_zh-CN": "绿葡萄",
			"weight": 1.23,
			"label_fr-CA": "raisin vert",
			"label_ko": "청포도",
			"label_id": "anggur hijau",
			"label_ja": "緑ぶどう"
		}, {
			"id": 111,
			"label": "concord grape",
			"color": "#978EC2",
			"parent_id": 23,
			"label_es": "uva concord",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "uva Concord",
			"label_zh-CN": "康科德葡萄",
			"weight": 1.31,
			"label_fr-CA": "raisin de concorde",
			"label_ko": "포도",
			"label_id": "anggur concord",
			"label_ja": "コンコードグレープ"
		}, {
			"id": 112,
			"label": "red grape",
			"color": "#BC9EAE",
			"parent_id": 23,
			"label_es": "uva roja",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "uva roxa",
			"label_zh-CN": "红葡萄",
			"weight": 1.2,
			"label_fr-CA": "raisin rouge",
			"label_ko": "적포도",
			"label_id": "anggur merah",
			"label_ja": "赤ぶどう"
		}]
	}, {
		"id": 24,
		"label": "tropical fruit",
		"color": "#F7F26C",
		"parent_id": 2,
		"label_es": "frutas tropicales",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "frutas tropicais",
		"label_zh-CN": "热带水果",
		"weight": 2.11,
		"label_fr-CA": "fruit tropical",
		"label_ko": "열대과일",
		"label_id": "buah tropik",
		"label_ja": "トロピカルフルーツ",
		"groups": [{
			"id": 113,
			"label": "lychee",
			"color": "#FEFEED",
			"parent_id": 24,
			"label_es": "lychee",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "lichia",
			"label_zh-CN": "荔枝",
			"weight": 1.0,
			"label_fr-CA": "litchi",
			"label_ko": "리치",
			"label_id": "leci",
			"label_ja": "ライチ"
		}, {
			"id": 114,
			"label": "star fruit",
			"color": "#F9F697",
			"parent_id": 24,
			"label_es": "carambola",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "carambola",
			"label_zh-CN": "杨桃",
			"weight": 1.21,
			"label_fr-CA": "carambola",
			"label_ko": "스타프루트",
			"label_id": "belimbing",
			"label_ja": "スターフルーツ"
		}, {
			"id": 115,
			"label": "tamarind",
			"color": "#A1C0E7",
			"parent_id": 24,
			"label_es": "tamarindo",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "tamarindo",
			"label_zh-CN": "罗望子",
			"weight": 1.25,
			"label_fr-CA": "tamarin",
			"label_ko": "타마린드",
			"label_id": "tamarin",
			"label_ja": "タマリンド"
		}, {
			"id": 116,
			"label": "passion fruit",
			"color": "#FDFDD8",
			"parent_id": 24,
			"label_es": "maracuyá",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "maracujá",
			"label_zh-CN": "百香果",
			"weight": 1.23,
			"label_fr-CA": "fruit de la passion",
			"label_ko": "패션프루트",
			"label_id": "markisa",
			"label_ja": "パッションフルーツ"
		}, {
			"id": 117,
			"label": "mango",
			"color": "#FCCF7F",
			"parent_id": 24,
			"label_es": "mango",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "manga",
			"label_zh-CN": "芒果",
			"weight": 1.24,
			"label_fr-CA": "mangue",
			"label_ko": "망고",
			"label_id": "mangga",
			"label_ja": "マンゴー"
		}, {
			"id": 118,
			"label": "papaya",
			"color": "#FBC79A",
			"parent_id": 24,
			"label_es": "papaya",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "mamão",
			"label_zh-CN": "木瓜",
			"weight": 1.22,
			"label_fr-CA": "papaye",
			"label_ko": "파파야",
			"label_id": "pepaya",
			"label_ja": "パパイヤ"
		}, {
			"id": 119,
			"label": "kiwi",
			"color": "#FAF9BC",
			"parent_id": 24,
			"label_es": "kiwi",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "kiwi",
			"label_zh-CN": "猕猴桃",
			"weight": 1.25,
			"label_fr-CA": "kiwi",
			"label_ko": "키위",
			"label_id": "kiwi",
			"label_ja": "キウイ"
		}, {
			"id": 120,
			"label": "banana",
			"color": "#F5F574",
			"parent_id": 24,
			"label_es": "platano",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "banana",
			"label_zh-CN": "香蕉",
			"weight": 1.22,
			"label_fr-CA": "banane",
			"label_ko": "바나나",
			"label_id": "pisang",
			"label_ja": "バナナ"
		}, {
			"id": 121,
			"label": "coconut",
			"color": "#FDFCD4",
			"parent_id": 24,
			"label_es": "coco",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "coco",
			"label_zh-CN": "椰子",
			"weight": 1.18,
			"label_fr-CA": "noix de coco",
			"label_ko": "코코넛",
			"label_id": "kelapa",
			"label_ja": "ココナッツ、ヤシの実"
		}, {
			"id": 122,
			"label": "pineapple",
			"color": "#FAF8A9",
			"parent_id": 24,
			"label_es": "pineapple",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "abacaxi",
			"label_zh-CN": "苹果",
			"weight": 1.25,
			"label_fr-CA": "ananas",
			"label_ko": "파인애플",
			"label_id": "nanas",
			"label_ja": "パイナップル"
		}]
	}, {
		"id": 25,
		"label": "stone fruit",
		"color": "#F79769",
		"parent_id": 2,
		"label_es": "frutas de hueso",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "frutas de caroço",
		"label_zh-CN": "核果",
		"weight": 1.21,
		"label_fr-CA": "fruits à noyau",
		"label_ko": "핵과류",
		"label_id": "buah beriji keras \u0026 besar",
		"label_ja": "核果、モモ、スモモ、さくらんぼ",
		"groups": [{
			"id": 123,
			"label": "peach",
			"color": "#FDD66A",
			"parent_id": 25,
			"label_es": "durazno",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "pêssego",
			"label_zh-CN": "桃子",
			"weight": 1.23,
			"label_fr-CA": "pêche",
			"label_ko": "복숭아",
			"label_id": "peach",
			"label_ja": "ピーチ、桃"
		}, {
			"id": 124,
			"label": "nectarine",
			"color": "#FCBC49",
			"parent_id": 25,
			"label_es": "nectarina",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "nectarina",
			"label_zh-CN": "油桃",
			"weight": 1.25,
			"label_fr-CA": "nectarine",
			"label_ko": "천도복숭아",
			"label_id": "nektarin",
			"label_ja": "ネクタリン・ツバキモモ"
		}, {
			"id": 125,
			"label": "apricot",
			"color": "#F3833E",
			"parent_id": 25,
			"label_es": "albaricoque",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "damasco",
			"label_zh-CN": "杏",
			"weight": 1.23,
			"label_fr-CA": "abricot",
			"label_ko": "살구",
			"label_id": "aprikot",
			"label_ja": "アプリコット、杏"
		}, {
			"id": 126,
			"label": "plum",
			"color": "#E03371",
			"parent_id": 25,
			"label_es": "ciruela",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "ameixa",
			"label_zh-CN": "李子",
			"weight": 1.21,
			"label_fr-CA": "prune",
			"label_ko": "자두",
			"label_id": "buah plum",
			"label_ja": "プラム・スモモ"
		}, {
			"id": 127,
			"label": "cherry",
			"color": "#E8353F",
			"parent_id": 25,
			"label_es": "cereza",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "cereja",
			"label_zh-CN": "樱桃",
			"weight": 1.21,
			"label_fr-CA": "cerise",
			"label_ko": "체리",
			"label_id": "ceri",
			"label_ja": "チェリー、さくらんぼ"
		}, {
			"id": 128,
			"label": "black cherry",
			"color": "#671924",
			"parent_id": 25,
			"label_es": "cereza negra",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "cereja preta",
			"label_zh-CN": "黑樱桃",
			"weight": 1.21,
			"label_fr-CA": "cerise noire",
			"label_ko": "블랙체리",
			"label_id": "ceri hitam",
			"label_ja": "ダークチェリー"
		}]
	}, {
		"id": 26,
		"label": "berry",
		"color": "#362F5A",
		"parent_id": 2,
		"label_es": "moras",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "frutas silvestres",
		"label_zh-CN": "浆果",
		"weight": 1.21,
		"label_fr-CA": "baie",
		"label_ko": "베리",
		"label_id": "beri",
		"label_ja": "ベリー",
		"groups": [{
			"id": 129,
			"label": "cranberry",
			"color": "#941F35",
			"parent_id": 26,
			"label_es": "arándano",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "oxicoco",
			"label_zh-CN": "蔓越莓",
			"weight": 1.25,
			"label_fr-CA": "canneberge",
			"label_ko": "크랜베리",
			"label_id": "cranberry",
			"label_ja": "クランベリー"
		}, {
			"id": 130,
			"label": "raspberry",
			"color": "#B60045",
			"parent_id": 26,
			"label_es": "frambuesa",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "framboesa",
			"label_zh-CN": "覆盆子",
			"weight": 1.26,
			"label_fr-CA": "framboise",
			"label_ko": "라즈베리",
			"label_id": "raspberry",
			"label_ja": "ラズベリー"
		}, {
			"id": 131,
			"label": "strawberry",
			"color": "#86222C",
			"parent_id": 26,
			"label_es": "fresa",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "morango",
			"label_zh-CN": "草莓",
			"weight": 1.23,
			"label_fr-CA": "fraise",
			"label_ko": "딸기",
			"label_id": "strawberry",
			"label_ja": "イチゴ、苺、ストロベリー"
		}, {
			"id": 132,
			"label": "blueberry",
			"color": "#2E427A",
			"parent_id": 26,
			"label_es": "mora azul",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "mirtilo",
			"label_zh-CN": "蓝莓",
			"weight": 1.3,
			"label_fr-CA": "myrtille",
			"label_ko": "블루베리",
			"label_id": "blueberry",
			"label_ja": "ブルーベリー"
		}, {
			"id": 133,
			"label": "red currant",
			"color": "#E54F72",
			"parent_id": 26,
			"label_es": "grosella roja",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "groselha vermelha",
			"label_zh-CN": "红醋栗",
			"weight": 1.21,
			"label_fr-CA": "groseille",
			"label_ko": "레드커런트",
			"label_id": "kismis merah",
			"label_ja": "レッドカレント・赤スグリ"
		}, {
			"id": 134,
			"label": "black currant",
			"color": "#514C69",
			"parent_id": 26,
			"label_es": "grosella negra",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "groselha negra",
			"label_zh-CN": "黑醋栗",
			"weight": 1.25,
			"label_fr-CA": "cassis",
			"label_ko": "블랙커런트",
			"label_id": "kismis hitam",
			"label_ja": "カシス"
		}]
	}, {
		"id": 27,
		"label": "dry fruit",
		"color": "#3952A1",
		"parent_id": 2,
		"label_es": "frutas secas",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "frutas secas",
		"label_zh-CN": "干果",
		"weight": 1.17,
		"label_fr-CA": "fruits secs",
		"label_ko": "말린과일",
		"label_id": "buah kering",
		"label_ja": "ドライフルーツ",
		"groups": [{
			"id": 135,
			"label": "golden raisin",
			"color": "#F4C982",
			"parent_id": 27,
			"label_es": "pasas amarillas",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "uva passa branca",
			"label_zh-CN": "白葡萄干",
			"weight": 1.17,
			"label_fr-CA": "raisin doré",
			"label_ko": "골든 건포도",
			"label_id": "kismis emas",
			"label_ja": "ゴルデンレーズン"
		}, {
			"id": 136,
			"label": "raisin",
			"color": "#6253A1",
			"parent_id": 27,
			"label_es": "uvas pasas",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "uva passa",
			"label_zh-CN": "葡萄干",
			"weight": 1.2,
			"label_fr-CA": "raisin sec",
			"label_ko": "건포도",
			"label_id": "kismis",
			"label_ja": "レーズン"
		}, {
			"id": 137,
			"label": "dried fig",
			"color": "#7876B6",
			"parent_id": 27,
			"label_es": "higo seco",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "figo seco",
			"label_zh-CN": "无花果干",
			"weight": 1.21,
			"label_fr-CA": "figue séchée",
			"label_ko": "말린 무화과",
			"label_id": "dried fig/ara kering",
			"label_ja": "ドライいちじく、ドライイチジク、"
		}, {
			"id": 138,
			"label": "dried dates",
			"color": "#817CBA",
			"parent_id": 27,
			"label_es": "dátiles",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "tâmaras",
			"label_zh-CN": "干枣",
			"weight": 1.25,
			"label_fr-CA": "datte",
			"label_ko": "말린 대추",
			"label_id": "kurma kering",
			"label_ja": "ドライデーツ・ドライナツメヤシ"
		}, {
			"id": 139,
			"label": "prune",
			"color": "#303060",
			"parent_id": 27,
			"label_es": "ciruela pasa",
			"open": 1,
			"zoomed": 0,
			"selected": false,
			"flavor_type": "flavor",
			"positive": 1,
			"label_pt": "ameixa passa",
			"label_zh-CN": "西梅干",
			"weight": 1.22,
			"label_fr-CA": "pruneau",
			"label_ko": "말린 자두",
			"label_id": "buah prune",
			"label_ja": "プルーン"
		}]
	}]
}, {
	"id": 3,
	"label": "chocolate",
	"color": "#6F5740",
	"parent_id": null,
	"label_es": "chocolate",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "flavor",
	"positive": 1,
	"label_pt": "chocolate",
	"label_zh-CN": "巧克力",
	"weight": 1.17,
	"label_fr-CA": "chocolat",
	"label_ko": "초콜릿",
	"label_id": "coklat",
	"label_ja": "チョコレート",
	"groups": [{
		"id": 28,
		"label": "cacao nibs",
		"color": "#48373F",
		"parent_id": 3,
		"label_es": "granos de cacao",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "amêndoas de cacau",
		"label_zh-CN": "可可碎粒",
		"weight": 1.18,
		"label_fr-CA": "cacao",
		"label_ko": "카카오닙스",
		"label_id": "biji coklat",
		"label_ja": "カカオニブ",
		"groups": []
	}, {
		"id": 29,
		"label": "dark chocolate",
		"color": "#3B241F",
		"parent_id": 3,
		"label_es": "chocolate oscuro",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "chocolate preto",
		"label_zh-CN": "黑巧克力",
		"weight": 1.17,
		"label_fr-CA": "chocolat noir",
		"label_ko": "다크초콜릿",
		"label_id": "dark chocolate",
		"label_ja": "ダークチョコレート",
		"groups": []
	}, {
		"id": 30,
		"label": "bakers chocolate",
		"color": "#4F322A",
		"parent_id": 3,
		"label_es": "chocolate de reposteria",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "chocolate de panificação",
		"label_zh-CN": "烘焙巧克力",
		"weight": 1.14,
		"label_fr-CA": "chocolat sucré",
		"label_ko": "제과용 초콜릿",
		"label_id": "bakers chocolate",
		"label_ja": "ベイカーズチョコレート",
		"groups": []
	}, {
		"id": 31,
		"label": "bittersweet chocolate",
		"color": "#613E29",
		"parent_id": 3,
		"label_es": "chocolate amargo",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "chocolate amargo",
		"label_zh-CN": "半甜巧克力",
		"weight": 1.15,
		"label_fr-CA": "chocolat aigre-doux",
		"label_ko": "비터스윗 초콜릿",
		"label_id": "bittersweet chocolate",
		"label_ja": "　ビタースイートチョコレート",
		"groups": []
	}, {
		"id": 32,
		"label": "cocoa powder",
		"color": "#9C644D",
		"parent_id": 3,
		"label_es": "cocoa en polvo",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "cacau em pó",
		"label_zh-CN": "可可粉",
		"weight": 1.16,
		"label_fr-CA": "poudre de cacao",
		"label_ko": "코코아 파우더",
		"label_id": "coklat bubuk",
		"label_ja": "ココアパウダー",
		"groups": []
	}, {
		"id": 33,
		"label": "milk chocolate",
		"color": "#B39881",
		"parent_id": 3,
		"label_es": "chocolate de leche",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "chocolate ao leite",
		"label_zh-CN": "牛奶巧克力",
		"weight": 1.15,
		"label_fr-CA": "chocolat au lait",
		"label_ko": "밀크초콜릿",
		"label_id": "milk chocolate",
		"label_ja": "ミルクチョコレート",
		"groups": []
	}]
}, {
	"id": 4,
	"label": "sweet",
	"color": "#FAF8A9",
	"parent_id": null,
	"label_es": "dulce",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "flavor",
	"positive": 1,
	"label_pt": "doce",
	"label_zh-CN": "甜",
	"weight": 1.75,
	"label_fr-CA": "doux",
	"label_ko": "달콤한",
	"label_id": "manis",
	"label_ja": "甘い、甘味",
	"groups": [{
		"id": 34,
		"label": "vanilla",
		"color": "#FAFABD",
		"parent_id": 4,
		"label_es": "vainilla",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "baunilha",
		"label_zh-CN": "香草",
		"weight": 1.19,
		"label_fr-CA": "vanille",
		"label_ko": "바닐라",
		"label_id": "vanila",
		"label_ja": "バニラ",
		"groups": []
	}, {
		"id": 35,
		"label": "nougat",
		"color": "#FBFACA",
		"parent_id": 4,
		"label_es": "nougat",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "torrone",
		"label_zh-CN": "牛扎糖",
		"weight": 1.17,
		"label_fr-CA": "nougat",
		"label_ko": "누가",
		"label_id": "nougat",
		"label_ja": "ヌガー",
		"groups": []
	}, {
		"id": 36,
		"label": "honey",
		"color": "#F3C832",
		"parent_id": 4,
		"label_es": "miel",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "mel",
		"label_zh-CN": "蜂蜜",
		"weight": 1.19,
		"label_fr-CA": "miel",
		"label_ko": "꿀",
		"label_id": "madu",
		"label_ja": "ハニー、蜂蜜",
		"groups": []
	}, {
		"id": 37,
		"label": "butter",
		"color": "#FCFDCF",
		"parent_id": 4,
		"label_es": "mantequilla",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "manteiga",
		"label_zh-CN": "黄油",
		"weight": 1.19,
		"label_fr-CA": "beurre",
		"label_ko": "버터",
		"label_id": "mentega",
		"label_ja": "バター",
		"groups": []
	}, {
		"id": 38,
		"label": "cream",
		"color": "#FEFFEE",
		"parent_id": 4,
		"label_es": "crema",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "creme",
		"label_zh-CN": "奶油",
		"weight": 1.19,
		"label_fr-CA": "crème",
		"label_ko": "크림",
		"label_id": "krim",
		"label_ja": "クリーム",
		"groups": []
	}, {
		"id": 39,
		"label": "marshmallow",
		"color": "#FCFCD5",
		"parent_id": 4,
		"label_es": "malvavisco",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "marshmallow",
		"label_zh-CN": "棉花糖",
		"weight": 1.23,
		"label_fr-CA": "guimauve",
		"label_ko": "마시멜로",
		"label_id": "marshmallow",
		"label_ja": "マシュマロ",
		"groups": []
	}, {
		"id": 40,
		"label": "sugar cane",
		"color": "#F4F5D2",
		"parent_id": 4,
		"label_es": "azucar de caña",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "cana-de-açúcar",
		"label_zh-CN": "甘蔗",
		"weight": 1.17,
		"label_fr-CA": "canne à sucre",
		"label_ko": "사탕수수",
		"label_id": "daun tebu",
		"label_ja": "サトウキビ",
		"groups": []
	}, {
		"id": 41,
		"label": "brown sugar",
		"color": "#FEDDBE",
		"parent_id": 4,
		"label_es": "azucar morena",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "açúcar mascavo",
		"label_zh-CN": "红糖",
		"weight": 1.19,
		"label_fr-CA": "sucre brun",
		"label_ko": "황설탕",
		"label_id": "gula merah",
		"label_ja": "ブラウンシュガー",
		"groups": []
	}, {
		"id": 42,
		"label": "caramel",
		"color": "#E68B3D",
		"parent_id": 4,
		"label_es": "caramelo",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "caramelo",
		"label_zh-CN": "焦糖",
		"weight": 1.17,
		"label_fr-CA": "caramel",
		"label_ko": "캐러멜",
		"label_id": "karamel",
		"label_ja": "キャラメル",
		"groups": []
	}, {
		"id": 43,
		"label": "maple syrup",
		"color": "#C8774D",
		"parent_id": 4,
		"label_es": "miel de maple",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "xarope de bordo",
		"label_zh-CN": "枫糖浆",
		"weight": 1.19,
		"label_fr-CA": "sirop d'érable",
		"label_ko": "메이플 시럽",
		"label_id": "sirup maple",
		"label_ja": "メープルシロップ",
		"groups": []
	}, {
		"id": 44,
		"label": "cola",
		"color": "#443932",
		"parent_id": 4,
		"label_es": "cola",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "cola",
		"label_zh-CN": "可乐",
		"weight": 1.25,
		"label_fr-CA": "cola",
		"label_ko": "콜라",
		"label_id": "kola",
		"label_ja": "コーラ",
		"groups": []
	}, {
		"id": 45,
		"label": "molasses",
		"color": "#826B5E",
		"parent_id": 4,
		"label_es": "melasa",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "melaço",
		"label_zh-CN": "糖蜜",
		"weight": 1.16,
		"label_fr-CA": "mélasse",
		"label_ko": "당밀",
		"label_id": "molase/gula tetes",
		"label_ja": "糖蜜",
		"groups": []
	}]
}, {
	"id": 5,
	"label": "nut",
	"color": "#F38546",
	"parent_id": null,
	"label_es": "nuez",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "flavor",
	"positive": 1,
	"label_pt": "noz",
	"label_zh-CN": "坚果",
	"weight": 1.15,
	"label_fr-CA": "noix",
	"label_ko": "견과류",
	"label_id": "kacang-kacangan",
	"label_ja": "ナッツ",
	"groups": [{
		"id": 46,
		"label": "walnut",
		"color": "#6D5143",
		"parent_id": 5,
		"label_es": "nuez de nogal",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "nozes",
		"label_zh-CN": "核桃",
		"weight": 1.13,
		"label_fr-CA": "noix de grenoble",
		"label_ko": "호두",
		"label_id": "walnut/kacang kenari",
		"label_ja": "ウォルナッツ、クルミ",
		"groups": []
	}, {
		"id": 47,
		"label": "peanut",
		"color": "#C0A770",
		"parent_id": 5,
		"label_es": "cacahuate",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "amendoim",
		"label_zh-CN": "花生",
		"weight": 1.1,
		"label_fr-CA": "arachide",
		"label_ko": "땅콩",
		"label_id": "peanut/kacang tanah",
		"label_ja": "ピーナッツ",
		"groups": []
	}, {
		"id": 48,
		"label": "cashew",
		"color": "#F9F9B1",
		"parent_id": 5,
		"label_es": "nuez de marañon",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "castanha de cajú",
		"label_zh-CN": "腰果",
		"weight": 1.17,
		"label_fr-CA": "noix de cajou",
		"label_ko": "캐슈",
		"label_id": "cashew/kacang mete",
		"label_ja": "カシューナッツ、カシュー",
		"groups": []
	}, {
		"id": 49,
		"label": "pecan",
		"color": "#FBF9A8",
		"parent_id": 5,
		"label_es": "pecano",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "noz-pecã",
		"label_zh-CN": "山核桃",
		"weight": 1.15,
		"label_fr-CA": "pacane",
		"label_ko": "피칸",
		"label_id": "pecan/kacang kemiri",
		"label_ja": "ピーカンナッツ、ピーカン",
		"groups": []
	}, {
		"id": 50,
		"label": "hazelnut",
		"color": "#E2A95B",
		"parent_id": 5,
		"label_es": "avellana",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "avelã",
		"label_zh-CN": "榛子",
		"weight": 1.17,
		"label_fr-CA": "noisette",
		"label_ko": "헤이즐넛",
		"label_id": "hazelnut",
		"label_ja": "ヘーゼルナッツ",
		"groups": []
	}, {
		"id": 51,
		"label": "almond",
		"color": "#FDFDD5",
		"parent_id": 5,
		"label_es": "almendra",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "amêndoas",
		"label_zh-CN": "杏仁",
		"weight": 1.16,
		"label_fr-CA": "amande",
		"label_ko": "아몬드",
		"label_id": "kacang almond",
		"label_ja": "アーモンド",
		"groups": []
	}]
}, {
	"id": 6,
	"label": "grain/cereal",
	"color": "#AB9566",
	"parent_id": null,
	"label_es": "granos/cereal",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "flavor",
	"positive": 1,
	"label_pt": "grãos/cereais",
	"label_zh-CN": "谷物",
	"weight": 1.12,
	"label_fr-CA": "grain/céréale",
	"label_ko": "곡물/곡류",
	"label_id": "gandum/sereal",
	"label_ja": "穀物、シリアル",
	"groups": [{
		"id": 52,
		"label": "sweet bread pastry",
		"color": "#F9C57E",
		"parent_id": 6,
		"label_es": "pan dulce",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "pão doce",
		"label_zh-CN": "甜面包／糕点",
		"weight": 1.01,
		"label_fr-CA": "pâtisserie sucrée",
		"label_ko": "달콤한 패스트리",
		"label_id": "roti manis",
		"label_ja": "甘いパン、甘いペーストリー",
		"groups": []
	}, {
		"id": 53,
		"label": "granola",
		"color": "#F9F9B3",
		"parent_id": 6,
		"label_es": "granola",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "granola",
		"label_zh-CN": "麦片",
		"weight": 1.0,
		"label_fr-CA": "granola",
		"label_ko": "그래놀라",
		"label_id": "granola",
		"label_ja": "グラノラ",
		"groups": []
	}, {
		"id": 54,
		"label": "graham cracker",
		"color": "#C2864B",
		"parent_id": 6,
		"label_es": "galleta salada",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "bolacha salgada",
		"label_zh-CN": "全麦饼干",
		"weight": 1.16,
		"label_fr-CA": "biscuits graham",
		"label_ko": "그레이엄 크래커",
		"label_id": "graham cracker/biskuit",
		"label_ja": "グラハムクラッカー",
		"groups": []
	}, {
		"id": 55,
		"label": "rye",
		"color": "#E8A46E",
		"parent_id": 6,
		"label_es": "centeno",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "centeio",
		"label_zh-CN": "黑麦",
		"weight": 1.0,
		"label_fr-CA": "seigle",
		"label_ko": "호밀",
		"label_id": "rye/gandum hitam",
		"label_ja": "ライ麦",
		"groups": []
	}, {
		"id": 56,
		"label": "wheat",
		"color": "#B3A782",
		"parent_id": 6,
		"label_es": "trigo",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "trigo",
		"label_zh-CN": "小麦",
		"weight": 1.18,
		"label_fr-CA": "blé",
		"label_ko": "밀",
		"label_id": "gandum",
		"label_ja": "小麦",
		"groups": []
	}, {
		"id": 57,
		"label": "fresh bread",
		"color": "#E5CA8B",
		"parent_id": 6,
		"label_es": "pan fresco",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "pão fresco",
		"label_zh-CN": "新鲜面包",
		"weight": 1.14,
		"label_fr-CA": "pain frais",
		"label_ko": "갓 구운 빵",
		"label_id": "roti segar",
		"label_ja": "焼きたてのパン",
		"groups": []
	}, {
		"id": 58,
		"label": "barley",
		"color": "#F9A76E",
		"parent_id": 6,
		"label_es": "cebada",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "cevada",
		"label_zh-CN": "大麦",
		"weight": 1.14,
		"label_fr-CA": "orge",
		"label_ko": "보리",
		"label_id": "barli/jelai",
		"label_ja": "大麦",
		"groups": []
	}, {
		"id": 242,
		"label": "malt",
		"color": "#C96C55",
		"parent_id": 6,
		"label_es": "malta",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "malte",
		"label_zh-CN": "麦芽",
		"weight": 1.17,
		"label_fr-CA": "malt",
		"label_ko": "맥아, 엿기름",
		"label_id": "gandum/malt",
		"label_ja": "モルト・麦芽",
		"groups": []
	}]
}, {
	"id": 7,
	"label": "roast",
	"color": "#766E6D",
	"parent_id": null,
	"label_es": "tostado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "flavor",
	"positive": 1,
	"label_pt": "torrado",
	"label_zh-CN": "烘焙",
	"weight": 1.13,
	"label_fr-CA": "pain grillé",
	"label_ko": "구운",
	"label_id": "matang/panggang",
	"label_ja": "ロースト、焙煎",
	"groups": [{
		"id": 59,
		"label": "toast",
		"color": "#C8A346",
		"parent_id": 7,
		"label_es": "pan tostado",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "torradas",
		"label_zh-CN": "吐司",
		"weight": 1.14,
		"label_fr-CA": "pain grillé",
		"label_ko": "토스트",
		"label_id": "terpanggang",
		"label_ja": "トースト",
		"groups": []
	}, {
		"id": 60,
		"label": "burnt sugar",
		"color": "#FABB8B",
		"parent_id": 7,
		"label_es": "azucar quemada",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "açúcar queimado",
		"label_zh-CN": "焦糖",
		"weight": 1.16,
		"label_fr-CA": "sucre brûlé",
		"label_ko": "졸인 설탕",
		"label_id": "gula gosong",
		"label_ja": "焦げた砂糖",
		"groups": []
	}, {
		"id": 61,
		"label": "smokey",
		"color": "#8A937B",
		"parent_id": 7,
		"label_es": "ahumado",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "defumado",
		"label_zh-CN": "烟熏",
		"weight": 1.15,
		"label_fr-CA": "fumé",
		"label_ko": "스모키",
		"label_id": "berasap",
		"label_ja": "スモーキー",
		"groups": []
	}, {
		"id": 62,
		"label": "carbon",
		"color": "#182729",
		"parent_id": 7,
		"label_es": "carbon",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "carbônico",
		"label_zh-CN": "炭烧",
		"weight": 1.11,
		"label_fr-CA": "carbone",
		"label_ko": "카본",
		"label_id": "karbon",
		"label_ja": "炭",
		"groups": []
	}]
}, {
	"id": 8,
	"label": "spice",
	"color": "#BF4141",
	"parent_id": null,
	"label_es": "especias",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "flavor",
	"positive": 1,
	"label_pt": "especiarias",
	"label_zh-CN": "香料",
	"weight": 1.2,
	"label_fr-CA": "épice",
	"label_ko": "향신료",
	"label_id": "rempah",
	"label_ja": "スパイス",
	"groups": [{
		"id": 63,
		"label": "black pepper",
		"color": "#B7C29B",
		"parent_id": 8,
		"label_es": "pimienta negra",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "pimenta-do-reino preta",
		"label_zh-CN": "黑胡椒",
		"weight": 1.18,
		"label_fr-CA": "poivre noir",
		"label_ko": "후추",
		"label_id": "lada hitam",
		"label_ja": "黒こしょう、黒胡椒、ブラックペッパー",
		"groups": []
	}, {
		"id": 64,
		"label": "white pepper",
		"color": "#B9BA91",
		"parent_id": 8,
		"label_es": "pimienta verde",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "pimenta-do reino branca",
		"label_zh-CN": "白胡椒",
		"weight": 1.2,
		"label_fr-CA": "poivre blanc",
		"label_ko": "백후추",
		"label_id": "merica putih",
		"label_ja": "白こしょう、白胡椒、ホワイトペッパー",
		"groups": []
	}, {
		"id": 65,
		"label": "cinnamon",
		"color": "#CB7450",
		"parent_id": 8,
		"label_es": "canela",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "canela",
		"label_zh-CN": "肉桂",
		"weight": 1.2,
		"label_fr-CA": "cannelle",
		"label_ko": "계피",
		"label_id": "cinnamon",
		"label_ja": "シナモン",
		"groups": []
	}, {
		"id": 66,
		"label": "coriander",
		"color": "#FBF9AE",
		"parent_id": 8,
		"label_es": "cilantro",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "coentro",
		"label_zh-CN": "香菜",
		"weight": 1.17,
		"label_fr-CA": "coriandre",
		"label_ko": "고수",
		"label_id": "ketumbar",
		"label_ja": "コリアンダー",
		"groups": []
	}, {
		"id": 67,
		"label": "ginger",
		"color": "#F68845",
		"parent_id": 8,
		"label_es": "gengibre",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "gengibre",
		"label_zh-CN": "姜",
		"weight": 1.21,
		"label_fr-CA": "gingembre",
		"label_ko": "생강",
		"label_id": "jahe",
		"label_ja": "生姜、ジンジャー",
		"groups": []
	}, {
		"id": 68,
		"label": "nutmeg",
		"color": "#EBAB59",
		"parent_id": 8,
		"label_es": "nuez moscada",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "noz-moscada",
		"label_zh-CN": "肉豆蔻",
		"weight": 1.22,
		"label_fr-CA": "noix de muscade",
		"label_ko": "넛맥",
		"label_id": "pala",
		"label_ja": "ナツメグ",
		"groups": []
	}, {
		"id": 69,
		"label": "licorice/anise",
		"color": "#131313",
		"parent_id": 8,
		"label_es": "anís",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "anis",
		"label_zh-CN": "欧亚干草／八角",
		"weight": 1.17,
		"label_fr-CA": "réglisse/anis",
		"label_ko": "감초/아니스",
		"label_id": "akar/adas manis",
		"label_ja": "アニス",
		"groups": []
	}, {
		"id": 70,
		"label": "clove",
		"color": "#424C44",
		"parent_id": 8,
		"label_es": "clavo",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "cravo",
		"label_zh-CN": "丁香",
		"weight": 1.25,
		"label_fr-CA": "girofle",
		"label_ko": "정향",
		"label_id": "cengkeh",
		"label_ja": "クローブ",
		"groups": []
	}, {
		"id": 71,
		"label": "curry",
		"color": "#F8A36A",
		"parent_id": 8,
		"label_es": "curry",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "curry",
		"label_zh-CN": "咖喱",
		"weight": 1.12,
		"label_fr-CA": "curry",
		"label_ko": "카레",
		"label_id": "kari",
		"label_ja": "カレー",
		"groups": []
	}]
}, {
	"id": 9,
	"label": "savory",
	"color": "#9B2E2B",
	"parent_id": null,
	"label_es": "salado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "flavor",
	"positive": 1,
	"label_pt": "salgados",
	"label_zh-CN": "鲜",
	"weight": 1.2,
	"label_fr-CA": "umami",
	"label_ko": "풍미있는",
	"label_id": "gurih",
	"label_ja": "旨味",
	"groups": [{
		"id": 72,
		"label": "leathery",
		"color": "#A64B34",
		"parent_id": 9,
		"label_es": "cuero",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "couro",
		"label_zh-CN": "皮革",
		"weight": 1.16,
		"label_fr-CA": "cuir",
		"label_ko": "가죽",
		"label_id": "kasar (kulit)",
		"label_ja": "革、革製品",
		"groups": []
	}, {
		"id": 73,
		"label": "meat-like",
		"color": "#6D3E37",
		"parent_id": 9,
		"label_es": "carne",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "carne",
		"label_zh-CN": "肉味",
		"weight": 1.14,
		"label_fr-CA": "boeuf cuit",
		"label_ko": "고기 같은",
		"label_id": "rasa daging",
		"label_ja": "肉",
		"groups": []
	}, {
		"id": 74,
		"label": "soy sauce",
		"color": "#50413A",
		"parent_id": 9,
		"label_es": "salsa de soya",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "molho de soja",
		"label_zh-CN": "酱油",
		"weight": 1.0,
		"label_fr-CA": "sauce de soja",
		"label_ko": "간장",
		"label_id": "kecap asin",
		"label_ja": "醤油",
		"groups": []
	}, {
		"id": 75,
		"label": "sundried tomato",
		"color": "#A13037",
		"parent_id": 9,
		"label_es": "tomate seco",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "tomate seco",
		"label_zh-CN": "番茄干",
		"weight": 1.0,
		"label_fr-CA": "tomates séchées",
		"label_ko": "햇볕에 말린 토마토",
		"label_id": "tomat kering",
		"label_ja": "サンドライトマト",
		"groups": []
	}, {
		"id": 76,
		"label": "tomato",
		"color": "#D5202A",
		"parent_id": 9,
		"label_es": "tomate",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "tomate",
		"label_zh-CN": "番茄",
		"weight": 1.2,
		"label_fr-CA": "tomate",
		"label_ko": "토마토",
		"label_id": "tomat",
		"label_ja": "トマト",
		"groups": []
	}]
}, {
	"id": 10,
	"label": "herb",
	"color": "#919F42",
	"parent_id": null,
	"label_es": "herbal",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "flavor",
	"positive": 1,
	"label_pt": "herbal",
	"label_zh-CN": "草药",
	"weight": 1.18,
	"label_fr-CA": "herbe",
	"label_ko": "허브",
	"label_id": "herbal",
	"label_ja": "ハーブ、草系",
	"groups": [{
		"id": 77,
		"label": "cedar",
		"color": "#B05549",
		"parent_id": 10,
		"label_es": "cedro",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "cedro",
		"label_zh-CN": "雪松",
		"weight": 1.19,
		"label_fr-CA": "cèdre",
		"label_ko": "삼나무",
		"label_id": "pohon cedar/aras",
		"label_ja": "杉、ヒマラヤ杉",
		"groups": []
	}, {
		"id": 78,
		"label": "olive",
		"color": "#7A9F55",
		"parent_id": 10,
		"label_es": "oliva",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "azeitona",
		"label_zh-CN": "橄榄",
		"weight": 1.21,
		"label_fr-CA": "olive",
		"label_ko": "올리브",
		"label_id": "zaitun",
		"label_ja": "オリーブ",
		"groups": []
	}, {
		"id": 79,
		"label": "dill",
		"color": "#94B96C",
		"parent_id": 10,
		"label_es": "eneldo",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "endro",
		"label_zh-CN": "莳萝",
		"weight": 1.18,
		"label_fr-CA": "aneth",
		"label_ko": "딜",
		"label_id": "daun dill/adas sowa",
		"label_ja": "ディル",
		"groups": []
	}, {
		"id": 80,
		"label": "sage",
		"color": "#7F9B5C",
		"parent_id": 10,
		"label_es": "salvia",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "sálvia",
		"label_zh-CN": "鼠尾草",
		"weight": 1.27,
		"label_fr-CA": "sauge",
		"label_ko": "세이지",
		"label_id": "daun sage",
		"label_ja": "セージ",
		"groups": []
	}, {
		"id": 81,
		"label": "mint",
		"color": "#C2DF83",
		"parent_id": 10,
		"label_es": "menta",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "menta",
		"label_zh-CN": "薄荷",
		"weight": 1.23,
		"label_fr-CA": "menthe",
		"label_ko": "민트",
		"label_id": "daun mint",
		"label_ja": "ミント",
		"groups": []
	}, {
		"id": 82,
		"label": "green tea",
		"color": "#ABAD4F",
		"parent_id": 10,
		"label_es": "té verde",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "chá verde",
		"label_zh-CN": "绿茶",
		"weight": 1.19,
		"label_fr-CA": "thé vert",
		"label_ko": "녹차",
		"label_id": "teh hijau",
		"label_ja": "緑茶",
		"groups": []
	}, {
		"id": 83,
		"label": "black tea",
		"color": "#675B5B",
		"parent_id": 10,
		"label_es": "té negro",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "chá preto",
		"label_zh-CN": "红茶",
		"weight": 1.28,
		"label_fr-CA": "thé noire",
		"label_ko": "홍차",
		"label_id": "teh hitam",
		"label_ja": "ブラックティー、紅茶",
		"groups": []
	}, {
		"id": 84,
		"label": "hops",
		"color": "#F69C46",
		"parent_id": 10,
		"label_es": "lupulo",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "lúpulo",
		"label_zh-CN": "啤酒花",
		"weight": 1.34,
		"label_fr-CA": "houblon",
		"label_ko": "홉",
		"label_id": "daun hops",
		"label_ja": "ホップ",
		"groups": []
	}, {
		"id": 85,
		"label": "bergamot",
		"color": "#F8FDDA",
		"parent_id": 10,
		"label_es": "bergamota",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "bergamota",
		"label_zh-CN": "佛手柑",
		"weight": 1.34,
		"label_fr-CA": "bergamote",
		"label_ko": "베르가못",
		"label_id": "jeruk bergamot",
		"label_ja": "ベルガモット",
		"groups": []
	}]
}, {
	"id": 11,
	"label": "earthy",
	"color": "#463B34",
	"parent_id": null,
	"label_es": "terroso",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "flavor",
	"positive": 1,
	"label_pt": "terroso",
	"label_zh-CN": "土味",
	"weight": 1.09,
	"label_fr-CA": "terreux",
	"label_ko": "흙",
	"label_id": "rasa tanah",
	"label_ja": "土の匂い、土臭さ",
	"groups": [{
		"id": 86,
		"label": "soil",
		"color": "#231D1B",
		"parent_id": 11,
		"label_es": "tierra",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "terra",
		"label_zh-CN": "泥土",
		"weight": 1.15,
		"label_fr-CA": "terre noire",
		"label_ko": "흙",
		"label_id": "rasa tanah",
		"label_ja": "土",
		"groups": []
	}, {
		"id": 87,
		"label": "fresh wood",
		"color": "#D28A4C",
		"parent_id": 11,
		"label_es": "madera fresca",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "madeira verde",
		"label_zh-CN": "新鲜木头",
		"weight": 1.17,
		"label_fr-CA": "bois frais",
		"label_ko": "싱싱한 나무",
		"label_id": "kayu segar",
		"label_ja": "新鮮な木材",
		"groups": []
	}, {
		"id": 88,
		"label": "tobacco",
		"color": "#994C2D",
		"parent_id": 11,
		"label_es": "tabaco",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "tabaco",
		"label_zh-CN": "烟草",
		"weight": 1.15,
		"label_fr-CA": "tabac",
		"label_ko": "담배",
		"label_id": "tembakau",
		"label_ja": "タバコ",
		"groups": []
	}, {
		"id": 89,
		"label": "hay/straw",
		"color": "#E4CA5E",
		"parent_id": 11,
		"label_es": "heno/paja",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "feno / palha",
		"label_zh-CN": "干草／稻草",
		"weight": 1.12,
		"label_fr-CA": "foin/paille",
		"label_ko": "짚/밀짚",
		"label_id": "jerami",
		"label_ja": "干し草・わら",
		"groups": []
	}, {
		"id": 90,
		"label": "mushroom",
		"color": "#C09B47",
		"parent_id": 11,
		"label_es": "hongos",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "cogumelo",
		"label_zh-CN": "蘑菇",
		"weight": 1.04,
		"label_fr-CA": "champignon",
		"label_ko": "버섯",
		"label_id": "jamur",
		"label_ja": "マッシュルーム",
		"groups": []
	}]
}, {
	"id": 12,
	"label": "vegetal",
	"color": "#66752A",
	"parent_id": null,
	"label_es": "vegetal",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "flavor",
	"positive": 1,
	"label_pt": "vegetal",
	"label_zh-CN": "蔬菜",
	"weight": 1.14,
	"label_fr-CA": "légume amidon",
	"label_ko": "채소류",
	"label_id": "dedaunan",
	"label_ja": "植物っぽい",
	"groups": [{
		"id": 91,
		"label": "leafy greens",
		"color": "#1D9F61",
		"parent_id": 12,
		"label_es": "hojas verdes",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "folhas verdes",
		"label_zh-CN": "绿叶菜",
		"weight": 1.0,
		"label_fr-CA": "à feuilles verts",
		"label_ko": "푸른잎 채소",
		"label_id": "sayur/daun hijau",
		"label_ja": "葉菜類",
		"groups": []
	}, {
		"id": 92,
		"label": "squash",
		"color": "#EFE63C",
		"parent_id": 12,
		"label_es": "calabacin",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "abobrinha",
		"label_zh-CN": "南瓜",
		"weight": 1.1,
		"label_fr-CA": "courge",
		"label_ko": "호박",
		"label_id": "labu",
		"label_ja": "かぼちゃ",
		"groups": []
	}, {
		"id": 93,
		"label": "sweet pea",
		"color": "#C09E43",
		"parent_id": 12,
		"label_es": "guisante",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "ervilha-de-cheiro",
		"label_zh-CN": "甜豌豆",
		"weight": 1.0,
		"label_fr-CA": "pois",
		"label_ko": "스위트피",
		"label_id": "sweet pea",
		"label_ja": "グリーンピース、えんどう豆",
		"groups": []
	}, {
		"id": 94,
		"label": "snow pea",
		"color": "#B9DA8E",
		"parent_id": 12,
		"label_es": "arveja",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "ervilha-torta / orelha-de-padre",
		"label_zh-CN": "荷兰豆",
		"weight": 1.12,
		"label_fr-CA": "pois mange-tout",
		"label_ko": "깍지완두",
		"label_id": "snow pea",
		"label_ja": "サヤエンドウ、絹さやえんどう",
		"groups": []
	}, {
		"id": 95,
		"label": "green pepper",
		"color": "#6DBF78",
		"parent_id": 12,
		"label_es": "pimienta verde",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "pimentão verde",
		"label_zh-CN": "青椒",
		"weight": 1.13,
		"label_fr-CA": "poivron vert",
		"label_ko": "피망",
		"label_id": "paprika hijau",
		"label_ja": "ピーマン",
		"groups": []
	}, {
		"id": 96,
		"label": "grassy",
		"color": "#85C680",
		"parent_id": 12,
		"label_es": "herboso",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "flavor",
		"positive": 1,
		"label_pt": "herbáceo",
		"label_zh-CN": "草味",
		"weight": 1.12,
		"label_fr-CA": "herbe",
		"label_ko": "풀향",
		"label_id": "rasa rerumputan",
		"label_ja": "草っぽい",
		"groups": []
	}]
}]
