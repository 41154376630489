import { Api } from "./api"

export class CombinedReportApi extends Api {
  constructor() {
    super('combined_report', ['aggregates'],
      {
        prefix: 'master_samples'
      }
    )
  }
}
