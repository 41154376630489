import React from "react"
import {Chip} from "@mui/material"

import {FilterChipProps} from "./filter-chip.props"

export const FilterChip = (props: FilterChipProps) => {
  const { label, onRemove } = props

  return (
    <Chip
      label={label}
      onDelete={onRemove}
      color="primary"
      sx={{'& .MuiChip-deleteIcon': {color: 'white'}}}
    />
  )
}
