import { Instance, SnapshotOut, types, applySnapshot, getSnapshot } from "mobx-state-tree"
import { withSetValue } from "models/extensions"
/**
 * Model description here for TypeScript hints.
 */
export const ModalStoreModel = types
  .model("ModalStore")
  .props({
    profile: types.optional(types.boolean, false),
    setting: types.optional(types.boolean, false),
    inviteCupper: types.optional(types.boolean, false),
    sampleId: types.optional(types.boolean, false),
    greenGrading: types.optional(types.boolean, false),
    greenGradingOptions: types.optional(types.boolean, false),
    privacy: types.optional(types.boolean, false),
    termsOfUse: types.optional(types.boolean, false),
    serverError: types.optional(types.boolean, false),
    addCard: types.optional(types.boolean, false),
    trialEnd: types.optional(types.boolean, false),
    welcome: types.optional(types.boolean, false),
    noConnection: types.optional(types.boolean, false),
    removeAccount: types.optional(types.boolean, false),
    masterSample: types.optional(types.boolean, false),
    scaPolicy: types.optional(types.boolean, false),
    settingTab: types.optional(types.string, 'profile')
  })
  .extend(withSetValue)
  .actions(self => ({
    resetError() {
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        serverError: false,
        noConnection: false
      })
    }
  }))

/**
* Un-comment the following to omit model attributes from your snapshots (and from async storage).
* Useful for sensitive data like passwords, or transitive state like whether a modal is open.

* Note that you'll need to import `omit` from ramda, which is already included in the project!
*  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
*/

type ModalStoreType = Instance<typeof ModalStoreModel>
export interface ModalStore extends ModalStoreType {}
type ModalStoreSnapshotType = SnapshotOut<typeof ModalStoreModel>
export interface ModalStoreSnapshot extends ModalStoreSnapshotType {}
