/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-28 18:31:58
 * @modify date 2022-02-01 16:38:32
 */

import React from 'react'
import { has } from 'lodash'
import { observer } from "mobx-react-lite"
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Typography } from '@mui/material'

import { useStores } from "models"
import { findRoute } from 'constants/routes'
import { AuthenticationWrapper, StackAlert } from 'components'

import { VerificationProps } from './verification.props'
import { globalAction } from 'utils'

export const Verification: React.FC<VerificationProps> = observer ((props: VerificationProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const params = useParams()

  const signInLink = findRoute('signIn')
  const homeLink = findRoute('home')
  const { image } = props
  const {
    userStore: { setValue: setUserValue, verifyEmail },
    modalStore: { setValue: setModalValue },
    notificationStore
  } = useStores()
  const [errors, setErrors] = React.useState<any>({})

  const onLoad = async() => {
    globalAction(notificationStore, {
      action: verifyEmail,
      afterAction: () => {
        setModalValue('welcome', true)
        setUserValue('fromActivation', true)
        setUserValue('isAuthenticated', true)
        navigate(homeLink, { replace: true })
      },
      setErrors: setErrors
    })
  }

  React.useEffect(() => {
    if (!params.token) {
      navigate(signInLink, { replace: true })
      return
    }

    setModalValue('serverError', false)
    setUserValue('token', params.token)
    onLoad()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthenticationWrapper title={t('verification.title')} image={image} >
      {has(errors, 'base') && <StackAlert alerts={errors['base']} sx={{mb: 2}} />}

      <Typography align="center" variant="h6" sx={{my: 2}}>
        {t('verification.haveAnAccount')}
        <Link to={signInLink}>{t('verification.signIn')}</Link>
      </Typography>
    </AuthenticationWrapper>
  )
})

export default Verification
