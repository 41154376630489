/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-30 13:05:28
 * @modify date 2022-01-30 13:05:28
 * @desc [description]
 */
import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { ArrowCircleLeftOutlined } from '@mui/icons-material'
import {Box, Container, Stack, Typography} from '@mui/material'

import { useStyles } from './header-title-wide.styles'
import { HeaderTitleWideProps } from './header-title-wide.props'
import {isArray} from "lodash";

export const HeaderTitleWide: React.FC<HeaderTitleWideProps> = (props: HeaderTitleWideProps) => {
  const classes = useStyles()
  const { title, subtitle, backUrl, breadcrumb, rightContent, dataLinks, ...rest } = props

  const renderSubtitle = () => {
    if (typeof subtitle === 'string') {
      return (
        <Typography>
          {ReactHtmlParser(subtitle)}
        </Typography>
      )
    }

    return subtitle
  }

  return (
    <Box>
      {isArray(dataLinks) ?
          <Stack direction="row" spacing={2} className={classes.linkWrapper}>
            {dataLinks.map((data, index) => {
              return(
                <Box key={index} className={classes.linkWrapper}>
                  { data.backUrl && (
                    <Link to={data.backUrl} className={classes.link}>
                      <ArrowCircleLeftOutlined />
                    </Link>
                  )}
                  <Typography variant='body2'>
                    {data.breadcrumb}
                  </Typography>
                </Box>
              )
            })}
          </Stack>
        :
          <Box className={classes.linkWrapper}>
            { backUrl && (
              <Link to={backUrl} className={classes.link}>
                <ArrowCircleLeftOutlined />
              </Link>
            )}
            <Typography variant='body2'>
              {breadcrumb}
            </Typography>
          </Box>
      }
      <Box className={classes.titleWrapper}>
        <Box className={clsx(classes.title, rightContent && 'has-content')}>
          {title && (
            <Typography variant="h4" sx={{wordBreak: 'break-word', pr: 2}}>
              {title}
            </Typography>
          )}
          {subtitle && renderSubtitle()}
        </Box>
        {rightContent}
      </Box>
    </Box>
  )
}
