/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-28 18:31:58
 * @modify date 2022-03-31 01:04:25
 */

import React from 'react'
import { has, toString, isBoolean } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {
  TextField,
  Container,
  Typography,
  Button,
  Grid,
  Collapse,
  FormControlLabel,
  Radio,
  Divider
} from '@mui/material'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { ExpandMore, ExpandLess } from '@mui/icons-material'

import { useStores } from 'models'
import { ThankYou } from 'pages'
import { findRoute } from 'constants/routes'
import { roles, companyTypes} from "constants/form"
import { AuthenticationWrapper, StackAlert } from 'components'
import {validateEmail, errorField, globalAction, str2bool, errorStatus, errorMessage} from 'utils'

import { useStyles } from './sign-up.styles'
import { SignUpProps } from './sign-up.props'

export const SignUp: React.FC<SignUpProps> = observer ((props: SignUpProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { image } = props
  const signInLink = findRoute('signIn')
  const {
    userStore: { email, isAuthenticated, newRegistration, role: userRole, isQgrader, isProducer, setValue: setUserValue },
    companyStore: { companyType, setValue: setCompanyValue },
    notificationStore
  } = useStores()

  const [openThankYou, setOpenThankYou] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})
  const [expandRole, setExpandRole] = React.useState(true)
  const [expandType, setExpandType] = React.useState(true)
  const [expandOption, setExpandOption] = React.useState(true)
  const [isSpecialEvent, setIsSpecialEvent] = React.useState(false)

  const signUp = async () => {
    setErrors({})

    if (!validateEmail(email) || !userRole || !isBoolean(isQgrader) || !companyType || !isBoolean(isProducer)) {
      setErrors({
        ...(!validateEmail(email) && {"email": [{ message: t('signUp.error.emailIsInvalid') }]}),
        ...(!userRole && {"role": [{ message: t('signUp.error.fieldIsRequired') }]}),
        ...(!isBoolean(isQgrader) && {"isQgrader": [{ message: t('signUp.error.fieldIsRequired') }]}),
        ...(!isBoolean(isProducer) && {"isProducer": [{ message: t('signUp.error.fieldIsRequired') }]}),
        ...(!companyType && {"companyType": [{ message: t('signUp.error.fieldIsRequired') }]}),
      })
      return
    }

    if (!executeRecaptcha) return

    const token = await executeRecaptcha('public')
    setUserValue('captchaToken', token)

    await globalAction(notificationStore, {
      action: async () => await newRegistration(isSpecialEvent),
      afterAction: () => {
        setOpenThankYou(true)
      },
      onError: () => {
        setUserValue('captchaToken', null)
      },
      setErrors: setErrors
    })
  }

  React.useEffect(() => {
    if (location.pathname === '/special-event/register') setIsSpecialEvent(true)
  }, [])

  return (
    <AuthenticationWrapper title={t(isSpecialEvent ? 'signUp.specialEvent.title' : 'signUp.title')} image={image} >
      <Typography variant='h4' textAlign='center' sx={{mb: 2, mt: -5}}>
        {isAuthenticated ? t('signUp.completeRegistration') : t(isSpecialEvent ? 'signUp.specialEvent.header' : 'signUp.header')}
      </Typography>
      {has(errors, 'base') && <StackAlert alerts={errors['base']} />}

      <TextField
        fullWidth
        margin='normal'
        variant="standard"
        label={t('signUp.email')}
        value={email}
        onChange={event => setUserValue('email', event.target.value)}
        {...errorField('email', email, errors)}
      />

      <Grid container sx={{mb: 3}}>
        <Grid item xs={12} className={classes.expandWrapper} onClick={() => setExpandRole(!expandRole)}>
          <Typography variant='h6'>{t('signUp.chooseRole')}</Typography>
          {expandRole ? <ExpandLess /> : <ExpandMore />}
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expandRole} timeout="auto" unmountOnExit>
            <Grid container spacing={1}>
              {roles.map(role =>
                <Grid item xs={12} md={6}>
                <FormControlLabel
                  key={role.key}
                  value={role.key}
                  control={<Radio color="primary" />}
                  label={toString(t(role.label))}
                  labelPlacement="end"
                  checked={role.key === userRole}
                  onChange={e => setUserValue('role', (e.target as HTMLInputElement).value)}
                />
                </Grid>
              )}
            </Grid>
          </Collapse>
          {errorStatus('role', userRole, errors) &&
            <Typography variant='caption' color='error'>{errorMessage('role', userRole, errors)}</Typography>
          }
        </Grid>
        { expandRole && <Grid item xs={12} sx={{mt: 3}}><Divider /></Grid> }

        <Grid item xs={12} className={classes.expandWrapper} onClick={() => setExpandType(!expandType)}>
          <Typography variant='h6'>{t('signUp.doesYourOrganizationDo')}</Typography>
          {expandType ? <ExpandLess /> : <ExpandMore />}
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expandType} timeout="auto" unmountOnExit>
            <Grid container spacing={1}>
              {companyTypes.map(org =>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    key={org.key}
                    value={org.key}
                    control={<Radio color="primary" />}
                    label={toString(t(org.label))}
                    labelPlacement="end"
                    checked={org.key === companyType}
                    onChange={e => setCompanyValue('companyType', (e.target as HTMLInputElement).value)}
                  />
                </Grid>
              )}
            </Grid>
          </Collapse>
          {errorStatus('companyType', companyType, errors) &&
              <Typography variant='caption' color='error'>{errorMessage('companyType', companyType, errors)}</Typography>
          }
        </Grid>
        { expandType && <Grid item xs={12} sx={{mt: 3}}><Divider /></Grid>}

        <Grid item xs={12} className={classes.expandWrapper} onClick={() => setExpandOption(!expandOption)}>
          <Typography variant='h6'>{t('signUp.areYouQgrader')}</Typography>
          {expandOption ? <ExpandLess /> : <ExpandMore />}
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expandOption} timeout="auto" unmountOnExit>
            <Grid container spacing={1}>
              {[true, false].map(bool =>
                <Grid item xs={6}>
                  <FormControlLabel
                    key={`option-${bool}`}
                    value={bool}
                    control={<Radio color="primary" />}
                    label={toString(t(`options.boolean.${bool}`))}
                    labelPlacement="end"
                    checked={bool === isQgrader}
                    onChange={e => setUserValue('isQgrader', str2bool((e.target as HTMLInputElement).value))}
                  />
                </Grid>
              )}
            </Grid>
          </Collapse>
          {errorStatus('isQgrader', isQgrader, errors) &&
              <Typography variant='caption' color='error'>{errorMessage('isQgrader', isQgrader, errors)}</Typography>
          }
        </Grid>

        { expandType && <Grid item xs={12} sx={{mt: 3}}><Divider /></Grid>}
        <Grid item xs={12} className={classes.expandWrapper} onClick={() => setExpandOption(!expandOption)}>
          <Typography variant='h6'>{t('signUp.areYouProducer')}</Typography>
          {expandOption ? <ExpandLess /> : <ExpandMore />}
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expandOption} timeout="auto" unmountOnExit>
            <Grid container spacing={1}>
              {[true, false].map(bool =>
                <Grid item xs={6}>
                  <FormControlLabel
                    key={`option-${bool}`}
                    value={bool}
                    control={<Radio color="primary" />}
                    label={toString(t(`options.boolean.${bool}`))}
                    labelPlacement="end"
                    checked={bool === isProducer}
                    onChange={e => setUserValue('isProducer', str2bool((e.target as HTMLInputElement).value))}
                  />
                </Grid>
              )}
            </Grid>
          </Collapse>
          {errorStatus('isProducer', isProducer, errors) &&
            <Typography variant='caption' color='error'>{errorMessage('isProducer', isProducer, errors)}</Typography>
          }
        </Grid>
      </Grid>

      <Container className={classes.buttonWrapper}>
        <Button
          variant="contained"
          onClick={signUp}
          className={classes.button}
          sx={{ fontSize: 'h6.fontSize' }}
        >
          {t('signUp.signUp')}
        </Button>
      </Container>

      <Typography align="center" variant="h6" sx={{my: 2}}>
        {t('signUp.haveAnAccount')}
        <Link to={signInLink}>{t('signUp.signIn')}</Link>
      </Typography>

      <ThankYou
        email={email}
        open={openThankYou}
        close={() => {
          setOpenThankYou(false)
          navigate(signInLink)
        }}
      />
    </AuthenticationWrapper>
  )
})

export default SignUp
