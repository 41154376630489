import React from 'react'
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import {Box} from "@mui/material";
import {Elements} from "@stripe/react-stripe-js";

import { useStores } from "models"
import {DormantModal, ModalMessage, ModalWrapper, StripePayment} from "components"
import {STRIPE_PROMISE} from "config/env";

export const DormantAccount = () => {
  const { t } = useTranslation()
  const params = useParams()
  const rootStore = useStores()
  const {
    reset: resetStore,
    companyStore: { getCompany }
  } = useStores()

  const [openModalMessage, setOpenModalMessage] = React.useState(false)
  const [openDormantModal, setOpenDormantModal] = React.useState(false)
  const [openDormant, setOpenDormant] = React.useState(false)
  const [openStripeCard, setOpenStripeCard] = React.useState(false)
  const [openError, setOpenError] = React.useState(false)
  const [error, setError] = React.useState('')

  const redirect = () => {
    window.location.href = '/'
  }

  const openStripe = () => {
    setOpenDormantModal(false)
    setOpenStripeCard(true)
  }

  React.useEffect(() => {
    resetStore(false)

    if (!params.token) {
      redirect()
      return
    }

    (async () => {
      try {
        await getCompany({ subscription_customer_id: params.token})
        setOpenDormantModal(true)
      } catch (e: any) {
        if (e.errors) {
          setError(e.errors.base[0].message)
          setOpenError(true)
          return
        }

        redirect()
      }
    })()
  }, [])

  return (
    <>
      {openDormantModal &&
        <DormantModal
          rootStore={rootStore}
          open={openDormantModal}
          setOpen={openStripe}
          openDormant={openDormant}
          setOpenDormant={setOpenDormant}
          isActivation
        />
      }

      <ModalWrapper
        maxWidth='xs'
        open={openStripeCard}
        onClose={() => {
          setOpenDormantModal(true)
          setOpenStripeCard(false)
        }}
      >
        <Box sx={{p: 4}}>
          <Elements stripe={STRIPE_PROMISE}>
            <StripePayment
              subscriptionCustomerId={params.token}
              onSuccess={() => {
                setOpenStripeCard(false)
                setOpenModalMessage(true)
              }}
            />
          </Elements>
        </Box>
      </ModalWrapper>

      <ModalMessage
        open={openModalMessage}
        onSubmit={() => {
          setOpenModalMessage(false)
          redirect()
        }}
        description={t('subscription.success.dormantAccountSuccessMessage')}
      />

      <ModalMessage
        open={openError}
        onSubmit={() => {
          setOpenError(false)
          redirect()
        }}
        description={error}
        type={'error'}
      />
    </>
  )
}

export default DormantAccount
