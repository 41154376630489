/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-07 19:28:17
 * @modify date 2022-03-07 19:28:17
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      fontWeight: 600,
      color: colors.primary.main
    },
    roastLevelIcon: {
      width: 24,
      height: 24,
      '&.color-0': { fill: '#A5715B' },
      '&.color-25': { fill: '#8C4E34' },
      '&.color-50': { fill: '#8C3B19' },
      '&.color-75': { fill: '#652C14' },
      '&.color-100': { fill: '#451400' },
    },
  })
)
