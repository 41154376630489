import React, {useEffect, useState} from "react";
import {cloneDeep, every, isEmpty, keys, some} from "lodash";
import {ModalWrapper} from "components";
import {useTranslation} from "react-i18next";
import {
  Box,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Accordion,
  AccordionDetails, AccordionSummary, Grid
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material"
import { colors } from "assets";

const PREFIX = 'shipment.rejectModal'

export const RejectModal = (props) => {
  const { t } = useTranslation()
  const {open, onClose, onReject} = props

  const defaultChecked = {
    cup: {
      checked: false,
      indeterminate: false,
      children: {
        profile: {checked: false},
        defect: {
          checked: false,
          indeterminate: false,
          children: {
            age: {checked: false},
            fungus: {checked: false},
            potato: {checked: false},
            baggy: {checked: false},
            mold: {checked: false},
            quaker: {checked: false},
            chemical: {checked: false},
            phenol: {checked: false},
            rubber: {checked: false},
            ferment: {checked: false},
            rio: {checked: false},
          }
        }
      }
    },
    physical: {
      checked: false,
      indeterminate: false,
      children: {
        moistureContent: {checked: false},
        foreignMatter: {checked: false},
        defectCount: {checked: false},
        screenSize: {checked: false},
        density: {checked: false},
      }
    },
    otherReason: {
      checked: false,
      indeterminate: false,
      children: {
        containerDamage: {checked: false},
        ochratoxin: {checked: false},
        glyphosate: {checked: false},
        other: {checked: false},
        aflatoxin: {checked: false},
      }
    }
  }

  const [checked, setChecked] = useState<any>(defaultChecked);
  const [description, setDescription] = useState('')
  const [reason, setReason] = useState('')
  const [chackedOrther, setCheckedOther] =  useState(false)
  const [clickSubmit, setClickSubmit] =  useState(false)

  const descriptionStyle = {
    borderRadius: '5px',
    border: `1px solid #E13824`,
  }
  const descriptionDefaultStyle = {
    borderRadius: '5px',
  }

  const accordionStyle = {
    borderRadius: '5px',
    border: `1px solid #CBCED1`,
    boxShadow: 'none',
    backgroundColor: colors.magnolia,
    marginBottom: '16px',
    '& .MuiAccordionSummary-root.Mui-expanded': {
      borderBottom: `1px solid #CBCED1`,
    }
  }

  const childAccordionStyle = {
    borderRadius: 0,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }

  const checkAllChildren = (newChecked, targetChecked) => {
    if (newChecked.children) {
      Object.keys(newChecked.children).forEach((childrenKey) => {
        newChecked.children[childrenKey].checked = targetChecked
        if (newChecked.children[childrenKey].indeterminate) {
          newChecked.children[childrenKey].indeterminate = false
        }

        checkAllChildren(newChecked.children[childrenKey], targetChecked)
      })
    }
  }

  const setIndeterminate = (newChecked) => {
    let indeterminate = false
    if (newChecked.children) {
      indeterminate = some(newChecked.children, (children) => {
        return children.checked || children.indeterminate
      })

      newChecked.indeterminate = indeterminate
    }
  }

  const setEveryChildChecked = (newChecked) => {
    if (newChecked.children) {
      Object.keys(newChecked.children).forEach((childrenKey) => {
        setEveryChildChecked(newChecked.children[childrenKey])
        if (childrenKey === "other") setCheckedOther(newChecked.children[childrenKey])
      })

      if (every(newChecked.children, (children) => children.checked)) {
        newChecked.checked = true
        newChecked.indeterminate = false
      } else {
        newChecked.checked = false
        newChecked.indeterminate = some(newChecked.children, (children) => children.checked || children.indeterminate)
      }
    }
  }

  const handleCheck = (event, parentKey, childrenKey?, grandChildrenKey?) => {
    const newChecked = cloneDeep(checked)

    if (grandChildrenKey){
      newChecked[parentKey].children[childrenKey].children[grandChildrenKey].checked = event.target.checked
      setIndeterminate(newChecked[parentKey].children[childrenKey])
      setIndeterminate(newChecked[parentKey])
    } else if (childrenKey) {
      newChecked[parentKey].children[childrenKey].checked = event.target.checked
      setIndeterminate(newChecked[parentKey])

      checkAllChildren(newChecked[parentKey].children[childrenKey], event.target.checked)
    } else {
      newChecked[parentKey].checked = event.target.checked
      newChecked[parentKey].indeterminate = false

      checkAllChildren(newChecked[parentKey], event.target.checked)
    }

    setEveryChildChecked(newChecked[parentKey])
    setChecked(newChecked)

    if (childrenKey === "other") setCheckedOther(newChecked[parentKey].children[childrenKey].checked)

  }

  const textReason = (checkedReason) => {
    let text = ''

    keys(checkedReason).forEach((key, index) => {
      const isChildrenChecked = some(checkedReason[key].children, (children) => children.checked || children.indeterminate)

      if (checkedReason[key].checked || checkedReason[key].indeterminate) {
        if (index > 0 && text !== '') {
          text += ', '
        }

        text += `${t(`${PREFIX}.${key}`)}`
      }

      if (checkedReason[key].children && isChildrenChecked) {
        text += ` (${textReason(checkedReason[key].children)})`
      }
    })

    return text
  }

  useEffect(() => {
    if (!open) {
      setChecked(defaultChecked)
      setDescription('')
    }
  }, [open])

  useEffect(() => {
    let text = textReason(checked)
    if (text) text += '. '

    setReason(text + description)
  }, [checked, description])

  return (
    <ModalWrapper open={open} maxWidth="sm">
      <Box textAlign="center" mx={4} mt={1}>
        <Typography variant="h5" mb={1}>{t('shipment.rejectModal.title')}</Typography>
        <Typography variant="body2">{t('shipment.rejectModal.description')}</Typography>
        <Typography variant="body2" mb={2}>{t('shipment.rejectModal.confirmation')}</Typography>

        <Typography variant="body2" mb={2}>{t('shipment.rejectModal.checkRejectionReason')}</Typography>

        <Accordion sx={accordionStyle}>
          <AccordionSummary expandIcon={<KeyboardArrowDown />}>
            <FormControlLabel
              label={t(`${PREFIX}.cup`)}
              control={
                <Checkbox
                  checked={checked.cup.checked}
                  indeterminate={checked.cup.indeterminate}
                  onChange={(e) => handleCheck(e, 'cup')}
                  onClick={(e) => e.stopPropagation()}
                />
              }
            />
          </AccordionSummary>
          <AccordionDetails sx={{px: 3}}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                label={t(`${PREFIX}.profile`)}
                control={
                  <Checkbox
                    checked={checked.cup.children.profile.checked}
                    onChange={(e) => handleCheck(e, 'cup', 'profile')}
                  />
                }
              />
              <Accordion sx={childAccordionStyle}>
                <AccordionSummary expandIcon={<KeyboardArrowDown />} sx={{ p: 0 }}>
                  <FormControlLabel
                    label={t(`${PREFIX}.defect`)}
                    control={
                      <Checkbox
                        checked={checked.cup.children.defect.checked}
                        indeterminate={checked.cup.children.defect.indeterminate}
                        onChange={(e) => handleCheck(e, 'cup', 'defect')}
                        onClick={(e) => e.stopPropagation()}
                      />
                    }
                  />
                </AccordionSummary>
                <AccordionDetails sx={{px: 3}}>
                  <Grid container spacing={1}>
                    {Object.keys(checked.cup.children.defect.children).map((key) => {
                      return (
                        <Grid key={key} item md={4} xs={6} textAlign="left">
                          <FormControlLabel
                            label={t(`${PREFIX}.${key}`)}
                            control={
                              <Checkbox
                                checked={checked.cup.children.defect.children[key].checked}
                                onChange={(e) => handleCheck(e, 'cup', 'defect', key)}
                              />
                            }
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={accordionStyle}>
          <AccordionSummary expandIcon={<KeyboardArrowDown />}>
            <FormControlLabel
              label={t(`${PREFIX}.physical`)}
              control={
                <Checkbox
                  checked={checked.physical.checked}
                  indeterminate={checked.physical.indeterminate}
                  onChange={(e) => handleCheck(e, 'physical')}
                  onClick={(e) => e.stopPropagation()}
                />
              }
            />
          </AccordionSummary>
          <AccordionDetails sx={{px: 3}}>
            <Grid container spacing={1}>
              {Object.keys(checked.physical.children).map((key) => {
                return (
                  <Grid key={key} item xs={6} textAlign="left">
                    <FormControlLabel
                      label={t(`${PREFIX}.${key}`)}
                      control={
                        <Checkbox
                          checked={checked.physical.children[key].checked}
                          onChange={(e) => handleCheck(e, 'physical', key)}
                        />
                      }
                    />
                  </Grid>
                )
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={accordionStyle}>
          <AccordionSummary expandIcon={<KeyboardArrowDown />}>
            <FormControlLabel
              label={t(`${PREFIX}.otherReason`)}
              control={
                <Checkbox
                  checked={checked.otherReason.checked}
                  indeterminate={checked.otherReason.indeterminate}
                  onChange={(e) => handleCheck(e, 'otherReason')}
                  onClick={(e) => e.stopPropagation()}
                />
              }
            />
          </AccordionSummary>
          <AccordionDetails sx={{px: 3}}>
            <Grid container spacing={1}>
              {Object.keys(checked.otherReason.children).map((key) => {
                return (
                  <Grid key={key} item xs={6} textAlign="left">
                    <FormControlLabel
                      label={t(`${PREFIX}.${key}`)}
                      control={
                        <Checkbox
                          checked={checked.otherReason.children[key].checked}
                          onChange={(e) => handleCheck(e, 'otherReason', key)}
                        />
                      }
                    />
                  </Grid>
                )
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Typography variant="body2">{t('shipment.rejectModal.infoDescription')}</Typography>
        <TextField
          multiline
          rows={4}
          variant="outlined"
          sx={(isEmpty(description) && chackedOrther && clickSubmit) ? descriptionStyle : descriptionDefaultStyle}
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <Stack direction="row" justifyContent="center" spacing={1} mt={4} mb={1}>
          <Button variant="contained" onClick={() => {
            setClickSubmit(true)
            if(isEmpty(description) && chackedOrther) return
            onReject(reason, checked)
          }} sx={{ px: 3}} disabled={!reason}>
            {t('shipment.button.reject')}
          </Button>
          <Button variant="outlined" onClick={() => {
            setClickSubmit(false)
            setDescription('')
            setCheckedOther(false)
            onClose()
          }} sx={{ px: 3}}>
            {t('common.cancel')}
          </Button>
        </Stack>
      </Box>
    </ModalWrapper>
  )
}
