/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 20:03:48
 * @modify date 2022-02-06 20:03:48
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: colors.magnolia,
      padding: theme.spacing(4),
      borderRadius: 5,
      border: `1px solid ${colors.magnolia}`,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
        borderRadius: 0,
      }
    },
    accordion: {
      boxShadow: 'none',
      borderBottom: `1px solid ${colors.magnolia}`,
      '& .MuiAccordionDetails-root': {
        padding: 0,

        '&.content': {
          padding: theme.spacing(1),
          margin: theme.spacing(2),
          marginTop: 0,
          border: `1px solid ${colors.magnolia}`,
        }
      }
    },
    table: {
      '& tr > td:first-child': {
        fontWeight: 'bold',
      },
      '& td': {
        verticalAlign: 'top',
      }
    },
  })
)
