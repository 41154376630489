/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-01 02:10:55
 * @modify date 2022-03-01 02:10:55
 */
import React from 'react'
import clsx from 'clsx'
import { v4 as uuid4 } from 'uuid'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {isMobile} from "react-device-detect"
import {find, isEmpty, some, clone, uniq, pull, isUndefined, range} from 'lodash'
import {
  Card,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
  InputAdornment,
  Chip,
  Divider,
  Autocomplete, MenuItem, Select, Stack,
  Switch, FormControlLabel,
} from '@mui/material'
import { AddCircle, RemoveCircle, AddCircleOutline, TurnLeft, Search as SearchIcon } from '@mui/icons-material'

import { colors } from 'assets'
import { useStores } from 'models'
import { DescriptorSlide, ModalWrapper } from 'components'
import {
  addAlpha,
  descriptorSelection,
  descriptorTranslation,
  descriptorColor,
  setDescriptor,
  flatListDescriptors
} from 'utils'
import {CuppingProtocol} from "constants/constant"
import {
  maximumCommercialScore,
  maximumCupScore, maximumScore, minimumCommercialScore,
  minimumCupScore, minimumScore,
  scoreCommercialStep,
  scoreStep
} from "constants/form"

import { useStyles } from './descriptor.styles'
import { DescriptorProps } from './descriptor.props'

export const Descriptor: React.FC<DescriptorProps> = observer((props: DescriptorProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    title, descriptors, descriptorList, slides, score, isRow, isEmptyDescriptor, cuppingProtocol,
    addDescriptor, setScore, decreaseScore, addScore, children, maxSelection
  } = props
  const {
    userStore: { languagePreference },
    guideStore: { guideScore }
  } = useStores()

  const [openDescriptor, setOpenDescriptor] = React.useState(false)
  const [customDescriptor, setCustomDescriptor] = React.useState('')
  const [temporaryDescriptors, setTemporaryDescriptors] = React.useState<any[]>([])
  const [temporaryDescriptorList, setTemporaryDescriptorList] = React.useState<any[]>([])
  const [temporaryDescriptorFlatList, setTemporaryDescriptorFlatList] = React.useState<any[]>([])
  const [showWarningClose, setShowWarningClose] = React.useState(false)
  const [openMainDescriptor, setOpenMainDescriptor] = React.useState(false)
  const modalRef = React.createRef<HTMLDivElement | undefined>()

  const descriptorStyle = (descriptor: any, isActive: boolean) => {
    if (isEmpty(temporaryDescriptors) || isActive) {
      return {
        borderColor: descriptor.color,
        boxShadow: isActive ? `0px 0px 4px ${addAlpha(descriptor.color, 0.5)}`: ''
      }
    }

    return {
      borderColor: colors.border.primary,
      color: colors.text.o50
    }
  }

  const addOrRemoveTemporaryDescriptors = (descriptor: any, isActive: boolean, isShortcut = false) => {
    const newDescriptors = clone(temporaryDescriptors)
    if (!newDescriptors) return

    isActive ? pull(newDescriptors, descriptor) : newDescriptors.push(descriptor)
    setTemporaryDescriptors(uniq(newDescriptors))

    if (isShortcut && addDescriptor) addDescriptor(setDescriptor(uniq(newDescriptors)))
  }

  const updateDescriptorList = (temporaryList, descriptor, isActive) => {
    const applySelected = find(temporaryList, ['id', descriptor.id])
    if (applySelected) {
      temporaryList.forEach(list => list.selected = false)
      applySelected.selected = !isActive
      return
    }

    const next = find(temporaryList, 'selected')
    if (!next) return

    updateDescriptorList(next.groups, descriptor, isActive)
  }

  const addOrRemoveGroupDescriptors = (descriptor, isActive: boolean) => {
    const newTemporaryDescriptorList = clone(temporaryDescriptorList)
    updateDescriptorList(newTemporaryDescriptorList, descriptor, isActive)
    setTemporaryDescriptorList(newTemporaryDescriptorList)
  }

  const addCustomDescriptor = () => {
    if (!temporaryDescriptorList) return

    const descriptorObject = {
      id: uuid4(),
      label: customDescriptor
    }

    setTemporaryDescriptorList(uniq([...temporaryDescriptorList, descriptorObject]))
    addOrRemoveTemporaryDescriptors(descriptorObject, false)
    setCustomDescriptor('')
  }

  const descriptorPicker = (descriptor) => {
    const hasGroups = !isEmpty(descriptor.groups)
    const isActive = hasGroups ? descriptor.selected : some(temporaryDescriptors, ['label', descriptor.label])

    return (
      <Chip
        key={descriptor.id}
        size="small"
        variant='outlined'
        label={descriptorTranslation(languagePreference, descriptor)}
        deleteIcon={<AddCircleOutline />}
        sx={{ ...descriptorStyle(descriptor, isActive), ...descriptorColor(descriptor, isActive) }}
        {...(!isActive && {onClick: () => hasGroups ? addOrRemoveGroupDescriptors(descriptor, isActive) : addOrRemoveTemporaryDescriptors(descriptor, isActive)})}
        {...(isActive && hasGroups && {onDelete: () => addOrRemoveTemporaryDescriptors(descriptor, false)})}
        {...(maxSelection && {disabled: temporaryDescriptors.length >= maxSelection})}
      />
    )
  }

  const descriptorItems = () => {
    if (isEmpty(descriptors) && isMobile) return null

    if (isEmpty(descriptors)) {
      return (
        <Typography variant='body3' className={classes.hasNoDescriptor}>
          {t('descriptor.noDescriptorInfo')}
        </Typography>
      )
    }

    return (
      <Box sx={{my: 2}}>
        {temporaryDescriptors?.map(descriptor =>
          <Chip
            key={descriptor.id}
            size="small"
            variant='outlined'
            sx={descriptorColor(descriptor)}
            label={descriptorTranslation(languagePreference, descriptor)}
            onDelete={() => addOrRemoveTemporaryDescriptors(descriptor, true, true)}
          />
        )}
      </Box>
    )
  }

  const descriptorContent = (
    <Box className={clsx(classes.descriptorWrapper, isEmpty(temporaryDescriptors) && 'no-descriptor')}>
      {descriptorItems()}
    </Box>
  )

  const titleMargin = () => {
    let mb = temporaryDescriptors ? 1 : 5
    let mr = 0

    if (isRow) {
      mb = 0
      mr = 2
    }

    return {
      mb,
      mr,
      ...(isEmptyDescriptor && {
        flex: 1,
        alignSelf: 'flex-start'
      })
    }
  }

  const renderDescriptors = (descriptors, parentDescriptor: any = {}, isChild: boolean = false) => {
    if (isEmpty(descriptors)) return

    return (
      <Box key={uuid4()} sx={{mt: 2}}>
        {isChild &&
          <Box sx={{display: 'inline-flex'}}>
            <TurnLeft className={classes.leftArrow}/>
            <Typography>{t('descriptor.descriptorOf')}</Typography>
            <Chip
              key={parentDescriptor.id}
              size="small"
              variant='outlined'
              sx={{...descriptorColor(parentDescriptor), ml: 1}}
              label={descriptorTranslation(languagePreference, parentDescriptor)}
            />
          </Box>
        }
        <Box className={classes.descriptorWrapper} sx={{ml: isChild ? 3 : 0}}>
          {descriptors.map(descriptor => descriptorPicker(descriptor))}

          {descriptors.map(descriptor => {
            if (descriptor.selected) return renderDescriptors(descriptor.groups, descriptor, true)

            return null
          })}
        </Box>
      </Box>
    )
  }

  const renderDescriptorModal = (
    <ModalWrapper
      maxWidth='sm'
      open={openDescriptor}
      containerProps={{
        ref: modalRef,
        ...(guideScore && {onClick: () => setOpenDescriptor(false)}),
        sx: {
          '& .MuiAutocomplete-listbox': {
            p: 1
          }
        }
      }}
      {...(!showWarningClose && {onClose: () => setShowWarningClose(true)})}
    >
      <Typography variant='h5' sx={{position: showWarningClose ? 'unset' : 'absolute', top: 20}}>
        {t('descriptor.descriptor')}
      </Typography>

      {temporaryDescriptors &&
        <>
          <Typography variant='subtitle1' mb={temporaryDescriptors.length > 0 ? 1 : 3}>
            {t('descriptor.selectedDescriptors')}
          </Typography>
          {temporaryDescriptors.map(descriptor =>
            <Chip
              key={descriptor.id}
              size="small"
              variant='outlined'
              sx={descriptorColor(descriptor)}
              label={descriptorTranslation(languagePreference, descriptor)}
              onDelete={() => addOrRemoveTemporaryDescriptors(descriptor, true)}
            />
          )}
        </>
      }

      <Typography variant='subtitle1' sx={{my: 1}}>{t('descriptor.selectDescriptors')}</Typography>
      <Autocomplete
        freeSolo
        fullWidth
        disablePortal
        autoComplete
        size='small'
        open={customDescriptor.length > 0}
        inputValue={customDescriptor}
        onInputChange={(e, value) => setCustomDescriptor(value)}
        options={temporaryDescriptorFlatList}
        getOptionLabel={(option) => descriptorTranslation(languagePreference, option)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t(`descriptor.findDescriptor`)}
            InputProps={{
              ...params.InputProps,
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  addCustomDescriptor()
                }
              },
              sx: {paddingRight: '8px !important'},
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <Chip
              size="small"
              variant='outlined'
              label={descriptorTranslation(languagePreference, option)}
              sx={descriptorColor(option)}
              onClick={() => {
                addOrRemoveTemporaryDescriptors(option, false)
                setCustomDescriptor('')
              }}
            />
          )
        }}
      />

      <FormControlLabel
        control={<Switch checked={openMainDescriptor} onChange={(e) => setOpenMainDescriptor(e.target.checked)} />}
        label={t('descriptor.showDescriptors')}
        sx={{mb: 1}}
      />

      {openMainDescriptor &&
        <>
          <Typography variant='h6'>{t('descriptor.mainDescriptors')}</Typography>
          {renderDescriptors(temporaryDescriptorList)}
        </>
      }

      {addDescriptor &&
        <Box className={classes.descriptorButton} mt={3}>
          {showWarningClose &&
            <Typography color='error' sx={{mb: 1}}>
              {t('descriptor.areYouSureToClose')}
            </Typography>
          }
          <Button
            variant='contained'
            className='wide'
            sx={{mr: 2}}
            onClick={() => {
              if (showWarningClose) {
                setShowWarningClose(false)
                return
              }

              setOpenDescriptor(false)
              addDescriptor(setDescriptor(temporaryDescriptors))
            }}
          >
            {showWarningClose ? t('descriptor.keepSelection') : t('descriptor.apply')}
          </Button>
          <Button
            variant='outlined'
            className='wide'
            onClick={() => {
              setTemporaryDescriptors([])
              addDescriptor([])
              if (showWarningClose) {
                setOpenDescriptor(false)
                setShowWarningClose(false)
              }
            }}
          >
            {showWarningClose ? t('descriptor.cancelSelection') : t('descriptor.reset')}
          </Button>
        </Box>
      }
    </ModalWrapper>
  )

  const resetDescriptorList = (list) => {
    list.selected = false

    if (!isEmpty(list.groups)) {
      list.groups.map(group => resetDescriptorList(group))
    }

    return list
  }

  const renderSelection = () => {
    let min, max, step
    switch (cuppingProtocol) {
      case CuppingProtocol.CUP_OF_EXCELLENCE:
        min = minimumCupScore
        max = maximumCupScore
        step = scoreStep
        break
      case CuppingProtocol.COMMERCIAL:
        min = minimumCommercialScore
        max = maximumCommercialScore
        step = scoreCommercialStep
        break
      default:
        min = minimumScore
        max = maximumScore
        step = scoreStep
    }

    return (
      <Select
        size='small'
        value={score}
        onChange={(e) => {
          if (setScore) setScore(e.target.value)
        }}
      >
        {range(min, max + 0.1, step).map(value =>
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        )}
      </Select>
    )

  }

  React.useEffect(() => {
    if (!descriptors || !descriptorList) return

    const cloneDescriptorList = clone(descriptorList).map(list => resetDescriptorList(list))
    const descriptorSelections = descriptorSelection(cloneDescriptorList, clone(descriptors))

    setTemporaryDescriptors(descriptorSelections)
    setTemporaryDescriptorList(cloneDescriptorList)
    setTemporaryDescriptorFlatList(flatListDescriptors(cloneDescriptorList))
  }, [descriptors])

  React.useEffect(() => {
    if (!showWarningClose) return

    const c = modalRef.current
    if (c) {
      const modalHeight = c.offsetTop + c.offsetHeight
      c.scrollTo({top: modalHeight})
    }
  }, [showWarningClose])

  return (
    <Card className={clsx(classes.cardWrapper, !descriptors && 'no-descriptor', isRow && 'row', !title && 'no-title')}>
      <Box className={classes.titleWrapper}>
        {title &&
          <Typography variant='subtitle1' sx={{...titleMargin()}}>
            {title}
          </Typography>
        }

        <Stack direction="row" spacing={1}>
          {descriptors &&
            <Button
              size='small'
              variant='outlined'
              startIcon={<AddCircle />}
              onClick={() => setOpenDescriptor(true)}
              className={classes.addDescriptor}
            >
              {t('descriptor.addDescriptors')}
            </Button>
          }

          {!isUndefined(score)  && isMobile && renderSelection()}
        </Stack>
      </Box>

      <Box className={classes.slideDescriptorWrapper}>
        {descriptors && descriptorContent}

        {slides &&
          <Grid container spacing={3} justifyContent='center'>
            {slides?.map((item, index) =>
              <Grid item xs={12/slides.length} key={index}>
                <DescriptorSlide {...item} />
              </Grid>
            )}
          </Grid>
        }
      </Box>

      {!isUndefined(score) && !isMobile &&
        <Box className={classes.scoreWrapper}>
          <Typography>{t('descriptor.score')}</Typography>
          <TextField
            disabled
            size='small'
            value={score}
            className={classes.inputScore}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  onClick={decreaseScore}
                >
                  <RemoveCircle color="primary" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={addScore}
                >
                  <AddCircle color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      }

      {children}

      {temporaryDescriptors && renderDescriptorModal}
    </Card>
  )
})
