import {styled} from "@mui/material/styles";
import {colors} from "assets";

export const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: colors.primary.main,
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));
