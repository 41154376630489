import {types} from "mobx-state-tree";
import {withReset, withSetValue} from "models/extensions";

export const SampleShipmentScoreModel = types
  .model("SampleShipmentScore")
  .props({
    flavorDescriptors: types.optional(types.array(types.string), []),
    defectsDescriptors: types.optional(types.array(types.string), []),
    defects: types.optional(types.boolean, false),
    totalScore: types.optional(types.union(types.string, types.number), ''),
    notes: types.optional(types.string, ''),
    isSubmitted: types.optional(types.boolean, false),
    sampleId: types.optional(types.string, ''),
    sampleName: types.optional(types.string, ''),
  })
  .extend(withSetValue)
  .extend(withReset)
