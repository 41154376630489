/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-09 03:06:20
 * @modify date 2022-03-09 03:06:20
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, verticalCentered } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      border: `1px solid ${colors.primary.o25}`,
      borderLeft: 'none',

      [theme.breakpoints.down('md')]: {
        border: 'none'
      }
    },
    tabPanelWrapper: {
      backgroundColor: 'white',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(5)
      },

      '&.use-overflow': {
        overflowY: 'auto',
        height: 'calc(100vh - 270px)',
        marginBottom: 0,
      }
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      backgroundColor: 'white',
    },
    title: {
      ...verticalCentered,
    },
    formRow: {
      marginBottom: theme.spacing(2),
      alignItems: 'start'
    },
    date: {
      '& svg': {
        fill: colors.primary.main
      }
    },
    autocomplete: {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(5)
      }
    },
    accordion: {
      borderBottom: `1px solid ${colors.border.primary}`,
      '&:before': {
        opacity: 0
      },
      '&.Mui-expanded': {
        border: 'none',
        '& .MuiAccordionSummary-root': {
          display: 'none'
        }
      }
    },
    greenGradingItem: {
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${colors.border.primary}`,
    },
    buttonWrapper: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      '& button': {
        marginTop: `0 !important`
      },
      [theme.breakpoints.up('md')]: {
        justifyContent: 'end'
      },
      [theme.breakpoints.down('md')]: {
        position: "fixed",
        left: 0,
        bottom: 0,
        backgroundColor: "white",
        padding: "16px",
        width: "100%",
        boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.25)",
        marginLeft: "0 !important",
        zIndex: 1,

        '& button': {
          marginTop: `0 !important`
        }
      }
    },
    iconRounded: {
      width: 20,
      height: 20,
      backgroundColor: 'white',
      '& svg': {
        height: 16,
        fill: theme.palette.primary.main,
      }
    },
    buttonRounded: {
      borderRadius: theme.spacing(8),
      '&:hover': {
        '& svg': {
          fill: theme.palette.primary.dark
        }
      }
    },
    warningIcon: {
      fill: theme.palette.warning.main,
      width: 75,
      height: 75
    }
  })
)
