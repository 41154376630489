import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {colors} from "assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: 'white',
      padding: theme.spacing(4),
      borderRadius: 5,
      border: `1px solid ${colors.magnolia}`,
    },
    buttonTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1)
    }
  })
)
