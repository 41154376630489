/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-18 00:15:10
 * @modify date 2022-03-30 22:10:49
 */
import React from 'react'
import moment from 'moment'
import {assign, isEmpty, toString} from "lodash"
import {useParams, useNavigate, useLocation} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {useTranslation} from 'react-i18next'
import {
  Container,
  Typography,
  Stack,
  Button,
  Box,
  Card,
  Grid,
  FormControlLabel,
  Radio, FormLabel, FormControl, FormHelperText
} from '@mui/material'
import {isMobile} from "react-device-detect"

import {useStores} from "models"
import {errorMessage, errorStatus, globalAction, validateEmail} from 'utils'
import {findRoute} from 'constants/routes'
import {colors} from 'assets'
import {signUp as signUpImg} from 'assets/images'
import {FormInput} from 'components'
import { roles } from "constants/form"

export const Invitation: React.FC = observer(() => {
  const {t} = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const locations = useLocation()
  const searchParams = new URLSearchParams(locations.search)
  const sampleScoreLink = findRoute('sampleScore')
  const sampleReviewLink = findRoute('sampleReview')
  const {
    guestInformationStore: { guestName, guestEmail, companyName, role, setValue: setGuestInformationValue },
    cuppingSessionStore: {
      getGuestCuppingSession, selectedCuppingSession, getCuppedCuppingSession, setValue, guestUrlToken,
    },
    cuppingSessionFormStore: { name, description, startsAt, location },
    sampleStore: { samples, getIndividualUrl },
    notificationStore
  } = useStores()

  const tableItems = [
    {label: t('guest.description'), value: description},
    {label: t('guest.location'), value: location},
    {label: t('guest.time'), value: moment(startsAt).utc().format("DD MMMM YYYY HH:mm")},
    {label: t('guest.owner'), value: selectedCuppingSession?.ownerName},
    {label: t('guest.company'), value: selectedCuppingSession?.company},
  ]

  const [errors, setErrors] = React.useState<any>({})
  const [isCupped, setIsCupped] = React.useState(false)
  const readyToCup = !selectedCuppingSession?.canNotCupNow
    && !moment(selectedCuppingSession?.endsAt).isBefore(new Date())
    && !isCupped
    && !selectedCuppingSession?.isPaused

  const requestInformation = selectedCuppingSession?.publicCuppingRequestInformation

  const getIndividualUrlReport = async () => {
    let url = `/individual-report/${params.uniqueToken}?guest_invitation_token=`
    if (guestUrlToken) {
      url += guestUrlToken
    } else {
      url += await getIndividualUrl(params.uniqueToken)
    }

    navigate(url)
  }

  const renderInformation = () => {
    if (isCupped) {
      return (
        <>
          <Typography color={colors.text.primary} variant={isMobile ? 'h3' : 'h2'} sx={{mt: 2.5, mb: 2}}>
            {t('invitation.youAlreadyCupThisSession')}
          </Typography>

          <Box>
            {t('invitation.youAlreadyCupInThis')} &nbsp;
            <Typography variant='h6' color="primary" component="b">
              {t('invitation.cuppingSession')} #{selectedCuppingSession?.numberForCompany} - {name},
            </Typography>
            <Typography variant='body1'>
              {t('invitation.noLongerParticipate')}
            </Typography>
          </Box>

          <Stack direction="row" spacing={2} sx={{mt: 2}} justifyContent="center">
            <Button
              variant='contained'
              onClick={getIndividualUrlReport}
            >
              {t('invitation.seePersonalReport')}
            </Button>

            <Button
              variant='contained'
              onClick={() => {
                navigate(`/sample-report-collective/${selectedCuppingSession?.uniqueToken}`)
              }}
            >
              {t('invitation.seeCollectiveReport')}
            </Button>
          </Stack>
        </>
      )
    }

    if (selectedCuppingSession?.isPaused) {
      return (
        <>
          <Typography color={colors.text.primary} variant={isMobile ? 'h3' : 'h2'} sx={{mt: 2.5, mb: 2}}>
            {t('invitation.cuppingHasNotStarted')}
          </Typography>
          <Typography variant='h6' color="primary">
            {t('invitation.cuppingSession')} #{selectedCuppingSession?.numberForCompany} - {name}
          </Typography>
          <Typography variant='body1'>
            {t('invitation.isBeingPaused')}
          </Typography>
          <Typography variant='body1' sx={{mt: 1}}>
            {t('invitation.pleaseCheckWithTheOwner')}
          </Typography>
        </>
      )
    }

    if (selectedCuppingSession?.canNotCupNow) {
      return (
        <>
          <Typography color={colors.text.primary} variant={isMobile ? 'h3' : 'h2'} sx={{mt: 2.5, mb: 2}}>
            {t('invitation.cuppingHasNotStarted')}
          </Typography>
          <Typography variant='h6' color="primary">
            {t('invitation.cuppingSession')} #{selectedCuppingSession?.numberForCompany} - {name}
          </Typography>
          <Typography variant='body1'>
            {t('invitation.cuppingHasNotStartedByOwner')}
          </Typography>
          <Typography variant='body1' sx={{mt: 1}}>
            {t('invitation.pleaseCheckWithTheOwner')}
          </Typography>
        </>
      )
    }

    if (moment(selectedCuppingSession?.endsAt).isBefore(new Date())) {
      return (
        <>
          <Typography color={colors.text.primary} variant={isMobile ? 'h3' : 'h2'} sx={{mt: 2.5, mb: 2}}>
            {t('invitation.cuppingSessionExpired')}
          </Typography>
          <Typography variant='h6' color="primary">
            {t('invitation.cuppingSession')} #{selectedCuppingSession?.numberForCompany} - {name}
          </Typography>
          <Typography variant='body1'>
            {t('invitation.isAlreadyExpired')}
          </Typography>
        </>
      )
    }

    return (
      <>
        <Typography color={colors.text.primary} variant={isMobile ? 'h3' : 'h2'} sx={{mt: 2.5, mb: 2}}>
          {t('invitation.joinCuppingSession')}
        </Typography>
        <Typography variant='h6' color="primary">
          {t('invitation.cuppingSession')} #{selectedCuppingSession?.numberForCompany} - {name}
        </Typography>
        <Typography variant='body1'>
          {t('invitation.isAlreadyStarted')}
        </Typography>

        <Stack direction='row' spacing={1} justifyContent="center" sx={{mt: 1, mb: 3}}>
          <Typography variant='body1'>
            {t('invitation.cuppingOwner')}:
          </Typography>
          <Typography variant='body1' color="primary">
            {selectedCuppingSession?.ownerName}
          </Typography>
        </Stack>

        <Typography variant='body1' sx={{whiteSpace: "pre-line"}}>
          {t('invitation.fillYourEmailDescription')}
        </Typography>
      </>
    )
  }

  const setCupped = async (token) => {
    const status = await getCuppedCuppingSession(token)
    setIsCupped(status)
  }

  const validations = () => {
    let validationErrors = {}

    if (requestInformation.nameStatus > 1 && !guestName) {
      assign(validationErrors,{
        "name": [{message: t('guest.error.fieldIsRequired')}]
      })
    }

    if (requestInformation.emailStatus > 1 && !validateEmail(guestEmail)) {
      assign(validationErrors,{
        "email": [{message: t('guest.error.emailIsInvalid')}]
      })
    }

    if (requestInformation.companyNameStatus > 1 && !companyName) {
      assign(validationErrors,{
        "companyName": [{message: t('guest.error.fieldIsRequired')}]
      })
    }

    if (requestInformation.roleStatus > 1 && !role) {
      assign(validationErrors,{
        "role": [{message: t('guest.error.fieldIsRequired')}]
      })
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors)
      return false
    }

    return true
  }

  const onStartCupping = () => {
    if (requestInformation && !validations()) return

    navigate(sampleScoreLink)
  }

  const renderMandatory = (
    <sup style={{color: colors.primary.main, fontSize: 'large'}}>*</sup>
  )

  const renderPublicCupping = () => {

    return (
      <>
        <Box sx={{mb: 1, textAlign: "center"}}>
          <img alt="cupping-session" src={signUpImg} width={isMobile ? '167px' : '346px'}/>
          {renderInformation()}
        </Box>
        {readyToCup &&
          <>
            {!guestUrlToken && requestInformation &&
              <>
                {requestInformation.nameStatus > 0 &&
                  <FormInput
                    label={
                      <Box>
                        {t('guest.name')}
                        {requestInformation.nameStatus > 1 && renderMandatory}
                      </Box>
                    }
                    textFieldProps={{
                      placeholder: t('guest.namePlaceholder'),
                      value: guestName,
                      onChange: (e) => setGuestInformationValue('guestName', e.target.value),
                      error: errorStatus('name', guestName, errors)
                    }}
                    formControlProps={{
                      error: errorStatus('name', guestName, errors)
                    }}
                    helperText={errorMessage('name', guestName, errors) || ''}
                  />
                }

                {requestInformation.emailStatus > 0 &&
                  <FormInput
                    label={
                      <Box>
                        {t('guest.email')}
                        {requestInformation.emailStatus > 1 && renderMandatory}
                      </Box>
                    }
                    textFieldProps={{
                      placeholder: t('guest.emailPlaceholder'),
                      value: guestEmail,
                      onChange: (e) => setGuestInformationValue('guestEmail', e.target.value),
                      error: errorStatus('email', guestEmail, errors)
                    }}
                    formControlProps={{
                      error: errorStatus('email', guestEmail, errors)
                    }}
                    helperText={errorMessage('email', guestEmail, errors) || ''}
                  />
                }

                {requestInformation.companyNameStatus > 0 &&
                  <FormInput
                    label={
                      <Box>
                        {t('guest.companyName')}
                        {requestInformation.companyNameStatus > 1 && renderMandatory}
                      </Box>
                    }
                    textFieldProps={{
                      placeholder: t('guest.companyNamePlaceholder'),
                      value: companyName,
                      onChange: (e) => setGuestInformationValue('companyName', e.target.value),
                      error: errorStatus('companyName', companyName, errors)
                    }}
                    formControlProps={{
                      error: errorStatus('companyName', companyName, errors)
                    }}
                    helperText={errorMessage('companyName', companyName, errors) || ''}
                  />
                }

                {requestInformation.roleStatus > 0 &&
                  <FormControl fullWidth margin='normal' error={errorStatus('role', role, errors)}>
                    <FormLabel>
                      {t('guest.chooseRole')}
                      {requestInformation.roleStatus > 1 && renderMandatory}
                    </FormLabel>
                    <Grid container>
                      {roles.map(r =>
                        <Grid item key={r.key} xs={12} md={6}>
                          <FormControlLabel
                            value={r.key}
                            control={<Radio color="primary"/>}
                            label={toString(t(r.label))}
                            labelPlacement="end"
                            checked={r.key === role}
                            onChange={e => setGuestInformationValue('role', (e.target as HTMLInputElement).value)}
                          />
                        </Grid>
                      )}
                    </Grid>

                    {errorStatus('role', role, errors) &&
                      <FormHelperText sx={{mx: 0}}>{errorMessage('role', role, errors) || ''}</FormHelperText>
                    }
                  </FormControl>
                }
              </>
            }

            {requestInformation &&
              (requestInformation.nameStatus > 0 || requestInformation.emailStatus > 0 || requestInformation.companyNameStatus > 0 && requestInformation.roleStatus > 0) && (
              <Box>
                {renderMandatory}
                <span>{t('guest.mandatory')}</span>
              </Box>
            )}

            <Button
              fullWidth
              variant='contained'
              onClick={onStartCupping}
              sx={{mt: 2}}
              disabled={!params.uniqueToken}
            >
              {t('menu.startCupping')}
            </Button>
          </>
        }
      </>
    )
  }

  const renderGuestByEmail = () => {
    return (
      <Card variant="outlined" sx={{p: 3, mt: 5}}>
        <Typography variant='h4' sx={{mb: 2}}>{t('guest.guestInvitation')}</Typography>
        <Stack direction='row' spacing={1} sx={{mb: 1}}>
          <Typography>{t('guest.youAreInvited')}</Typography>
          <Typography color='primary' variant='h6'>
            {t('guest.cuppingSession')} - {name}
          </Typography>
        </Stack>
        <table>
          <tbody>
          {tableItems.map((item, index) =>
            <tr key={index}>
              <td><Typography variant='subtitle1' sx={{mr: 3, mb: 1}}>{item.label}</Typography></td>
              <td><Typography sx={{mr: 3, mb: 1}}>: {item.value}</Typography></td>
            </tr>
          )}
          </tbody>
        </table>

        <Stack direction='row' spacing={2} sx={{mt: 3}}>
          <Button
            variant='contained'
            onClick={() => navigate(sampleScoreLink)}
            disabled={selectedCuppingSession?.canNotCupNow}
          >
            {t('guest.joinCupping')}
          </Button>

          <Button
            variant='contained'
            onClick={() => navigate(sampleReviewLink)}
            disabled={!selectedCuppingSession?.hasSubmittedScores}
          >
            {t('guest.seeReport')}
          </Button>
        </Stack>
      </Card>
    )
  }

  React.useEffect(() => {
    if (!params.uniqueToken) return

    globalAction(notificationStore, {
      action: async () => await getGuestCuppingSession(params.uniqueToken, searchParams.get('token')),
      afterAction: () => {
        setCupped(params.uniqueToken)
        setValue('guestUrlToken', searchParams.get('token') || '')
      }

    })
  }, [])

  if (!selectedCuppingSession) return null

  return (
    <Container maxWidth='md' sx={{pb: 4}}>
      {(guestUrlToken && selectedCuppingSession?.cuppingProtocol !== "sca") ? renderGuestByEmail() : renderPublicCupping()}
    </Container>
  )
})

export default Invitation
