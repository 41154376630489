import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useParams, useSearchParams, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'
import {Box, Button, Card, Container, Grid, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";

import {useStores} from "models";
import {optionsTranslation, translateDescriptors} from "utils";
import {Descriptor, FormInput, HeaderTitle} from "components";
import { flavorDescriptorList } from 'constants/flavor-descriptors'
import { defectDescriptorList } from "constants/defect-descriptors"

export const SampleShipmentScore = observer(() => {
  const { t } = useTranslation()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const {
    userStore: { languagePreference },
    sampleShipmentStore: { getShipmentScore, sendShipmentScore,
      score: {
        flavorDescriptors, defectsDescriptors, defects, notes, totalScore, isSubmitted,
        sampleId, sampleName, setValue: setScoreValue, reset: resetScore
      }
    },
    notificationStore
  } = useStores()

  const token = searchParams.get('token')
  const hasItemId = !!params.itemId
  const transactionId = hasItemId ? params.id : searchParams.get('transaction_id')
  const transactionItemId =  hasItemId ? params.itemId : searchParams.get('item_id')
  const backUrl = hasItemId ? `/sample-shipments/${params.id}` : `/sample-shipment-review/${params.id}?token=${token}`
  const language = languagePreference ?? 'en'

  const onSubmitScore = async () => {
    try {
      notificationStore.setLoading(true)

      await sendShipmentScore(transactionId, transactionItemId, token)
      setScoreValue('isSubmitted', true)
    } finally {
      notificationStore.setLoading(false)
    }
  }

  const getScore = async () => {
    try {
      resetScore()
      notificationStore.setLoading(true)

      await getShipmentScore(transactionId, transactionItemId, token)
    } catch (e) {
      navigate(backUrl, { replace: true })
    } finally {
      notificationStore.setLoading(false)
    }
  }

  useEffect(() => {
    if (transactionId && transactionItemId) {
      getScore()
    } else {
      navigate('/', { replace: true })
    }
  }, [])

  return (
    <Box>
      <HeaderTitle
        backUrl={backUrl}
        breadcrumb={t('common.backTo', { menu: t(`shipmentReview.${hasItemId ? 'shipmentDetail' : 'shipmentReview'}`) })}
        title={t('shipmentReview.sampleCupping')}
      />

      <Container>
        <Card sx={{p: 2, mb: 2}}>
          <Typography>{t('sample.sample')}</Typography>
          <Typography variant="h5">{sampleName}</Typography>
        </Card>

        {!isSubmitted && (
          <>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} md={5}>
                <Descriptor
                  key={`flavor${flavorDescriptors}`}
                  sampleId={sampleId}
                  title={t('descriptor.flavor')}
                  descriptors={flavorDescriptors}
                  descriptorList={flavorDescriptorList}
                  addDescriptor={(values) => setScoreValue('flavorDescriptors', values)}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Descriptor
                  key={`defects${defectsDescriptors}`}
                  sampleId={sampleId}
                  title={t('descriptor.defects')}
                  descriptors={defectsDescriptors}
                  descriptorList={defectDescriptorList}
                  addDescriptor={(values) => setScoreValue('defectsDescriptors', values)}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Card sx={{p: 2, height: '100%'}}>
                  <FormInput label={t('descriptor.defects')}>
                    <Select
                      size='small'
                      value={defects}
                      onChange={(e) => setScoreValue('defects', /^true$/i.test(e.target.value))}
                    >
                      {[true, false].map(bool =>
                        <MenuItem key={bool.toString()} value={bool.toString()}>
                          {optionsTranslation('boolean', bool.toString())}
                        </MenuItem>
                      )}
                    </Select>
                  </FormInput>
                </Card>
              </Grid>
              <Grid item xs={12} md={10}>
                <Card sx={{p: 2, height: '100%'}}>
                  <Typography variant='h6' sx={{mb: 2}}>{t('descriptor.notes')}</Typography>
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    value={notes}
                    onChange={(e) => setScoreValue('notes', e.target.value)}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} md={2}>
                <Card sx={{p: 2, height: '100%'}}>
                  <FormInput label={'Score'}>
                    <CurrencyTextField
                      keys={transactionItemId}
                      size='small'
                      type='text'
                      inputMode='numeric'
                      variant='outlined'
                      textAlign='right'
                      currencySymbol=''
                      outputFormat="string"
                      decimalCharacter="."
                      digitGroupSeparator=","
                      minimumValue="0"
                      maximumValue="100"
                      decimalPlaces={2}
                      value={totalScore}
                      onChange={(e, value) => setScoreValue('totalScore', value)}
                    />
                  </FormInput>
                </Card>
              </Grid>
            </Grid>

            <Stack direction='row' spacing={2} justifyContent="end">
              <Button
                variant='contained'
                onClick={onSubmitScore}
                disabled={!totalScore}
              >
                {t('common.submit')}
              </Button>
              <Button
                variant='outlined'
                onClick={() => navigate(backUrl)}
              >
                {t('common.cancel')}
              </Button>
            </Stack>
          </>
        )}

        {isSubmitted && (
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} md={5}>
              <Card sx={{p: 2, height: '100%'}}>
                <Typography variant="h6" sx={{mb: 1.5}}>{t('descriptor.flavor')}</Typography>
                <Typography color="primary">{translateDescriptors(language, flavorDescriptors).join(' | ')}</Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={5}>
              <Card sx={{p: 2, height: '100%'}}>
                <Typography variant="h6" sx={{mb: 1.5}}>{t('descriptor.defects')}</Typography>
                <Typography color="primary">{translateDescriptors(language, defectsDescriptors).join(' | ')}</Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={2}>
              <Card sx={{p: 2, height: '100%', textAlign: 'center'}}>
                <Typography variant="h6" sx={{mb: 1.5}}>{t('descriptor.defects')}</Typography>
                <Typography variant="h3" color="primary">{optionsTranslation('boolean', defects.toString())}</Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={10}>
              <Card sx={{p: 2, height: '100%'}}>
                <Typography variant='h6' sx={{mb: 1.5}}>{t('descriptor.notes')}</Typography>
                <Typography>{notes}</Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={2}>
              <Card sx={{p: 2, height: '100%', textAlign: 'center'}}>
                <Typography variant="h6" sx={{mb: 1.5}}>{t('descriptor.totalScore')}</Typography>
                <Typography variant="h3" color="primary">{totalScore}</Typography>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  )
})

export default SampleShipmentScore
