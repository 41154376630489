import moment from "moment";
import { fulfillmentTypes } from "constants/form"
import { samplePreProcessor } from "models/sample";
import { shipmentOrderFormPreProcessor } from "models/shipment-order-form";

export const sampleShipmentPreProcessor = (sn: any) => {
  return {
    ...sn,
    ...shipmentOrderFormPreProcessor(sn),
    id: sn.sample ? sn.id.toString() : '',
    sampleType: sn.sampleType || '',
    sampleName: sn.sampleName || sn.name,
    sampleGrade: sn.sampleGrade || sn.grade,
    sampleLocation: sn.sampleLocation || '',
    sampleFulfillmentType: sn.sampleFulfillmentType || fulfillmentTypes[0],
    isApprovalNeeded: sn.isApprovalNeeded || true,
    sampleWeight: sn.sampleWeight?.toString() || sn.receivedWeight,
    isRoasted: sn.isRoasted || false,
    thirdPartyEmail: sn.thirdPartyEmail || '',
    sampleWarehouseReference: sn.sampleWarehouseReference || sn.warehouseReference,
    sampleId: sn.sampleId,
    sample: sn.sample ? samplePreProcessor(sn.sample) : samplePreProcessor(sn),
  }
}

export const sampleShipmentListPreProcessor = (sn: any) => {
  return {
    ...sn,
    ...shipmentOrderFormPreProcessor(sn),
    tastifyOrderNumber: sn.tastifyOrderNumber || '',
    uniqueToken: sn.uniqueToken || '',
    createdAt: moment(sn.createdAt).format('MM/DD/YYYY') || '',
    fulfillmentStatus: sn.fulfillmentStatus || '',
    sampleOrderNumber: sn.sampleOrderNumber || '',
  }
}
