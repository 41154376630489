import React from 'react'
import { Box, Pagination as PaginationComponent } from '@mui/material'

import { horizontalCentered } from 'assets'
import { PaginationProps } from './pagination.props'

export const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const { onChange, boxStyle, ...rest } = props

  return (
    <Box
      sx={{
        ...horizontalCentered,
        mt: 3, mb: 5,
        ...boxStyle
      }}
    >
      <PaginationComponent
        variant="outlined"
        shape="rounded"
        color="primary"
        onChange={onChange}
        siblingCount={5}
        {...rest}
      />
    </Box>
  )
}
