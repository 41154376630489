import {camelCase} from "lodash";
import i18n from "i18n/i18n"

export const optionsTranslation = (key: string, value: string, withEscapeValue?: boolean) => {
  const camelCaseKey = camelCase(key)
  const camelCaseValue = camelCase(value)
  const keyExist = i18n.exists(`options.${camelCaseKey}.${camelCaseValue}`)

  if (value && keyExist) return i18n.t(`options.${camelCaseKey}.${camelCaseValue}`)
  if (value && !keyExist) return value

  if (withEscapeValue) return '-'

  return ''
}
