/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-09 02:40:45
 * @modify date 2022-03-09 02:40:45
 * @desc [description]
 */
import React from 'react'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {toNumber, camelCase, cloneDeep, groupBy, isNull, startCase, isEmpty} from 'lodash'
import { LoadingButton } from '@mui/lab'
import {Button, Grid, Box, MenuItem, Typography, Select, Stack} from '@mui/material'
import {useDropzone} from "react-dropzone";
import {Add, Edit} from "@mui/icons-material";

import { useStores } from 'models'
import { ModalWrapper, FormInput, GreenGradingForm } from 'components'
import {colors, defectKeys} from 'constants/form'
import { colors as colorAsset } from 'assets'
import {IconEmptyPhotoGreenGrading} from "assets/images";

import { useStyles } from './green-grading-modal.styles'
import { GreenGradingModalProps } from './green-grading-modal.props'

export const GreenGradingModal: React.FC<GreenGradingModalProps> = observer((props: GreenGradingModalProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { open, onClose } = props
  const {
    userStore: { name },
    cuppingSessionStore: { selectedCuppingSession },
    sampleStore: { getSamples, selectedSample: { unsetGreenGrading, addGreenGrading,
      selectedGreenGrading: {
        id, color, weight, smell, defects,
        roastColor, ovenTest, otaTest, glyphosateTest, density, moisture, waterActivity,
        size10, size11, size12, size13, size14, size15, size16, size17, size18, size19, size20,
        unsetDefectInformation, picture, picture2, quakerNumber, yieldFactor, setValue
    }}},
    greenGradingStore: { selectedGreenGradingType, greenGradingTypeWeight },
    notificationStore: { setNotification }
  } = useStores()

  const [isLoading, setIsLoading] = React.useState(false)
  const [tmpDefects, setTmpDefects] = React.useState<any>([])
  const [tmpPicture, setTmpPicture] = React.useState<File>();
  const [tmpPicture2, setTmpPicture2] = React.useState<File>();
  const groupDefects = groupBy(tmpDefects, 'category')
  const [showPicture, setShowPicture] = React.useState(false);
  const [showPicture2, setShowPicture2] = React.useState(false);

  const screenSizes = [
    {id: 'size10', value: size10},
    {id: 'size11', value: size11},
    {id: 'size12', value: size12},
    {id: 'size13', value: size13},
    {id: 'size14', value: size14},
    {id: 'size15', value: size15},
    {id: 'size16', value: size16},
    {id: 'size17', value: size17},
    {id: 'size18', value: size18},
    {id: 'size19', value: size19},
    {id: 'size20', value: size20},
  ]

  const {getRootProps: pictureGetRootProps, getInputProps: pictureGInputProps} = useDropzone({
    maxFiles: 1,
    maxSize: 5242880,
    accept: 'image/jpeg, image/jpg, image/png, image/jfif',
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      if (!isEmpty(fileRejections)) {
        const file = fileRejections[0]
        file.errors.forEach(error => {
          setNotification({
            severity: 'error',
            message: error.code === "file-too-large" ?
              t(`error.${camelCase(error.code)}`, {maxSize: '5 MB'}) :
              error.message
          })
        })

        return
      }

      const tempPicture = acceptedFiles[0]
      const url = URL.createObjectURL(tempPicture)
      Object.assign(tempPicture, {
        preview: url
      })

      setValue('picture', url)
      setTmpPicture(tempPicture)
    }
  })

  const {getRootProps: picture2GetRootProps, getInputProps: picture2GInputProps} = useDropzone({
    maxFiles: 1,
    maxSize: 5242880,
    accept: 'image/jpeg, image/jpg, image/png, image/jfif',
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      if (!isEmpty(fileRejections)) {
        const file = fileRejections[0]
        file.errors.forEach(error => {
          setNotification({
            severity: 'error',
            message: error.code === "file-too-large" ?
              t(`error.${camelCase(error.code)}`, {maxSize: '5 MB'}) :
              error.message
          })
        })

        return
      }

      const tempPicture2 = acceptedFiles[0]
      const url = URL.createObjectURL(tempPicture2)
      Object.assign(tempPicture2, {
        preview: url
      })

      setValue('picture2', url)
      setTmpPicture2(tempPicture2)
    }
  })

  const onAddGreenGrading = async () => {
    setIsLoading(true)

    try{
      const res = await addGreenGrading(tmpDefects, tmpPicture, tmpPicture2)
      onClose()

      if (res) {
        res.base?.map(message => setNotification(message))
        return
      }
      getSamples(selectedCuppingSession?.uniqueToken)
      const message = {
        severity: 'success',
        message: t('greenGrading.success.successfullyAddedGreenGrading')
      }
      setNotification(message)
    } catch (e: any) {
      Object.keys(e.errors).map(key => e.errors[key].map(error => setNotification({severity: 'error', message: error.message})))
    } finally {
      unsetDefectInformation()
      setIsLoading(false)
    }
  }

  const onCancelAdding = () => {
    if (isNull(id)) unsetGreenGrading()
    onClose()
  }

  const handleChangeDefect = (value, id) => {
    tmpDefects[tmpDefects.findIndex( key => key.defectTypeId === id)].count = value
  }

  React.useEffect(() => {
    if (open) {
      setTmpDefects(cloneDeep(defects))
      if (weight === 0) setValue('weight', greenGradingTypeWeight)
    }
  }, [open])

  React.useEffect(() => {
    setValue('author', name)
    setValue('createdAt', moment().format('YYYY-MM-DD HH:mm'))
  }, [])

  return (
    <ModalWrapper maxWidth="lg" open={open}>
      <Stack spacing={2} mb={2}>
        <Typography variant="h6">
          {isNull(id)
            ? t("greenGrading.newGreenGrading")
            : t("greenGrading.updateGreenGrading")}
        </Typography>

        <Typography variant="subtitle1">
          {t('greenGrading.greenGradingForm') + ': ' + selectedGreenGradingType?.name}
        </Typography>
      </Stack>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems:'center'}}>
            {picture ?
              <Box className={classes.img}>
                <img src={picture} width="100%" onClick={()=>setShowPicture(true)}/>
              </Box>
              :
              <Box sx={{width: 250, height: 175, border: `3px dashed  ${colorAsset.primary.main}`, borderRadius: 2, mb: 2, display: '',alignItems: 'center', justifyContent: 'center', mt: 2, p: 1 }}>
                <Box sx={{p: 3, ml: 4}}>
                  <IconEmptyPhotoGreenGrading />
                </Box>
              </Box>
            }

            <Box {...pictureGetRootProps()} width="100%">
              <input {...pictureGInputProps()} />
              <Button
                fullWidth
                size='small'
                variant='contained'
                className='wide'
                startIcon={ picture ? <Edit /> : <Add />}
              >
                { picture ? t('greenGrading.changeRoastedBeanPhoto') : t('greenGrading.addRoastedBeanPhoto')}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems:'center'}} >
            {picture2 ?
              <Box className={classes.img}>
                <img src={picture2} width="100%" style={{}} onClick={()=>setShowPicture2(true)}/>
              </Box>
              :
              <Box sx={{width: 250, height: 175, border: `3px dashed  ${colorAsset.primary.main}`, borderRadius: 2, mb: 2, display: '',alignItems: 'center', justifyContent: 'center', mt: 2, p: 1 }}>
                <Box sx={{p: 3, ml: 4}}>
                  <IconEmptyPhotoGreenGrading />
                </Box>
              </Box>
            }

            <Box {...picture2GetRootProps()} width="100%">
              <input {...picture2GInputProps()} />
              <Button
                fullWidth
                size='small'
                variant='contained'
                className='wide'
                startIcon={ picture2 ? <Edit /> : <Add />}
              >
                { picture2 ? t('greenGrading.changeGreenBeanPhoto') : t('greenGrading.addGreenBeanPhoto')}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} alignItems="end">
            <Grid item xs={12} md={3}>
              <GreenGradingForm
                formInputLabel={`${t("greenGrading.weight")}`}
                formInputProps={{ margin: "none" }}
                value={weight}
                onChange={(e,value) => setValue("weight", toNumber(value))}
                size="small"
                label='gr.'
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput
                label={t("greenGrading.color")}
                formControlProps={{ margin: "none" }}
              >
                <Select
                  size="small"
                  defaultValue=""
                  value={color}
                  onChange={(e) => setValue("color", e.target.value)}
                >
                  <MenuItem value='' sx={{ height: 34 }} />
                  {colors.map((item) => (
                    <MenuItem key={item} value={item}>
                      {t(`options.color.${camelCase(item)}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormInput>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput
                label={t("greenGrading.smell")}
                formControlProps={{ margin: "none" }}
                textFieldProps={{
                  value: smell ?? '',
                  onChange: (e) => setValue("smell", e.target.value),
                  inputProps: { maxLength: 255 },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput
                label={t("greenGrading.quakerNumber")}
                formControlProps={{ margin: "none" }}
                textFieldProps={{
                  value: quakerNumber ?? '',
                  onChange: (e) => setValue("quakerNumber", toNumber(e.target.value)),
                  inputProps: { maxLength: 255 },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <GreenGradingForm
                formInputLabel={t(`greenGrading.density`)}
                formInputProps={{ margin: "none", sx: {'& .MuiInputAdornment-root > div': { width: '50px', '& p': { mt: 1 }}} }}
                value={density}
                onChange={(e,value) => setValue('density', value)}
                size="small"
                label='g/ml'
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <GreenGradingForm
                formInputLabel={t(`greenGrading.moisture`)}
                formInputProps={{ margin: "none", sx: {'& .MuiInputAdornment-root > div p': { mt: 1, ml: 1 }}}}
                value={moisture}
                onChange={(e,value) => setValue('moisture', value)}
                size="small"
                label='%'
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <GreenGradingForm
                formInputLabel={t(`greenGrading.waterActivity`)}
                formInputProps={{ margin: "none" }}
                value={waterActivity}
                onChange={(e,value) => setValue('waterActivity', value)}
                size="small"
                showLabel={false}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <GreenGradingForm
                formInputLabel={t(`greenGrading.yieldFactor`)}
                formInputProps={{ margin: "none", sx: {'& .MuiInputAdornment-root > div p': { mt: 1, ml: 1 }}}}
                value={yieldFactor}
                onChange={(e,value) => setValue('yieldFactor', value)}
                size="small"
                label='%'
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormInput
                label={t("greenGrading.roastColor")}
                formControlProps={{ margin: "none" }}
                textFieldProps={{
                  value: roastColor ?? '',
                  onChange: (e) => setValue("roastColor", e.target.value),
                  inputProps: { maxLength: 255 },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput
                label={t("greenGrading.ovenTest")}
                formControlProps={{ margin: "none" }}
                textFieldProps={{
                  value: ovenTest ?? '',
                  onChange: (e) => setValue("ovenTest", e.target.value),
                  inputProps: { maxLength: 255 },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput
                label={t("greenGrading.otaTest")}
                formControlProps={{ margin: "none" }}
                textFieldProps={{
                  value: otaTest ?? '',
                  onChange: (e) => setValue("otaTest", e.target.value),
                  inputProps: { maxLength: 255 },
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput
                label={t("greenGrading.glyphosateTest")}
                formControlProps={{ margin: "none" }}
                textFieldProps={{
                  value: glyphosateTest ?? '',
                  onChange: (e) => setValue("glyphosateTest", e.target.value),
                  inputProps: { maxLength: 255 },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2} alignItems="end">
        <Grid item xs={12}>
          <Typography variant="h6">
            {t('greenGrading.screenSize.title')}
          </Typography>
        </Grid>
        {screenSizes.map((item) =>
          <Grid item xs={12} md={2} key={item.id}>
            <GreenGradingForm
              formInputLabel={t(`greenGrading.screenSize.${item.id}`)}
              formInputProps={{ margin: "none" }}
              value={item.value}
              onChange={(e,value) => setValue(item.id, toNumber(value))}
              size="small"
              label='gr.'
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2}>
        {defectKeys.map((key) =>
          <Grid item xs={12} mt={2}>
            <Typography variant="h6" mt={2} mb={2}>
              {startCase(key)}
            </Typography>
            <Grid container spacing={2} alignItems="end">
            {groupDefects[key]?.map((defect) =>
              <Grid item xs={12} md={3}>
                <GreenGradingForm
                  key={defect.id}
                  id={defect.id}
                  size="small"
                  formInputLabel={defect.defectType ?? ''}
                  formInputProps={{ margin: "none" }}
                  value={defect.count}
                  type={key}
                  onChange={(e, v) => handleChangeDefect(v, defect.defectTypeId)}
                />
              </Grid>
            )}
            </Grid>
          </Grid>
        )}
      </Grid>

      <Box className={classes.buttonWrapper}>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={onAddGreenGrading}
          className="wide"
          sx={{ mr: 2 }}
        >
          {t("common.save")}
        </LoadingButton>
        <Button variant="outlined" onClick={onCancelAdding} className="wide">
          {t("common.cancel")}
        </Button>
      </Box>
      <ModalWrapper
        maxWidth='sm'
        open={showPicture}
        onClose={() => {setShowPicture(false)}}
      >
        <img src={picture} width="100%" height="100%"/>
      </ModalWrapper>
      <ModalWrapper
        maxWidth='sm'
        open={showPicture2}
        onClose={() => {setShowPicture2(false)}}
      >
        <img src={picture2} width="100%" height="100%"/>
      </ModalWrapper>
    </ModalWrapper>
  );
})

