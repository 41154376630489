import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {colors} from "assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRightBorder: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: colors.text.o25,
      borderStyle: 'solid',
    },
    tableRightBorderWithBackground: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: colors.text.o25,
      borderStyle: 'solid',
      backgroundColor: "#EBE8F2"
    },
    tableTopBorder: {
      borderTop: `1px solid ${colors.text.o25}`,
    }
  })
)
