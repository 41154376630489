export const solubleDescriptorList = [{
  "id": 1,
  "label": "caramel",
  "color": "#A0511C",
  "label_es": "caramelo",
  "parent_id": null,
  "open": 1,
  "zoomed": 0,
  "selected": false,
  "flavor_type": "flavor",
  "positive": 1,
  "label_pt": "caramelo",
  "label_zh-CN": "焦糖",
  "weight": 1.17,
  "label_fr-CA": "caramel",
  "label_ko": "캐러멜",
  "label_id": "karamel",
  "label_ja": "キャラメル",
  "groups": [{
    "id": 11,
    "label": "toffee",
    "color": "#B5804A",
    "parent_id": 1,
    "label_es": "caramelo",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "torrão",
    "label_zh-CN": "太妃糖",
    "weight": 1.19,
    "label_fr-CA": "toffee",
    "label_ko": "토피",
    "label_id": "permen karamel",
    "label_ja": "トフィ",
    "groups": []
  }, {
    "id": 12,
    "label": "burned sugar",
    "color": "#9D7A5E",
    "parent_id": 1,
    "label_es": "azúcar quemado",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "açúcar queimado",
    "label_zh-CN": "焦糖",
    "weight": 1.28,
    "label_fr-CA": "sucre brûlé",
    "label_ko": "탄 설탕",
    "label_id": "gula terbakar",
    "label_ja": "焦がした砂糖",
    "groups": []
  }]
}, {
  "id": 2,
  "label": "acidic",
  "color": "#93FE11",
  "parent_id": null,
  "label_es": "ácido",
  "open": 1,
  "zoomed": 0,
  "selected": false,
  "flavor_type": "flavor",
  "positive": 1,
  "label_pt": "ácido",
  "label_zh-CN": "酸的",
  "weight": 1.17,
  "label_fr-CA": "acide",
  "label_ko": "신맛의",
  "label_id": "asam",
  "label_ja": "酸味の",
  "groups": [{
    "id": 13,
    "label": "citric",
    "color": "#A5C613",
    "parent_id": 2,
    "label_es": "cítrico",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "cítrico",
    "label_zh-CN": "柠檬酸",
    "weight": 1.19,
    "label_fr-CA": "citrique",
    "label_ko": "구연산",
    "label_id": "sitrat",
    "label_ja": "シトリック",
    "groups": []
  }, {
    "id": 14,
    "label": "sour",
    "color": "#9FE732",
    "parent_id": 2,
    "label_es": "agrio",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "azedo",
    "label_zh-CN": "酸",
    "weight": 1.28,
    "label_fr-CA": "aigre",
    "label_ko": "신맛",
    "label_id": "asam",
    "label_ja": "酸っぱい",
    "groups": []
  }]
}, {
  "id": 3,
  "label": "herbal",
  "color": "#919F42",
  "label_es": "herbal",
  "parent_id": null,
  "open": 1,
  "zoomed": 0,
  "selected": false,
  "flavor_type": "flavor",
  "positive": 1,
  "label_pt": "herbal",
  "label_zh-CN": "草药",
  "weight": 1.18,
  "label_fr-CA": "herbe",
  "label_ko": "허브",
  "label_id": "herbal",
  "label_ja": "ハーブ、草系",
  "groups": [{
    "id": 15,
    "label": "brown spice",
    "color": "#B5804A",
    "parent_id": 3,
    "label_es": "especia marrón",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "especiaria marrom",
    "label_zh-CN": "褐色香料",
    "weight": 1.19,
    "label_fr-CA": "épice brune",
    "label_ko": "갈색 향신료",
    "label_id": "rempah coklat",
    "label_ja": "茶色いスパイス",
    "groups": []
  }, {
    "id": 16,
    "label": "pepper",
    "color": "#9D7A5E",
    "parent_id": 3,
    "label_es": "pimienta",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "pimenta",
    "label_zh-CN": "胡椒",
    "weight": 1.28,
    "label_fr-CA": "poivre",
    "label_ko": "후추",
    "label_id": "lada",
    "label_ja": "コショウ",
    "groups": []
  }]
}, {
  "id": 4,
  "label": "roasted",
  "color": "#766E6D",
  "label_es": "tostado",
  "parent_id": null,
  "open": 1,
  "zoomed": 0,
  "selected": false,
  "flavor_type": "flavor",
  "positive": 1,
  "label_pt": "torrado",
  "label_zh-CN": "烤",
  "weight": 1.18,
  "label_fr-CA": "rôti",
  "label_ko": "구운",
  "label_id": "Hasil panggang",
  "label_ja": "焙煎された",
  "groups": [{
    "id": 17,
    "label": "roasty",
    "color": "#766E6D",
    "parent_id": 4,
    "label_es": "tostado",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "tostado",
    "label_zh-CN": "烘焙",
    "weight": 1.19,
    "label_fr-CA": "rôti",
    "label_ko": "구운",
    "label_id": "panggang",
    "label_ja": "ロースティ",
    "groups": []
  }, {
    "id": 18,
    "label": "earthy",
    "color": "#463B34",
    "parent_id": 4,
    "label_es": "terroso",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "terroso",
    "label_zh-CN": "泥土",
    "weight": 1.28,
    "label_fr-CA": "terreux",
    "label_ko": "흙냄새",
    "label_id": "berbau tanah",
    "label_ja": "土っぽい",
    "groups": []
  }, {
    "id": 19,
    "label": "woody",
    "color": "#4A3737",
    "parent_id": 4,
    "label_es": "amaderado",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "amadeirado",
    "label_zh-CN": "木质的",
    "weight": 1.28,
    "label_fr-CA": "boisé",
    "label_ko": "나무 향의",
    "label_id": "kayu",
    "label_ja": "木質の",
    "groups": []
  }, {
    "id": 20,
    "label": "smoky",
    "color": "#766E6D",
    "parent_id": 4,
    "label_es": "ahumado",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "defumado",
    "label_zh-CN": "烟熏的",
    "weight": 1.28,
    "label_fr-CA": "fumé",
    "label_ko": "연기가 나는",
    "label_id": "berasap",
    "label_ja": "スモーキー",
    "groups": []
  }]
}, {
  "id": 5,
  "label": "chocolate",
  "color": "#6F5740",
  "label_es": "chocolate",
  "parent_id": null,
  "open": 1,
  "zoomed": 0,
  "selected": false,
  "flavor_type": "flavor",
  "positive": 1,
  "label_pt": "chocolate",
  "label_zh-CN": "巧克力",
  "weight": 1.17,
  "label_fr-CA": "chocolat",
  "label_ko": "초콜릿",
  "label_id": "coklat",
  "label_ja": "チョコレート",
  "groups": [{
    "id": 21,
    "label": "cacao",
    "color": "#48373F",
    "parent_id": 5,
    "label_es": "cacao",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "cacau",
    "label_zh-CN": "可可",
    "weight": 1.18,
    "label_fr-CA": "cacao",
    "label_ko": "카카오",
    "label_id": "kakao",
    "label_ja": "カカオ",
    "groups": []
  }, {
    "id": 22,
    "label": "dark",
    "color": "#3B241F",
    "parent_id": 5,
    "label_es": "oscuro",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "escuro",
    "label_zh-CN": "黑暗",
    "weight": 1.17,
    "label_fr-CA": "foncé",
    "label_ko": "어두운",
    "label_id": "gelap",
    "label_ja": "ダーク",
    "groups": []
  }, {
    "id": 23,
    "label": "milk",
    "color": "#9D7A5E",
    "parent_id": 5,
    "label_es": "leche",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "leite",
    "label_zh-CN": "牛奶",
    "weight": 1.15,
    "label_fr-CA": "lait",
    "label_ko": "우유",
    "label_id": "susu",
    "label_ja": "ミルク",
    "groups": []
  }]
}, {
  "id": 6,
  "label": "nuts",
  "color": "#F38546",
  "label_es": "nuez",
  "parent_id": null,
  "open": 1,
  "zoomed": 0,
  "selected": false,
  "flavor_type": "flavor",
  "positive": 1,
  "label_pt": "noz",
  "label_zh-CN": "坚果",
  "weight": 1.15,
  "label_fr-CA": "noix",
  "label_ko": "견과류",
  "label_id": "kacang-kacangan",
  "label_ja": "ナッツ",
  "groups": [{
    "id": 24,
    "label": "peanut",
    "color": "#C0A770",
    "parent_id": 6,
    "label_es": "cacahuate",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "amendoim",
    "label_zh-CN": "花生",
    "weight": 1.1,
    "label_fr-CA": "arachide",
    "label_ko": "땅콩",
    "label_id": "peanut/kacang tanah",
    "label_ja": "ピーナッツ",
    "groups": []
  }, {
    "id": 25,
    "label": "hazelnut",
    "color": "#E2A95B",
    "parent_id": 6,
    "label_es": "avellana",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "avelã",
    "label_zh-CN": "榛子",
    "weight": 1.17,
    "label_fr-CA": "noisette",
    "label_ko": "헤이즐넛",
    "label_id": "hazelnut",
    "label_ja": "ヘーゼルナッツ",
    "groups": []
  }, {
    "id": 26,
    "label": "almond",
    "color": "#FDFDD5",
    "parent_id": 6,
    "label_es": "almendra",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "amêndoas",
    "label_zh-CN": "杏仁",
    "weight": 1.16,
    "label_fr-CA": "amande",
    "label_ko": "아몬드",
    "label_id": "kacang almond",
    "label_ja": "アーモンド",
    "groups": []
  }]
}, {
  "id": 7,
  "label": "floral",
  "color": "#F5F058",
  "label_es": "floral",
  "parent_id": null,
  "open": 1,
  "zoomed": 0,
  "selected": false,
  "flavor_type": "flavor",
  "positive": 1,
  "label_pt": "floral",
  "label_zh-CN": "花",
  "weight": 1.21,
  "label_fr-CA": "florale",
  "label_ko": "꽃향기",
  "label_id": "bunga",
  "label_ja": "フローラル、花のような",
  "groups": [{
    "id": 27,
    "label": "honey",
    "color": "#F3C832",
    "parent_id": 7,
    "label_es": "miel",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "mel",
    "label_zh-CN": "蜂蜜",
    "weight": 1.19,
    "label_fr-CA": "miel",
    "label_ko": "꿀",
    "label_id": "madu",
    "label_ja": "ハチミツ",
    "groups": []
  },
  {
    "id": 28,
    "label": "hay",
    "color": "#DBC78A",
    "parent_id": 7,
    "label_es": "heno",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "feno",
    "label_zh-CN": "干草",
    "weight": 1.12,
    "label_fr-CA": "foin",
    "label_ko": "짚",
    "label_id": "jerami",
    "label_ja": "干し草・わら",
    "groups": []
  }]
}, {
  "id": 8,
  "label": "fruity",
  "color": "#AA274E",
  "label_es": "frutal",
  "parent_id": null,
  "open": 1,
  "zoomed": 0,
  "selected": false,
  "flavor_type": "flavor",
  "positive": 1,
  "label_pt": "frutado",
  "label_zh-CN": "果味",
  "weight": 1.21,
  "label_fr-CA": "fruité",
  "label_ko": "과일 같은",
  "label_id": "berasa buah",
  "label_ja": "フルーティー",
  "groups": [{
    "id": 29,
    "label": "stone fruit",
    "color": "#F79769",
    "parent_id": 8,
    "label_es": "fruta de hueso",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "fruta de caroço",
    "label_zh-CN": "核果",
    "weight": 1.19,
    "label_fr-CA": "fruit à noyau",
    "label_ko": "핵과류",
    "label_id": "buah berbiji",
    "label_ja": "核果",
    "groups": []
  }, {
    "id": 30,
    "label": "berries",
    "color": "#362F5A",
    "parent_id": 8,
    "label_es": "bayas",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "bagas",
    "label_zh-CN": "浆果",
    "weight": 1.19,
    "label_fr-CA": "baies",
    "label_ko": "베리류",
    "label_id": "beri",
    "label_ja": "ベリー",
    "groups": []
  },
  {
    "id": 31,
    "label": "plum",
    "color": "#E03371",
    "parent_id": 8,
    "label_es": "ciruela",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "ameixa",
    "label_zh-CN": "李子",
    "weight": 1.12,
    "label_fr-CA": "prune",
    "label_ko": "자두",
    "label_id": "plum",
    "label_ja": "プラム",
    "groups": []
  }]
}, {
  "id": 9,
  "label": "cereal",
  "color": "#AB9566",
  "label_es": "cereal",
  "parent_id": null,
  "open": 1,
  "zoomed": 0,
  "selected": false,
  "flavor_type": "flavor",
  "positive": 1,
  "label_pt": "cereal",
  "label_zh-CN": "谷物",
  "weight": 1.21,
  "label_fr-CA": "céréale",
  "label_ko": "시리얼",
  "label_id": "sereal",
  "label_ja": "シリアル",
  "groups": [{
    "id": 32,
    "label": "grain",
    "color": "#FFBD41",
    "parent_id": 9,
    "label_es": "grano",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "grão",
    "label_zh-CN": "谷粒",
    "weight": 1.19,
    "label_fr-CA": "grain",
    "label_ko": "곡물",
    "label_id": "biji-bijian",
    "label_ja": "穀物",
    "groups": []
  },
  {
    "id": 33,
    "label": "oat",
    "color": "#F5DEB3",
    "parent_id": 9,
    "label_es": "avena",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "aveia",
    "label_zh-CN": "燕麦",
    "weight": 1.12,
    "label_fr-CA": "avoine",
    "label_ko": "귀리",
    "label_id": "gandum",
    "label_ja": "オート",
    "groups": []
  }, {
    "id": 34,
    "label": "malta",
    "color": "#C96C55",
    "parent_id": 9,
    "label_es": "malta",
    "open": 1,
    "zoomed": 0,
    "selected": false,
    "flavor_type": "flavor",
    "positive": 1,
    "label_pt": "malte",
    "label_zh-CN": "麦芽",
    "weight": 1.12,
    "label_fr-CA": "malt",
    "label_ko": "맥아",
    "label_id": "malt",
    "label_ja": "モルト",
    "groups": []
  }]
}, {
  "id": 10,
  "label": "chemical",
  "color": "#A0511C",
  "label_es": "químico",
  "parent_id": null,
  "open": 1,
  "zoomed": 1,
  "selected": false,
  "flavor_type": "flavor",
  "positive": 1,
  "label_pt": "químico",
  "label_zh-CN": "化学",
  "label_fr-CA": "chimique",
  "label_ko": "화학적인",
  "label_id": "Bahan kimia",
  "label_ja": "化学的",
  "weight": 1,
  "groups": [{
    "id": 35,
    "label": "carbolic",
    "positive": 0,
    "color": "#5D005D",
    "label_es": "carbólico",
    "selected": false,
    "open": 1,
    "flavor_type": "flavor",
    "zoomed": 0,
    "label_pt": "carbólico",
    "weight": 1,
    "parent_id": 10,
    "label_zh-CN": "碳味",
    "label_fr-CA": "carbolique",
    "label_ko": "석탄산",
    "label_id": "karbol",
    "label_ja": "石炭酸（フェノール）のような",
    "groups": []
  }, {
    "id": 36,
    "label": "rubbery",
    "positive": 0,
    "color": "#0193CF",
    "label_es": "goma",
    "selected": false,
    "open": 1,
    "flavor_type": "flavor",
    "zoomed": 0,
    "label_pt": "borracha",
    "weight": 1,
    "parent_id": 10,
    "label_zh-CN": "橡胶味",
    "label_fr-CA": "caoutchouteux",
    "label_ko": "고무 같은",
    "label_id": "karet",
    "label_ja": "ゴムっぽい",
    "groups": []
  }, {
    "id": 37,
    "label": "carton",
    "positive": 0,
    "color": "#BB9669",
    "label_es": "cartón",
    "selected": false,
    "open": 1,
    "flavor_type": "flavor",
    "zoomed": 0,
    "label_pt": "cartão",
    "weight": 1,
    "parent_id": 10,
    "label_zh-CN": "纸板",
    "label_fr-CA": "carton",
    "label_ko": "판지",
    "label_id": "karton",
    "label_ja": "ダンボール",
    "groups": []
  }]
}]
