/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-04-18 00:08:44
 */

import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"

import { MasterSampleModel, samplePreProcessor } from "models/sample"
import { withSampleActions } from "./master-sample-action"
import { sampleProps } from "../sample/sample.props";
import {filterProps} from "../review-sample-store/filter-props";
import {withMasterSampleViews} from "./master-sample-view";


/**
 * Model description here for TypeScript hints.
 */
export const MasterSampleStoreModel = types
  .model("MasterSampleStore")
  .props({
    search: types.optional(filterProps, {}),
    masterSample: types.optional(MasterSampleModel, samplePreProcessor({})),
    childSample: types.optional(MasterSampleModel, samplePreProcessor({})),
    searchMasterSamples: types.optional(types.array(types.model({
      id: types.maybeNull(types.number),
      ...sampleProps
    })), []),
    searchQuery: types.optional(types.string, ''),
    masterSamples: types.optional(types.array(MasterSampleModel), []),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0),
    masterSampleDetail: types.optional(types.model({
      id: types.maybeNull(types.union(types.string, types.number)),
      ...sampleProps,
      aggregatedSpiderWeb: types.optional(types.string, '{}'),
      aggregatedCloudWords: types.optional(types.string, '{}'),
      aggregatedFlavorWheels: types.optional(types.string, '{}'),
    }), samplePreProcessor({})),
    cuppingSessionSamples: types.optional(types.array(types.model({
      id: types.string,
      sampleName: types.string,
    })), []),
    masterSampleShipment: types.optional(types.array(types.model({
      approvalStatus: types.maybeNull(types.string),
      salesContractReference: types.maybeNull(types.string),
      customer: types.maybeNull(types.string),
      sampleLocation: types.maybeNull(types.string),
      sampleType: types.maybeNull(types.string),
      courierName: types.maybeNull(types.string),
      status: types.maybeNull(types.string),
      masterSampleUniqueToken: types.maybeNull(types.string),
      sampleUniqueToken: types.maybeNull(types.string),
      sampleTransactionUniqueToken: types.maybeNull(types.string),
      sampleTransactionItemUniqueToken: types.maybeNull(types.string),
    })), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withSampleActions)
  .extend(withMasterSampleViews)

type MasterSampleStoreType = Instance<typeof MasterSampleStoreModel>
export interface MasterSampleStore extends MasterSampleStoreType {}
type MasterSampleStoreSnapshotType = SnapshotOut<typeof MasterSampleStoreModel>
export interface MasterSampleStoreSnapshot extends MasterSampleStoreSnapshotType {}
