import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Stack, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment"

import {useStores} from "models";
import {CourierSelection, FormInput, ModalWrapper} from "components";
import {SampleShipmentFulfillmentStatus} from "constants/constant";

export const ShipmentFulfillModal = (props) => {
  const { t } = useTranslation()
  const { id, shipment, open, onClose, onSubmit } = props
  const {
    companyStore: { isSucafinaFeature },
    sampleShipmentStore: { updateSampleShipment },
  } = useStores()

  const [courierName, setCourierName] = React.useState('')
  const [trackingNumber, setTrackingNumber] = React.useState('')
  const [eta, setEta] = React.useState<any>(null)
  const [loading, setLoading] = React.useState(false)

  const onUpdateShipmentInfo = async () => {
    try {
      setLoading(true)
      const payload: any = { courierName, trackingNumber, id, estimatedTimeArrival: eta.format("YYYY-MM-DD") }
      payload.fulfillmentStatus = SampleShipmentFulfillmentStatus.FULFILLED

      await updateSampleShipment(id, payload)

      onClose()
      onSubmit()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open) {
      setCourierName(shipment?.courierName || '')
      setTrackingNumber(shipment?.trackingNumber || '')
    }
  }, [open])

  return (
    <ModalWrapper open={open} maxWidth="sm" containerProps={{ sx: { p: '32px !important' } }}>
      <Box textAlign="center" mb={2}>
        <Typography variant="h5" mb={1}>{t('shipment.shipmentFulfillModal.title')}</Typography>
        <Typography variant="body2" mb={2} whiteSpace="pre">{t('shipment.shipmentFulfillModal.description')}</Typography>
        <Typography variant="body2">{t('shipment.shipmentFulfillModal.information')}</Typography>
      </Box>

      <Box>
        {isSucafinaFeature ?
          <CourierSelection
            label={t('shipmentOrderForm.courier')}
            value={courierName}
            onChange={(value) => setCourierName(value)}
          /> :
          <FormInput
            label={t('shipmentOrderForm.courier')}
            textFieldProps={{
              value: courierName,
              onChange: (e) => setCourierName(e.target.value)
            }}
          />
        }

        <FormInput
          label={t('shipmentOrderForm.trackingNumber')}
          textFieldProps={{
            value: trackingNumber,
            onChange: (e) => setTrackingNumber(e.target.value)
          }}
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <FormInput label={t('shipmentOrderForm.eta')}>
            <DatePicker
              mask='__/__/____'
              inputFormat="MM/DD/yyyy"
              value={eta}
              onChange={(value) => setEta(value)}
              renderInput={(params) =>
                <TextField {...params} size='small' onKeyDown={e => e.preventDefault()}/>
              }
            />
          </FormInput>
        </LocalizationProvider>
      </Box>

      <Stack direction="row" justifyContent="center" spacing={1} mt={2} mb={1}>
        <LoadingButton
          variant="contained"
          sx={{ px: 3}}
          loading={loading}
          disabled={!courierName || !trackingNumber || !eta}
          onClick={onUpdateShipmentInfo}
        >
          {t('common.submit')}
        </LoadingButton>
        <Button variant="outlined" onClick={onClose} sx={{ px: 3}}>
          {t('common.cancel')}
        </Button>
      </Stack>
    </ModalWrapper>
  )
}
