import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {Link} from "react-router-dom";
import {HelpOutlineOutlined} from "@mui/icons-material";
import {Button, Container, Stack, Typography, Box} from '@mui/material'

import { useStores } from 'models'
import {LightTooltip, ModalWrapper} from 'components'
import {globalAction} from "utils";
import {SampleForm} from "modules"

import { useStyles } from './sample-information-modal.styles'
import { SampleInformationModalProps } from './sample-information-modal.props'

export const SampleInformationModal: React.FC<SampleInformationModalProps> = observer((props:SampleInformationModalProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    masterSampleStore: { childSample, saveChildSample },
    notificationStore
  } =  useStores()
  const { open, onClose} = props

  const [errors, setErrors] = useState<any>([])

  const validation = () => {
    if (!childSample.name) {
      setErrors({
        "name": [{ message: t('sample.error.sampleNameRequired') }]
      })

      return false
    }

    return true
  }

  const onSaveSample = () => {
    setErrors({})
    if (!validation()) return

    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        await saveChildSample()
        notificationStore.setNotification({
          severity: 'success',
          message: t(`sample.masterSample.success.successfullyUpdatedSample`)
        })
        onClose()
      }
    })
  }

  return (
  <ModalWrapper
    maxWidth='lg'
    open={open}
  >
    <Container sx={{py: 2}}>
      <Typography variant="h4">{t('sample.masterSample.form.title')}</Typography>
      {childSample.masterSampleUniqueToken ? (
        <Link to={`/samples/${childSample.masterSampleUniqueToken}`} style={{display: 'inline-flex', marginBottom: '32px'}}>
          <Typography variant="h5">{childSample.masterSampleName}</Typography>
          &nbsp;
          <LightTooltip
            arrow
            title={t('sample.clickHereToMasterSample')}
          >
            <HelpOutlineOutlined color='primary' />
          </LightTooltip>
        </Link>
      ) : <Box mb={4} />}

      <SampleForm
        {...childSample}
        errors={errors}
        setValue={childSample.setValue}
      />

      <Stack direction='row' spacing={2} className={classes.buttonWrapper}>
        <Button
          variant='contained'
          className='wide'
          onClick={onSaveSample}
        >
          {t('common.submit')}
        </Button>
        <Button
          variant='outlined'
          className='wide'
          onClick={onClose}
        >
          {t('common.cancel')}
        </Button>
      </Stack>
    </Container>
  </ModalWrapper>
  );
})


