import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {Box, Button, FormControl, FormLabel, Stack, TextField, Typography} from "@mui/material";
import {isEmpty} from "lodash";

import {BasicTable, ModalWrapper} from "components/index";
import {optionsTranslation} from "utils";
import {colors} from "assets";

import {SubmitOrderProps} from "./submit-order.props";

export const SubmitOrder: React.FC<SubmitOrderProps> = observer((props: SubmitOrderProps) => {
  const { t } = useTranslation()
  const { open, onClose, onSubmit, data, setValue} = props
  const thirdPartyEmail = !isEmpty(data[0]?.thirdPartyEmail) ? data[0].thirdPartyEmail : ''

  const columnHelper = createColumnHelper<any>()

  const onCancel= () => {
    onClose()
  }

  const columns = [
    columnHelper.accessor('sampleName', {
      id: 'sampleName',
      header: () => <Box textAlign="left">{t('shipment.submitOrder.sampleName')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('sampleGrade', {
      id: 'sampleGrade',
      header: () => <Box textAlign="left">{t('shipment.submitOrder.sampleGrade')}</Box>,
      cell: info => optionsTranslation('grades', info.renderValue()),
    }),
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left">{t('shipment.submitOrder.sampleType')}</Box>,
      cell: info => optionsTranslation('sampleType', info.renderValue()),
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  useEffect(() => {
    if (open) {
      setValue('thirdPartyEmail', thirdPartyEmail)
    }
  }, [open])

  return(
    <ModalWrapper
      maxWidth='sm'
      open={open}
      onClose={onCancel}
    >
      <Box
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        sx={{mb: 1, pl: 8, pr: 8}}
      >
        <Typography variant="h4">{t('shipment.submitOrder.title')}</Typography>
        <Typography sx={{ fontWeight: 400 }}>{t('shipment.submitOrder.submitOrderDescription')}</Typography>
      </Box>
      <Box sx={{borderRadius: 1, p: 2, mt: 2, backgroundColor: colors.primary.o25}}>
        <BasicTable table={table} />
      </Box>
      <Box
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{mb: 1, pl: 8, pr: 8}}
      >
        <FormControl fullWidth margin='normal'>
          <FormLabel>{t('shipment.submitOrder.3rdPartyName')}</FormLabel>
          <TextField
            type="email"
            size='small'
            onChange= {(event) => setValue('thirdPartyName', event.target.value)}
          />
        </FormControl>
        <FormControl fullWidth margin='normal'>
          <FormLabel>{t('shipment.submitOrder.3rdPartyEmail')}</FormLabel>
          <TextField
            type="email"
            size='small'
            placeholder="example@email.com"
            defaultValue={thirdPartyEmail}
            onChange= {(event) => setValue('thirdPartyEmail', event.target.value)}
          />
        </FormControl>
      </Box>
      <Typography variant="body2" sx={{ fontWeight: 200 }}>{t('shipment.submitOrder.note')}</Typography>
      <Stack
        justifyContent="center"
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{mb: 1, mt: 4}}
      >
        <Button
          variant='contained'
          onClick={onSubmit}
        >
          {t('shipment.button.submit')}
        </Button>
        <Button
          variant='outlined'
          onClick={onClose}
        >
          {t('shipment.button.cancel')}
        </Button>
      </Stack>
    </ModalWrapper>
  )
})
