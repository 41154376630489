import moment from "moment";

export const generateLabel = (data: any) => {
  const sample = data['sample']

  return [
    {
      attribute: "qrcode",
      status: true,
      value: data["qrcode"]
    },
    {
      attribute: "sampleId",
      status: !!sample["sampleUniqueNumber"],
      value: sample["sampleUniqueNumber"]
    },
    {
      attribute: "description",
      status: !!sample["description"],
      value: sample["description"]
    },
    {
      attribute: "sampleType",
      status: !!data["sampleType"],
      value: data["sampleType"]
    },
    {
      attribute: "species",
      status: !!sample["species"],
      value: sample["species"]
    },
    {
      attribute: "country",
      status: !!sample["countryCode"],
      value: sample["countryCode"]
    },
    {
      attribute: "sampleName",
      status: !!data["sampleName"],
      value: data["sampleName"]
    },
    {
      attribute: "cropYear",
      status: !!sample["cropYear"],
      value: sample["cropYear"]
    },
    {
      attribute: "supplier",
      status: !!sample["supplierName"],
      value: sample["supplierName"]
    },
    {
      attribute: "purchaseContractReference",
      status: !!sample["purchaseContractReference"],
      value: sample["purchaseContractReference"]
    },
    {
      attribute: "client",
      status: !!sample["customer"],
      value: sample["customer"]
    },
    {
      attribute: "salesContractReference",
      status: !!sample["salesContractReference"],
      value: sample["salesContractReference"]
    },
    {
      attribute: "grade",
      status: !!sample["grade"],
      value: sample["grade"]
    },
    {
      attribute: "coffeeProcessing",
      status: !!sample["processName"],
      value: sample["processName"]
    },
    {
      attribute: "numberOfBags",
      status: !!sample["numberOfBag"],
      value: sample["numberOfBag"]
    },
    {
      attribute: "bagWeight",
      status: !!sample["bagWeight"],
      value: sample["bagWeight"]
    },
    {
      attribute: "shipmentMonth",
      status: !!sample["shipmentDate"],
      value: sample["shipmentDate"] ? moment(sample["shipmentDate"]).format('L') : ''
    },
    {
      attribute: "varietals",
      status: !!sample["varietalsTags"],
      value: sample["varietalsTags"]
    },
    {
      attribute: "moisture",
      status: !!sample["moisture"],
      value: sample["moisture"]
    },
    {
      attribute: "waterActivity",
      status: !!sample["waterActivity"],
      value: sample["waterActivity"]
    },
    {
      attribute: "cuppingDate",
      status: !!sample["cuppingDate"],
      value: sample["cuppingDate"]
    },
    {
      attribute: "paperSize",
      status: true,
      value: "letter"
    },
  ]
}
