import { createStyles, makeStyles } from '@mui/styles'
import {Theme} from "@mui/material"
import { colors } from 'assets'

export const useStyles = makeStyles((theme: Theme ) =>
  createStyles({
    descriptorWrapper: {
      marginTop: 16
    },
    tasteWrapper: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #EBE8F2',
      borderRadius: 4,
      margin: '8px 0'
    },
    tasteForm: {
      marginLeft: 0,
      marginRight: 0,
      borderBottom: '1px solid #EBE8F2'
    },
    menuWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    tasteText: {
      '& span': {
        color: colors.primary.main,
        fontWeight: 700
      }
    },
    cardStyle: {
      padding: theme.spacing(2),
      height: '100%',

      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderRadius: 0
      }
    }
  })
)
