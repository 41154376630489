import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"

import { withCollectiveReportActions } from "./collective-report-action"
import {preProcessorSampleScore, SampleScoreModel} from "models/sample-score";

/**
 * Model description here for TypeScript hints.
 */

const DescriptorModel = types.model({
  label: types.string,
  qty: types.number
})

export const CollectiveReportStoreModel = types
  .model("CollectiveReportStore")
  .props({
    collectiveReports: types.optional(
      types.array(types.model({
        id: types.optional(types.string, ''),
        sampleName: types.optional(types.string, ''),
        average: types.optional(types.string, ''),
        range: types.optional(types.string, ''),
        totalCupper: types.optional(types.number, 0),
        descriptors: types.optional(types.array(DescriptorModel),[]),
        defects: types.optional(types.array(DescriptorModel),[]),
        scores: types.optional(types.string, '{}'),
        flavorWheelGroups: types.optional(types.string, '{}'),
        flavorWheelOnlyFragrance: types.optional(types.string, '{}'),
        flavorWheelOnlyFlavor: types.optional(types.string, '{}'),
        topFlavorWheelGroups: types.optional(types.string, '{}'),
        topFlavorWheelOnlyFragrance: types.optional(types.string, '{}'),
        topFlavorWheelOnlyFlavor: types.optional(types.string, '{}'),
        cuppingProtocol: types.optional(types.string, ''),
        emoticons: types.optional(types.array(types.number), []),
        interestStatus: types.optional(types.string, '{}'),
        sampleUniqueNumber: types.maybeNull(types.string),
      }
    )), [])
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withCollectiveReportActions)
  .views((self) => ({
    get isComboCupping() {
      if (self.collectiveReports?.length === 1) return false

      const seenValues = new Set();
      for (const report of self.collectiveReports) {
        if (seenValues.has(report.cuppingProtocol)) {
          return false;
        }
        seenValues.add(report.cuppingProtocol);
      }

      return true;
    }
  }))

type CollectiveReportStoreType = Instance<typeof CollectiveReportStoreModel>
export interface CollectiveReportStore extends CollectiveReportStoreType {}
type CollectiveReportStoreSnapshotType = SnapshotOut<typeof CollectiveReportStoreModel>
export interface CollectiveReportStoreSnapshot extends CollectiveReportStoreSnapshotType {}
