/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-17 22:31:28
 * @modify date 2022-02-17 22:31:28
 */

import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import {colors} from "assets"

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerWrapper: {
      paddingBottom: theme.spacing(1),
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(0),
        marginBottom: theme.spacing(1),
        textAlign: 'right',
      }
    },
    iconRounded: {
      width: 20,
      height: 20,
      backgroundColor: 'white',
      '& svg': {
        height: 16,
        fill: theme.palette.primary.main,
      }
    },
    buttonRounded: {
      borderRadius: theme.spacing(8),
      '&:hover': {
        '& svg': {
          fill: theme.palette.primary.dark
        }
      }
    },
    sampleNavWrapper: {
      overflowY: 'auto',
      height: 'calc(100vh - 200px)',
    },
    subtitle: {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
        textAlign: 'center'
      }
    },
    sampleNav: {
      cursor: 'pointer',
      padding: theme.spacing(2),
      paddingTop: 12,
      paddingBottom: 12,
      backgroundColor: colors.brightGray,
      border: `1px solid ${colors.primary.o25}`,

      '&:nth-child(n+2)': {
        borderTop: 'none'
      },

      '&.active': {
        backgroundColor: 'white',
        borderRight: 'none'
      }
    },
    buttonCupNow: {
      backgroundColor: 'white',
      padding: theme.spacing(2),
      position: 'fixed',
      width: '100%',
      bottom: 0,
    }
  })
)
