import React, {useEffect} from "react"
import {useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";

import {useStores} from "models";
import {NewShipmentForm, ShowShipmentForm} from "./components";
import {SampleShipmentFulfillmentStatus} from "constants/constant";

export const SampleShipmentForm = observer(() => {
  const params = useParams()
  const isNew = params.id === 'new'
  const isNewRequest = params.id === SampleShipmentFulfillmentStatus.NEW_REQUEST

  const {
    sampleShipmentStore: { resetShipmentForm },
  } = useStores()

  useEffect(() => {
    if (isNew) {
      resetShipmentForm()
    }
  }, [])

  if (isNew || isNewRequest) {
    return <NewShipmentForm />
  }

  return <ShowShipmentForm />
})

export default SampleShipmentForm
