import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {isEmpty, truncate} from "lodash";
import {observer} from "mobx-react-lite";
import moment from "moment";
import {Radar} from "react-chartjs-2";
import ReactWordcloud from 'react-wordcloud'
import {useParams, useNavigate, useSearchParams} from "react-router-dom";
import {BarChart, ExpandLess, ExpandMore, Edit as EditIcon} from "@mui/icons-material";
import {Box, Button, Collapse, Container, Grid, Stack, Tab, Tabs, Typography} from "@mui/material";

import {useStores} from "models";
import {ApproveReject} from "modules";
import {HeaderTitle} from "components";
import {findRoute} from "constants/routes";
import {countryName, flavorWheel, globalAction, minMaxGraph, optionsTranslation} from "utils";
import {
  emptyCloudWords,
  emptyCupping,
  emptyFlavorWheel,
  emptySpiderWeb,
  IconBean,
  IconCountryOfOrigin,
  IconYear,
  IconSampleProccess,
  IconSampleType,
  IconStartCupping,
  IconDoubleBeans,
  IconShippingFast
} from "assets/images";
import {isMobile} from "react-device-detect"

import {useStyles} from "./sample-details.styles";
import {
  CuppingResults,
  GreenGradings,
  Activity,
  Shipments,
  SampleInformationModal,
  ApproveRejectButton,
  GenerateReport
} from "./components"

export const SampleDetails = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const params = useParams()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const {
    companyStore: { base64Logo, isEnterprisePlus, isBayers },
    masterSampleStore: {
      masterSampleDetail: {
        name, countryCode, sampleType, processName, cropYear, receivedOn, species, grade, bagWeight, volume,
        supplierName, varietalsTags, cuppingProtocol, moisture, temperature, producerName, salesContractReference,
        receivedWeight, waterActivity, customer, purchaseContractReference, numberOfBag, mass, sampleUniqueNumber,
        approvalStatus, isDecaf, basketCode, otaTestValue, contractWeight, referenceNumber, purchaseGrade
      },
      getFlavorWheelGroups, getAverageScoresForGraph, getCloudWords, getMasterSample
    },
    notificationStore
  } = useStores()

  const [openSample, setOpenSample] = useState(true)
  const [tab, setTab] = useState('cupping')
  const [openModal, setOpenModal] = useState(false)
  const [openConfirmApprove, setOpenConfirmApprove] = useState(false)
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [openQcReason, setOpenQcReason] = useState(false)
  const [openReport, setOpenReport] = useState(false)

  const homeLink = findRoute('home')

  const sampleInformation = [
    {
      icon: <IconBean className={classes.icon} style={{ transform: 'scale(0.8)'}} />,
      label: t('sample.sampleName'),
      value: name
    },
    {
      icon: <IconCountryOfOrigin className={classes.icon} />,
      label: t('sample.country'),
      value: countryName(countryCode)
    },
    {
      icon: <IconSampleType className={classes.icon} />,
      label: t('sample.sampleType'),
      value: optionsTranslation('sampleType', sampleType)
    },
    {
      icon: <IconSampleProccess className={classes.icon} />,
      label: t('sample.coffeeProcessing'),
      value: processName
    },
    {
      icon: <IconYear className={classes.icon} />,
      label: t('sample.cropYear'),
      value: cropYear
    }
  ]

  const sampleDetails = [
    {
      label: t('sample.receivedDate'),
      value: isEmpty(receivedOn) ? "-" :  moment(receivedOn).format('DD/MM/YYYY')
    },
    {
      label: t('sample.supplier'),
      value: supplierName
    },
    {
      label: t('sample.producer'),
      value: producerName
    },
    {
      label: t('sample.customer'),
      value: customer
    },
    {
      label: t('sample.species'),
      value: optionsTranslation('species', species)
    },
    {
      label: t('sample.varietals'),
      value: varietalsTags
    },
    {
      label: t('sample.referenceNumber'),
      value: truncate(referenceNumber,{'length': 25, 'separator': ''}) || '-'
    },
    {
      label: t('sample.salesContractReference'),
      value: salesContractReference || '-'
    },
    {
      label: t('sample.purchaseContractReference'),
      value: purchaseContractReference || '-'
    },
    {
      label: t('sample.purchaseGrade'),
      value: purchaseGrade || '-'
    },
    {
      label: t('sample.grade'),
      value: optionsTranslation('grades', grade)
    },
    {
      label: t('sample.preferredCuppingProtocol'),
      value: optionsTranslation('cuppingProtocol', cuppingProtocol),
    },
    {
      label: t('sample.receivedWeight'),
      value: receivedWeight ? `${receivedWeight} grams` : '-'
    },
    {
      label: t('sample.numberOfBags'),
      value: numberOfBag || '-'
    },
    {
      label: t('sample.bagWeight'),
      value: bagWeight ? `${bagWeight} kg` : '-'
    },
    {
      label: t('sample.moisture'),
      value: moisture ? `${moisture}%` : '-'
    },
    {
      label: t('sample.waterActivity'),
      value: waterActivity
    },
    {
      label: t('sample.mass'),
      value: mass ? `${mass} gr` : ''
    },
    {
      label: t('sample.volume'),
      value: volume ? `${volume} mL` : '-'
    },
    {
      label: t('sample.temperature'),
      value: temperature || '-'
    },
    ...(isBayers ? [
      {
        label: t('sample.decaf'),
        value: optionsTranslation('boolean', isDecaf?.toString())
      },
      {
        label: t('sample.otaTestValue'),
        value: otaTestValue ? `${otaTestValue} ppb` : '-'
      },
      {
        label: t('sample.basketCode'),
        value: basketCode || '-'
      },
      {
        label: t('sample.contractWeight'),
        value: contractWeight ? `${contractWeight} gr` : '-'
      },
    ] : []),
  ]

  const getSample = () => {
    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        await getMasterSample(params.id, true)
      },
    })
  }

  const renderTabContent = () => {
    switch (tab) {
      case 'cupping':
        return <CuppingResults loadSample={getSample} />
      case 'green-grading':
        return <GreenGradings />
      case 'shipment':
        return <Shipments />
      case 'activity':
        return <Activity />
      default:
        return (
          <Box py={4} textAlign="center">
            <img src={emptyCupping} />
          </Box>
        )
    }
  }

  const renderButtons = (
    <Stack direction={isMobile ? 'column-reverse' : 'row'} spacing={1} className={classes.titleButtons}>
      <Stack direction="row" spacing={1}>
        <ApproveRejectButton
          approvalStatus={approvalStatus}
          onApprove={(hasApproval) => {
            if (hasApproval) setOpenQcReason(true)
            setOpenConfirmApprove(true)
          }}
          onReject={(hasApproval) => {
            if (hasApproval) setOpenQcReason(true)
            setOpenRejectModal(true)
          }}
          buttonProps={{fullWidth: isMobile}}
        />
      </Stack>
      <Button
        fullWidth={isMobile}
        size="small"
        variant="contained"
        startIcon={<EditIcon />}
        onClick={() => navigate(`/samples/${params.id}`)}
      >
        {t('common.edit')}
      </Button>
      <Button
        fullWidth={isMobile}
        size="small"
        variant="contained"
        onClick={() => setOpenReport(true)}
      >
        {t('sample.detail.generateReport')}
      </Button>
    </Stack>
  )

  useEffect(() => {
    flavorWheel(getFlavorWheelGroups, isEnterprisePlus && base64Logo)
  }, [getFlavorWheelGroups])

  useEffect(() => {
    getSample()
  }, [params.id])

  useEffect(() => {
    setSearchParams({ tab })
  }, [tab])

  useEffect(() => {
    if (searchParams.has('tab')) {
      setTab(searchParams.get('tab') || 'cupping')
    }
  }, [])

  return (
    <>
      <Box>
        <HeaderTitle
          backUrl={homeLink}
          title={t('menu.sampleDetails')}
          breadcrumb={t('common.backTo', { menu: t('menu.home')})}
          {...(!isMobile && { rightContent: renderButtons }) }
        />

        <Container>
          <Box sx={{mb: 1}}>
            <Typography variant='h4'>{sampleUniqueNumber}</Typography>
          </Box>
          <Box className={classes.wrapper} sx={{ px: 3, py: 2.5 }}>
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={12} md={4} textAlign="center">
                {isEmpty(getFlavorWheelGroups) ?
                  <img src={emptyFlavorWheel} height="100%" /> :
                  <div id="tastify-wheel" style={{width: '100%', height: '100%'}}></div>
                }
              </Grid>

              <Grid item xs={12} md={4} textAlign="center">
                {isEmpty(getAverageScoresForGraph.datasets[0].data) ?
                  <img src={emptySpiderWeb} height="100%" /> :
                  <Radar
                    data={getAverageScoresForGraph}
                    options={{
                      plugins: {
                        legend: {
                          display: false
                        }
                      },
                      scales: {
                        r: {
                          ...minMaxGraph(getAverageScoresForGraph.datasets[0].data),
                          pointLabels: {
                            font: {
                              size: 16,
                            }
                          }
                        }
                      }
                    }}
                  />
                }
              </Grid>

              <Grid item xs={12} md={4} textAlign="center">
                {isEmpty(getCloudWords) ?
                  <img src={emptyCloudWords} height="100%" width="291px" /> :
                  <ReactWordcloud
                    words={getCloudWords}
                    options={{
                      rotations: 1,
                      rotationAngles: [0, 0],
                      fontSizes: [20, 30],
                      enableTooltip: false
                    }}
                  />
                }
              </Grid>
            </Grid>

            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mb: 3 }} useFlexGap flexWrap="wrap">
              {sampleInformation.map((item, index) => (
                <Box key={index} className={classes.sampleInfo}>
                  {item.icon}
                  <Box>
                    <Typography variant="subtitle1">{item.label}:</Typography>
                    <Typography variant="body2">{item.value}</Typography>
                  </Box>
                </Box>
              ))}
            </Stack>

            <Box className={classes.sampleDetail}>
              <Box
                className={classes.collapseButton}
                onClick={() => setOpenSample(!openSample)}
              >
                <Typography variant="h6">{t('sample.viewDetailedInformation')}</Typography>
                {openSample ?
                  <ExpandLess color='primary' fontSize='large' /> :
                  <ExpandMore color='primary' fontSize='large' />
                }
              </Box>

              <Collapse in={openSample} timeout="auto" unmountOnExit>
                <Grid container spacing={2} columns={10} mt={2}>
                  {sampleDetails.map((item, index) => (
                    <Grid item xs={5} md={2} key={index}>
                      {item.label && <Typography variant="subtitle1">{item.label}:</Typography>}
                      {item.value && <Typography variant="body2">{item.value}</Typography>}
                    </Grid>
                  ))}
                </Grid>
              </Collapse>
            </Box>
          </Box>

          <Box className={classes.wrapper}>
            <Tabs
              value={tab}
              onChange={(e, v) => setTab(v)}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              className={classes.tabs}
            >
              <Tab value="cupping" label="Cupping Result" icon={<IconStartCupping />} iconPosition="start" />
              <Tab value="green-grading" label="Green Grading" icon={<IconDoubleBeans />} iconPosition="start" />
              <Tab value="shipment" label="Shipment" icon={<IconShippingFast />} iconPosition="start" />
              <Tab value="activity" label="Activity" icon={<BarChart />} iconPosition="start" />
            </Tabs>

            {renderTabContent()}
          </Box>
          <SampleInformationModal
            open={openModal}
            onClose={() => setOpenModal(false)}
          />
        </Container>

        <ApproveReject
          id={params.id}
          isSample
          confirmApproveTitle={approvalStatus ? t('sample.detail.approval.changeApproveTitle') : undefined}
          confirmApproveDescription={approvalStatus ? t('sample.detail.approval.changeApproveDescription') : undefined}
          openQcReason={openQcReason}
          setOpenQcReason={setOpenQcReason}
          openConfirmApprove={openConfirmApprove}
          setOpenConfirmApprove={setOpenConfirmApprove}
          openRejectModal={openRejectModal}
          setOpenRejectModal={setOpenRejectModal}
          successMessage={t('sample.detail.approval.successfullyChanged')}
          onSuccess={getSample}
        />

        <GenerateReport
          open={openReport}
          onClose={() => setOpenReport(false)}
          sampleId={params.id}
        />
      </Box>
      {isMobile && renderButtons}
    </>
  )
})

export default SampleDetails
