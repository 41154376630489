import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {flexRender} from "@tanstack/react-table";
import {
  Box,
  Grid,
  Paper,
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Tab,
  Tabs,
  Chip,
  SvgIcon
} from "@mui/material";
import { ceil, chunk, debounce, flatMapDeep, includes, isEmpty, upperFirst } from "lodash";

import { colors } from 'assets'
import { useStyles } from "./export-pdf.styles";
import { flavorWheel, printPage, } from 'utils'
import {fulfillmentStatus, fulfillmentTypes} from "constants/form";
import {emptyFlavorWheel, IconNoFilter} from "assets/images";
import { rejectedColors } from "constants/rejected-colors";
import { allDescriptor } from "constants/all-descriptors";
import { scaDescriptorList } from 'constants/sca-descriptors'

export const ExportPdf = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { 
    title, 
    nameFile, 
    columnFilters, 
    onExportPdf,  
    dateValue, 
    titleCountData, 
    countData, 
    table,
    summaryThirdPartyShipments,
    summaryLocalShipments,
    thirdPartyShipmentsType,
    localShipmentsType,
    totalShipments,
    tabValue,
    approvedSamples, 
    cuppingSubmitted, 
    rejectedSamples, 
    sampleReceived, 
    sampleShipmentCreated, 
    topFlavorWheel, 
    descriptors, 
    averageScore,
    isEnterprisePlus,
    base64Logo,
    maxScore, 
    minScore, 
    noStatusSamples,
    dataRejection,
    languagePreference,
    isPrinting} = props
 
  const printElement = React.createRef<Element | undefined>()

  const renderDailyArrivalCupping = () => {
    return(
      <Box
      sx={{
        mt: 5,
        borderRadius: 2,
        backgroundColor: "#EBE8F2",
        height: 150,
      }}
    >
      <Grid container spacing={2}>
        
        <Grid item xs={2}>
          <Box
            sx={{
              backgroundColor: "white",
              height: 120,
              ml: 2,
              borderRadius: 2,
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              justifyContent="center"
              textAlign="center"
            >
              <Typography variant="h6">
                {titleCountData}
              </Typography>
              <Typography variant="h2" color="primary">
                {countData}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={10}>
        <Typography variant="h6" sx={{mb: 1}}>{t("reportAnalytics.filter")}</Typography>
          <Grid container spacing={1}>
          {isEmpty(columnFilters) ?
            <Grid item xs={12}>
              <Box sx={{justifyContent:'center', display: 'flex'}}>
                <SvgIcon component={IconNoFilter}  inheritViewBox sx={{height: 75, width: 200}} />
              </Box>
            </Grid>
            : columnFilters.map( data => {
            return( 
              <Grid item xs={5}>
                <Stack direction="row" spacing={1}>
                    <Typography variant="body1">
                    {t(`reportAnalytics.${data.id}`)}:
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      {data.value}
                    </Typography>
                  </Stack>
              </Grid>
               )
              })}
          </Grid>
        </Grid>
      </Grid>
    </Box>
    
    )
  }

  const renderTab = (summaryData, types) => {
    const tabSelect = types === fulfillmentTypes[0] ? "local-" : ""

    return(
    <Tabs
      value={tabValue}
      textColor="secondary"
      indicatorColor="secondary"
      variant="fullWidth"
      aria-label="full width tabs example"
      className={classes.tabs}
    >
       {fulfillmentStatus.map( data => {

        if(types === fulfillmentTypes[0] && data === fulfillmentStatus[1]) return
        return(
          <Tab
          value={types === fulfillmentTypes[0] ? `local-${data}` : data}
          label={
            <Stack
              direction="column"
              justifyContent="center"
              textAlign="center"
            >
              <Typography
                variant="subtitle1"
                color={tabValue === `${tabSelect}${data}` ? "white" : colors.text.o75}
              >
                {t(`reportAnalytics.${data}`)}
              </Typography>
              <Typography
                variant="h4"
                color={tabValue === `${tabSelect}${data}` ? "white" : "primary"}
              >
                {summaryData[data]}
              </Typography>
            </Stack>
          }
        />
        )

       })}
    </Tabs>
    )

}

const renderList = (data, key, index) => {
  const hasChildren = data[key]?.children
 
  return(
    <ul style={{marginLeft: -20}}>
      <li style={{listStyle: "square", fontSize: '21px', color: rejectedColors[key]}}>
        <Typography  color={index < 3 ? colors.text.primary : colors.text.o75} variant={index < 3 ? "h6": "body1"}>{data[key].count} {t(`shipment.rejectModal.${key}`)}</Typography>
      </li>
      {hasChildren && Object.keys(data[key].children).map( k => {
        return(
          renderList(data[key].children, k, 4)
        )
      })}
      
    </ul>
  )
}

const getDescriptor = (descriptor) => {
  if (!descriptor.groups || !descriptor.groups.length) return descriptor

  return [descriptor, flatMapDeep(descriptor.groups, getDescriptor)]
}

const pickTextColorBasedOnBgColorSimple = (bgColor) => {
  if(bgColor === undefined) return

  var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
    colors.text.primary : 'white';
}

const descriptorColor = (data) => {
  const flatList = flatMapDeep(allDescriptor, getDescriptor)

  if (flatList.findIndex(x => upperFirst(x.label) === upperFirst(data)) >= 0) {
    return flatList.filter(x => upperFirst(x.label) === upperFirst(data))[0]['color']
  }
}

const descriptorTranslation = (language, data) => {
  const flatList = flatMapDeep(scaDescriptorList, getDescriptor)
  const label = `label_${language}`

  if (language === 'en') return upperFirst(data)

  return upperFirst(flatList.filter(x => upperFirst(x.label) === upperFirst(data))[0][label])
}

const renderDescription = (data) => {
  return (
    <Chip
      key={data.label}
      size="small"
      variant='outlined'
      sx={{ mr: 1, mt: 1, backgroundColor: descriptorColor(data.label), color: pickTextColorBasedOnBgColorSimple(descriptorColor(data.label)), borderColor: descriptorColor(data.label) }}
      label={`${descriptorTranslation(languagePreference, data.label)}`}

    />
  )
}

const checkIncludeInternalScore = (data) =>{
  if(data === "0") return "Yes"
    return "No"
}

const renderShipment = () => {
  return(
    <>
      <Stack direction="row" sx={{mt: 1, mb: -5}}>
        <Typography variant="body1" color={colors.text.o75}>
        {t("reportAnalytics.allShipments")}
        </Typography>
        <Typography variant="body1" color="primary">
          : {totalShipments}
        </Typography>
      </Stack>
      <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box className={classes.wrapper}>
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <Typography variant="h6">3rd Party Shipment</Typography>
          </Box>

          <Stack direction="row" justifyContent="center" textAlign="center">
            <Typography variant="h4" color="primary">
              {summaryThirdPartyShipments.total}
            </Typography>
            <Typography variant="h4" color={colors.text.o50}>
              /{totalShipments}
            </Typography>
          </Stack>

          <Box>
            <Typography>{t("reportAnalytics.detail")}</Typography>
            {renderTab(summaryThirdPartyShipments, thirdPartyShipmentsType)}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={classes.wrapper}>
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <Typography variant="h6">Local Shipments</Typography>
          </Box>

          <Stack direction="row" justifyContent="center" textAlign="center">
            <Typography variant="h4" color="primary">
              {summaryLocalShipments.total}
            </Typography>
            <Typography variant="h4" color={colors.text.o50}>
              /{totalShipments}
            </Typography>
          </Stack>

          <Box>
            <Typography>{t("reportAnalytics.detail")}</Typography>
            {renderTab(summaryLocalShipments, localShipmentsType)}
          </Box>
        </Box>
      </Grid>
      </Grid>

      <Box
      sx={{
        mt: 5,
        borderRadius: 2,
        backgroundColor: "#EBE8F2",
        height: 150,
      }}
    >
      <Grid container spacing={2} sx={{p: 1}}> 
        <Grid item xs={12}>
        <Typography variant="h6" sx={{mb: 1}}>{t("reportAnalytics.filter")}</Typography>
          <Grid container spacing={1}>
          {isEmpty(columnFilters) ?
            <Grid item xs={12}>
              <Box sx={{justifyContent:'center', display: 'flex'}}>
                <SvgIcon component={IconNoFilter}  inheritViewBox sx={{height: 75, width: 200}} />
              </Box>
            </Grid>
            : columnFilters.map( data => {
            return( 
              <Grid item xs={5}>
                <Stack direction="row" spacing={1}>
                    <Typography variant="body1">
                    {t(`reportAnalytics.${data.id}`)}:
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      {data.value}{includes(data.id, "number") && " to 10"}
                    </Typography>
                  </Stack>
              </Grid>
               )
              })}
          </Grid>
        </Grid>
      </Grid>
    </Box>
    </>
    )
  }

  const renderSample = () => {
    const splitDataDes = ceil(descriptors.length/2)

    return(
      <Box>
      <Box
        sx={{
          mt: 5,
          borderRadius: 2,
          backgroundColor: "#EBE8F2",
          height: 150,
        }}
      >
        <Grid container spacing={2} sx={{p: 1}}> 
          <Grid item xs={12}>
          <Typography variant="h6" sx={{mb: 1}}>{t("reportAnalytics.filter")}</Typography>
            <Grid container spacing={1}>
            {isEmpty(columnFilters) ?
              <Grid item xs={12}>
                <Box sx={{justifyContent:'center', display: 'flex'}}>
                  <SvgIcon component={IconNoFilter}  inheritViewBox sx={{height: 75, width: 200}} />
                </Box>
              </Grid>
              : Object.keys(columnFilters).map( k => {
              return( 
                <Grid item xs={3}>
                  <Stack direction="row" spacing={1}>
                      <Typography variant="body1">
                      { k === "removeInternalScore" ? t('reportAnalytics.removeInternalScore') : t(`sample.${k}`)}:
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {k === "removeInternalScore" ?  checkIncludeInternalScore(columnFilters[k]): columnFilters[k]}
                      </Typography>
                    </Stack>
                </Grid>
                )
                })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
        <Grid container spacing={2} >
        <Grid item xs={3}>
          <Box className={classes.cardSummary}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6">{t("reportAnalytics.sampleReceived")}</Typography>
            </Box>

            <Stack direction="row">
              <Typography variant="h3" color={colors.text.primary}>
                {sampleReceived}
              </Typography>
            </Stack>
          </Box>
          <Box className={classes.cardSummary}>
            <Box sx={{mb: 2 }}>
              <Typography variant="h6">{t("reportAnalytics.cuppingSubmitted")}</Typography>
            </Box>

            <Stack direction="row">
              <Typography variant="h3" color={colors.text.primary}>
                {cuppingSubmitted}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.wrapper} height={243}>
            <Box sx={{mb: 2 }}>
              <Typography variant="h6">{t("reportAnalytics.averageScore")}</Typography>
            </Box>
            <Stack direction="column" justifyContent="center" textAlign="center">
              <Typography variant="h2" color="primary">
                {averageScore}
              </Typography>
              <Typography variant="h4" color={colors.primary.o50}>
              {`(${minScore} - ${maxScore})`}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={3}>
        <Box className={classes.cardSummary}>
            <Box sx={{mb: 2 }}>
              <Typography variant="h6">{t("reportAnalytics.sampleShipmentCreated")}</Typography>
            </Box>
            <Stack direction="row">
              <Typography variant="h3" color={colors.text.primary}>
                {sampleShipmentCreated}
              </Typography>
            </Stack>
          </Box>
          <Box className={classes.cardSummary}>
            <Box sx={{mb: 2 }}>
              <Typography variant="h6">{t("reportAnalytics.approvedSample")}</Typography>
            </Box>
            <Stack direction="row">
              <Typography variant="h3" color={colors.success.primary}>
                {approvedSamples}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={3}>
        <Box className={classes.cardSummary}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6">{t("reportAnalytics.rejectedSample")}</Typography>
            </Box>
            <Stack direction="row">
              <Typography variant="h3" color={colors.error.primary}>
                {rejectedSamples}
              </Typography>
            </Stack>
          </Box>
          <Box className={classes.cardSummary}>
            <Box sx={{mb: 2 }}>
              <Typography variant="h6">{t("reportAnalytics.sampleWithNoStatus")}</Typography>
            </Box>
            <Stack direction="row">
              <Typography variant="h3" color={colors.text.o75}>
              {noStatusSamples}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        </Grid>
        <Grid container spacing={2} >
          <Grid item xs={6}>
          <Typography variant="h6">{t("reportAnalytics.topDescriptor")}</Typography>
          <Box className={classes.wrapper} >
            <Box height={400}>
                {isEmpty(topFlavorWheel) ?
                  <img src={emptyFlavorWheel} height="100%" /> :
                  <div id="tastify-wheel-1" style={{width: '100%', height: '100%'}}></div>
                }    
            </Box>
            <Box sx={{backgroundColor: "#EBE8F2", borderRadius: 2, mt: 3, p: 2}}>
              <Grid container spacing={1}>
                {chunk(descriptors, splitDataDes).map((data , index) => {
                      let number = index === 0 ? 0: index * splitDataDes 
                      return( 
                        <Grid item xs={6}>
                          <Stack direction="column" spacing={1}>
                            {data.map((d: any) => {
                              number ++
                              return(
                                <Stack direction="row" spacing={1}>
                                  <Typography variant="body1">
                                  {number}
                                  </Typography>
                                  {renderDescription(d)}
                                  <Typography variant="body1" sx={{ fontWeight: 600, color: colors.primary.main}} >
                                    {d.qty} %
                                  </Typography>
                                </Stack>
                              )})}
                            
                            </Stack>
                        </Grid>
                      )})}
              </Grid>
            </Box>
          </Box>
          </Grid>
          <Grid item xs={6}>
          <Typography variant="h6">{t("reportAnalytics.rejectionDetail")}</Typography>
          <Box className={classes.wrapper} minHeight={243}>
            <Box sx={{backgroundColor: "#EBE8F2", borderRadius: 2, mt: 3}}>
            <Grid container sx={{pl: 3}}>
              {Object.keys(dataRejection).map((key, index) => {
                
                return( 
                  <Grid item xs={4}>
                    {renderList(dataRejection, key, index)}
                    
                  </Grid>
                )
              })}
            </Grid>
            </Box>
          </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const renderHeader = () => {
    return(
      <>
      <Stack direction="row">
        <Typography variant="h4" color={colors.text.primary}>
          {t("reportAnalytics.title")} : 
        </Typography>
        <Typography variant="h4" color="primary">
          {title}
        </Typography>
      </Stack>
      <Stack direction="row">
        <Typography variant="h6" color={colors.text.primary}>
          {t("reportAnalytics.button.date")}
        </Typography>
        <Typography variant="h6" color={colors.text.o50}>
          : {dateValue}
        </Typography>
      </Stack>
    </>
    )
  }

  const renderTable = () => {

    return(
      <>
      {isEmpty(thirdPartyShipmentsType) ? renderDailyArrivalCupping(): renderShipment()}
      <Box sx={{ mt: 2, border: 1, borderRadius: 2, borderColor: "#EBE8F2" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableCell key={header.id} align="center" className={classes.tableRightBorderWithBackground}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row, index) => {
              const className = row.getIsExpanded() ? "expanded" : ""

              return (
                <React.Fragment key={row.id}>
                  <TableRow className={className}>
                    {row.getVisibleCells().map(cell => (
                      <TableCell key={cell.id} className={classes.tableRightBorder}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                  {(isPrinting && (index + 1) === 17 ) &&  <div className="html2pdf__page-break" />}
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    </>
    )
  }

  useEffect(() => {
    if (!isPrinting || !printElement.current) return

    if(isEmpty(topFlavorWheel)) {
      (async () => {
        try {
          await printPage(printElement.current,true)
        } finally {
          onExportPdf(false)
  
        }
      })()
      return
    }
    debounce(async () => {
      try {
        await printPage(printElement.current,true)
      } finally {
        onExportPdf(false)

      }
    }, 3000)()
  
  }, [isPrinting])

  useEffect(() => {
    if(isEmpty(topFlavorWheel)) return
    
    flavorWheel(JSON.parse(topFlavorWheel),isEnterprisePlus && base64Logo,"tastify-wheel-1")
   }, [topFlavorWheel])

  return (
  <Box
    ref={printElement}
    data-name={nameFile}
    sx={{ p: 2, mt: 2 }}
  >
    {renderHeader()}
    {isEmpty(topFlavorWheel) ? renderTable(): renderSample()}
   
  </Box>
  )
}
