import {orderBy, pick} from "lodash";
import {snakeCasePayload} from "utils";
import {attributes} from "services/api/attributes/sample-atribute"

export const withSampleViews = (self: any) => ({
  views: {
    get orderedSamples() {
      return orderBy(self.samples, ['orderPosition'], ['asc'])
    },
    get payloadBulkSamples() {
      return self.samples.map(sample => {
        const samplePayload = pick(sample, [...attributes, "id"])
        return snakeCasePayload(samplePayload)
      })
    }
  }
})
