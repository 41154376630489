/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-04-18 00:08:44
 */

import { withReferencePool } from "mst-reference-pool"
import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {orderBy} from "lodash"

import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"

import { SampleModel } from "models/sample"
import { withSampleActions } from "./sample-action"
import {withSampleViews} from "./sample-view";


/**
 * Model description here for TypeScript hints.
 */
export const SampleStoreModel = types
  .model("SampleStore")
  .props({
    samples: types.array(types.safeReference(SampleModel)),
    selectedSample: types.safeReference(SampleModel),
    pool: types.array(SampleModel),
    tmpId: types.maybeNull(types.string)
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withReferencePool(SampleModel))
  .extend(withSampleViews)
  .extend(withSampleActions)


type SampleStoreType = Instance<typeof SampleStoreModel>
export interface SampleStore extends SampleStoreType {}
type SampleStoreSnapshotType = SnapshotOut<typeof SampleStoreModel>
export interface SampleStoreSnapshot extends SampleStoreSnapshotType {}
