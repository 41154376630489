import { lazy } from 'react'
import { RouteProps } from 'react-router-dom'
import { find, concat, some } from 'lodash'

import {
  signIn as signInImage,
  signUp as signUpImage,
  IconHome,
  IconFillHome,
  IconHomeFilled,
  IconCupper,
  IconFillCupper,
  IconCupperFilled,
  IconStartCupping,
  IconFillStartCupping,
  IconStartCuppingFilled,
  IconReviewSamples,
  IconFillReviewSamples,
  IconReviewSamplesFilled,
  IconHelp,
  IconTermConditions,
  IconFillTermConditions,
  IconPrivacyPolicy,
  IconFillPrivacyPolicy,
  IconFeedback,
  IconPlay,
  IconPlayFilled,
  IconHelpFilled,
  IconChartOutline,
  IconShippingFast,
  IconContract,
  IconAnalyticsFill,
  IconAnalyticsFilled
} from 'assets/images'
import { colors } from 'assets'
import SalesContractDetails from 'pages/sales-contract-details/sales-contract-details'

export interface Route extends RouteProps {
  /**
   * Menu name
   */
   name: string
}

const SignIn = lazy(() => import('pages/sign-in'))
const SignUp = lazy(() => import('pages/sign-up'))
const ForgotPassword = lazy(() => import('pages/forgot-password'))
const ResetPassword = lazy(() => import('pages/reset-password'))
const Home = lazy(() => import('pages/home'))
const Cuppers = lazy(() => import('pages/cuppers'))
const CuppingSessions = lazy(() => import('pages/cupping-sessions'))
const CuppingSessionsForm = lazy(() => import('pages/cupping-sessions-form'))
const CuppingSessionsSnapshot = lazy(() => import('pages/cupping-sessions-snapshot'))
const SampleInformation = lazy(() => import('pages/sample-information'))
const MasterSample = lazy(() => import('pages/sample-information/master-sample'))
const MasterSamples = lazy(() => import('pages/samples'))
const Compare = lazy(() => import('pages/compare'))
const Plan = lazy(() => import('pages/plan'))
const License = lazy(() => import('pages/license'))
const CardStripe = lazy(() => import('pages/card-stripe'))
const SampleScore = lazy(() => import('pages/sample-score'))
const CuppingSessionReview = lazy(() => import('pages/cupping-session-review/cupping-session-review'))
const SampleReport = lazy(() => import('pages/sample-report'))
const SampleDetails = lazy(() => import('pages/sample-details'))
const Invitation = lazy(() => import('pages/invitation'))
const Tutorial = lazy(() => import('pages/tutorial'))
const Verification = lazy(() => import('pages/verification'))
const WatchVideo = lazy(() => import('pages/watch-video'))
const SampleReportCalibration = lazy(() => import('pages/sample-report-calibration'))
const SampleReportCollective = lazy(() => import('pages/sample-report-collective'))
const SampleReportIndividual = lazy(() => import('pages/sample-report-individual'))
const DormantAccount = lazy(() => import('pages/dormant-account'))
const Forbidden = lazy(() => import('pages/errors/forbidden'))
const ParentSampleReport = lazy(() => import('pages/parent-sample-report'))
const SampleShipments = lazy(() => import('pages/sample-shipments'))
const SampleShipmentForm = lazy(() => import('pages/sample-shipment-form'))
const SampleShipmentReview = lazy(() => import('pages/sample-shipment-review'))
const SampleShipmentScore = lazy(() => import('pages/sample-shipment-score'))
const SampleOrderForm = lazy(() => import('pages/sample-order-form'))
const ReportsAnalytics = lazy(() => import('pages/reports-analytics'))
const Contracts = lazy(() => import('pages/contracts'))
const SampleOrders = lazy(() => import('pages/sample-orders'))
const GenerateLabel = lazy(() => import('pages/generate-label'))
const GreenGrading = lazy(() => import('pages/green-grading'))
const ContractDetails = lazy(() => import('pages/contract-details/contract-details'));
const NotFound = lazy(() => import('pages/errors/not-found'))
const CombinedReport = lazy(() => import('pages/combined-report'))
const MultipleSamples = lazy(() => import('pages/multiple-samples'))
const SampleInformationMasterSample = lazy(() => import('pages/sample-information/components/master-sample-item/master-sample-item'))
const MasterSampleInformation = lazy(() => import('pages/master-sample-information'))

export const authenticationRoutes: Route[] = [
  {
    path: '/sign-in',
    name: 'signIn',
    element: <SignIn image={signInImage} />
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    element: <ForgotPassword image={signInImage} />
  },
  {
    path: '/reset-password/:token',
    name: 'resetPassword',
    element: <ResetPassword image={signInImage} />
  },
  {
    path: '/sign-up',
    name: 'signUp',
    element: <SignUp image={signUpImage} />
  },
  {
    path: '/sample-report/:id',
    name: 'sampleReport',
    element: <SampleReport />
  },
  {
    path: '/individual-report/:id',
    name: 'individualReport',
    element: <SampleReportIndividual />
  },
  {
    path: '/invitations/:uniqueToken',
    name: 'invitation',
    element: <Invitation />
  },
  {
    path: '/share-samples/:token',
    name: 'shareSamples',
    element: <MasterSamples />
  },
  {
    path: '/sample-score',
    name: 'sampleScore',
    element: <SampleScore />
  },
  {
    path: '/cupping-session-review',
    name: 'sampleReview',
    element: <CuppingSessionReview />
  },
  {
    path: '/verification/:token',
    name: 'verification',
    element: <Verification image={signUpImage} />
  },
  {
    path: '/sample-report-calibration/:id',
    name: 'sampleReportCalibration',
    element: <SampleReportCalibration />
  },
  {
    path: '/sample-report-collective/:id',
    name: 'SampleReportCollective',
    element: <SampleReportCollective />
  },
  {
    path: '/special-event/register',
    name: 'specialEvent',
    element: <SignUp image={signUpImage} />
  },
  {
    path: '/parent-sample-report/:id',
    name: 'parentSampleReport',
    element: <ParentSampleReport />
  }
]

export const dashboardRoutes: Route[] = [
  {
    path: '/',
    name: 'home',
    element: <Home />
  },
  {
    path: '/cuppers',
    name: 'cuppers',
    element: <Cuppers />
  },
  {
    path: '/cupping-sessions',
    name: 'cuppingSessions',
    element: <CuppingSessions />
  },
  {
    path: '/cupping-sessions/:id',
    name: 'formCuppingSessions',
    element: <CuppingSessionsForm />
  },
  {
    path: '/cupping-sessions-snapshot/:id',
    name: 'cuppingSessionsSnapshot',
    element: <CuppingSessionsSnapshot />
  },
  {
    path: '/cupping-sessions/sample-information',
    name: 'sampleInformation',
    element: <SampleInformation />
  },
  {
    path: '/cupping-sessions/sample-information/master-sample-item/:id',
    name: 'sampleInformationMasterSample',
    element: <SampleInformationMasterSample />
  },
  {
    path: '/samples/:id',
    name: 'masterSample',
    element: <MasterSample />
  },
  {
    path: '/samples',
    name: 'reviewSamples',
    element: <MasterSamples />
  },
  {
    path: '/compare',
    name: 'compare',
    element: <Compare />
  },
  {
    path: '/subscription/plan',
    name: 'plan',
    element: <Plan />
  },
  {
    path: '/subscription/license',
    name: 'license',
    element: <License />
  },
  {
    path: '/subscription/card',
    name: 'card',
    element: <CardStripe />
  },
  {
    path: '/sample-score',
    name: 'sampleScore',
    element: <SampleScore />
  },
  {
    path: '/cupping-session-review',
    name: 'sampleReview',
    element: <CuppingSessionReview />
  },
  {
    path: '/sample-report/:id',
    name: 'sampleReport',
    element: <SampleReport />
  },
  {
    path: '/individual-report/:id',
    name: 'individualReport',
    element: <SampleReportIndividual />
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    element: <Tutorial />
  },
  {
    path: '/watch-video',
    name: 'watchVideo',
    element: <WatchVideo />
  },
  {
    path: '/sample-report-calibration/:id',
    name: 'sampleReportCalibration',
    element: <SampleReportCalibration />
  },
  {
    path: '/cupping-sessions/invitation/:id',
    name: 'cuppingSessionsInvitation',
    element: <CuppingSessions />
  },
  {
    path: '/sample-report-collective/:id',
    name: 'SampleReportCollective',
    element: <SampleReportCollective />
  },
  {
    path: '/share-samples/:token',
    name: 'shareSamples',
    element: <MasterSamples />
  },
  {
    path: '/parent-sample-report/:id',
    name: 'parentSampleReport',
    element: <ParentSampleReport />
  },
  {
    path: '/sample-shipments',
    name: 'sampleShipments',
    element: <SampleShipments />
  },
  {
    path: '/sample-shipments/:id',
    name: 'sampleShipmentForm',
    element: <SampleShipmentForm />
  },
  {
    path: '/sample-shipments/:id/item/:itemId/score',
    name: 'shipmentScore',
    element: <SampleShipmentScore />
  },
  {
    path: '/samples/:id/details',
    name: 'sampleDetails',
    element: <SampleDetails />
  },
  {
    path: '/sample-orders',
    name: 'sampleOrders',
    element: <SampleOrders />
  },
  {
    path: '/sample-orders/:id',
    name: 'sampleOrderForm',
    element: <SampleOrderForm />
  },
  {
    path: '/reports-analytics',
    name: 'reportsAnalytics',
    element: <ReportsAnalytics />
  },
  {
    path: '/contracts',
    name: 'contracts',
    element: <Contracts />
  },
  {
    path: '/contracts/:id/details',
    name: 'contractDetails',
    element: <ContractDetails />
  },
  {
    path: '/sales-contracts/:id/details',
    name: 'salesContractDetails',
    element: <SalesContractDetails />
  },
  {
    path: '/green-gradings/:id',
    name: 'greenGrading',
    element: <GreenGrading />
  },
	{
		path: '/multiple-samples',
		name: 'multipleSamples',
		element: <MultipleSamples />
	},
  {
    path: '/master-sample-information',
    name: 'masterSampleInformation',
    element: <MasterSampleInformation />
  }
]

export const publicRoutes: Route[] = [
  {
    path: '/activation-dormant-account/:token',
    name: 'dormantAccount',
    element: <DormantAccount />
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    element: <Forbidden />
  },
  {
    path: '/sample-shipment-review/:id',
    name: 'sampleShipmentReview',
    element: <SampleShipmentReview />
  },
  {
    path: '/sample-shipment-review/:id/score',
    name: 'sampleShipmentScore',
    element: <SampleShipmentScore />
  },
  {
    path: '/sample-transaction-item/:id/generate-label',
    name: 'generateLabel',
    element: <GenerateLabel />
  },
  {
    path: '/shipment-to-be-created',
    name: 'shipmentToBeCreated',
    element: <NotFound />
  },
  {
    path: '/combined-report',
    name: 'combinedReport',
    element: <CombinedReport />
  }
]

export const findRoute = (name: string): string => {
  const route = find(concat(authenticationRoutes, dashboardRoutes, publicRoutes), route => route.name === name)

  return route?.path || '/'
}

export const sidebarMenu = [
  {
    path: findRoute('home'),
    name: 'home',
    icon: <IconHome fill={colors.primary.main} />,
    iconFill: <IconFillHome fill={colors.primary.main} />,
    iconFilled: <IconHomeFilled fill={colors.primary.main} />
  },
  {
    path: findRoute('cuppers'),
    name: 'cuppers',
    icon: <IconCupper fill={colors.primary.main} />,
    iconFill: <IconFillCupper fill={colors.primary.main} />,
    iconFilled: <IconCupperFilled fill={colors.primary.main} />
  },
  {
    path: findRoute('cuppingSessions'),
    name: 'cuppingSessions',
    icon: <IconStartCupping fill={colors.primary.main} />,
    iconFill: <IconFillStartCupping fill={colors.primary.main} />,
    iconFilled: <IconStartCuppingFilled fill={colors.primary.main} />
  },
  {
    path: findRoute('reviewSamples'),
    name: 'reviewSamples',
    icon: <IconReviewSamples fill={colors.primary.main} />,
    iconFill: <IconFillReviewSamples fill={colors.primary.main} />,
    iconFilled: <IconReviewSamplesFilled fill={colors.primary.main} />
  },
  {
    path: findRoute('sampleOrders'),
    name: 'sampleOrders',
    icon: <IconChartOutline fill={colors.primary.main} />,
    iconFill: <IconChartOutline fill={colors.primary.main} />,
    iconFilled: <IconChartOutline fill="white" />
  },
  {
    path: findRoute('sampleShipments'),
    name: 'sampleShipments',
    icon: <IconShippingFast fill={colors.primary.main} />,
    iconFill: <IconShippingFast fill={colors.primary.main} />,
    iconFilled: <IconShippingFast fill="white" />
  },
  {
    path: findRoute('reportsAnalytics'),
    name: 'reportsAnalytics',
    icon: <IconAnalyticsFill fill={colors.primary.main} />,
    iconFill: <IconAnalyticsFill fill={colors.primary.main} />,
    iconFilled: <IconAnalyticsFilled fill="white" />
  },
  {
    path: findRoute('contracts'),
    name: 'contracts',
    icon: <IconContract fill={colors.primary.main} />,
    iconFill: <IconContract fill={colors.primary.main} />,
    iconFilled: <IconContract fill="white" />
  }
]

export const sucafinaMenu = ['sampleOrders', 'sampleOrderForm', 'reportsAnalytics', 'contracts']

const pathWithParams = [
  'reset-password',
  'sample-report',
  'invitations',
  'share-samples',
  'verification',
  'sample-report-calibration',
  'sample-report-collective',
  'parent-sample-report',
  'individual-report',
  'master-sample',
  'sample-orders',
  'sample-shipments',
]

const invitationRoute = 'invitations'

export const isAuthenticateRoutes = (path: string): boolean => {
  if (pathWithParams.some(v => path.includes(v))) return true

  return some(authenticationRoutes, ['path', path])
}

export const isInvitationRoutes = (path: string): boolean => {
  return path.includes(invitationRoute)
}

export const isPublicRoutes = (path: string): boolean => {
  if (path === '/') return false

  return some(publicRoutes, route => {
    const splitPath = route.path!.split('/')
    return path.includes(splitPath[1])
  })
}

export const isResetPassword = (path: string): boolean => {
  return path.includes('reset-password');
}

export const sideMenuIcon = (condition: boolean, path: string = '', miniDrawer = false) => {
  switch (path) {
    case 'tutorial':
      return condition ?
          <IconHelpFilled fill={colors.primary.main} /> :
          <IconHelp fill={colors.primary.main} />
    case 'feedback':
      return <IconFeedback fill={colors.primary.main} />
    case 'termCondition':
      return condition ?
        <IconFillTermConditions fill={colors.primary.main} /> :
        <IconTermConditions fill={colors.primary.main} />
    case 'privacy':
      return condition ?
        <IconFillPrivacyPolicy fill={colors.primary.main} /> :
        <IconPrivacyPolicy fill={colors.primary.main} />
    case 'play':
      return condition ?
          <IconPlayFilled fill={colors.primary.main} /> :
          <IconPlay fill={colors.primary.main} />
    default:
      break
  }
}
