import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import moment from "moment";
import {
  createColumnHelper,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from "@tanstack/react-table";
import {Box, Button, Grid, IconButton, Stack, Typography} from "@mui/material";
import {
  AccessTime,
  DataUsage,
  EventAvailable,
  ExpandLess,
  ExpandMore,
  HowToReg,
  LocalShippingOutlined,
  LocationOnOutlined
} from "@mui/icons-material";

import {BasicTable} from "components";
import {optionsTranslation} from "utils";
import {SampleShipmentApproval} from "constants/constant";
import {IconStartCupping} from "assets/images";
import {ApproveRejectButton} from "modules";

import {useStyles} from "./shipment-table.styles";

const columnHelper = createColumnHelper<any>()

export const ShipmentTable = (props: any) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { id, groupSample, transactionId, onApprove, onReject } = props
  const token = searchParams.get('token')

  const [expanded, setExpanded] = useState<ExpandedState>({})

  const data = groupSample.sampleTransactionItems
  const { fulfillmentStatus, fulfilledOn, fulfilledBy, courierName, trackingNumber, estimatedTimeArrival } = groupSample

  const infoItems = [
    {
      icon: <DataUsage />,
      label: t('shipment.table.status'),
      value: optionsTranslation('fulfillmentStatus', fulfillmentStatus, true)
    },
    {
      icon: <EventAvailable />,
      label: t('shipment.table.fulfilledOn'),
      value: fulfilledOn ? moment(fulfilledOn).format('L') : '-'
    },
    {
      icon: <HowToReg />,
      label: t('shipment.table.fulfilledBy'),
      value: fulfilledBy ?? '-'
    },
    {
      icon: <LocalShippingOutlined />,
      label: t('shipment.table.courier'),
      value: courierName ?? '-'
    },
    {
      icon: <LocationOnOutlined />,
      label: t('shipment.table.trackingNumber'),
      value: trackingNumber ?? '-'
    },
    {
      icon: <AccessTime />,
      label: t('shipment.table.eta'),
      value: estimatedTimeArrival ? moment(estimatedTimeArrival).format('L') : '-'
    },
  ]

  const renderInfo = (info) => {
    return (
      <Box className={classes.infoWrapper}>
        {info.icon}
        <Box>
          <Typography variant="subtitle1">{info.label}</Typography>
          <Typography variant="body2">{info.value}</Typography>
        </Box>
      </Box>
    )
  }

  const renderSubComponent = ({ row }) => {
    const { approvalStatus, reason, salesContractReference, purchaseContractReference } = row.original
    const showReason = approvalStatus === SampleShipmentApproval.REJECTED || approvalStatus === SampleShipmentApproval.DECLINED
    const reasonLabel = approvalStatus === SampleShipmentApproval.REJECTED ? t('shipment.reasonWasRejected') : t('shipment.reasonWasDeclined')

    const items = [
      { label: t('shipment.table.salesContract'), value: salesContractReference },
      { label: t('shipment.table.purchaseContract'), value: purchaseContractReference },
    ]

    return (
      <Box className={classes.collapseWrapper}>
        <Stack direction="row" spacing={2}>
          {items.map(item => {
            const val = item.value ? item.value : '-'
            return (
              <Box width="50%">
                <Typography variant="subtitle1">{item.label}</Typography>
                <Typography variant="body2">{val}</Typography>
              </Box>
            )
          })}
        </Stack>

        {showReason && (
          <Box mt={2}>
            <Typography variant="subtitle1">{reasonLabel}</Typography>
            <Typography variant="body2">{reason ?? '-'}</Typography>
          </Box>
        )}
      </Box>
    )
  }

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <Box textAlign="left" fontWeight={600}>{t('shipment.table.id')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('purchaseGrade', {
      id: 'purchaseGrade',
      header: () => <Box textAlign="left" fontWeight={600}>{t('shipment.table.purchaseGrade')}</Box>,
      cell: info => info.renderValue() ?? '-',
    }),
    columnHelper.accessor('country', {
      id: 'country',
      header: () => <Box textAlign="left" fontWeight={600}>{t('shipment.table.country')}</Box>,
      cell: info =>  info.renderValue() ?? '-',
    }),
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left" fontWeight={600}>{t('shipment.table.sampleType')}</Box>,
      cell: info => optionsTranslation('sampleType', info.renderValue(), true),
    }),
    columnHelper.accessor('isRequestedNewSample', {
      id: 'isRequestedNewSample',
      header: () => <Box textAlign="center" fontWeight={600}>{t('shipment.table.requestNewSample')}</Box>,
      cell: info => <Box textAlign="center">{optionsTranslation('boolean', info.renderValue(), true)}</Box>
    }),
    columnHelper.accessor('processName', {
      id: 'processName',
      header: () => <Box textAlign="left" fontWeight={600}>{t('sample.samples.process')}</Box>,
      cell: info => info.renderValue() ?? '-',
    }),
    columnHelper.accessor('expand', {
      id: 'expand',
      header: () => <Box fontWeight={600}>{t('shipment.table.action')}</Box>,
      cell: info => {
        const { uniqueToken } = info.row.original
        return (
          <Stack direction="row" spacing={1} justifyContent="center">
            <Button
              size="small"
              variant="contained"
              sx={{minWidth: '1px'}}
              onClick={() => navigate(`/sample-shipment-review/${id}/score?transaction_id=${transactionId}&item_id=${uniqueToken}&token=${token}`)}
            >
              <IconStartCupping fill="white" width={24} height={24} />
            </Button>
            <ApproveRejectButton
              row={info.row.original}
              onApprove={(hasApproval) => onApprove({ id: groupSample.uniqueToken, uniqueToken: info.row.original.uniqueToken }, hasApproval)}
              onReject={(hasApproval) => onReject({ id: groupSample.uniqueToken, uniqueToken: info.row.original.uniqueToken }, hasApproval)}
            />
            <IconButton
              size="small"
              color="primary"
              onClick={() => info.row.toggleExpanded()}
            >
              {info.row.getIsExpanded() ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Stack>
        )
      }
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    state: { expanded },
    manualExpanding: true,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: row => row.id,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Box className={classes.wrapper}>
      <Typography variant="h5">{`${t('shipment.shipment')} #${groupSample.shipmentNumber ?? ''}`}</Typography>
      <Typography variant="body2" mb={2} color="primary">
        {t('shipment.table.fulfilledBy')} {groupSample.sampleLocation}
      </Typography>

      <Grid container spacing={2}>
        {infoItems.map(info => (
          <Grid item key={info.label} xs={12} md>
            {renderInfo(info)}
          </Grid>
        ))}
      </Grid>

      <BasicTable table={table} renderSubComponent={renderSubComponent} />
    </Box>
  )
}
