import React from 'react'
import {upperFirst} from "lodash";
import {useGridApiContext} from "@mui/x-data-grid";
import {Autocomplete, TextField} from "@mui/material";

import {flatListDescriptors} from "utils";

export const DataGridDescriptors = (props) => {
	const { id, value, field, hasFocus, colDef } = props;
	const apiRef = useGridApiContext();

	const valueOptions = (flatListDescriptors(colDef.valueOptions) || []).map((item) => ({
		id: item.id,
		value: item.label,
		label: upperFirst(item.label),
	}));

	const handleValueChange = (event, newValue) => {

		// Map selected options and free solo text entries
		const selectedValues = newValue.map((item) =>
			typeof item === 'string' ? item : item.value
		);

		// Update the grid cell's value
		apiRef.current.setEditCellValue({ id, field, value: selectedValues }, event);
	};

	return (
		<Autocomplete
			multiple
			fullWidth
			freeSolo // Enable free solo to allow custom input
			onChange={handleValueChange}
			options={valueOptions}
			value={value ? value.map((val) => valueOptions.find((opt) => opt.value === val) || val) : []}
			getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
			isOptionEqualToValue={(o, v) => o.value == v.value}
			renderInput={(params) => (
				<TextField {...params} autoFocus={hasFocus} />
			)}
		/>
	)
}
