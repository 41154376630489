import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Checkbox, ListItemText, MenuItem, Select, Stack, Typography} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";

import {useStores} from "models";
import {FormInput, ModalWrapper} from "components";
import {LoadingButton} from "@mui/lab";
import {isEmpty} from "lodash";

export const SendEmailModal = (props) => {
  const { t } = useTranslation()
  const { id, open, onClose, onSubmit } = props
  const {
    sampleShipmentStore: { sampleShipmentEmails, sendEmail },
  } = useStores()

  const [title, setTitle] = useState('')
  const [emails, setEmails] = useState<string[]>([])
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const keys = ['recipient', 'trader', 'qc', 'approval']

  const handleChangeEmail = (event: SelectChangeEvent<typeof emails>) => {
    const {
      target: { value },
    } = event
    setEmails(
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  const renderParentMenuItem = (key: string) => {
    return (
      <MenuItem key={key} disabled value="">
        <Typography variant="h6">{t(`shipmentOrderForm.${key}`)}</Typography>
      </MenuItem>
    )
  }

  const renderChildMenuItem = (key: string) => {
    return sampleShipmentEmails[key].map((email) => (
      <MenuItem key={key+email} value={email}>
        <Checkbox checked={emails.includes(email)} />
        <ListItemText primary={email} />
      </MenuItem>
    ))
  }

  const onSendEmail = async () => {
    try {
      setLoading(true)
      await sendEmail(id, {
        emailTitle: title,
        emailRecipients: emails.join(','),
        emailMessage: message,
      })
      onClose()
      onSubmit()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open) {
      setTitle('')
      setEmails([])
      setMessage('')
    }
  }, [open])

  return (
    <ModalWrapper
      open={open}
      maxWidth="sm"
      containerProps={{ sx: { p: '32px !important' } }}
    >
      <Box>
        <FormInput
          label={t('shipment.emailModal.subject')}
          textFieldProps={{
            placeholder: t('shipmentOrderForm.writeHere'),
            value: title,
            onChange: (e) => setTitle(e.target.value)
          }}
        />
        <FormInput label={t('shipmentOrderForm.to')}>
          <Select
            multiple
            size='small'
            value={emails}
            renderValue={(selected) => selected.join(", ")}
            onChange={handleChangeEmail}
          >
            {renderParentMenuItem(keys[0])}
            {renderChildMenuItem(keys[0])}
            {renderParentMenuItem(keys[1])}
            {renderChildMenuItem(keys[1])}
            {renderParentMenuItem(keys[2])}
            {renderChildMenuItem(keys[2])}
            {renderParentMenuItem(keys[3])}
            {renderChildMenuItem(keys[3])}
          </Select>
        </FormInput>
        <FormInput
          label={t('shipment.emailModal.emailMessage')}
          textFieldProps={{
            multiline: true,
            rows: 5,
            placeholder: t('shipmentOrderForm.writeHere'),
            value: message,
            onChange: (e) => setMessage(e.target.value)
          }}
        />

        <Stack direction="row" justifyContent="center" spacing={1} mt={2}>
          <Button variant="outlined" onClick={onClose} sx={{ px: 3}}>
            {t('common.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ px: 3}}
            loading={loading}
            onClick={onSendEmail}
            disabled={!title || isEmpty(emails) || !message}
          >
            {t('shipment.button.send')}
          </LoadingButton>
        </Stack>
      </Box>
    </ModalWrapper>
  )
}
