import { types } from "mobx-state-tree"

export const defaultSampleProps = {
  name: types.string,
  sampleType: types.string,
  species: types.string,
  receivedOn: types.maybeNull(types.string),
  cropYear: types.string,
  countryCode: types.string,
  country: types.maybeNull(types.string),
  producerName: types.string,
  bagWeight: types.string,
  varietalsTags: types.string,
  numberOfBag: types.string,
  referenceNumber: types.string,
  grade: types.string,
  processName: types.string,
  supplierName: types.string,
  shippingInfo: types.string,
  notesAndRemarks: types.string,
  waterActivity: types.string,
  moisture: types.string,
  mass: types.string,
  volume: types.string,
  customer: types.string,
  customerCode: types.string,
  description: types.string,
  receivedWeight: types.string,
  coffeeType: types.maybeNull(types.string),
  purchaseContractReference: types.maybeNull(types.string),
  salesContractReference: types.maybeNull(types.string),
  originGrade: types.maybeNull(types.string),
  warehouseReference: types.maybeNull(types.string),
  sampleReceptionDate: types.maybeNull(types.string),
  temperature: types.maybeNull(types.string),
  protocolForm: types.maybeNull(types.string),
  counterpartyType: types.maybeNull(types.string),
  counterpartyReferences: types.maybeNull(types.string),
  overallCuppingResult: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  density: types.maybeNull(types.string),

  uniqueToken: types.maybeNull(types.string),
  strategy: types.maybeNull(types.string),
  sampleLocation: types.maybeNull(types.string),
  fulfillmentType: types.maybeNull(types.string),
  cargoNumber: types.maybeNull(types.string),
  containerNumber: types.maybeNull(types.string),
  lotNumberOrIcoMarks: types.maybeNull(types.string),
  cuppingProtocol: types.maybeNull(types.string),

  courierName: types.maybeNull(types.string),
  trackingNumber: types.maybeNull(types.string),
  shipmentDate: types.maybeNull(types.string),
  sampleUniqueNumber: types.maybeNull(types.string),
  harvest: types.maybeNull(types.string),
  externalIdentification: types.maybeNull(types.string),
  sampleReference: types.maybeNull(types.string),

  approvalStatus: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),

  //  For Bayers
  dateDispatch: types.maybeNull(types.string),
  dateArrival: types.maybeNull(types.string),
  dateResults: types.maybeNull(types.string),

  isDecaf: types.maybeNull(types.boolean),
  otaTestValue: types.maybeNull(types.number),
  basketCode: types.maybeNull(types.string),
  contractWeight: types.maybeNull(types.string),
  certification: types.maybeNull(types.string),

  //  Flags
  canEditPurchaseContractReference: types.maybeNull(types.boolean),

  purchaseGrade: types.maybeNull(types.string),
}
