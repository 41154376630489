import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Stack, Typography} from "@mui/material";

import {useStores} from "models";
import {FormInput, ModalWrapper} from "components";
import {LoadingButton} from "@mui/lab";
import {isEmpty} from "lodash";

export const SendEmailModal = (props) => {
  const { t } = useTranslation()
  const { title, open, onClose, onSubmit } = props
  const {
    reportsAnalyticsStore: { sendEmail },
  } = useStores()

  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [ccEmail, setCcEmail] = useState('')
  const [bccEmail, setBccEmail] = useState('')


  const onSendEmail = async () => {
    try {
      setLoading(true)
      await sendEmail({
        emailTitle: title,
        emailMessage: message,
        emailCc: ccEmail,
        emailBcc: bccEmail,
        emailTo: ccEmail
      })
      onClose()
      onSubmit()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open) {
      setMessage('')
    }
  }, [open])

  return (
    <ModalWrapper
      open={open}
      maxWidth="sm"
      containerProps={{ sx: { p: '32px !important' } }}
    >
      <Box>
        <Typography sx={{ fontWeight: 600 }}>{t('reportAnalytics.emailModal.to')}</Typography>
        <FormInput
          label={t('reportAnalytics.emailModal.cc')}
          textFieldProps={{
            placeholder: t('reportAnalytics.emailModal.helperEmailText'),
            value: ccEmail,
            onChange: (e) => setCcEmail(e.target.value)
          }}
        />
        <FormInput
          label={t('reportAnalytics.emailModal.bcc')}
          textFieldProps={{
            placeholder: t('reportAnalytics.emailModal.helperEmailText'),
            value: bccEmail,
            onChange: (e) => setBccEmail(e.target.value)
          }}
        />
     
        <FormInput
          label={t('reportAnalytics.emailModal.emailMessage')}
          textFieldProps={{
            multiline: true,
            rows: 5,
            placeholder: t('shipmentOrderForm.writeHere'),
            value: message,
            onChange: (e) => setMessage(e.target.value)
          }}
        />

        <Stack direction="row" justifyContent="center" spacing={1} mt={2}>
          <Button variant="outlined" onClick={onClose} sx={{ px: 3}}>
            {t('common.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ px: 3}}
            loading={loading}
            onClick={onSendEmail}
            disabled={isEmpty(ccEmail) || isEmpty(bccEmail) || !message}
          >
            {t('reportAnalytics.button.send')}
          </LoadingButton>
        </Stack>
      </Box>
    </ModalWrapper>
  )
}
