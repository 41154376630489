import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {colors} from "assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 3,
      marginBottom: 5,
      marginTop: 24,
      padding: 10
    },
    dateRange:{
      height: 43, 
      minWidth: 250, 
      backgroundColor: 
      colors.primary.main, 
      borderRadius: 5, 
      padding: 4
    },
    cardSummary: {
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 3,
      marginBottom: 5,
      marginTop: 24,
      padding: 10,
      height: 110
    },
    wheelWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    date: {
      '& svg': {
        fill: colors.body.primary
      },
      '& .MuiOutlinedInput-root': {
        color: 'white',
        '& fieldset': {
          borderColor: colors.primary.main,
        },
        '&:hover fieldset': {
          borderColor: colors.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.primary.main,
        }
      }
    },
    tabs: {
        padding: '8px 16px',
        height: '90px',
        borderRadius: 4,
        background: colors.primary.o25,
  
        '& button': {
          background: 'transparent',
          color: colors.primary.main,
          borderRadius: 4,
          border: 'none',
          fontWeight: 600,
          width: '24%',
          height: '70px',
          minHeight: 'unset',
  
          '&.Mui-selected': {
            background: colors.primary.main,
            color: 'white'
          }
        },
  
        '& svg': {
          width: 20,
          height: 20,
          fill: colors.primary.main,
        },
  
        '& .MuiTabs-indicator': {
          display: 'none'
        }
      }
  })
)
