import * as html2pdf from 'html2pdf.js/dist/html2pdf.min.js';
import { v4 as uuidv4 } from 'uuid'

export const printPage = async (element, fixedHeight = false) => {
  const margin = 25
  const x = 3
  const mobile = element.clientWidth < 900
  const filename = `${element.getAttribute('data-name')}-${uuidv4()}.pdf`
  const height = fixedHeight ? 1754 : element.scrollHeight + (x * margin)
  const width = mobile ? element.clientWidth : 1200
  const scale = fixedHeight ? 3 : 7

  const opt = {
    margin: [margin, 0],
    filename: filename,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale, windowWidth: width },
    jsPDF: { unit: 'px', format: [height, width], orientation: 'portrait' }
  }

  if (mobile) {
    opt.html2canvas.scale = 1.5
  }

  await html2pdf(element, opt)
}

export const printPageWithSize = async (element, fixedHeight = false, width, height) => {
  const margin = 1
  const x = 3
  const mobile = element.clientWidth < 900
  const filename = `${element.getAttribute('data-name')}-${uuidv4()}.pdf`
  const scale = fixedHeight ? 3 : 7

  const opt = {
    margin: [margin, 0],
    filename: filename,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale, windowWidth: width, windowHeight: height },
    jsPDF: { unit: 'px', format: [height, width], orientation: 'portrait' }
  }

  if (mobile) {
    opt.html2canvas.scale = 1.5
  }

  await html2pdf(element, opt)
}
