import React from 'react'
import {isObject} from "lodash";
import {useGridApiContext} from "@mui/x-data-grid";
import {Autocomplete, TextField} from "@mui/material";

export const DataGridSelect = (props) => {
	const { id, value, field, hasFocus, colDef } = props;
	const apiRef = useGridApiContext();

	const valueOptions = colDef.valueOptions.map(option => ({
		value: isObject(option) ? colDef.getOptionValue(option) : option,
		label: colDef.getOptionLabel(option)
	}))
	valueOptions.unshift({ value: '', label: '' })

	const handleValueChange = (event, item) => {
		const newValue = item?.value || ''; // The new value entered by the user
		apiRef.current.setEditCellValue({ id, field, value: newValue });
	};

	return (
		<Autocomplete
			fullWidth
			value={valueOptions.find(option => option.value === value) || valueOptions[0]}
			onChange={handleValueChange}
			options={valueOptions}
			getOptionLabel={(option) => option.label}
			isOptionEqualToValue={(o, v) => o.value == v.value}
			renderInput={(params) => (
				<TextField {...params} autoFocus={hasFocus} />
			)}
		/>
	)
}
