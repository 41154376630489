import {ceil, chain, isEmpty, isNil, omitBy, pick, snakeCase, some} from "lodash";
import {applySnapshot, flow, getSnapshot} from "mobx-state-tree";
import {ApiResult} from "services/api";
import {shipmentOrderFormPreProcessor} from "models/shipment-order-form";
import {
  sampleShipmentPreProcessor,
  sampleShipmentListPreProcessor,
} from "./sample-shipment-preprocessor";
import {DATA_PER_PAGE} from "config/env";
import {fulfillmentTypes} from "constants/form";
import {generateLabel, snakeCasePayload} from "utils";

export const withSampleShipmentActions = (self: any) => ({
  actions: {
    setSamplesToBeShipment(samples) {
      const samplesToBeShipment = samples.map(sample => sampleShipmentPreProcessor(sample))

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    getNewSamples(samples) {
      const { name: companyName } = self.rootStore.companyStore

      return samples.map(sample => {
        const newSample = sampleShipmentPreProcessor(sample)

	      let sampleLocation = newSample.sampleLocation || ''
	      if (!newSample.sampleFulfillmentType || newSample.sampleFulfillmentType === fulfillmentTypes[0]) {
		      sampleLocation = companyName || ''
	      }

        return {
          ...newSample,
          sampleId: sample.id,
          sampleLocation,
          sample: {
            ...newSample.sample,
            cargoNumber: '',
            customer: '',
            salesContractReference: '',
          }
        }
      })
    },
    getSamplesToBeShipment(samples){
      return samples.map(sample => sampleShipmentPreProcessor(sample))
    },
    setThirdPartyEmails(emails, fulfillmentType, sampleLocation){
      const samplesToBeShipment = self.samplesToBeShipment.map(sample => {
        if (sample.sampleFulfillmentType === fulfillmentType && sample.sampleLocation === sampleLocation) {
          return {
            ...sample,
            thirdPartyEmail: emails,
            sample: {
              ...sample.sample
            }
          }
        }

        return {
          ...sample,
          sample: {
            ...sample.sample
          }
        }
      })

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    setSamplesToBeShipmentMultiGenerateLabel(data) {
      const samplesToBeShipment = self.samplesToBeShipment.map((sample , i) => {

        if(sample.sample.sampleUniqueNumber === data[1].value) {
          return {
            ...sample,
            sample: {
              ...sample.sample,
            },
            label: JSON.stringify(data)
          }
        }

        return {
          ...sample,
          sample: {
            ...sample.sample
          }
        }
      })

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    setSamplesToBeShipmentGenerateLabel(index, data) {
      const samplesToBeShipment = self.samplesToBeShipment.map((sample , i) => {
        if(i === index) {
          return {
            ...sample,
            sample: {
              ...sample.sample,
            },
            label: JSON.stringify(data)
          }
        }

        return {
          ...sample,
          sample: {
            ...sample.sample
          }
        }
      })

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    generateUncompletedLabels(){
      const samplesToBeShipment = self.samplesToBeShipment.map((sample) => {
        if (some(self.labelIncomplete, ['uniqueToken', sample.uniqueToken])) {
          return {
            ...sample,
            sample: {
              ...sample.sample,
            },
            label: JSON.stringify(generateLabel(sample))
          }
        }

        return {
          ...sample,
          sample: {
            ...sample.sample
          }
        }
      })

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    setSamplesToBeShipmentSample(index, data) {
      const samplesToBeShipment = self.samplesToBeShipment.map((sample, i) => {
        if(i === index) {
          return {
            ...sample,
            sample: {
              ...sample.sample,
              ...data
            },
          }
        }

        return {
          ...sample,
          sample: {
            ...sample.sample
          }
        }
      })

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    saveSampleShipment: flow(function * () {
      try {
        const payload: any = {
          ...self.shipmentForm,
          streetAddress_2: self.shipmentForm.streetAddress2,
          type: "SampleShipment",
          sampleTransactionItemsAttributes: self.samplesToBeShipment.map(sample => {
            return {
              "sample_id": sample.sampleId,
              "sample_type": sample.sampleType,
              "sample_name": sample.sampleName,
              "sample_grade": sample.sampleGrade,
              "sample_location": sample.sampleLocation,
              "sample_fulfillment_type": sample.sampleFulfillmentType,
              "is_approval_needed": sample.isApprovalNeeded,
              "sample_weight": sample.sampleWeight,
              "is_roasted": sample.isRoasted,
              "third_party_email": sample.thirdPartyEmail,
              "sample_warehouse_reference": sample.sampleWarehouseReference,
              "label": sample.label,
	            "delivery_information": sample.deliveryInformation,
              "sample_attributes": {
                ...snakeCasePayload(sample.sample, ['id', 'country']),
                "master_id": sample.sample.id,
              }
            }
          })
        }

        yield self.environment.sampleTransactionApi.save(payload)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw Error()
      }
    }),
    updateSampleShipment: flow(function * (id, payload?) {
      try {
        if (!payload) {
          payload = {
            ...self.shipmentInformation,
            id,
            streetAddress_2: self.shipmentInformation.streetAddress2,
            type: "SampleShipment",
            sampleTransactionItemsAttributes: self.samplesToBeShipment.map(sample => {
              return {
                ...snakeCasePayload(sample, ['sample']),
                "sample_attributes": snakeCasePayload(sample.sample, ['country'])
              }
            })
          }
        }

        const res: ApiResult = yield self.environment.sampleTransactionApi.save(payload)

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            shipmentInformation: sampleShipmentPreProcessor(res.data),
            samplesToBeShipment: self.getSamplesToBeShipment(res.data.sampleTransactionItems)
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw error()
      }
    }),
    addSampleShipments(index){
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment: [self.samplesToBeShipment[index]]
      })
    },
    removeSampleShipments(excludedIndexes){
      const samplesToBeShipment = self.samplesToBeShipment.filter((_, index) => !excludedIndexes.includes(index))

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })

      return samplesToBeShipment
    },
    resetShipmentForm(){
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        shipmentForm: shipmentOrderFormPreProcessor({})
      })
    },
    setShipmentForm(){
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        shipmentForm: shipmentOrderFormPreProcessor(self.shipmentInformation)
      })
    },
    getSampleShipments: flow(function * () {
      try {
        let filter = {}

        chain(self.filter)
          .omitBy(isEmpty)
          .forEach((value, key) => { filter[`q[${snakeCase(key)}]`] = value})
          .value()

        const params = {
          ...filter,
          "size": DATA_PER_PAGE,
          "page[number]": self.page,
          "sort": self.sort
        }
        const additionalPath = 'sample_shipment_lists'
        const res: ApiResult = yield self.environment.sampleTransactionApi.all(params, additionalPath)

        if (res.kind === "ok") {
          const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            sampleShipments: res.data.map(sampleShipment => sampleShipmentListPreProcessor(sampleShipment)),
            totalPage
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getSampleShipment: flow(function * (id) {
      try {
        const res: ApiResult = yield self.environment.sampleTransactionApi.find(id)

        if (res.kind === "ok") {
          const samplesToBeShipment = res.data.sampleTransactionItems.map(sample => {
            return {
              ...sampleShipmentPreProcessor(sample),
              sampleName: sample.sampleName ?? '',
            }
          })

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            shipmentInformation: sampleShipmentPreProcessor(res.data),
            samplesToBeShipment
          })

          return samplesToBeShipment
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    updateApprovalStatus: flow(function * (id, payload, token) {
      try {
        let additionalPath = `${id}/approve_transaction_item`

        let res: ApiResult;
        if (token) {
          additionalPath += `?token=${token}`
          res = yield self.environment.sampleTransactionGuestApi.save(payload, {}, additionalPath)
        } else {
          res = yield self.environment.sampleTransactionApi.save(payload, {}, additionalPath)
        }

        if (res.kind === "ok") {
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    updateBulkApprovalStatus: flow(function * (data, token) {
      try {
        let additionalPath = `bulk_approve_items`

        const payload: any = {
          itemIds: data
        }

        let res: ApiResult;
        if (token) {
          additionalPath += `?token=${token}`
          res = yield self.environment.sampleTransactionGuestApi.save(payload, {}, additionalPath)
        } else {
          res = yield self.environment.sampleTransactionApi.save(payload, {}, additionalPath)
        }

        if (res.kind === "ok") {
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    requestNewSample: flow(function * (id, payload, token) {
      try {
        let additionalPath = `${id}/request_new_sample`

        let res: ApiResult;
        if (token) {
          additionalPath += `?token=${token}`
          res = yield self.environment.sampleTransactionGuestApi.save(payload, {}, additionalPath)
        } else {
          res = yield self.environment.sampleTransactionApi.save(payload, {}, additionalPath)
        }

        if (res.kind === "ok") {
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    sendEmail: flow(function * (id, payload) {
      try {
        const additionalPath = `${id}/send_email_to`
        const res: ApiResult = yield self.environment.sampleTransactionApi.save(payload, {}, additionalPath)

        if (res.kind === "ok") {
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    sendEmailSubmitTheOrder: flow(function * (token) {
      try {
        const payload: any = {
          id: token,
          thirdPartyName: self.thirdPartyName,
          thirdPartyEmail: self.thirdPartyEmail,
          isSubmitted: true
        }
        const res: ApiResult = yield self.environment.sampleTransactionApi.save(payload)

        if (res.kind === "ok") {
          self.thirdPartyName = ''
          self.thirdPartyEmail =''

          return res.data

        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    archivedSampleShipment: flow(function * (token) {
      try {
        const payload: any = {id: token, archivedAt: self.archivedAt}
        const res: ApiResult = yield self.environment.sampleTransactionApi.save(payload)

        if (res.kind === "ok") {
          self.archivedAt = ''

          return res.data

        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    cencelSampleShipment: flow(function * (token) {
      try {
        const payload: any = {id: token, fulfillmentStatus: self.fulfillmentStatus}
        const res: ApiResult = yield self.environment.sampleTransactionApi.save(payload)

        if (res.kind === "ok") {
          self.fulfillmentStatus = ''

          return res.data

        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getGroupSampleShipment: flow(function * (group, token) {
      try {
        const additionalPath = 'group_order'
        const payload = {'q': group, 'token': token}
        const res: ApiResult = yield self.environment.sampleTransactionGuestApi.all(payload, additionalPath)

        if (res.kind === "ok") {
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw error
      }
    }),
    setScore(data) {
      const score = omitBy(data, isNil)

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        score: {
          ...score,
          isSubmitted: !!score.totalScore,
          sampleId: data.sampleId.toString(),
        }
      })
    },
    getShipmentScore: flow(function * (transactionId, transactionItemId, token) {
      try {
        let additionalPath = `sample_transaction_items/${transactionItemId}/score`
        let res: ApiResult;
        if (token) {
          additionalPath += `?token=${token}`
          res = yield self.environment.sampleTransactionGuestApi.find(transactionId, additionalPath)
        } else {
          res = yield self.environment.sampleTransactionApi.find(transactionId, additionalPath)
        }

        if (res.kind === "ok") {
          self.setScore(res.data)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    sendShipmentScore: flow(function * (transactionId, transactionItemId, token) {
      try {
        const payload: any = pick(self.score, ['flavorDescriptors', 'defectsDescriptors', 'defects', 'totalScore', 'notes'])
        let additionalPath = `${transactionId}/sample_transaction_items/${transactionItemId}/score`
        let res: ApiResult;
        if (token) {
          additionalPath += `?token=${token}`
          res = yield self.environment.sampleTransactionGuestApi.save(payload, {}, additionalPath)
        } else {
          res = yield self.environment.sampleTransactionApi.save(payload, {}, additionalPath)
        }

        if (res.kind === "ok") {
          self.setScore(res.data)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw error
      }
    }),
    getSampleLabel: flow(function * (id, token) {
      try {
        const additionalPath = `generate_label?token=${token}`
        const res: ApiResult = yield self.environment.sampleTransactionItemGuestApi.find(id, additionalPath)

        if (res.kind === "ok") {
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw error
      }
    }),
  }
})
