export const snakeCasePayload = (payload: any, except?: string[]) => {
  const newPayload: any = {}
  for (const key in payload) {
    if (except && except.includes(key)) continue

    newPayload[key.replace(/([A-Z])/g, "_$1").toLowerCase()] = payload[key]
  }
  return newPayload
}

export const removeEmptyValuePayload = (obj: any) => {
  const newObj: any = {}
  for (const key in obj) {
    if (obj[key]) newObj[key] = obj[key]
  }
  return newObj
}
