export const menuListSoluble = [
    {
      value: 1,
      label: 'descriptor.veryLow',
      color: "#BF3027"
    },
    {
      value: 2,
      label: 'descriptor.low',
      color: "#DC4F22"
    },
    {
      value: 3,
      label: 'descriptor.medium',
      color: "#FC6C17"
    },
    {
      value: 4,
      label: 'descriptor.high',
      color: "#A4B040"
    },
    {
      value: 5,
      label: 'descriptor.veryHigh',
      color: "#7CD55A"
    },
  ]
  
  export const menuListSolubleBody = [
    {
      value: 1,
      label: 'descriptor.veryThin',
      color: "#BF3027"
    },
    {
      value: 2,
      label: 'descriptor.thin',
      color: "#DC4F22"
    },
    {
      value: 3,
      label: 'descriptor.medium',
      color: "#FC6C17"
    },
    {
      value: 4,
      label: 'descriptor.thick',
      color: "#A4B040"
    },
    {
      value: 5,
      label: 'descriptor.veryThick',
      color: "#7CD55A"
    },
  ]
  
  export const menuListSolubleAftertaste = [
    {
      value: 1,
      label: 'descriptor.poor',
      color: "#BF3027"
    },
    {
      value: 2,
      label: 'descriptor.belowAverage',
      color: "#DC4F22"
    },
    {
      value: 3,
      label: 'descriptor.average',
      color: "#FC6C17"
    },
    {
      value: 4,
      label: 'descriptor.aboveAverage',
      color: "#A4B040"
    },
    {
      value: 5,
      label: 'descriptor.outstanding',
      color: "#7CD55A"
    },
  ]