import {samplePreProcessor} from "../sample";


export const contractSamplePreProcessor = (sn: any) => {
  return {
    ...sn,
    averageScore: sn.averageScore || "",
    sampleType: sn.sampleType || "",
    customer: sn.customer || "",
    sampleName: sn.sampleName || "",
  }
}

export const contractSalesContractPreProcessor = (sn: any) => {
  return {
    ...sn,
    averageScore: sn.averageScore || "",
    sampleType: sn.sampleType || "",
    customer: sn.customer || "",
    sampleName: sn.sampleName || "",
    country: sn.country || "",
  }
}

export const contractDetailPreProcessor = (sn: any) => {
  return {
    ...sn,
    id: sn.id || "",
    averageScore: sn.averageScore || "",
    certification: sn.certification || "",
    coffeeProcessing: sn.coffeeProcessing || "",
    country: sn.country || "",
    cropYear: sn.cropYear || "",
    numberOfUnit: sn.numberOfUnit || "",
    producer: sn.producer || "",
    purchaseContractReference: sn.purchaseContractReference || "",
    sampleGrade: sn.sampleGrade || "",
    species: sn.species || "",
    weight: sn.weight || "",
  }
}

export const reviewSamplePreProcessor = (sn: any) => {
  return {
    ...sn,
    ...samplePreProcessor(sn),
    id: sn.id,
  }
}