import {useTranslation} from "react-i18next";
import {Box, Stack, Typography} from "@mui/material";
import {Warning} from "@mui/icons-material";
import {
  MAINTENANCE_BANNER_ON,
  MAINTENANCE_BANNER_DATE,
  MAINTENANCE_BANNER_START_TIME,
  MAINTENANCE_BANNER_END_TIME
} from 'config/env'

export const MaintenanceBanner = () => {
  const { t } = useTranslation()

  if (!MAINTENANCE_BANNER_ON) return null

  return (
    <Box
      sx={{
        padding: "12px",
        backgroundColor: "#B12028",
        color: "white",
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Warning />
        <Typography variant="h6">
          {t('maintenance.banner',
            {
              date: MAINTENANCE_BANNER_DATE,
              startTime: MAINTENANCE_BANNER_START_TIME,
              endTime: MAINTENANCE_BANNER_END_TIME
            }
          )}
        </Typography>
      </Stack>
    </Box>
  )
}
