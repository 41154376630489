import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {useParams, useSearchParams} from "react-router-dom";
import {Box, Button, Container, Typography} from "@mui/material";

import {HeaderTitle, ModalConfirm, ModalMessage} from "components";
import {useStores} from "models";
import {ApproveReject} from "modules";

import {useStyles} from "./sample-shipment-review.styles";
import {BulkApprove, SampleInformation, ShipmentTable} from "./components";

export const SampleShipmentReview = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const params = useParams()
  const [searchParams] = useSearchParams()

  const {
    sampleShipmentStore: { getGroupSampleShipment, updateBulkApprovalStatus },
    notificationStore
  } = useStores()
  const [openConfirmApprove, setOpenConfirmApprove] = useState(false)
  const [openConfirmBulkApprove, setOpenConfirmBulkApprove] = useState(false)
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [openQcReason, setOpenQcReason] = useState(false)
  const [openBulkApprove, setOpenBulkApprove] = useState(false)
  const [transactionItem, setTransactionItem] = useState<any>({})
  const [data, setData] = useState<any>({})
  const [dataBulkApprove, setDataBulkApprove] = useState<any>({})
  const [openSuccessBulkApprove, setOpenSuccessBulkApprove] =  useState(false)
  const token = searchParams.get('token')
  const title = (data.tastifyOrderNumber || data.orderRemoteId)
    ? `${t('order.title')} #${data.orderRemoteId}`
    : t('shipment.review.title')

  const onLoadData = async () => {
    try {
      notificationStore.setLoading(true)

      const sampleShipment = await getGroupSampleShipment(params.id, token)
      setData(sampleShipment)
    } finally {
      notificationStore.setLoading(false)
    }
  }

  const handlerBulkApproval = async (data) => {
    try {
      notificationStore.setLoading(true)

      await updateBulkApprovalStatus(data, token)
      setOpenConfirmBulkApprove(false)
      setOpenSuccessBulkApprove(true)

    } finally {
      notificationStore.setLoading(false)
    }
  }

  useEffect(() => {
    onLoadData()
  }, [params.id])

  return (
    <Box>
      <HeaderTitle
        title={title}
        subtitle={t('shipment.review.subtitle')}
      />

      <Container sx={{mb: 3}}>
        <Typography variant='h5' mb={1}>{t('shipment.generalInformation')}</Typography>

        <Box className={classes.wrapper}>
          <SampleInformation {...data} />
        </Box>
      </Container>

      <Container sx={{mb: 3}}>
        <Box className={classes.buttonTitle}>
          <Typography variant='h5'>{t('shipment.shipmentInformation')}</Typography>
          <Button
            variant="contained"
            color="success"
            onClick={() => setOpenBulkApprove(true)}
          >
            {t('shipment.button.bulkApprove')}
          </Button>
        </Box>

        {data?.sampleTransactions?.map(sample => {
          return (
            <ShipmentTable
              key={sample.id}
              id={params.id}
              transactionId={sample.uniqueToken}
              groupSample={sample}
              onApprove={(params, hasApproval) => {
                setTransactionItem(params)
                if (hasApproval) setOpenQcReason(true)
                setOpenConfirmApprove(true)
              }}
              onReject={(params, hasApproval) => {
                setTransactionItem(params)
                if (hasApproval) setOpenQcReason(true)
                setOpenRejectModal(true)
              }}
            />
          )
        })}
      </Container>

      <ApproveReject
        id={transactionItem.id}
        transactionItemId={transactionItem.uniqueToken}
        setTransactionItemId={(_) => setTransactionItem({})}
        openQcReason={openQcReason}
        setOpenQcReason={setOpenQcReason}
        openConfirmApprove={openConfirmApprove}
        setOpenConfirmApprove={setOpenConfirmApprove}
        confirmApproveDescription={t('shipment.confirmApprove.description2')}
        openRejectModal={openRejectModal}
        setOpenRejectModal={setOpenRejectModal}
        needRequestNewSample
        onSuccess={()=>onLoadData()}
        token={token ?? undefined}
      />

      <BulkApprove
        open={openBulkApprove}
        onClose={() => setOpenBulkApprove(false)}
        shipments={data.sampleTransactions ?? []}
        onApprove={(data) => {
          setOpenBulkApprove(false)
          setOpenConfirmBulkApprove(true)
          setDataBulkApprove(data)
        }}
      />

      <ModalConfirm
        open={openConfirmBulkApprove}
        onClose={() => setOpenConfirmBulkApprove(false)}
        title={t('shipment.confirmApprove.title')}
        description={ t('shipment.confirmApprove.description2')}
        confirm={t('shipment.button.approve')}
        onConfirm={()=>handlerBulkApproval(dataBulkApprove)}
      />

      <ModalMessage
        open={openSuccessBulkApprove}
        onSubmit={() => {
          setOpenSuccessBulkApprove(false)
          onLoadData()
        }}
        description={t('shipment.success.successfullyApprovedSample')}
        buttonText={t('common.backToPage')}
      />
    </Box>
  )
})

export default SampleShipmentReview
