
import { types } from 'mobx-state-tree'
import {
  withSetValue,
  withReset
} from "models/extensions"

export const dateFilterModel = types.model({
  sampleStartDate: types.maybe(types.Date),
  sampleEndDate: types.maybe(types.Date),
  shipmentStartDate: types.maybe(types.Date),
  shipmentEndDate: types.maybe(types.Date),
  cuppingDate: types.maybe(types.string),
  arrivalDate: types.maybe(types.string),
})
.extend(withSetValue)
.extend(withReset)
