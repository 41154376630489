import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {colors} from "assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 3,
      marginBottom: 24,
      marginTop: 24,
      padding: 10
    },
    wheelWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    cardSummary: {
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 3,
      marginBottom: 5,
      marginTop: 24,
      padding: 10,
      height: 110
    },
    tableRightBorder: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: colors.text.o25,
      borderStyle: 'solid',
    },
    tableRightBorderWithBackground: {
        borderWidth: 0,
        borderRightWidth: 1,
        borderColor: colors.text.o25,
        borderStyle: 'solid',
        backgroundColor: '#EBE8F2'
    },
    tabs: {
      padding: '8px 16px',
      height: '90px',
      borderRadius: 4,
      background: "#EBE8F2",

      '& button': {
        background: 'transparent',
        color: colors.primary.main,
        borderRadius: 4,
        border: 'none',
        fontWeight: 600,
        width: '24%',
        height: '70px',
        minHeight: 'unset',

        '&.Mui-selected': {
          background: colors.primary.main,
          color: 'white'
        }
      },

      '& svg': {
        width: 20,
        height: 20,
        fill: colors.primary.main,
      },

      '& .MuiTabs-indicator': {
        display: 'none'
      }
    }
  })
)
