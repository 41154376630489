import {types} from "mobx-state-tree";

export const reportAnalyticsDailyArrivalsProps = {
    id: types.maybeNull(types.union(types.string, types.number)),
    sampleUniqueNumber: types.maybeNull(types.string),
    sampleName: types.maybeNull(types.string),
    producerName: types.maybeNull(types.string),
    sampleType: types.maybeNull(types.string),
    origin: types.maybeNull(types.string),
    customerName: types.maybeNull(types.string),
    shipmentStatus: types.maybeNull(types.string),
    process: types.maybeNull(types.string),
    contractNumber: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    salesContractReference:  types.maybeNull(types.string),
}

export const reportAnalyticsDailyCuppingsProps = {
    id: types.maybeNull(types.union(types.string, types.number)),
    sampleUniqueNumber: types.maybeNull(types.string),
    cuppingSessionId: types.maybeNull(types.union(types.string, types.number)),
    shipmentId: types.maybeNull(types.string),
    sampleName: types.maybeNull(types.string),
    cupperName: types.maybeNull(types.string),
    shipmentCupperName: types.maybeNull(types.string),
    producerName: types.maybeNull(types.string),
    sampleType: types.maybeNull(types.string),
    origin: types.maybeNull(types.string),
    process: types.maybeNull(types.string),
    contractNumber: types.maybeNull(types.string),
    averageScore: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    topDescriptors: types.maybeNull(types.string),
}

export const reportAnalyticsShipmentProps = {
    uniqueToken: types.maybeNull(types.string),
    customer: types.maybeNull(types.string),
    createdDate: types.maybeNull(types.string),
    id: types.maybeNull(types.union(types.string, types.number)),
    numberOfApprovalSamples: types.optional(types.number, 0),
    numberOfContractedSamples: types.optional(types.number, 0),
    numberOfRejectedSamples: types.optional(types.number, 0),
    numberOfSamples: types.optional(types.number, 0)
}

export const fulfilledProps = types.model({
    cancelled: types.optional(types.number, 0),
    fulfilled: types.optional(types.number, 0),
    newRequested: types.optional(types.number, 0),
    requested: types.optional(types.number, 0),
    total: types.optional(types.number, 0),
    unfulfilled: types.optional(types.number, 0)
})

export const descriptorProps = types.model({
    label: types.string,
    qty: types.number
  })