/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-01 00:50:09
 * @modify date 2022-03-01 00:50:09
 */
import {Theme} from "@mui/material"
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    value: {
      padding: `4px 8px`,
      minWidth: 30,
      textAlign: 'center',
      color: colors.text.o50,

      [theme.breakpoints.down('md')]: {
        border: 'none'
      }
    },
    slider: {
      [theme.breakpoints.down('md')]: {
        padding: 0
      }
    }
  })
)
