/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-16 22:28:06
 * @modify date 2022-02-16 22:28:06
 */
import React from 'react'
import { find, clone } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"
import { Container, Card, Typography, TextField, Button } from '@mui/material'

import { useStores } from 'models'
import { HeaderTitle } from 'components'
import { findRoute } from 'constants/routes'
import { formatMoney, isZero } from 'utils'

import { useStyles } from './license.styles'

export const License: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const homeLink = findRoute('home')
  const {
    companyStore: { licensesNumber, updateNumberOfLicense, subscriptionPlanId, initialPlanIdSelection, setValue: setCompanyValue },
    cupperStore: { members, getCupperSummaries },
    subscriptionStore: { subscriptions, getSubscriptions },
    notificationStore: { setNotification, setValue: setNotificationValue} ,
    guideStore: { guideLicense }
  } = useStores()
  const [price, setPrice] = React.useState('')
  const [tempLicensesNumber, setTempLicensesNumber] = React.useState(clone(licensesNumber))

  const zeroLisence = tempLicensesNumber === 0
  const disabled = tempLicensesNumber < members || zeroLisence

  const onUpdateLicense = async () => {
    if (guideLicense) return

    setCompanyValue('licensesNumber', parseInt(tempLicensesNumber))
    setNotificationValue('loading', true)

    try{
      const res = await updateNumberOfLicense()
      res?.base?.map(message => setNotification(message))
    } finally {
      setNotificationValue('loading', false)
    }
  }

  const onSetPrice = () => {
    const id = subscriptionPlanId || initialPlanIdSelection
    const subscription = find(subscriptions, ['id', id])

    if (!subscription) return
    const { currency, price, billingInterval } = subscription
    setPrice(`${formatMoney(price, currency)} /${billingInterval}`)
  }

  React.useEffect(() => {
    if (!subscriptionPlanId && !guideLicense) navigate(homeLink)
  }, [guideLicense])

  React.useEffect(() => {
    if (subscriptions.length > 0) {
      onSetPrice()
      return
    }

    (async () => {
      setNotificationValue('loading', true)

      try{
        if (members <= 0) await getCupperSummaries()
        await getSubscriptions()
        onSetPrice()
      } finally {
        setNotificationValue('loading', false)
      }
    })()
  }, [])

  if (!subscriptionPlanId && !guideLicense) return null

  return (
    <>
      <HeaderTitle
        backUrl={homeLink}
        breadcrumb={t('common.backTo', { menu: t('menu.home')})}
        title={t('subscription.updateNumberOfLicenses')}
      />

      <Container>
        <Card className={classes.cardWrapper}>
          <Typography variant='subtitle1' sx={{mb: 3}}>
            {t('subscription.numberOfLicenses')}
          </Typography>
          <TextField
            focused
            fullWidth
            type='tel'
            size='small'
            inputMode='numeric'
            sx={{mb: 1}}
            value={isZero(tempLicensesNumber)}
            onChange={(e) => setTempLicensesNumber(parseInt(e.target.value) || 0)}
            error={disabled}
            {...(disabled && !zeroLisence && {helperText: t('subscription.error.downgradeNumberOfLicense')})}
            inputProps={{min: 0, pattern: '[0-9]*'}}
          />

          <Typography variant='caption' paragraph color="text.disabled" sx={{mb: 3}}>
            {t('subscription.priceEachLicense', { pricing: price })}
          </Typography>

          <Button
            fullWidth
            variant='contained'
            size='small'
            disabled={disabled}
            onClick={onUpdateLicense}
          >
            {t('subscription.updateNumberOfLicenses')}
          </Button>
        </Card>
      </Container>
    </>
  )
})

export default License
