import React from "react"
import clsx from "clsx"
import {every, isString, last, range, toNumber, truncate} from "lodash"
import {useTranslation} from "react-i18next"
import {Avatar, Box, Grid, MenuItem, Select, Typography} from "@mui/material"
import {isMobile} from "react-device-detect"

import { Descriptor } from 'components'
import { scaDescriptorList} from "constants/sca-descriptors"

import {IconStartCupping} from "assets/images"
import {translateScaDescriptor} from "utils"
import { useStyles } from "./sca-information.styles"

export const ScaInformation = (
  {
    score,
    sampleId,
    cupsPerSample,
    isEdit,
    setIsEditScore,
    setIsEditDescriptor,
    language,
    cuppingProtocol,
	  fiveCups
  }: {
    score: any,
    sampleId: any,
    cupsPerSample: any,
    isEdit: boolean,
    setIsEditScore: () => void,
    setIsEditDescriptor: () => void,
    language: string,
    cuppingProtocol?: string
	  fiveCups: boolean
  }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    fragranceIntensity, aromaIntensity, fragranceAromaDescriptors,
    flavorIntensity, aftertasteIntensity, flavorAftertasteDescriptors,
    acidityIntensity, mouthfeelIntensity, sweetnessIntensity,
    fragrance, aroma, fragranceAromaNote, flavor, aftertaste, flavorAftertasteNote,
    acidity, acidityNote, mouthfeel, mouthfeelNote, sweetnessScore, sweetnessNote,
    overall, overallNote, totalNonUniform, nonUniform, nonUniformScore, totalDefective, defective,
	  defects, setScoreValue
  } = score


  const isScaAffective = cuppingProtocol === 'sca_affective'
  const isScaDescriptive = cuppingProtocol === 'sca_descriptive'

  const numberOfCups = [{
    key: 'numberOfCups',
    label: t('descriptor.numberOfCups'),
    value: cupsPerSample
  }]

  const fragranceAromaDescriptive = [
    {
      key: 'fragranceIntensity',
      label: t('descriptor.fragrance'),
      value: fragranceIntensity
    },
    {
      key: 'aromaIntensity',
      label: t('descriptor.aroma'),
      value: aromaIntensity
    }
  ]

  const flavorAftertasteDescriptive = [
    {
      key: 'flavorIntensity',
      label: t('descriptor.flavor'),
      value: flavorIntensity
    },
    {
      key: 'aftertasteIntensity',
      label: t('descriptor.aftertaste'),
      value: aftertasteIntensity
    }
  ]

  const acidityIntensityDescriptive = [{
    key: 'acidityIntensity',
    label: t('descriptor.acidity'),
    value: acidityIntensity
  }]

  const mouthfeelIntensityDescriptive = [{
    key: 'mouthfeelIntensity',
    label: t('descriptor.mouthfeel'),
    value: mouthfeelIntensity
  }]

  const sweetnessIntensityDescriptive = [{
    key: 'sweetnessIntensity',
    label: t('descriptor.sweetness'),
    value: sweetnessIntensity
  }]

  const fragranceAromaAffective = [
    {
      key: 'fragrance',
      label: t('descriptor.fragrance'),
      value: fragrance
    },
    {
      key: 'aroma',
      label: t('descriptor.aroma'),
      value: aroma
    }
  ]

  const flavorAftertasteAffective = [
    {
      key: 'flavor',
      label: t('descriptor.flavor'),
      value: flavor
    },
    {
      key: 'aftertaste',
      label: t('descriptor.aftertaste'),
      value: aftertaste
    }
  ]

  const acidityAffective = [{
    key: 'acidity',
    label: t('descriptor.acidity'),
    value: acidity
  }]

  const mouthfeelAffective = [{
    key: 'mouthfeel',
    label: t('descriptor.mouthfeel'),
    value: mouthfeel
  }]

  const sweetnessAffective = [{
    key: 'sweetnessScore',
    label: t('descriptor.sweetness'),
    value: sweetnessScore
  }]

  const overallAffective = [{
    key: 'overall',
    label: t('descriptor.overall'),
    value: overall
  }]

  const nonUniformAffective = [{
    key: 'nonUniform',
    label: t('descriptor.nonUniformCups'),
    value: fiveCups ? totalNonUniform : nonUniformScore
  }]

  const defectiveAffective = [{
    key: 'defective',
    label: t('descriptor.defectiveCups'),
    value: fiveCups ? totalDefective : defects
  }]

  const setValue = (key, value) => {
		switch (true) {
			case key === 'defective' && !fiveCups:
				setScoreValue('defects', value)
				break
			case key === 'nonUniform' && !fiveCups:
				setScoreValue('nonUniformScore', value)
				break
			default:
				setScoreValue(key, value)
		}

    setIsEditScore()
  }

  const setValueDescriptor = (key, value) => {
    setScoreValue(key, value)
    setIsEditDescriptor()
  }

  const selection = (score: any, min: number, max: number) => {
    return (
      <Select
        autoWidth
        value={score.value}
        onChange={(e) => setValue(score.key, toNumber(e.target.value))}
        size='small'
      >
        {range(min, max + 1).map(r =>
          <MenuItem value={r}>
            {r}
          </MenuItem>
        )}
      </Select>
    )
  }

  const truncateDescription = (desc: string) => {
    if (isMobile) return truncate(desc, {
      'length': 50,
      'omission': '...'
    })

    return desc
  }

  const editDescriptor = (key) => {
    return(
      <Descriptor
        key={key}
        descriptorList={scaDescriptorList}
        sampleId={sampleId}
        descriptors = {key === 'fragranceIntensity' ? fragranceAromaDescriptors : flavorAftertasteDescriptors}
        addDescriptor={(values) => setValueDescriptor(key === 'fragranceIntensity' ? 'fragranceAromaDescriptors' : 'flavorAftertasteDescriptors', values)}
      />
    )
  }

	const editScore = (score, isBoolean, isIntensity) => {
		if (score.key === 'numberOfCups' || fiveCups) return <Typography variant="body2" color="primary">{score.value}</Typography>

		if (score.key === 'nonUniform' || score.key === 'defective') return selection(score, 0, 10)

		return selection(score, isIntensity ? 0 : 1, isIntensity ? 15 : 9)
	}

  const renderScore = (scores, description: any = undefined, isIntensity = false, isBoolean = false, isDefective = false) => {
    const [firstScore] = scores

    return (
      <Box className={classes.scoreWrapper}>
        <Box width="30%">
          {scores.map(score => (
            <Box key={score.key} className={clsx(classes.scoreItem, last(scores) === score && 'no-border')}>
              <Typography variant="body2">{score.label}</Typography>
              {isEdit ?
	              editScore(score, isBoolean, isIntensity) :
                <Typography variant="body2" color="primary">{score.value}</Typography>
              }
            </Box>
          ))}
        </Box>

        {description &&
          <Box className={classes.descriptor}>
            {isBoolean ?
              description.map((item, index) =>
                <Avatar
                  key={index}
                  className={clsx(classes.cup, item && 'selected', (item && isDefective) && 'defective')}
                  {...(isBoolean && isEdit && {onClick: () => setValue(`${firstScore.key}${index + 1}`, !item)})}
                >
                  <IconStartCupping className={clsx(classes.cupIcon, item && 'selected')} />
                </Avatar>
              ):
              isEdit ? editDescriptor(firstScore.key) :
                <Typography variant={isString(description) ? "body2" : "h6"} color="primary">
                  {isString(description) ? truncateDescription(description) : translateScaDescriptor(language, description)}
                </Typography>
            }
          </Box>
        }
      </Box>
    )
  }

  React.useEffect(() => {
		if (!fiveCups) return

    setScoreValue('nonUniformScore', nonUniform.filter(Boolean).length * 2)
  }, [nonUniform])

  React.useEffect(() => {
	  if (!fiveCups) return

    if (every(defective, e => e === false)) setScoreValue('defectsDescriptors', [])

    setScoreValue('defects', defective.filter(Boolean).length * 4)
  }, [defective])

  return (
    <Grid container spacing={2}>
      {!isScaAffective && (
        <Grid item xs={12}>
          <Typography variant="h6" color="primary">{t('descriptor.descriptive')}</Typography>
          {renderScore(numberOfCups)}
          {renderScore(fragranceAromaDescriptive, fragranceAromaDescriptors, true)}
          {renderScore(flavorAftertasteDescriptive, flavorAftertasteDescriptors, true)}
          {renderScore(acidityIntensityDescriptive, '', true)}
          {renderScore(sweetnessIntensityDescriptive, '', true)}
          {renderScore(mouthfeelIntensityDescriptive, '', true)}
        </Grid>
      )}
      {!isScaDescriptive && (
        <Grid item xs={12}>
          <Typography variant="h6" color="primary">{t('descriptor.affective')}</Typography>
          {renderScore(numberOfCups)}
          {renderScore(fragranceAromaAffective, fragranceAromaNote)}
          {renderScore(flavorAftertasteAffective, flavorAftertasteNote)}
          {renderScore(acidityAffective, acidityNote)}
          {renderScore(sweetnessAffective, sweetnessNote)}
          {renderScore(mouthfeelAffective, mouthfeelNote)}
          {renderScore(overallAffective, overallNote)}
          {fiveCups ? renderScore(nonUniformAffective, nonUniform, false, true) : renderScore(nonUniformAffective)}
          {fiveCups ? renderScore(defectiveAffective, defective, false, true, true) : renderScore(defectiveAffective)}
        </Grid>
      )}
    </Grid>
  )
}
