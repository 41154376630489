import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {camelCase, cloneDeep, isEmpty, remove, truncate} from "lodash";
import {useNavigate} from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  ListItem,
  Checkbox,
  ListItemText,
  List,
  Collapse,
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Popover
} from "@mui/material";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import {
  Visibility,
  Edit as IconEdit,
  Close as CloseIcon,
  MoreHoriz,
  ArrowDropDown,
  ExpandLess,
  ExpandMore,
  SearchOutlined,
  ArchiveOutlined
} from "@mui/icons-material";
import moment from "moment/moment";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {isMobile} from "react-device-detect";

import {useStores} from "models";
import {
  BasicTable,
  HeaderTitleWide,
  SubmitOrder,
  ModalMessage,
  FormInput,
  FilterChip,
  Pagination,
  MenuIcon
} from "components";
import {findRoute} from "constants/routes";
import {SampleFulfillmentType, SampleShipmentFulfillmentStatus} from "constants/constant";
import {IconEmptyOrder, IconSubmitOrder, SortAsc, SortDefault, SortDesc} from "assets/images";
import {filterSampleShipments, formatDate, sortSampleShipments} from "constants/form";
import {globalAction, optionsTranslation} from "utils";
import {colors} from "assets";

import {useStyles} from "./sample-shipments.styles";

const columnHelper = createColumnHelper<any>()

const RenderMenuItems = ({onArchive, onCancel, cantBeCancelled}) => {
  const { t } = useTranslation()
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorMenu)

  return (
    <>
      <IconButton
        size='small'
        color='primary'
        onClick={(event) => setAnchorMenu(event.currentTarget)}
        {...(isMobile && {
          sx: {
            color: 'white',
            borderRadius: "5px",
            backgroundColor: colors.primary.main
          }
        })}
      >
        <MoreHoriz />
      </IconButton>

      <Menu
        keepMounted
        anchorEl={anchorMenu}
        open={open}
        onClose={() => setAnchorMenu(null)}
        onClick={() => setAnchorMenu(null)}
      >
        <MenuItem onClick={onArchive}>
          <MenuIcon><ArchiveOutlined /></MenuIcon>
          {t('shipment.button.archive')}
        </MenuItem>
        <MenuItem onClick={onCancel} disabled={cantBeCancelled}>
          <MenuIcon color="error" sx={{backgroundColor: colors.error.o25, mr: 1}}><CloseIcon /></MenuIcon>
          {t('shipment.button.cancel')}
        </MenuItem>
      </Menu>
    </>
  )
}

export const SampleShipments = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()
  const {
    sampleShipmentStore: { sampleShipments,  sendEmailSubmitTheOrder,
      page, totalPage, sort, filter, filter: {createdAt, country, customer, purchaseContractReference, salesContractReference, thirdPartyEmailBlank, fulfillmentStatus, shipmentNumber, setValue: setFilterValue},
      getSampleShipments, archivedSampleShipment, cencelSampleShipment, setValue: setSampleShipmentsValue },
    notificationStore
  } = useStores()
  const homeLink = findRoute('home')
  const [openSubmitOrder, setOpenSubmitOrder] = useState(false)
  const [sortMenu, setSortMenu] = React.useState<null | HTMLElement>(null)
  const openSort = Boolean(sortMenu)
  const [indexSamples, setIndexSamples] = useState(0)
  const [openSuccessSubmitOrder, setOpenSuccessSubmitOrder] = useState(false)
  const [filterMenu, setFilterMenu] = React.useState<null | HTMLElement>(null)
  const openMenuFilter = Boolean(filterMenu)
  const [checkedItems, setCheckedItems] = useState([{group: '', value: ''}] as any);
  const [openGroups, setOpenGroups] = useState([] as any);
  const [selectedSort, setSelectedSort] = useState(sortSampleShipments);
  const [selectedFilter, setSelectedFilter] = useState(filterSampleShipments);
  const [selectCheckBoxGroup, setSelectCheckBoxGroup] = useState("")

  const handleSelectedSort = (index) => {
    let newArr = [...selectedSort];

    newArr.map((data, i) => {
      if(i !== index && newArr[i]["sort"] !== "default") newArr[i]["sort"] = "default"
    })

    if(newArr[index]["sort"] === "default"){
      newArr[index]["sort"] = "asc"
    } else if(newArr[index]["sort"] === "asc") {
      newArr[index]["sort"] = "desc"
    } else {
      newArr[index]["sort"] = "default"
    }
    setSampleShipmentsValue("sort",`${newArr[index].key} ${newArr[index].sort}`)
    setSelectedSort(newArr)
    setSampleShipmentsValue('page', 1)
  }

  const onRemoveFilter = (key) => {
    setSelectedFilter(
      selectedFilter.map((item) => {
        if (item.key === key) {
          return {...item, value: ""}
        }
        return item
      })
    )
  }

  const onCloseFilter = () => {
    let thirdPartyEmail = ""

    if(isEmpty(thirdPartyEmailBlank)){
      thirdPartyEmail = ""
    } else {
      thirdPartyEmail = t(`options.fulfillmentType.${thirdPartyEmailBlank === "0" ? "thirdParty" : "local"}`)
    }

    setSelectedFilter(
      selectedFilter.map((item) => {
        switch (item.key) {
          case "third_party_email_blank":
            return {...item, value: thirdPartyEmail}
          case "fulfillment_status":
            return {...item, value: optionsTranslation('fulfillmentStatus', fulfillmentStatus)}
          case "created_at":
            return {...item, value: createdAt}
          case "customer":
            return {...item, value: customer}
          case "country":
            return {...item, value: country}
          case "purchase_contract_reference":
            return {...item, value: purchaseContractReference}
          case "sales_contract_reference":
            return {...item, value: salesContractReference}
          case "shipment_number":
            return {...item, value: shipmentNumber}
          default:
            return item
        }
      })
    )
  }

  const handleCheckboxToggle = (key, value) => {
    const currentIndex =  checkedItems.findIndex(i => i.group === key.group);
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push({group: key.group, value: value});
    } else {
      if(value === newCheckedItems[currentIndex].value  && newCheckedItems[currentIndex].group === key.group) {
        newCheckedItems.splice(currentIndex, 1)
        setFilterValue(camelCase(key.key), "")
        setCheckedItems(newCheckedItems);
        return
      } else {
        newCheckedItems[currentIndex].group = key.group;
        newCheckedItems[currentIndex].value = value;
      }
    }
    checkedItems.map((data, index) => {
      if(isEmpty(value) && data.group === key.group) {
        newCheckedItems.splice(index, 1)
        setFilterValue(camelCase(key.key), "")
      } else {
        if(key.group === "type"){
          setFilterValue(camelCase(key.key), value === "third_party" ? "0" : "1")
        }else{
          setFilterValue(camelCase(key.key), value)
        }
      }
    })
    setCheckedItems(newCheckedItems);
    setSampleShipmentsValue('page', 1)
  }

  const handleGroupToggle = (group) => {
    const currentIndex = openGroups.indexOf(group);
    const newOpenGroups = [...openGroups];

    if (currentIndex === -1) {
      newOpenGroups.push(group);
    } else {
      newOpenGroups.splice(currentIndex, 1);
    }

    setOpenGroups(newOpenGroups);
  };

  const renderIconSort = (data) => {
    switch (data) {
      case "asc": return SortAsc
      case "desc": return SortDesc
      default : return SortDefault

    }
  }

  const renderComponentFilter = (item) => {

    switch (item.key) {
      case "created_at":
        return(
          <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                mask='____/__/__'
                inputFormat="yyyy/MM/DD"
                value={createdAt}
                onChange={(newValue) =>  {
                   handleCheckboxToggle(item, newValue ? moment(newValue?.valueOf()).format('YYYY-MM-DD') : '')
                  setFilterValue('createdAt', newValue ? moment(newValue?.valueOf()).format('YYYY-MM-DD') : '')
                } }
                renderInput={(params) => <TextField size='small' {...params}  />}
              />
          </LocalizationProvider>
        )
      case "country":
        return (
          <FormInput
            textFieldProps={{
              value: country,
              onChange: (e) => {
                 handleCheckboxToggle(item, e.target.value)
                setFilterValue('country', e.target.value)
              },
              InputProps:{
                endAdornment: (<InputAdornment position="end"><SearchOutlined /></InputAdornment>)
              }
            }}
            formControlProps={{ margin: 'none' }}
          />
        )
      case "customer":
        return (
          <FormInput
            textFieldProps={{
              value: customer,
              onChange: (e) => {
                 handleCheckboxToggle(item, e.target.value)
                setFilterValue('customer', e.target.value)
              },
              InputProps:{
                endAdornment: (<InputAdornment position="end"><SearchOutlined /></InputAdornment>)
              }
            }}
            formControlProps={{ margin: 'none' }}
          />
        )
      case "purchase_contract_reference":
        return (
            <FormInput
              textFieldProps={{
                value: purchaseContractReference,
                onChange: (e) => {
                  handleCheckboxToggle(item, e.target.value)
                  setFilterValue('purchaseContractReference', e.target.value)
                },
                InputProps:{
                  endAdornment: (<InputAdornment position="end"><SearchOutlined /></InputAdornment>)
                }
              }}
              formControlProps={{ margin: 'none' }}
            />
        )
      case "sales_contract_reference":
        return (
            <FormInput
              textFieldProps={{
                value: salesContractReference,
                onChange: (e) => {
                   handleCheckboxToggle(item, e.target.value)
                  setFilterValue('salesContractReference', e.target.value)
                },
                InputProps:{
                  endAdornment: (<InputAdornment position="end"><SearchOutlined /></InputAdornment>)
                }
              }}
              formControlProps={{ margin: 'none' }}
            />
        )
      case "shipment_number":
        return (
          <FormInput
            textFieldProps={{
              value: shipmentNumber,
              onChange: (e) => {
                handleCheckboxToggle(item, e.target.value)
                setFilterValue('shipmentNumber', e.target.value)
              },
              InputProps:{
                endAdornment: (<InputAdornment position="end"><SearchOutlined /></InputAdornment>)
              }
            }}
            formControlProps={{ margin: 'none' }}
          />
        )
      default:
          return(
            <>
            {item.items.map((data, i) => {
              return(
                <ListItem key={i} dense >
                  <Checkbox
                  edge="start"
                  checked={checkedItems.findIndex(index => index.value === data)!== -1}
                  tabIndex={-1}
                  disableRipple
                  onClick = {() =>{
                    handleCheckboxToggle(item, data)
                  }}
                />
                <ListItemText primary={t(`shipment.filter.${data}`)} />
              </ListItem>
              )
            })}
            </>
          )

    }
  }
  const handleRightClick = (event, uniqueToken) => {
    event.preventDefault(); // Prevent the default browser context menu
    const url = `/sample-shipments/${uniqueToken}`;
    if (url) {
      window.open(url, '_blank'); // Open the URL in a new tab
    }
  };

  const renderCell = (info, text?) => {
    const { uniqueToken } = info.row.original
    return (
      <Box
        onClick={() => navigate(`/sample-shipments/${uniqueToken}`)}
        sx={{cursor: 'pointer'}}
        onContextMenu={(event)=>handleRightClick(event, uniqueToken)}
      >
        {text || info.renderValue()}
      </Box>
    )
  }

  const renderDot = (info) => {
    const { uniqueToken } = info.row.original
    return (
      <Box
        onClick={() => navigate(`/sample-shipments/${uniqueToken}`)}
        sx={{cursor: 'pointer'}}
        onContextMenu={(event)=>handleRightClick(event, uniqueToken)}
      >
        {truncate(info.renderValue(),{'length': 15, 'separator': ''})}
      </Box>
    )
  }

  const columns = [
    columnHelper.accessor('shipmentNumber', {
      id: 'shipmentNumber',
      header: () => <Box textAlign="left">{t('shipment.table.shipment')} #</Box>,
      cell: info => renderCell(info),
    }),
    columnHelper.accessor('sampleOrderNumber', {
      id: 'sampleOrderNumber',
      header: () => <Box textAlign="left">{t('shipment.table.sampleOrderNumber')}</Box>,
      cell: info => isEmpty(info.renderValue()) ? "-" : renderCell(info),
    }),
    columnHelper.accessor('customer', {
      id: 'customer',
      header: () => <Box textAlign="left">{t('shipment.table.customer')}</Box>,
      cell: info => renderCell(info),
    }),
    columnHelper.accessor('country', {
      id: 'country',
      header: () => <Box textAlign="left">{t('shipment.table.country')}</Box>,
      cell: info => renderCell(info),
    }),
    columnHelper.accessor('purchaseContractReference', {
      id: 'purchaseContractReference',
      header: () => <Box textAlign="left">{t('shipment.table.purchaseContract')} #</Box>,
      cell: info => renderDot(info),
    }),
    columnHelper.accessor('salesContractReference', {
      id: 'salesContractReference',
      header: () => <Box textAlign="left">{t('shipment.table.salesContract')} #</Box>,
      cell: info => renderDot(info),
    }),
    columnHelper.accessor('numberOfSamples', {
      id: 'numberOfSamples',
      header: () => <Box textAlign="left">{t('shipment.table.ofSamples')}</Box>,
      cell: info => renderCell(info, t('shipment.table.sample', { count: info.renderValue() }))
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => <Box textAlign="left">{t('shipment.table.createdOn')}</Box>,
      cell: info => renderCell(info, moment(info.renderValue()).format(formatDate)),
    }),
    columnHelper.accessor('fulfillment', {
      id: 'fulfillment',
      header: () => <Box textAlign="left">{t('shipment.table.type')}</Box>,
      cell: info => renderCell(info, optionsTranslation('fulfillmentType', info.renderValue())),
    }),
    columnHelper.accessor('sampleLocation', {
      id: 'sampleLocation',
      header: () => <Box textAlign="left">{t('shipment.table.sampleLocation')}</Box>,
      cell: info => renderCell(info),
    }),
    columnHelper.accessor('fulfillmentStatus', {
      id: 'fulfillmentStatus',
      header: () => <Box textAlign="left">{t('shipment.table.status')}</Box>,
      cell: info => renderCell(info, optionsTranslation('fulfillmentStatus', info.renderValue())),
    }),
    columnHelper.accessor('uniqueToken', {
      id: 'uniqueToken',
      header: () => <Box textAlign="center">{t('shipment.table.action')}</Box>,
      cell: info => renderButton(info.row.original, info.row.id),
    }),
  ]

  const renderButton = (row, index) => {
    const { fulfillment, fulfillmentStatus, uniqueToken, isSubmitted, cantBeCancelled } = row
    const isUnfulfilled = fulfillmentStatus?.toLowerCase() === SampleShipmentFulfillmentStatus.UNFULFILLED
    const isThirdParty = fulfillment === SampleFulfillmentType.THIRD_PARTY
    const isRequestNewSample = fulfillmentStatus?.toLowerCase() === SampleShipmentFulfillmentStatus.NEW_REQUEST

    return (
      <Stack direction="row" spacing={1}>
        {isThirdParty && (
          <Button
            size='small'
            variant='contained'
            fullWidth={isMobile}
            onClick={() => {
              setIndexSamples(index)
              setOpenSubmitOrder(true)
            }}
            sx={{p: 0.5, minWidth: 0}}
            disabled={isSubmitted}
          >
            <SvgIcon component={IconSubmitOrder} inheritViewBox sx={{ fontSize: 25}}/>
            {isMobile && <Typography variant="body3" ml={0.5}>{t('shipment.button.submit')}</Typography>}
          </Button>
        )}
        {!isThirdParty && !isMobile && <Box width={33} p={0.5} />}

        <Button
          size='small'
          variant='contained'
          fullWidth={isMobile}
          color={(isUnfulfilled || isRequestNewSample) ? 'warning' : 'primary'}
          onClick={() => navigate(`/sample-shipments/${uniqueToken}`)}
          sx={{p: 0.5, minWidth: 0}}
        >
         <SvgIcon component={isUnfulfilled ? IconEdit : Visibility} inheritViewBox sx={{ fontSize: 25}}/>
          {isMobile && <Typography variant="body3" ml={0.5}>{t(`shipment.button.${isUnfulfilled ? 'edit' : 'preview'}`)}</Typography>}
        </Button>
        <RenderMenuItems
          onArchive={() => {
            const date = new Date()
            setSampleShipmentsValue("archivedAt", moment(date).format('DD-MM-YYYY HH:mm'))
            archivedSampleShipments(index)
          }}
          onCancel={()=>{
            setSampleShipmentsValue("fulfillmentStatus", "cancelled")
            cancelSampleShipments(index)
          }}
          cantBeCancelled ={cantBeCancelled}
        />
      </Stack>
    )
  }

  const renderButtonFilter = () => {
    return (
      <Popover
        open={openMenuFilter}
        anchorEl={filterMenu}
        onClose={() => {
          setFilterMenu(null)
          onCloseFilter()
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {selectedFilter.map((group, index) => (
          <Box key={index}>
            <ListItem
              onClick={()=> {
                handleGroupToggle(group.group)
              }}
            >
              <Checkbox
                edge="start"
                checked={!isEmpty(checkedItems.filter(x=> x.group === group.group))}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={t(`shipment.filter.${group.group}`)} />
              {openGroups.includes(group.group) ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openGroups.includes(group.group)}>
              <List sx={{p: 2, mt: -3}}>
                {renderComponentFilter(group) }
              </List>
            </Collapse>
          </Box>
        ))}
      </Popover>
    );
  }

  const table = useReactTable({
    data: cloneDeep(sampleShipments),
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const renderMobile = (
    <Box mt={2} className={classes.contentMobileWrapper}>
      {sampleShipments.map((row, index) => {
        return (
          <Box key={row.id}>
            <Accordion
              key={row.id}
              sx={{boxShadow: 'none'}}
            >
              <AccordionSummary expandIcon={<ExpandMore />} className={classes.accordion}>
                <Typography variant="h6">{row.shipmentNumber}</Typography>

                <Stack
                  direction="row"
                  spacing={0.5}
                  divider={<Divider orientation="vertical" flexItem sx={{borderColor: "#999FA4"}} />}
                >
                  <Typography variant="body2" fontWeight={500}>{t('shipment.table.sample', {count: row.numberOfSamples})}</Typography>
                  <Typography variant="body2" color="#999FA4">{optionsTranslation('fulfillmentType', row.fulfillment)}</Typography>
                  <Typography variant="body2" color="#999FA4">{optionsTranslation('fulfillmentStatus', row.fulfillmentStatus)}</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails className={classes.contentMobile}>
                <table cellPadding={2}>
                  <tr>
                    <td>{t('shipment.table.order')}</td>
                    <td>{row.tastifyOrderNumber}</td>
                  </tr>
                  <tr>
                    <td>{t('shipment.table.customer')}</td>
                    <td>{row.customer}</td>
                  </tr>
                  <tr>
                    <td>{t('shipment.table.country')}</td>
                    <td>{row.country}</td>
                  </tr>
                  <tr>
                    <td>{t('shipment.table.purchaseContract')}</td>
                    <td>{row.purchaseContractReference}</td>
                  </tr>
                  <tr>
                    <td>{t('shipment.table.salesContract')}</td>
                    <td>{row.salesContractReference}</td>
                  </tr>
                  <tr>
                    <td>{t('shipment.table.createdOn')}</td>
                    <td>{row.createdAt}</td>
                  </tr>
                  <tr>
                    <td>{t('shipment.table.sampleLocation')}</td>
                    <td>{row.sampleLocation}</td>
                  </tr>
                </table>
              </AccordionDetails>
            </Accordion>

            <Box className={classes.buttonWrapperMobile}>
              {renderButton(row, index)}
            </Box>
          </Box>
        )
      })}
    </Box>
  )

  const loadSampleShipments = () => {
    globalAction(notificationStore, {
      action: getSampleShipments,
    })
  }

  const sendEmail = async () => {
    try {
      notificationStore.setLoading(true)
      await sendEmailSubmitTheOrder(sampleShipments[indexSamples]['uniqueToken'])
      setOpenSuccessSubmitOrder(true)
    } finally {
      notificationStore.setLoading(false)
    }
  }

  const archivedSampleShipments = async (index) => {
    try {
      notificationStore.setLoading(true)
      await archivedSampleShipment(sampleShipments[index]['uniqueToken'])
      setOpenSuccessSubmitOrder(true)
    } finally {
      notificationStore.setLoading(false)
    }
  }

  const cancelSampleShipments = async (index) => {
    try {
      notificationStore.setLoading(true)
      await cencelSampleShipment(sampleShipments[index]['uniqueToken'])
      setOpenSuccessSubmitOrder(true)
    } finally {
      notificationStore.setLoading(false)
    }
  }

  useEffect(() => {
    setFilterValue('createdAt',"")
    loadSampleShipments()
  }, [])

  useEffect(() => {
    loadSampleShipments()
  }, [page])

  useEffect(() => {
    setFilterValue('createdAt',"")
    loadSampleShipments()
  }, [openSuccessSubmitOrder])


  useEffect(() => {
    loadSampleShipments()
  }, [
    selectedSort,
    checkedItems,
    createdAt,
    selectCheckBoxGroup,
    country,
    customer,
    purchaseContractReference,
    salesContractReference,
    shipmentNumber,
    selectedFilter
  ])

  return (
    <Box px={isMobile ? 0 : 4} py={isMobile ? 0 : 2.5}>
      <Box px={isMobile ? 2 : 0}>
        <HeaderTitleWide
          backUrl={homeLink}
          breadcrumb={t('common.backTo', { menu: t('menu.home')})}
          title={t(`menu.sampleShipments`)}
          rightContent={(
            <Stack direction='row' spacing={2} className={classes.buttonWrapper}>
              <Button
                variant="outlined"
                endIcon={<ArrowDropDown />}
                sx={{borderRadius: 8}}
                onClick={(event) => setSortMenu(event.currentTarget)}
              >
                {t("shipment.button.sortBy")}
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={sortMenu}
                open={openSort}
                onClose={() => setSortMenu(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {selectedSort.map((data, index) => {
                  return(
                    <MenuItem
                      sx={{justifyContent: 'space-between', alignItems: 'center'}}
                        onClick={()=>{
                          handleSelectedSort(index)
                      }}
                    >
                      <Typography>{t(data.label)}</Typography>
                      <SvgIcon component={renderIconSort(data.sort)} inheritViewBox sx={{ fontSize: 20, textAlign: '' }}/>
                    </MenuItem>
                    )
                })}

              </Menu>

              <Button
                variant="outlined"
                endIcon={<ArrowDropDown />}
                sx={{borderRadius: 8}}
                onClick={(event) => setFilterMenu(event.currentTarget)}
              >
                {t("shipment.button.filter")}
              </Button>
              {renderButtonFilter()}
            </Stack>
          )}
        />

        {selectedFilter.some(f => f.value !== "") && (
          <Stack direction="row" spacing={1} mt={2} useFlexGap flexWrap="wrap">
            {selectedFilter.map((filter) => {
              if (isEmpty(filter.value)) return null

              return (
                <FilterChip
                  key={filter.key}
                  label={`${t(`shipment.filter.${filter.group}`)}: ${filter.value}`}
                  onRemove={() => {
                    setFilterValue(camelCase(filter.key), "")
                    onRemoveFilter(filter.key)
                    remove(checkedItems,{group: filter.group})
                  }}
                />
              )
            })}

            <Button
              variant="text"
              size="small"
              color="error"
              onClick={() => {
                filter.reset()
                setSelectedFilter(filterSampleShipments)
                checkedItems.length = 0
              }}
              sx={{px: 1}}
            >
              {t('common.clearFilter')}
            </Button>
          </Stack>
        )}
      </Box>

      {isEmpty(sampleShipments) && (
        <Box className={classes.emptyList}>
          <IconEmptyOrder width={154} height={158} fill="#aeaeae" />
          <Typography mt={2} variant="h5">{t('shipment.emptySampleShipment')}</Typography>
        </Box>
      )}

      {!isEmpty(sampleShipments) && !isMobile && (
        <BasicTable
          table={table}
          page={page}
          totalPage={totalPage}
          onChangePage={(e, p) => setSampleShipmentsValue('page', p)}
        />
      )}

      {!isEmpty(sampleShipments) && isMobile && renderMobile}

      {isMobile && (
        <Pagination
          page={page}
          count={totalPage}
          onChange={(e, p) => setSampleShipmentsValue('page', p)}
        />
      )}

      {!isEmpty(sampleShipments) && (
        <SubmitOrder
          data={cloneDeep(sampleShipments[indexSamples]['sampleTransactionItems'])}
          open={openSubmitOrder}
          onClose={() => setOpenSubmitOrder(false)}
          onSubmit={() => {
            setOpenSubmitOrder(false)
            sendEmail()
          }}
          setValue={(attribute,value) => {
            setSampleShipmentsValue(attribute, value)
          }}
        />
      )}

      <ModalMessage
        open={openSuccessSubmitOrder}
        description={t('shipment.success.successfullySubmitOrder')}
        onSubmit={() => setOpenSuccessSubmitOrder(false)}
      />
    </Box>
  )
})

export default SampleShipments
