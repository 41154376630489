import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'
import {
  Box,
  Typography,
  Button,
} from '@mui/material'
import {LoadingButton} from "@mui/lab"

import { useStores } from 'models'
import { centered } from 'assets'
import { ModalWrapper } from 'components'
import { colors } from 'assets'

import { useStyles } from "./sca-cva-notification.styles"

export const ScaCvaNotification: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    companyStore: {
      setValue, updateScaPolicy, scaSync
    },
    userStore: { isCompanyAdmin },
    modalStore: { setValue: setModalValue }
  } = useStores()

  const [open, setOpen] = React.useState(false)

  const handleChange = () => {
    setModalValue('settingTab', "scaPolicy")
    setValue('scaSync', false)
    updateScaPolicy(false)
    setOpen(false)
  }

  const handlerGoToSetting = () => {
    setModalValue('setting', true)
    handleChange()
  }

  React.useEffect(() => {

    if(!isCompanyAdmin) return

    if (scaSync == null) { // This will match both null and undefined
      setOpen(true);
    } else {
      setOpen(false);
    }

  }, [])

  return (
    <ModalWrapper
      maxWidth='sm'
      open={open}
    >
      <Box sx={{...centered, flexDirection: 'column', flex: 1}}>
        <Typography
          variant='h4'
          align='center'
          sx={{my: 2}}
        >
          {ReactHtmlParser(t('modal.scaCvaNotificationTitle'))}
        </Typography>
        <Typography
          variant='subtitle1'
          align='center'
          sx={{my: 2, color: colors.text.o75}}
        >
          {ReactHtmlParser(t('modal.scaCvaNotificationDescription'))}
        </Typography>
        <Box mt={2} display="flex">
          <LoadingButton
            fullWidth
            size='large'
            variant="contained"
            sx={{mr: 2}}
            onClick={() => handlerGoToSetting()}
          >
            {t('profile.goToSettings')}
          </LoadingButton>
          <Button
            variant='outlined'
            color='secondary'
            size="large"
            onClick={() => handleChange()}
          >
            {t('profile.skip')}
          </Button>
        </Box>
      </Box>
    </ModalWrapper>
  )
})
