/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-17 22:31:28
 * @modify date 2022-02-17 22:31:28
 */

import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, verticalCentered } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& button': {
          marginTop: `${theme.spacing(2)} !important`
        },
        [theme.breakpoints.up('md')]: {
          justifyContent: 'end'
        }
  },
  })
)
