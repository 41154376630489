import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {clone, cloneDeep, find, isEmpty} from "lodash";
import moment from "moment/moment";
import {observer} from "mobx-react-lite";
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from "@tanstack/react-table";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox, Collapse, Divider, SwipeableDrawer,
  FormControlLabel, IconButton, Stack, SvgIcon, Typography,
} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import { isMobile } from "react-device-detect"

import {useStores} from "models";
import {BasicTable, ModalWrapper, Pagination, Puller} from "components";
import {AdvanceSearch} from "pages/samples/components";
import {countryName, globalAction, optionsTranslation} from "utils";
import {colors} from "assets"
import {IconTotalScore} from "assets/images"

import {SubComponent} from "./sub-component"

interface AddSamplesModalProps {
  open: boolean
  onOpen: () => void
  onClose: () => void
  onSuccess: (samples) => void
}

const renderValue = (info) => {
  if (info.renderValue()) return info.renderValue()

  return '-'
}

const columnHelper = createColumnHelper<any>()

export const AddSamplesModal = observer(({ open, onOpen, onClose, onSuccess }: AddSamplesModalProps) => {
  const { t } = useTranslation()
  const {
    reviewSampleStore: { search: { setValue: setSearchValue },
      searchValues, samples, page, totalPage, getSamples, getChildSamples,
      setValue: setReviewSampleValue,
    },
    sampleShipmentStore: { getNewSamples },
    notificationStore
  } = useStores()

  const [temporaryAcidityAndSaltDescriptors, setTemporaryAcidityAndSaltDescriptors] = React.useState<any[]>([])
  const [temporaryBodyAndMouthfeelDescriptors, setTemporaryBodyAndMouthfeelDescriptors] = React.useState<any[]>([])
  const [temporaryFragranceDescriptors, setTemporaryFragranceDescriptors] = React.useState<any[]>([])
  const [temporaryFlavorAndAftertasteDescriptors, setTemporaryFlavorAndAftertasteDescriptors] = React.useState<any[]>([])
  const [temporaryDefectsDescriptors, setTemporaryDefectsDescriptors] = React.useState<any[]>([])
  const [searchAdvanceData,setSearchAdvanceData] = React.useState<any[]>([])

  const [expandedSearch, setExpandedSearch] = React.useState(false)
  const [temporaryListSearch, setTemporaryListSearch] = React.useState<any[]>([])
  const [expanded, setExpanded] =React.useState({})
  const [rowSelection, setRowSelection] = React.useState({})
  const [rowSelectionChild, setRowSelectionChild] = React.useState({})
  const [selectedSamples, setSelectedSamples] = React.useState<any[]>([])
  const [selectedChildSamples, setSelectedChildSamples] = React.useState({})

  const data = cloneDeep(samples)

  const onGetChildSample = async (index) => {
    try {
      notificationStore.setLoading(true)
      await getChildSamples(index)
    } finally {
      notificationStore.setLoading(false)
    }
  }

  const renderSubComponent = ({ row }) => {
    const { childSamples, uniqueToken } = row.original

    return (
      <SubComponent
        key={uniqueToken}
        token={uniqueToken}
        childSamples={childSamples}
        renderValue={renderValue}
        rowSelectionChild={rowSelectionChild}
        setRowSelectionChild={(token, value, rowSelectionSamples) => {
          const newSelection = cloneDeep(rowSelectionChild)
          const newSelectionSamples = cloneDeep(selectedChildSamples)
          if (isEmpty(value)) {
            delete newSelection[token]
            delete newSelectionSamples[token]
          } else {
            newSelection[token] = value
            newSelectionSamples[token] = rowSelectionSamples
          }

          setRowSelectionChild(newSelection)
          setSelectedChildSamples(newSelectionSamples)
        }}
      />
    )
  }

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: ({ table }) =>
        <Box textAlign="left">
          <FormControlLabel
            label={t('sample.samples.name')}
            control={
              <Checkbox
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
            }
          />
        </Box>,
      cell: info =>
        <Box textAlign="left">
          <FormControlLabel
            label={info.renderValue()}
            control={
              <Checkbox
                checked={info.row.getIsSelected()}
                onChange={info.row.getToggleSelectedHandler()}
              />
            }
          />
        </Box>,
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => <Box textAlign="left">{t('sample.samples.createdOn')}</Box>,
      cell: info => moment(info.renderValue()).format('DD/MM/YYYY'),
    }),
    columnHelper.accessor('species', {
      id: 'species',
      header: () => <Box textAlign="left">{t('sample.samples.species')}</Box>,
      cell: info => optionsTranslation('cuppingProtocol', info.renderValue(), true),
    }),
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left">{t('sample.samples.type')}</Box>,
      cell: info => optionsTranslation('sampleType', info.renderValue(), true),
    }),
    columnHelper.accessor('countryCode', {
      id: 'countryCode',
      header: () => <Box textAlign="left">{t('sample.samples.origin')}</Box>,
      cell: info => countryName(info.renderValue()),
    }),
    columnHelper.accessor('processName', {
      id: 'processName',
      header: () => <Box textAlign="left">{t('sample.samples.process')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('purchaseContractReference', {
      id: 'purchaseContractReference',
      header: () => <Box textAlign="left">{t('sample.samples.purchaseContract')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('salesContractReference', {
      id: 'salesContractReference',
      header: () => <Box textAlign="left">{t('sample.samples.salesContract')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('grade', {
      id: 'grade',
      header: () => <Box textAlign="left">{t('sample.samples.grade')}</Box>,
      cell: info => optionsTranslation('grades', info.renderValue(), true),
    }),
    columnHelper.accessor('numberOfSamples', {
      id: 'numberOfSamples',
      header: () => <Box textAlign="left">{t('sample.samples.numberOfSamples')}</Box>,
      cell: info => <Box textAlign="center">{info.renderValue()}</Box>,
    }),
    columnHelper.accessor('averageScore', {
      id: 'averageScore',
      header: () => <Box textAlign="left">{t('sample.samples.averageScore')}</Box>,
      cell: info => <Box textAlign="center">{info.renderValue()}</Box>,
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => "",
      cell: info => {
        const { numberOfSamples } = info.row.original
        if (numberOfSamples === 0) return null

        return (
          <Box textAlign="center">
            <IconButton
              size="small"
              color="primary"
              onClick={async () => {
                await onGetChildSample(info.row.index)
                info.row.toggleExpanded()
              }}
            >
              {info.row.getIsExpanded() ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        )
      },
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    state: { rowSelection, expanded },
    enableRowSelection: true,
    manualPagination: true,
    pageCount: totalPage,
    autoResetPageIndex: false,
    enableSubRowSelection: false,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.uniqueToken,
    manualExpanding: true,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: row => row.id,
    getCoreRowModel: getCoreRowModel(),
  })

  const handleChangeCategory = () => {
    temporaryListSearch.map(item =>
      Object.keys(searchValues).forEach(k => {
        if(k !== item )
          setTemporaryListSearch(current =>
            current.filter(data => {
              return data !== item;
            }),
          );
      })
    )
  }

  const onGetSamples = () => {
    globalAction(notificationStore, {
      action: async () => await getSamples()
    })
  }

  const onAddingSamples = () => {
    const childSamples = Object.keys(selectedChildSamples).reduce((acc: any[], key) => {
      return [...acc, ...selectedChildSamples[key]]
    }, [])

    setRowSelection({})
    setRowSelectionChild([])

    onSuccess(getNewSamples([...selectedSamples, ...childSamples]))
  }

  const onChangePage = (_, page) => {
    setExpanded({})
    setReviewSampleValue('page', page)
    onGetSamples()
  }

  const renderTitle = (
    <Box p={isMobile ? 2 : 0} mt={isMobile ? 2 : 0}>
      <Typography variant="h5">{t('shipment.modal.addSample')}</Typography>
      <Typography>{t('shipment.modal.selectSamplesToBeAdd')}</Typography>
    </Box>
  )

  const renderAdvanceSearch = (
    <AdvanceSearch
      isMobile={isMobile}
      expanded={expandedSearch}
      temporaryDefectsDescriptors = {temporaryDefectsDescriptors}
      temporarySearchAdvanceData = {searchAdvanceData}
      temporaryFlavorAndAftertasteDescriptors = {temporaryFlavorAndAftertasteDescriptors}
      temporaryFragranceDescriptors = {temporaryFragranceDescriptors}
      temporaryBodyAndMouthfeelDescriptors = {temporaryBodyAndMouthfeelDescriptors}
      temporaryAcidityAndSaltDescriptors = {temporaryAcidityAndSaltDescriptors}
      temporaryListSearch = {temporaryListSearch}
      addTemporarySearchAdvanceData={(item)=>{
        setSearchAdvanceData(item)
      }}
      addTemporaryDefectsDescriptors={(descriptors) => {
        setTemporaryDefectsDescriptors(descriptors)
      }}
      addTemporaryFlavorAndAftertasteDescriptors={(descriptors) => {
        setTemporaryFlavorAndAftertasteDescriptors(descriptors)
      }}
      addTemporaryFragranceDescriptors={(descriptors) => {
        setTemporaryFragranceDescriptors(descriptors)
      }}
      addTemporaryBodyAndMouthfeelDescriptors={(descriptors) => {
        setTemporaryBodyAndMouthfeelDescriptors(descriptors)
      }}
      addTemporaryAcidityAndSaltDescriptors={(descriptors) => {
        setTemporaryAcidityAndSaltDescriptors(descriptors)
      }}
      addTemporaryListSearch={(data) => {
        setTemporaryListSearch(data)
      }}
      onSearch={() => {
        setSearchValue('generalSearch', false)
        setReviewSampleValue('page', 1)
        handleChangeCategory()
        onGetSamples()
      }}
      onCancel={() => {
        setExpandedSearch(false)
      }}
    />
  )

  const renderAdvanceSearchButton = (params?) => {
    return (
      <Button
        size='large'
        variant='contained'
        onClick={() => {
          setExpandedSearch(!expandedSearch)
          handleChangeCategory()
        }}
        {...params}
      >
        {t('sample.advanceSearch')}
      </Button>
    )
  }

  const renderSubmitButton = (
    <Stack direction="row" spacing={2} justifyContent="end" mt={isMobile ? 2 : 3}>
      <Button
        variant="contained"
        sx={{width: 200}}
        onClick={onAddingSamples}
      >
        {t(`shipment.button.submit`)}
      </Button>
      <Button
        variant="outlined"
        sx={{width: 200}}
        onClick={onClose}
      >
        {t("common.cancel")}
      </Button>
    </Stack>
  )

  const renderWeb = (
    <ModalWrapper open={open}>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        {renderTitle}
        {renderAdvanceSearchButton()}
      </Stack>

      <Collapse in={expandedSearch} timeout="auto" unmountOnExit sx={{mb: 2}}>
        {renderAdvanceSearch}
      </Collapse>

      <BasicTable
        table={table}
        page={page}
        totalPage={totalPage}
        onChangePage={onChangePage}
        renderSubComponent={renderSubComponent}
      />

      {renderSubmitButton}
    </ModalWrapper>
  )

  const renderMobile = (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
        }
      }}
    >
      <Puller />
      {renderTitle}

      {data.length > 0 && (
        <Box sx={{maxHeight: '50vh', overflowY: 'auto', borderTop: `1px solid ${colors.magnolia}`}}>
          {data.map((sample) => (
            <Accordion
              key={sample.id}
              sx={{boxShadow: 'none'}}
              className="accordion"
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Stack direction="row" spacing={0.5} mr={1} sx={{alignItems: "center"}}>
                  <Checkbox
                    onChange={(e) => {
                      e.stopPropagation()

                      if (e.target.checked) {
                        setRowSelection({
                          ...rowSelection,
                          [sample.uniqueToken]: true
                        })
                      } else {
                        const updatedRowSelection = { ...rowSelection }
                        delete updatedRowSelection[sample.uniqueToken]
                        setRowSelection(updatedRowSelection)
                      }
                    }}
                  />
                  <Box>
                    <Typography variant="body1">{sample.name}</Typography>
                    <Stack direction="row" spacing={0.5} divider={<Divider orientation="vertical" flexItem sx={{borderColor: colors.text.caption}} />}>
                      <Box display="flex" alignItems="center">
                        <SvgIcon component={IconTotalScore} inheritViewBox color="primary" sx={{mr: 0.5}} />
                        <Typography variant="h6">{sample.averageScore || '-'}</Typography>
                      </Box>
                      <Typography>{sample.purchaseContractReference || '-'}</Typography>
                    </Stack>
                  </Box>
                </Stack>
              </AccordionSummary>
              <AccordionDetails className="content">
                <table cellPadding={5} className="mobile-content">
                  <tbody>
                  <tr>
                    <td>{t('sample.samples.createdOn')}</td>
                    <td>{moment(sample.createdAt).format('DD/MM/YYYY')}</td>
                  </tr>
                  <tr>
                    <td>{t('sample.samples.species')}</td>
                    <td>{optionsTranslation('cuppingProtocol', sample.species, true)}</td>
                  </tr>
                  <tr>
                    <td>{t('sample.samples.type')}</td>
                    <td>{optionsTranslation('sampleType', sample.sampleType, true)}</td>
                  </tr>
                  <tr>
                    <td>{t('sample.samples.origin')}</td>
                    <td>{countryName(sample.countryCode)}</td>
                  </tr>
                  <tr>
                    <td>{t('sample.samples.process')}</td>
                    <td>{sample.processName || '-'}</td>
                  </tr>
                  <tr>
                    <td>{t('sample.samples.salesContract')}</td>
                    <td>{sample.salesContractReference || '-'}</td>
                  </tr>
                  <tr>
                    <td>{t('sample.samples.grade')}</td>
                    <td>{optionsTranslation('grades', sample.grade, true)}</td>
                  </tr>
                  <tr>
                    <td>{t('sample.samples.numberOfSamples')}</td>
                    <td>{sample.numberOfSamples}</td>
                  </tr>
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}

      {totalPage > 0 &&
        <Pagination
          page={page}
          count={totalPage}
          onChange={onChangePage}
          siblingCount={0}
          boundaryCount={2}
          boxStyle={{mt: 2, mb:2}}
        />
      }
      <Box p={2} sx={{borderTop: `1px solid ${colors.magnolia}`}}>
        {renderAdvanceSearchButton({fullWidth: true})}
        {renderSubmitButton}
      </Box>
    </SwipeableDrawer>
  )

  useEffect(() => {
    const tokens = Object.keys(rowSelection)
    const newSelectedSamples = tokens.map(token => {
      let sample = find(selectedSamples, ['uniqueToken', token])

      if (!sample) {
        sample = find(samples, ['uniqueToken', token])
      }

      return clone(sample)
    })

    setSelectedSamples(newSelectedSamples)
  }, [rowSelection])

  useEffect(() => {
    // resetSearch()

    (async () => await onGetSamples())()
  }, [])

  if (isMobile) {
    return expandedSearch ? renderAdvanceSearch : renderMobile
  }

  return renderWeb
})
