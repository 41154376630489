/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-22 19:42:42
 * @modify date 2022-04-22 19:42:42
 * @desc [description]
 */

import { applySnapshot, getSnapshot, flow } from "mobx-state-tree"
import { ApiResult } from "services/api"
import { samplePreProcessor } from "models/sample"
import {DATA_PER_PAGE} from "config/env";
import {ceil, chain, isEmpty, snakeCase} from "lodash";
import fileDownload from "js-file-download";
import {snakeCasePayload} from "../../utils";

export const withSampleActions = (self: any) => ({
  actions: {
    setMasterSample(masterSample) {
      const newMasterSample = samplePreProcessor(masterSample)

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        masterSample: newMasterSample
      })
      return newMasterSample
    },
    setMasterSampleDetail(masterSample) {
      const newMasterSample = {
        ...samplePreProcessor(masterSample),
        aggregatedSpiderWeb: JSON.stringify(masterSample.aggregatedSpiderWeb),
        aggregatedCloudWords: JSON.stringify(masterSample.aggregatedCloudWords),
        aggregatedFlavorWheels: JSON.stringify(masterSample.aggregatedFlavorWheels),
      }

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        masterSampleDetail: newMasterSample
      })
    },
    saveMasterSample: flow(function * () {
      try {
        const payload = {
          ...self.masterSample,
          id: self.masterSample.uniqueToken,
        }

        const res: ApiResult = yield self.environment.masterSampleApi.save(payload)

        if (res.kind === "ok") {
          self.setMasterSample(res.data)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getMasterSample: flow(function * (id: string, isDetail = false) {
      try {
        const additionalPath = isDetail ? '/review' : ''
        const res: ApiResult = yield self.environment.masterSampleApi.find(id, additionalPath)

        if (res.kind === "ok") {
          if (isDetail) {
            self.setMasterSampleDetail(res.data)
            return
          }

          return self.setMasterSample(res.data)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getChildSample: flow(function * (masterId: string, childId: string) {
      try {
        const additionalPath = `show_child_sample/${childId}`
        const res: ApiResult = yield self.environment.masterSampleApi.find(masterId, additionalPath)

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            childSample: samplePreProcessor(res.data)
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw error
      }
    }),
    saveChildSample: flow(function * () {
      try {
        const payload = {
          ...self.childSample,
          id: self.childSample.uniqueToken,
        }

        yield self.environment.masterSampleApi.save(payload)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw error
      }
    }),
    searchMasterSample: flow(function * () {
      try {
        const query = { ['search[name]']: self.searchQuery }
        const res: ApiResult = yield self.environment.masterSampleApi.all(query)

        if (res.kind === "ok") {
          const masterSamples = res.data.map((masterSample) => samplePreProcessor(masterSample))

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            searchMasterSamples: masterSamples
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getSearchMasterSamples: flow(function * () {
      try {
        let search = {}
          chain(self.search)
            .omitBy(isEmpty)
            .assign({scoredByMe: self.search.scoredByMe, generalSearch: self.search.generalSearch})
            .forEach((value, key) => { search[`search[${snakeCase(key)}]`] = value})
            .value()

        const payload: any = {
          ...search,
          "size": DATA_PER_PAGE,
          "page[number]": self.page,
        }
        const res: ApiResult = yield self.environment.masterSampleApi.all(payload)

        if (res.kind === "ok") {
          const masterSamples = res.data.map((masterSample) => samplePreProcessor(masterSample))

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            searchMasterSamples: masterSamples
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getMasterSamples: flow(function * () {
      try {
        const res: ApiResult = yield self
          .environment
          .masterSampleApi
          .all({
            "size": DATA_PER_PAGE,
            "page[number]": self.page,
          })

        if (res.kind === "ok") {
          const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            totalPage,
            masterSamples: res.data.map(sample => samplePreProcessor(sample))
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    removeMasterSample: flow(function * (id: string) {
      try {
        const res: ApiResult = yield self.environment.masterSampleApi.remove(id)

        if (res.kind === "ok") {
          yield self.getMasterSamples()
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getCuppingSessionSamples: flow(function * (id: string) {
      try {
        const additionalPath = 'samples'
        const res: ApiResult = yield self.environment.masterSampleApi.find(id, additionalPath)

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            cuppingSessionSamples: res.data
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getMasterSampleShipment: flow(function * (id: string) {
      try {
        const additionalPath = 'shipments'
        const res: ApiResult = yield self.environment.masterSampleApi.find(id, additionalPath,{
          "size": DATA_PER_PAGE,
          "page[number]": self.page,
        })

        if (res.kind === "ok") {
          const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            masterSampleShipment: res.data,
            totalPage
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    exportMasterSample: flow(function * (ids?: string[]) {
      try {
				let query = {}

	      if (ids) {
	        query = {
	          ['search[ids]']: ids.join(',')
	        }
	      }

        const additionalPath = 'download_csv'
        const response = yield self.environment.masterSampleApi.export(query , additionalPath)
        fileDownload(response.data, response.name)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    saveAggregate: flow(function * (aggregates: any[]) {
      try {
        const payload = {
          aggregates: aggregates.map(aggregate => snakeCasePayload(aggregate))
        }
        yield self.environment.aggregatesApi.save(payload)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }
})
