export const basketCodes = [
  {code: '911003', basket: 'OM 2', value: '911003 - OM 2'},
  {code: '911004', basket: 'OM 3', value: '911004 - OM 3'},
  {code: '912002', basket: 'STS 2', value: '912002 - STS 2'},
  {code: '912003', basket: 'STS 3', value: '912003 - STS 3'},
  {code: '913002', basket: 'NAT 1', value: '913002 - NAT 1'},
  {code: '913003', basket: 'NAT 2', value: '913003 - NAT 2'},
  {code: '914002', basket: 'ROB 2', value: '914002 - ROB 2'},
  {code: '914003', basket: 'ROB 3', value: '914003 - ROB 3'},
  {code: '914004', basket: 'ROB 4', value: '914004 - ROB 4'},
  {code: '911005', basket: 'Colombia', value: '911005 - Colombia'},
  {code: '911012', basket: 'Kenya 2', value: '911012 - Kenya 2'},
  {code: '911007', basket: 'ETHIOPIA WASHED', value: '911007 - ETHIOPIA WASHED'},
  {code: '913001', basket: 'Ethiopia NAT', value: '913001 - Ethiopia NAT'},
  {code: '913005', basket: 'Kenya 3', value: '913005 - Kenya 3'},
  {code: '916012', basket: 'RFA OM 2', value: '916012 - RFA OM 2'},
  {code: '916030', basket: 'RFA OM 3', value: '916030 - RFA OM 3'},
  {code: '916003', basket: 'RFA STS 2', value: '916003 - RFA STS 2'},
  {code: '916004', basket: 'RFA STS 3', value: '916004 - RFA STS 3'},
  {code: '916007', basket: 'RFA NAT 1', value: '916007 - RFA NAT 1'},
  {code: '916008', basket: 'RFA NAT 2', value: '916008 - RFA NAT 2'},
  {code: '916006', basket: 'RFA ROB 2', value: '916006 - RFA ROB 2'},
  {code: '916015', basket: 'RFA ROB 3', value: '916015 - RFA ROB 3'},
  {code: '916013', basket: 'RFA Colombia', value: '916013 - RFA Colombia'},
  {code: '916002', basket: 'RFA Santos 1', value: '916002 - RFA Santos 1'},
  {code: '916050', basket: 'RFA Naturals Plus', value: '916050 - RFA Naturals Plus'},
  {code: '916014', basket: 'RFA Kenya 2', value: '916014 - RFA Kenya 2'},
  {code: '916018', basket: 'RFA ETHIOPIA WASHED', value: '916018 - RFA ETHIOPIA WASHED'},
  {code: '917002', basket: 'FT MILDS', value: '917002 - FT MILDS'},
  {code: '917004', basket: 'FT STS', value: '917004 - FT STS'},
  {code: '917005', basket: 'FT ROB', value: '917005 - FT ROB'},
  {code: '917001', basket: 'FT Colombia', value: '917001 - FT Colombia'},
  {code: '917012', basket: 'FT Kenya', value: '917012 - FT Kenya'},
  {code: '911013', basket: 'Peru Org', value: '911013 - Peru Org'},
  {code: '911014', basket: 'Honduras Org', value: '911014 - Honduras Org'},
  {code: '911015', basket: 'Colombia Org', value: '911015 - Colombia Org'},
  {code: '911010', basket: 'Milds Org', value: '911010 - Milds Org'},
  {code: '916011', basket: 'RFA Milds Org', value: '916011 - RFA Milds Org'},
  {code: '917010', basket: 'FT Peru Org', value: '917010 - FT Peru Org'},
  {code: '917011', basket: 'FT Honduras Org', value: '917011 - FT Honduras Org'},
  {code: '917003', basket: 'FT MILDS Org', value: '917003 - FT MILDS Org'},
  {code: '917006', basket: 'FT ROB  Org', value: '917006 - FT ROB  Org'},
  {code: '918007', basket: 'Water RFA Dec  MILDS', value: '918007 - Water RFA Dec  MILDS'},
  {code: '918029', basket: 'Water RFA Dec Colombia ', value: '918029 - Water RFA Dec Colombia '},
  {code: '918015', basket: 'Water Dec MILdS', value: '918015 - Water Dec MILdS'},
  {code: '918022', basket: 'RFA Dec MILDS', value: '918022 - RFA Dec MILDS'},
  {code: '918024', basket: 'RFA Dec NAT G', value: '918024 - RFA Dec NAT G'},
  {code: '918009', basket: 'RFA Dec ROB G', value: '918009 - RFA Dec ROB G'},
  {code: '918001', basket: 'Dec MILDS', value: '918001 - Dec MILDS'},
  {code: '918002', basket: 'Dec NAT G', value: '918002 - Dec NAT G'},
  {code: '918003', basket: 'Dec ROB G', value: '918003 - Dec ROB G'},
  {code: '918021', basket: 'Dec ROB LOW', value: '918021 - Dec ROB LOW'},
  {code: '918013', basket: 'FT Dec NAT ', value: '918013 - FT Dec NAT '},
  {code: '918014', basket: 'FT Dec ROB', value: '918014 - FT Dec ROB'},
]
