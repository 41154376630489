import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Stack, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import {ModalWrapper, ShipmentOrderForm} from "components";
import {pick} from "lodash";

export const ShipmentInfoModal = (props) => {
  const { t } = useTranslation()
  const { id, shipment, open, onClose, setShipment, onSubmit } = props

  const [shipmentInfo, setShipmentInfo] = useState<any>()
  const isComplete = shipmentInfo?.customer && shipmentInfo?.recipient && shipmentInfo?.requestedByEmail && shipmentInfo?.recipientEmails

  const setValue = (key, value) => {
    setShipmentInfo({ ...shipmentInfo, [key]: value })
  }

  const onSetShipmentInfo = () => {
    setShipment({ ...shipment, ...shipmentInfo })
    onSubmit()
  }

  useEffect(() => {
    if (open) {
      setShipmentInfo(pick(shipment, [
        'customer', 'streetAddress', 'recipient', 'streetAddress2', 'country', 'state', 'city', 'zipCode',
        'isUrgentDelivery', 'requestedByEmail', 'recipientEmails', 'traderEmails', 'qualityControlEmails', 'approvalEmails',
        'estimatedTimeArrival','phone'
      ]))
    }
  }, [open])

  return (
    <ModalWrapper open={open} onClose={onClose} maxWidth="md" containerProps={{ sx: { p: '32px !important' } }}>
      <Typography variant="h5" sx={{position: 'absolute', top: '35px'}}>
        {t(`shipment.editInformation`)}
      </Typography>

      <Box mt={2}>
        <ShipmentOrderForm {...shipmentInfo} setValue={setValue} showDifferentProps />
      </Box>

      <Stack direction="row" spacing={2} justifyContent="end" mt={4}>
        <LoadingButton
          variant="contained"
          sx={{width: 200}}
          disabled={!isComplete}
          onClick={onSetShipmentInfo}
        >
          {t(`shipment.button.submit`)}
        </LoadingButton>
        <Button
          variant="outlined"
          sx={{width: 200}}
          onClick={onClose}
        >
          {t("common.cancel")}
        </Button>
      </Stack>
    </ModalWrapper>
  )
}
