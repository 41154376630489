import { v4 as uuidv4 } from 'uuid'
import {flatMapDeep, find, isEmpty, isNumber, upperFirst, map, chain, some, isNull} from 'lodash'
import { colors } from 'assets'
import { allDescriptor } from 'constants/all-descriptors'
import { scaDescriptorList } from "constants/sca-descriptors"
import { scaDefectDescriptors } from 'constants/sca-defect-descriptors'
import { flavorDescriptorList } from "constants/flavor-descriptors";

const pickTextColorBasedOnBgColorSimple = (bgColor) => {
  var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
    colors.text.primary : 'white';
}

const getDescriptor = (descriptor) => {
  if(!descriptor.groups || !descriptor.groups.length) return descriptor

  return [descriptor, flatMapDeep(descriptor.groups, getDescriptor)]
}

export const descriptorSelection = (list: Array<any>, values: Array<string>) => {
  if (isEmpty(values)) return []

  const flatList = flatMapDeep(list, getDescriptor)

  return values.map(v => {
    const descriptor = find(flatList, ['label', v])
    if (descriptor) return descriptor

    return {id: uuidv4(), label: v}
  })
}

export const descriptorTranslation = (language: string, descriptor: any) => {
  if (language === 'en' || !isNumber(descriptor.id) || !descriptor[`label_${language}`]) return upperFirst(descriptor.label)

  return upperFirst(descriptor[`label_${language}`])
}

export const descriptorColor = (descriptor: any, isActive: boolean = true) => {
  if (!isActive) return {mb: 1, mr: 1}

  const bg = descriptor.color || colors.text.o25

  return {
    mb: 1,
    mr: 1,
    fontWeight: 500,
    backgroundColor: bg,
    color: pickTextColorBasedOnBgColorSimple(bg),
    '& svg': {
      color: 'inherit !important'
    }
  }
}

export const setDescriptor = (descriptors) => {
  return map(descriptors, 'label')
}

export const textDescriptorsTranslation = (language: string, descriptors: any) => {
  return chain(descriptors).map(language === 'en' ? 'label' : `label_${language}`).join(',').value()
}

export const allDescriptorTranslation = (language: string, descriptors: any) => {
  const flatList = flatMapDeep(allDescriptor, getDescriptor)

  return chain(descriptors)
            .split(', ')
            .map((label) => {
              const descriptor = find(flatList, ['label', label])

              if (descriptor) {
                return language === 'en' ? descriptor.label : descriptor[`label_${language}`]
              }

              return label
            })
            .join(', ')
            .value()
}

const flatListScaDescriptors = flatMapDeep(scaDescriptorList, getDescriptor)
const defectLabelScaDescriptors = map(scaDefectDescriptors, 'label')
export const translateScaDescriptor = (language: string, labels: string[]) => {
  const isDefect = some(labels, label => defectLabelScaDescriptors.includes(label))

  return chain(labels)
    .map((label) => {
      const descriptor = find(isDefect ? scaDefectDescriptors : flatListScaDescriptors, ['label', label])

      if (descriptor) {
        return upperFirst(language === 'en' ? descriptor.label : descriptor[`label_${language}`])
      }

      return label
    })
    .join(' | ')
    .value()
}

export const translateDescriptor = (language: string, label: string) => {
  const flatList = flatMapDeep(allDescriptor, getDescriptor)

  const descriptor = find(flatList, ['label', label])
  if (descriptor) {
    return language === 'en' ? descriptor.label : descriptor[`label_${language}`]
  }

  return label
}

export const translateDescriptors = (language: string, labels: string[]) => {
  const flatList = flatMapDeep(allDescriptor, getDescriptor)

  return chain(labels)
    .map((label) => {
      const descriptor = find(flatList, ['label', label])

      if (descriptor) {
        return language === 'en' ? descriptor.label : descriptor[`label_${language}`]
      }

      return label
    })
    .value()
}

export const flatListDescriptors = (list: any[]) => {
  return flatMapDeep(list, getDescriptor)
}

export const flavorDescriptorTranslation = (language: string, descriptors: any) => {
	const flatList = flatMapDeep(flavorDescriptorList, getDescriptor)

	return chain(descriptors)
		.map((label) => {
			const descriptor = find(flatList, ['label', label])

			if (descriptor) {
				return upperFirst(language === 'en' ? descriptor.label : descriptor[`label_${language}`])
			}

			return label
		})
		.join(', ')
		.value()
}
