import React, {useEffect, useState} from "react";
import {cloneDeep} from "lodash";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Stack,
  SvgIcon,
  Typography
} from "@mui/material";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import {Edit, ExpandMore, Visibility} from "@mui/icons-material";
import {isMobile} from "react-device-detect";

import {useStores} from "models";
import {BasicTable} from "components";
import {ApproveReject, ApproveRejectButton} from "modules";
import {emptyShipment, IconTotalScore} from "assets/images";
import {optionsTranslation} from "utils";

import {SampleInformationModal} from "../index";
import {useStyles} from "pages/sample-details/sample-details.styles";

const columnHelper = createColumnHelper<any>()

export const Shipments = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()

  const {
    masterSampleStore: {
      masterSampleDetail: { uniqueToken },
      page, totalPage,getChildSample, masterSampleShipment, getMasterSampleShipment, setValue: setMasterSampleValue },
    notificationStore
  } = useStores()

  const [openConfirmApprove, setOpenConfirmApprove] = useState(false)
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [openQcReason, setOpenQcReason] = useState(false)
  const [transactionItemId, setTransactionItemId] = useState('')
  const [transactionId, setTransactionId] = useState('')
  const [loading, setLoading] = React.useState(false)
  const [openSampleInformationModal, setOpenSampleInformationModal] = useState(false)

  const data = cloneDeep(masterSampleShipment)

  const onGetSample = async (masterSampleUniqueToken: string, sampleUniqueToken: string) => {
    try {
      notificationStore.setLoading(true)
      await getChildSample(masterSampleUniqueToken, sampleUniqueToken)
      setOpenSampleInformationModal(true)
    } finally {
      notificationStore.setLoading(false)
    }
  }

  const renderApprovalButton = (info) => {
    const { sampleTransactionUniqueToken, sampleTransactionItemUniqueToken } = info.row.original
    const setUniqueToken = () => {
      setTransactionId(sampleTransactionUniqueToken)
      setTransactionItemId(sampleTransactionItemUniqueToken)
    }

    return (
      <Stack direction="row" spacing={1} justifyContent="center">
        <ApproveRejectButton
          row={info.row.original}
          onApprove={(hasApproval) => {
            setUniqueToken()
            if (hasApproval) setOpenQcReason(true)
            setOpenConfirmApprove(true)
          }}
          onReject={(hasApproval) => {
            setUniqueToken()
            if (hasApproval) setOpenQcReason(true)
            setOpenRejectModal(true)
          }}
          buttonProps={{fullWidth: isMobile}}
        />
      </Stack>
    )
  }

  const columns = [
    columnHelper.accessor('salesContractReference', {
      id: 'salesContractReference',
      header: () => <Box textAlign="left">{t('shipment.table.salesContract')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('customer', {
      id: 'customer',
      header: () => <Box textAlign="left">{t('shipment.table.customer')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('sampleLocation', {
      id: 'sampleLocation',
      header: () => <Box textAlign="left">{t('shipment.table.sampleLocation')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left">{t('shipment.table.sampleType')}</Box>,
      cell: info => optionsTranslation('sampleType', info.renderValue()),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => <Box textAlign="left">{t('shipment.table.status')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('courierName', {
      id: 'courierName',
      header: () => <Box textAlign="left">{t('shipment.table.courier')}</Box>,
      cell: info =>info.renderValue(),
    }),
    columnHelper.accessor('expand', {
      id: 'expand',
      header: () => t('shipment.table.action'),
      cell: info => renderButtons(info),
    }),
  ]

  const renderButtons = (info) => {
    const { sampleUniqueToken, masterSampleUniqueToken, sampleTransactionUniqueToken} = info.row.original

    return (
      <Stack direction={isMobile ? "column" : "row"} spacing={1} justifyContent="center">
        <Stack direction="row" spacing={1} justifyContent="center">
          <Button
            fullWidth={isMobile}
            size='small'
            variant={isMobile ? "outlined" : "contained"}
            onClick={() => navigate(`/sample-shipments/${sampleTransactionUniqueToken}`)}
            startIcon={<Visibility />}
            className={classes.button}
          >
            {isMobile && t('sample.button.preview')}
          </Button>
          <Button
            fullWidth={isMobile}
            variant="contained"
            onClick={() => onGetSample(masterSampleUniqueToken,sampleUniqueToken)}
            startIcon={<Edit />}
            className={classes.button}
          >
            {isMobile && t('common.edit')}
          </Button>
        </Stack>

        {renderApprovalButton(info)}
      </Stack>
    )
  }

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const renderMobile = (row) => {
    const info = { row: { original: row } }

    return (
      <Accordion key={row.id} className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box>
            <Typography variant='h6'>{row.salesContractReference}</Typography>
            <Typography variant="body2" color="#999FA4">{row.customer}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className="content">
          <table cellPadding={2}>
            <tr>
              <td>{t('shipment.table.sampleLocation')}</td>
              <td>{row.sampleLocation}</td>
            </tr>
            <tr>
              <td>{t('shipment.table.sampleType')}</td>
              <td>{optionsTranslation('sampleType', row.sampleType)}</td>
            </tr>
            <tr>
              <td>{t('shipment.table.status')}</td>
              <td>{optionsTranslation('cuppingProtocol', row.status)}</td>
            </tr>
            <tr>
              <td>{t('shipment.table.courier')}</td>
              <td>{row.courierName}</td>
            </tr>
          </table>

          {renderButtons(info)}
        </AccordionDetails>
      </Accordion>
    )
  }

  const renderContent = () => {
    if (isMobile) {
      return data.map((item) => renderMobile(item))
    }

    return (
      <BasicTable
        table={table}
        page={page}
        totalPage={totalPage}
        onChangePage={onChangePage}
        isFetching={loading}
      />
    )
  }

  const onChangePage = (_, page) => {
    setMasterSampleValue("page",page)
  }

  const onGetMasterShipment = () => {
    (async () => {
      try {
        setLoading(true)
        await getMasterSampleShipment(uniqueToken)
      } finally {
        setLoading(false)
      }
    })()
  }

  useEffect(() => {
    onGetMasterShipment()
  }, [page])

  return (
    <Box py={4} textAlign="center">
      <Box sx={{p: 1}}>
        {masterSampleShipment.length > 0 ? renderContent()
          : (
            <Box py={4} textAlign="center">
              <img src={emptyShipment} />
              <Typography mt={2} variant="h5">{t('sample.detail.emptyShipment')}</Typography>
            </Box>
          )}
      </Box>
      <SampleInformationModal
        open={openSampleInformationModal}
        onClose={() => setOpenSampleInformationModal(false)}
      />

      <ApproveReject
        id={transactionId}
        transactionItemId={transactionItemId}
        setTransactionItemId={setTransactionItemId}
        openQcReason={openQcReason}
        setOpenQcReason={setOpenQcReason}
        openConfirmApprove={openConfirmApprove}
        setOpenConfirmApprove={setOpenConfirmApprove}
        openRejectModal={openRejectModal}
        setOpenRejectModal={setOpenRejectModal}
        onSuccess={onGetMasterShipment}
      />
    </Box>
  )
}
