export const attributes = [
  'sampleId', 'name', 'sampleType', 'species', 'receivedOn', 'cropYear', 'countryCode', 'producerName', 'bagWeight',
  'varietalsTags', 'numberOfBag', 'referenceNumber', 'grade', 'processName', 'supplierName', 'shippingInfo',
  'notesAndRemarks', 'waterActivity', 'moisture', 'mass', 'volume', 'density', 'status', 'farmerNotes', 'storySubject',
  'storyDescription', 'samplesToShare', 'title', 'to', 'message', 'isScoreTarget', 'numberOfSamples', 'cuppingSessionUniqueToken',
  'coffeeType', 'purchaseContractReference', 'salesContractReference','originGrade','warehouseReference','sampleReceptionDate',
  'temperature', 'protocolForm', 'counterpartyType','counterpartyReferences', 'customer', 'customerCode', 'description', 'receivedWeight',
  'strategy','sampleLocation','fulfillmentType','cargoNumber','containerNumber','lotNumberOrIcoMarks', 'type','isDecaf', 'courierName',
  'trackingNumber', 'shipmentDate', 'basketCode', 'otaTestValue', 'contractWeight', 'cuppingProtocol', 'harvest', 'externalIdentification', 'sampleReference',
  'dateDispatch', 'dateArrival', 'dateResults', 'certification', 'masterSampleIds', 'purchaseGrade'
]
