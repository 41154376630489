import React from "react";
import {flexRender, Table as TabelTanstack, Column} from "@tanstack/react-table";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableProps, TableRow, TextField} from "@mui/material";
import TablePagination from '@mui/material/TablePagination'

import { useStyles } from "./filter-table.styles";
import FilterTablePaginationActions from "./filter-table-pagination-action";

interface FilterTableProps {
  table: any
  page?: number
  totalPage?: number
  paginationEnable?: boolean
  onChangePage?: (event: React.ChangeEvent<unknown>, value: number) => void
  isFetching?: boolean
  renderSubComponent?: (props: { row }) => React.ReactElement
  tableProps?: TableProps
}

function Filter({
    column,
    table,
  }: {
    column: Column<any, unknown>
    table: TabelTanstack<any>
  }) {
    const firstValue = table
      .getPreFilteredRowModel()
      .flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()

    const sortedUniqueValues = React.useMemo(
      () =>
        typeof firstValue === 'number'
          ? []
          : Array.from(column.getFacetedUniqueValues().keys()).sort(),
      [column.getFacetedUniqueValues()]
    )

    switch (typeof firstValue) {
      case "number":
        return (
          <>
            <div className="flex space-x-2">
              <DebouncedInput
                type="number"
                value={(columnFilterValue ?? "") as number}
                onChange={(value) => column.setFilterValue(value)}
                placeholder={`0`}
                className="w-24 border shadow rounded"
              />
            </div>
          </>
        );
      case "string":
      default:
        return (
          <>
            <div className="flex space-x-2">
              <datalist id={column.id + "list"}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                  <option value={value} key={value} />
                ))}
              </datalist>
              <DebouncedInput
                type="text"
                value={(columnFilterValue ?? "") as string}
                onChange={(value) => column.setFilterValue(value)}
                placeholder={`Select`}
                list={column.id + "list"}
              />
              <div className="h-1" />
            </div>
          </>
        );
    }
  }

  // A debounced input react component
  function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
  }: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
  } & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value)
      }, debounce)

      return () => clearTimeout(timeout)
    }, [value])

    return (
      <TextField
      type={props.type}
      placeholder={props.placeholder}
      InputProps={{
        inputProps: {
          list: props.list
    }
      }}
      size='small'
      value={value} onChange={e => setValue(e.target.value)}
    />
    )
  }

export const FilterTable = (props: FilterTableProps) => {
  const { table, isFetching, tableProps, paginationEnable } = props

  const { pageSize, pageIndex } = table.getState().pagination

  const classes = useStyles()

  return (
    <Box>
      <TableContainer>
      <Table {...tableProps}>
        <TableHead>
          {table.getHeaderGroups().map(headerGroup => {
            return(
            <>
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <TableCell key={header.id} align="center" className={classes.tableRightBorder}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </TableCell>
              ))}
            </TableRow>
              <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <TableCell key={header.id} align="center" className={classes.tableRightBorderWithBackground}>
                 {header.column.getCanFilter() ? (
                      <Filter column={header.column} table={table} />
                  ) : null}
                </TableCell>
              ))}
            </TableRow>
            </>
            )
        })}
        </TableHead>
        <TableBody>
          {!isFetching && table.getRowModel().rows.map(row => {

            return (
              <React.Fragment key={row.id}>
                <TableRow >
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id} className={classes.tableRightBorder}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              </React.Fragment>
            )
          })}
        </TableBody>
      </Table>
      </TableContainer>

      {paginationEnable &&
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
          component="div"
          count={table.getFilteredRowModel().rows.length}
          rowsPerPage={pageSize}
          page={pageIndex}
          onPageChange={(_, page) => {
            table.setPageIndex(page)
          }}
          onRowsPerPageChange={e => {
            const size = e.target.value ? Number(e.target.value) : 10
            table.setPageSize(size)
          }}
          ActionsComponent={FilterTablePaginationActions}
          className={classes.tableTopBorder}
        />
      }

    </Box>
  )
}
