import React from 'react'
import {observer} from "mobx-react-lite"
import {useTranslation} from "react-i18next"
import {camelCase, isUndefined, range, remove, toString} from "lodash"
import {
	Autocomplete,
	Box, Checkbox,
	Grid, IconButton, InputAdornment, ListItemText,
	MenuItem, Popover,
	Select,
	TextField,
	Typography,
} from "@mui/material"
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'
import {HelpOutline, HelpOutlineOutlined} from "@mui/icons-material"
import {isMobile} from "react-device-detect"

import { useStores } from "models"
import {CourierSelection, FormInput, LightTooltip} from "components"
import {verticalCentered} from "assets"
import {CuppingProtocolForm} from "modules"
import {countries, countryName, errorMessage, errorStatus, optionsTranslation, str2bool} from "utils"
import {specieses, sampleTypes, grades} from 'constants/form'
import { basketCodes } from 'constants/basket-codes'

import { useStyles } from "./sample-form.styles"
import {SampleFormProps} from "./sample-form.props"

export const SampleForm = observer((props: SampleFormProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    id, name, sampleType = '', species = '', receivedOn, cropYear, countryCode, producerName, bagWeight,
    varietalsTags, numberOfBag, grade, processName, supplierName, notesAndRemarks, waterActivity, density,
    moisture, mass, volume, purchaseContractReference, salesContractReference, warehouseReference, cuppingProtocol,
    customer, customerCode, description, temperature, receivedWeight, masterId, errors, strategy, harvest, externalIdentification, sampleReference,
    sampleLocation, cargoNumber, containerNumber, lotNumberOrIcoMarks, courierName, trackingNumber, shipmentDate,
    isDecaf, basketCode, otaTestValue, contractWeight, dateDispatch, dateArrival, dateResults, certification,
    setValue, disableSampleType, canEditPurchaseContractReference, disablePurchaseContract, comboCuppingFlag,
    isSampleInformation, referenceNumber, purchaseGrade
  } = props

  const {
    companyStore: { isSucafinaFeature, isBayers },
  } = useStores()

  const [anchorCargoNumber, setAnchorCargoNumber] = React.useState<HTMLButtonElement | null>(null)
  const openCargoNumber = Boolean(anchorCargoNumber)

  const renderBayersFields = () => {
    if (!isBayers) return null

    return (
      <Grid container spacing={2} className={classes.formRow}>
        <Grid item xs={6} md={3}>
          <FormInput label={t('sample.decaf')} formControlProps={{ margin: 'none' }}>
            <Select
              size='small'
              value={isDecaf?.toString()}
              onChange={(e) => setValue('isDecaf', str2bool(e.target.value))}
            >
              {[true, false].map(bool =>
                <MenuItem key={bool.toString()} value={bool.toString()}>
                  {optionsTranslation('boolean', bool.toString())}
                </MenuItem>
              )}
            </Select>
          </FormInput>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormInput label={t('sample.otaTestValue')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`otaTestValue${id}${masterId}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={otaTestValue}
              outputFormat="number"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('otaTestValue', Number(value))}
              InputProps={{
                endAdornment: (<InputAdornment position="end">ppb</InputAdornment>)
              }}
            />
          </FormInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput label={t('sample.basketCode')} formControlProps={{ margin: 'none' }}>
            <Select
              multiple
              size='small'
              value={basketCode?.split(',') ?? []}
              onChange={(e) => {
                const { value } = e.target
                remove(value, v => v === '')

                setValue('basketCode',  typeof value === 'string' ? value : value.join(','))
              }}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={{ PaperProps: { style: { maxHeight: 300 }}}}
            >
              {basketCodes.map((bc) => (
                <MenuItem key={bc.code} value={bc.value} sx={{py: 0}}>
                  <Checkbox checked={basketCode?.includes(bc.code)} />
                  <ListItemText primary={bc.value} />
                </MenuItem>
              ))}
            </Select>
          </FormInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={t('sample.contractWeight')}
            textFieldProps={{
              value: contractWeight ?? '',
              onChange: (e) => setValue('contractWeight', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Box>
      <Grid container spacing={2} className={classes.formRow}>
        {/*First Row */}
        <Grid item xs={12} md={3}>
          <FormInput
            label={t('sample.sampleName')}
            textFieldProps={{
              placeholder: t('sample.writeSampleNameHere'),
              value: name,
              onChange: (e) => setValue('name', e.target.value),
              error: errorStatus('name', name, errors),
              helperText: errorMessage('name', name, errors),
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput label={t('sample.country')} formControlProps={{ margin: 'none' }}>
	          <Autocomplete
		          isOptionEqualToValue={(option) => option.code === countryCode}
		          getOptionLabel={(option) => {
								if (typeof option === 'string'){
									return countryName(option)
								}

								return option.name
		          }}
		          value={countryCode || null}
		          options={countries}
		          onChange={(event, value) => setValue('countryCode', value?.code || '')}
		          className={classes.autocomplete}
		          renderInput={(params) => (
			          <TextField
				          {...params}
				          size='small'
			          />
		          )}
	          />
          </FormInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput label={t('sample.sampleGrade')} formControlProps={{ margin: 'none' }}>
            <Select
              size='small'
              defaultValue=""
              value={grade}
              onChange={(e) => setValue('grade', e.target.value)}
            >
              <MenuItem value='' sx={{ height: 34 }} />
              {grades.map(item =>
                <MenuItem key={item} value={item}>{t(`options.grades.${item}`)}</MenuItem>
              )}
            </Select>
          </FormInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput label={t('sample.sampleType')} formControlProps={{ margin: 'none' }}>
            <Select
              size='small'
              defaultValue=""
              value={sampleType}
              disabled={disableSampleType}
              onChange={(e) => setValue('sampleType', e.target.value)}
            >
              <MenuItem value='' sx={{ height: 34 }} />
              {sampleTypes.map(item =>
                <MenuItem key={item} value={item}>{optionsTranslation('sampleType', item)}</MenuItem>
              )}
            </Select>
          </FormInput>
        </Grid>


        {isMobile && (
          <Grid item xs={12}>
            {renderBayersFields()}
          </Grid>
        )}

        {/* Second Row */}
        <Grid item xs={12} md={3}>
          <FormInput
            label={t('sample.purchaseGrade')}
            textFieldProps={{
              value: purchaseGrade,
              onChange: (e) => setValue('purchaseGrade', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
	        <FormInput
		        label={t('sample.supplierOrManufacturer')}
		        textFieldProps={{
			        value: supplierName,
			        onChange: (e) => setValue('supplierName', e.target.value)
		        }}
		        formControlProps={{ margin: 'none' }}
	        />
        </Grid>
        <Grid item xs={12} md={3}>
	        <FormInput
		        label={t('sample.producerName')}
		        textFieldProps={{
			        value: producerName,
			        onChange: (e) => setValue('producerName', e.target.value)
		        }}
		        formControlProps={{ margin: 'none' }}
	        />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput formControlProps={{ margin: 'none' }}>
            <Box sx={{...verticalCentered, mb: 1}}>
              <Typography variant="subtitle1">{`${t('sample.purchaseContractReference')} #`}</Typography>
              {(!canEditPurchaseContractReference || disablePurchaseContract) &&
                <LightTooltip
                  arrow
                  title={t('sample.purchaseContractReferenceTooltip')}
                >
                  <HelpOutlineOutlined color='primary' />
                </LightTooltip>
              }
            </Box>
            <TextField
              fullWidth
              size='small'
              disabled={!canEditPurchaseContractReference && disablePurchaseContract}
              value={purchaseContractReference}
              onChange={(e) => setValue('purchaseContractReference', e.target.value)}
            />
          </FormInput>
        </Grid>

        {/* Third Row */}
        <Grid item xs={12} md={3}>
          <FormInput
            label={t('sample.strategy')}
            textFieldProps={{
              value: strategy ?? '',
              onChange: (e) => setValue('strategy', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={t('sample.customer')}
            textFieldProps={{
              value: customer,
              onChange: (e) => setValue('customer', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={t('sample.customerCode')}
            textFieldProps={{
              value: customerCode,
              onChange: (e) => setValue('customerCode', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={`${t('sample.salesContractReference')} #`}
            textFieldProps={{
              value: salesContractReference,
              onChange: (e) => setValue('salesContractReference', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>

        {/* Fourth Row #2 */}
        <Grid item xs={12} md={3}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormInput label={t('sample.receivedDate')} formControlProps={{ margin: 'none' }}>
              <DatePicker
                mask='____/__/__'
                inputFormat="yyyy/MM/DD"
                value={receivedOn}
                onChange={(value) => setValue('receivedOn', value?.format('YYYY-MM-DD'))}
                renderInput={(params) => <TextField size='small' {...params} className={classes.date} />}
              />
            </FormInput>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormInput label={t('sample.dateOfDispatch')} formControlProps={{ margin: 'none' }}>
              <DatePicker
                mask='____/__/__'
                inputFormat="yyyy/MM/DD"
                value={dateDispatch}
                onChange={(value) => setValue('dateDispatch', value?.format('YYYY-MM-DD'))}
                renderInput={(params) => <TextField size='small' {...params} className={classes.date} />}
              />
            </FormInput>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormInput label={t('sample.dateOfArrival')} formControlProps={{ margin: 'none' }}>
              <DatePicker
                mask='____/__/__'
                inputFormat="yyyy/MM/DD"
                value={dateArrival}
                onChange={(value) => setValue('dateArrival', value?.format('YYYY-MM-DD'))}
                renderInput={(params) => <TextField size='small' {...params} className={classes.date} />}
              />
            </FormInput>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <FormInput label={t('sample.dateOfResults')} formControlProps={{ margin: 'none' }}>
                  <DatePicker
                    mask='____/__/__'
                    inputFormat="yyyy/MM/DD"
                    value={dateResults}
                    onChange={(value) => setValue('dateResults', value?.format('YYYY-MM-DD'))}
                    renderInput={(params) => <TextField size='small' {...params} className={classes.date} />}
                  />
                </FormInput>
              </LocalizationProvider>
            </Grid>

        {/* Fourth Row */}
        <Grid item xs={12} md={3}>
          <FormInput
            label={t('sample.harvest')}
            textFieldProps={{
              value: harvest,
              onChange: (e) => setValue('harvest', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={t('sample.externalIdentification')}
            textFieldProps={{
              value: externalIdentification,
              onChange: (e) => setValue('externalIdentification', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={t('sample.referenceNumber')}
            textFieldProps={{
              value: referenceNumber,
              onChange: (e) => setValue('referenceNumber', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={t('sample.sampleReference')}
            textFieldProps={{
              value: sampleReference,
              onChange: (e) => setValue('sampleReference', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <FormInput
            label={t('sample.description')}
            textFieldProps={{
              value: description,
              multiline: true,
              minRows: 5,
              onChange: (e) => setValue('description', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={4} display="flex" justifyContent="space-between" flexDirection='column' height={162}>
          <FormInput label={t('sample.species')} formControlProps={{ margin: 'none' }}>
            <Select
              size='small'
              defaultValue=""
              value={species}
              onChange={(e) => setValue('species', e.target.value)}
            >
              <MenuItem value='' sx={{ height: 34 }} />
              {specieses.map(item =>
                <MenuItem key={item} value={item}>{optionsTranslation('species', item)}</MenuItem>
              )}
            </Select>
          </FormInput>
          <FormInput
            label={t('sample.varietals')}
            textFieldProps={{
              value: varietalsTags,
              onChange: (e) => setValue('varietalsTags', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>

        {/* Fifth Row */}
        <Grid item xs={12} md={4}>
	        <FormInput
		        label={t('sample.coffeeProcessing')}
		        textFieldProps={{
			        value: processName,
			        onChange: (e) => setValue('processName', e.target.value)
		        }}
	        />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInput
            label={t('sample.certification')}
            textFieldProps={{
              value: certification ?? '',
              onChange: (e) => setValue('certification', e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CuppingProtocolForm
            comboCuppingFlag={comboCuppingFlag}
            label={t('sample.preferredCuppingProtocol')}
            formControlProps={{
              className: comboCuppingFlag ? classes.comboCupping : ''
            }}
            selectProps={{
              inputProps: { disabled: isSampleInformation && !comboCuppingFlag },
              value: cuppingProtocol,
              onChange: (e) => setValue('cuppingProtocol', e.target.value),
              sx: { backgroundColor: 'white' }
            }}
          />
        </Grid>

        {/*  Sixth Row */}
        <Grid item xs={6} md={3}>
          <FormInput label={t('sample.receivedWeight')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`receivedWeight${id}${masterId}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={receivedWeight}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('receivedWeight', value)}
              InputProps={{
                endAdornment: (<InputAdornment position="end">gr</InputAdornment>)
              }}
            />
          </FormInput>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormInput label={t('sample.cropYear')} formControlProps={{ margin: 'none' }}>
            <Select
              size='small'
              defaultValue=""
              value={cropYear}
              onChange={(e) => setValue('cropYear', e.target.value)}
            >
              <MenuItem value='' sx={{height: 34}} />
              {range((new Date()).getFullYear(), 2010).map(item =>
                <MenuItem key={item} value={toString(item)}>{item}</MenuItem>
              )}
            </Select>
          </FormInput>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormInput
            label={t('sample.numberOfBags')}
            formControlProps={{ margin: 'none' }}
            textFieldProps={{
              value: numberOfBag,
              onChange: (e) => setValue('numberOfBag', e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormInput label={t('sample.bagWeight')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`bagWeight${id}${masterId}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={bagWeight}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('bagWeight', value)}
              InputProps={{
                endAdornment: (<InputAdornment position="end">kg</InputAdornment>)
              }}
            />
          </FormInput>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.formRow}>
        <Grid item xs={6} md>
          <FormInput label={t('sample.moisture')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`moisture${id}${masterId}${name}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={moisture}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('moisture', value)}
              InputProps={{
                endAdornment: (<InputAdornment position="end">%</InputAdornment>)
              }}
            />
          </FormInput>
        </Grid>
        <Grid item xs={6} md>
          <FormInput label={t('sample.waterActivity')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`waterActivity${id}${masterId}${name}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={waterActivity}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('waterActivity', value)}
            />
          </FormInput>
        </Grid>
	      <Grid item xs={6} md>
		      <FormInput label={t('sample.density')} formControlProps={{ margin: 'none' }}>
			      <CurrencyTextField
				      key={`density${id}${masterId}${name}`}
				      fullWidth
				      size='small'
				      type='text'
				      inputMode='numeric'
				      variant='outlined'
				      textAlign='left'
				      currencySymbol=''
				      value={density}
				      outputFormat="string"
				      decimalCharacter="."
				      digitGroupSeparator=","
				      onChange={(event, value)=> setValue('density', value)}
				      InputProps={{
					      endAdornment: (<InputAdornment position="end">g/L</InputAdornment>)
				      }}
			      />
		      </FormInput>
	      </Grid>
        <Grid item xs={6} md>
          <FormInput label={t('sample.temperature')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`temperatures${id}${masterId}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={temperature}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('temperature', value)}
            />
          </FormInput>
        </Grid>
        <Grid item xs={6} md>
          <FormInput label={t('sample.mass')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`mass${id}${masterId}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={mass}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('mass', value)}
              InputProps={{
                endAdornment: (<InputAdornment position="end">gr</InputAdornment>)
              }}
            />
          </FormInput>
        </Grid>
        <Grid item xs={6} md>
          <FormInput label={t('sample.volume')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`volume${id}${masterId}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={volume}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('volume', value)}
              InputProps={{
                endAdornment: (<InputAdornment position="end">mL</InputAdornment>)
              }}
            />
          </FormInput>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.formRow}>
        <Grid item xs={12} md={4}>
          <FormInput
            label={t('sample.warehouse')}
            textFieldProps={{
              value: warehouseReference,
              onChange: (e) => setValue('warehouseReference', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInput
            label={t('sample.sampleLocation')}
            textFieldProps={{
              value: sampleLocation ?? '',
              onChange: (e) => setValue('sampleLocation', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormInput label={t('sample.shipmentDate')} formControlProps={{ margin: 'none' }}>
              <DatePicker
                mask='____/__/__'
                inputFormat="yyyy/MM/DD"
                value={shipmentDate}
                onChange={(value) => setValue('shipmentDate', value?.format('YYYY-MM-DD'))}
                renderInput={(params) => <TextField size='small' {...params} className={classes.date} />}
              />
            </FormInput>
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormInput
            label={
            <>
              {t('sample.cargoSeel')}
              <IconButton
                color='primary'
                size="small"
                onClick={(event) => setAnchorCargoNumber(event.currentTarget)}
              >
                <HelpOutline fontSize="small"/>
              </IconButton>
            </>
          }
            textFieldProps={{
              value: cargoNumber ?? '',
              onChange: (e) => setValue('cargoNumber', e.target.value),
            }}
            formControlProps={{ margin: 'none' }}
          />
          <Popover
            open={openCargoNumber}
            anchorEl={anchorCargoNumber}
            onClose={() => setAnchorCargoNumber(null)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {width: 300}
            }}
          >
            <Typography sx={{ p: 2 }}>{t('sample.cargoSeelDescription')}</Typography>
          </Popover>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInput
            label={t('sample.containerNumber')}
            textFieldProps={{
              value: containerNumber ?? '',
              onChange: (e) => setValue('containerNumber', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInput
            label={t('sample.lotNumber')}
            textFieldProps={{
              value: lotNumberOrIcoMarks ?? '',
              onChange: (e) => setValue('lotNumberOrIcoMarks', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.formRow}>
        <Grid item xs={12} md={6}>
          {isSucafinaFeature ?
            <CourierSelection
              label={t('sample.courier')}
              value={courierName}
              onChange={(value) => setValue('courierName', value)}
              formControlProps={{ margin: 'none' }}
            /> :
            <FormInput
              label={t('sample.courier')}
              textFieldProps={{
                value: courierName,
                onChange: (e) => setValue('courierName', e.target.value)
              }}
              formControlProps={{ margin: 'none' }}
            />
          }
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            label={t('sample.trackingNumber')}
            textFieldProps={{
              value: trackingNumber ?? '',
              onChange: (e) => setValue('trackingNumber', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
      </Grid>

      {!isMobile && renderBayersFields()}

      <Grid container spacing={2} className={classes.formRow}>
        <Grid item xs={12} md>
          <FormInput
            label={t('sample.notesAndRemarks')}
            textFieldProps={{
              value: notesAndRemarks,
              multiline: true,
              minRows: 6,
              onChange: (e) => setValue('notesAndRemarks', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
      </Grid>
    </Box>
  )
})
