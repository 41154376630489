import {fulfillmentTypes} from "constants/form"
import {at, chain, groupBy, isEmpty, zipObject} from "lodash";
import {SampleShipmentApproval} from "../../constants/constant";

export const withSampleOrderViews = (self: any) => ({
  views: {
    get groupSamplesToBeShipment(){
      let groupSamples
      const groupFulfillmentType = groupBy(self.samplesToBeShipment, 'sampleFulfillmentType')
      groupSamples = zipObject(fulfillmentTypes, at(groupFulfillmentType, fulfillmentTypes))

      if (!groupSamples[fulfillmentTypes[0]]) {
        delete groupSamples[fulfillmentTypes[0]]
      }

      if (groupSamples[fulfillmentTypes[1]]) {
        const groupThirdPartyLocation = groupBy(groupSamples.third_party, 'sampleLocation')
        groupSamples = { ...groupSamples, ...groupThirdPartyLocation }
      }

      delete groupSamples[fulfillmentTypes[1]]

      return groupSamples
    },
    get isFormComplete(){
      const email = chain(self.samplesToBeShipment)
        .filter(sample => sample.sampleFulfillmentType !== fulfillmentTypes[0])
        .every(sample => !!sample.thirdPartyEmail)
        .value()

      const printLabel = chain(self.samplesToBeShipment)
        .filter(sample => sample.approvalStatus !== SampleShipmentApproval.DECLINED)
        .every(sample => !isEmpty(sample.label))
        .value()

      const declined = chain(self.samplesToBeShipment)
        .every(sample => sample.approvalStatus === SampleShipmentApproval.DECLINED)
        .value()

      return self.shipmentInformation.customer &&
        self.shipmentInformation.recipient &&
        self.shipmentInformation.requestedByEmail &&
        self.shipmentInformation.recipientEmails &&
        email &&
        printLabel &&
        !declined
    },
    get isFormCompleteWithoutEmail(){

      const printLabel = chain(self.samplesToBeShipment)
        .filter(sample => sample.approvalStatus !== SampleShipmentApproval.DECLINED)
        .every(sample => !isEmpty(sample.label))
        .value()

      const declined = chain(self.samplesToBeShipment)
        .every(sample => sample.approvalStatus === SampleShipmentApproval.DECLINED)
        .value()

      return self.shipmentInformation.customer &&
        self.shipmentInformation.recipient &&
        self.shipmentInformation.requestedByEmail &&
        self.shipmentInformation.recipientEmails &&
        printLabel &&
        !declined
    },
    get sampleShipmentCounter(){
      let local = 0
      let thirdParty = 0

      Object.keys(self.groupSamplesToBeShipment).forEach(group => {
        if (group === fulfillmentTypes[0]) {
          local++
        } else {
          thirdParty++
        }
      })

      return {
        local,
        thirdParty
      }
    },
    get sampleShipmentEmails(){
      return {
        recipient: self.shipmentInformation.recipientEmails?.split(','),
        trader: self.shipmentInformation.traderEmails?.split(','),
        qc: self.shipmentInformation.qualityControlEmails?.split(','),
        approval: self.shipmentInformation.approvalEmails?.split(','),
      }
    },
    get address(){
      return `${self.shipmentInformation.streetAddress}, ${self.shipmentInformation.city}, ${self.shipmentInformation.state} ${self.shipmentInformation.zipCode}, ${self.shipmentInformation.country}`
    }
  }
})
