import React, {useEffect, useState}  from "react";
import { LoadingButton } from "@mui/lab";
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import {Box, Button, Grid, IconButton, Stack, SvgIcon, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {Event, ExpandMore} from "@mui/icons-material";
import { cloneDeep } from "lodash";
import moment from "moment";
import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getSortedRowModel,
  FilterFn,
  createColumnHelper,
} from '@tanstack/react-table'
import {
  rankItem,
} from '@tanstack/match-sorter-utils'

import { colors } from 'assets'
import {IconReportShare, IconShare} from "assets/images";
import { useStores } from "models";
import { FilterTable } from "components";
import { printPage } from 'utils'

import {useStyles} from "./daily-arrivals.styles";
import { SendEmailModal } from "../send-email-modal/send-email-modal";
import { ExportPdf } from "../export-pdf/export-pdf";

const columnHelper = createColumnHelper<any>()

export const DailyArrivals: React.FC = observer(() => {
  const { t } = useTranslation()
  const params = useParams()
  const classes = useStyles()

  const printElement = React.createRef<Element | undefined>()

  const {
    reportsAnalyticsStore: { getDailyArrivals, reportAnalyticsDailyArrivals,
      dateFilter: { arrivalDate, setValue: setDateValue }
    },
    notificationStore
  } = useStores()

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [descriptionSuccess, setDescriptionSuccess] = useState('')
  const [isPrinting, setIsPrinting] = useState(false)
  const [tmpData] = useState<any[]>([])

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
      itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
  }

  const renderButtons = (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-start"
      spacing={2}
    >
      <Box sx={{height: 43, backgroundColor: colors.primary.main, p: 0.5, borderRadius: 1}}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          inputFormat="DD-MMMM-YYYY"
          value={arrivalDate}
          onChange={(newValue) => setDateValue('arrivalDate', newValue.format('DD-MMMM-YYYY'))}
          renderInput={(params) =>
            <TextField
              size="small"
              {...params}
              className={classes.date}
              error={false}
              sx={{backgroundColor: colors.primary.main, borderRadius: 1}}
            />}
          components={{
            OpenPickerIcon: ExpandMore
          }}
          InputProps={{
            startAdornment: (
              <IconButton
                size='small'
                sx={{ color: "#FFFFFF" }}
              >
                <SvgIcon component={Event} color="info" inheritViewBox />
                <Typography sx={{ml: 1}}>
                  {t('reportAnalytics.button.date')} :
                </Typography>
              </IconButton>
            )
          }}
        />
      </LocalizationProvider>
      </Box>
      <LoadingButton
        size='small'
        variant='contained'
        onClick={() => setIsPrinting(true)}
        loading={isPrinting}
      >
        <IconButton
          size='small'
          style={{ color: "#FFFFFF" }}

          sx={{ mr: 0 }}><SvgIcon component={IconReportShare} inheritViewBox /> </IconButton>{t('reportAnalytics.button.export')}
      </LoadingButton>
      <Button
        size='small'
        variant='contained'
        onClick={() => setOpenEmailModal(true)}
      >
        <IconButton
          size='small'
          style={{ color: "#FFFFFF" }}

          sx={{ mr: 0 }}><SvgIcon component={IconShare} inheritViewBox /> </IconButton>{t('reportAnalytics.button.share')}
      </Button>
    </Stack>
  )

  const columns = [
    columnHelper.accessor('contractNumber', {
      id: 'contractNumber',
      header: () => <Box textAlign="left">{t('reportAnalytics.contractNumber')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('sampleUniqueNumber', {
      id: 'sampleUniqueNumber',
      header: () => <Box textAlign="left">{t('reportAnalytics.id')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('sampleName', {
      id: 'sampleName',
      header: () =>
      <Box textAlign="left">{t('reportAnalytics.sampleName')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('producerName', {
      id: 'producerName',
      header: () => <Box textAlign="left">{t('reportAnalytics.producer')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left">{t('reportAnalytics.sampleType')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('origin', {
      id: 'origin',
      header: () => <Box textAlign="left">{t('reportAnalytics.origin')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('customerName', {
      id: 'customerName',
      header: () => <Box textAlign="left">{t('reportAnalytics.customer')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('salesContractReference', {
      id: 'salesContractReference',
      header: () => <Box textAlign="left">{t('reportAnalytics.sContractNumber')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('process', {
      id: 'process',
      header: () => <Box textAlign="left">{t('reportAnalytics.process')}</Box>,
      cell: info => info.renderValue(),
    }),
  ]

  const table = useReactTable({
    data: cloneDeep(reportAnalyticsDailyArrivals),
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  useEffect(() => {
    (async () => {
      try {
        notificationStore.setLoading(true)
        await getDailyArrivals(moment(arrivalDate).format('DD-MM-YYYY'))
      } finally {
        notificationStore.setLoading(false)
      }
    })()
  }, [arrivalDate])

  React.useEffect(() => {
    if (!isPrinting || !printElement.current) return

    (async () => {
      try {
        tmpData.length=0
        table.getRowModel().rows.map(row => {
           tmpData.push(row.original)
        })

        await printPage(printElement.current,true)
      } finally {
        setIsPrinting(false)
      }
    })()
  }, [isPrinting])

  return (
    <>
      <Box className={classes.wrapper} sx={{ display: isPrinting? "none": "flex"}} >
        <Grid container spacing={2} sx={{p: 1}}>
          <Grid item xs={2}>
            <Stack
              direction="column"
              spacing={2}
              justifyContent="center"
              textAlign="center"
            >
              <Typography variant="h6">{t("reportAnalytics.newSample")}</Typography>
              <Typography variant="h3" color="primary">
                {table.getRowModel().rows.length}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={10}>
            {renderButtons}
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.wrapper} sx={{ display: isPrinting? "none": "flex"}} >
        <FilterTable table={table} />
      </Box>

      <SendEmailModal
        id={params.id}
        title={t("reportAnalytics.dailyArrival")}
        open={openEmailModal}
        onClose={() => setOpenEmailModal(false)}
        onSubmit={() => {
          setDescriptionSuccess(t("reportAnalytics.success.successfullySentEmail"));
          setOpenSuccess(true);
        }}
      />

      {isPrinting &&
        <ExportPdf
          title={t("reportAnalytics.dailyArrival")}
          nameFile={`export-pdf-${t("reportAnalytics.dailyArrival")}`}
          columnFilters={columnFilters}
          dateValue={arrivalDate}
          titleCountData={t("reportAnalytics.newSample")}
          countData={table.getRowModel().rows.length}
          table={table}
          isPrinting={isPrinting}
          onExportPdf={(data)=>{
            setIsPrinting(data)
          }}
        />
       }
    </>
  );
})
