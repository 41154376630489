/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-20 21:46:24
 * @modify date 2022-04-20 21:46:24
 */

import { types } from 'mobx-state-tree'

export const SampleInfo = types.model({
  name: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  sampleType: types.maybeNull(types.string),
  cropYear: types.maybeNull(types.number),
  processingMethod: types.maybeNull(types.string),
})