/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-16 21:36:43
 * @modify date 2022-03-16 21:36:43
 */

import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { centered, colors } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    socialIcon: {
      padding: `${theme.spacing(1)} !important`
    },
  })
)
