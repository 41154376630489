import React, {useEffect, useState} from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {Button, Container, Stack, Typography} from '@mui/material'

import {ModalWrapper} from 'components'
import {SampleForm} from "modules/index"

import { useStyles } from './sample-information-modal.styles'
import { SampleInformationModalProps } from './sample-information-modal.props'

export const SampleInformationModal: React.FC<SampleInformationModalProps> = observer((props:SampleInformationModalProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { open, onClose, sample, setSample } = props

  const [errors, setErrors] = useState<any>([])
  const [temporarySample, setTemporarySample] = useState<any>({})

  const setValue = (key: string, value: any) => {
    setTemporarySample({
      ...temporarySample,
      [key]: value
    })
  }

  const validation = () => {
    if (!temporarySample.name) {
      setErrors({
        "name": [{ message: t('sample.error.sampleNameRequired') }]
      })

      return false
    }

    return true
  }

  const onSaveSample = () => {
    setErrors({})
    if (!validation()) return

    setSample(temporarySample)
    onClose()
  }

  useEffect(() => {
    if (open) {
      setTemporarySample(sample)
    }
  }, [open])

  return (
    <ModalWrapper
      maxWidth='lg'
      open={open}
    >
      <Container sx={{py: 2}}>
        <Typography variant="h4" mb={4}>
          {t('sample.masterSample.form.title')}
        </Typography>

        <SampleForm
          {...temporarySample}
          errors={errors}
          setValue={setValue}
          disableSampleType
          disablePurchaseContract
        />

        <Stack direction='row' spacing={2} className={classes.buttonWrapper}>
          <Button
            variant='contained'
            className='wide'
            onClick={onSaveSample}
          >
            {t('common.submit')}
          </Button>
          <Button
            variant='outlined'
            className='wide'
            onClick={onClose}
          >
            {t('common.cancel')}
          </Button>
        </Stack>
      </Container>
    </ModalWrapper>
  )
})


