import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  IconReportShare,
  emptyFlavorWheel,
} from "assets/images";
import { Clear, Event, ExpandMore, FilterAlt } from "@mui/icons-material";
import moment from "moment";
import {
  camelCase,
  ceil,
  chunk,
  find,
  flatMapDeep,
  includes,
  isArray,
  isBoolean,
  isEmpty,
  isString,
  lowerCase,
  upperFirst
} from 'lodash'
import { DateRangePicker, DateRange } from "mui-daterange-picker";

import { ModalWrapper } from 'components'
import { colors } from "assets";
import { useStyles } from "./sample.styles";
import { useStores } from "models";
import { flavorWheel, textDescriptorsTranslation } from "utils";
import { scaDescriptorList } from 'constants/sca-descriptors'
import {allDescriptor} from "constants/all-descriptors";
import { AdvanceSearch } from "../advance-search/advance-search";
import { dateCategory } from "constants/form";
import { ExportPdf } from "../export-pdf/export-pdf";
import { printPage } from 'utils'
import { LoadingButton } from "@mui/lab";
import { RejectionDetail } from "../rejection-detail/rejection-detail";



export const Sample: React.FC = observer(() => {
  const { t } = useTranslation();
  const params = useParams();
  const classes = useStyles();

  const printElement = React.createRef<Element | undefined>()

  const {
    companyStore: { base64Logo, isEnterprisePlus },
    userStore: { languagePreference },
    reportsAnalyticsStore: {
      filter: {setValue: setFilterValue, reset: resetSearch, removeInternalScore},
      approvedSamples, cuppingSubmitted, rejectedSamples, sampleReceived, sampleShipmentCreated, rejectedReasons,
      topFlavorWheel, descriptors, averageScore, getSample, filterValues, maxScore, minScore, noStatusSamples,
      dateFilter: { sampleStartDate, sampleEndDate, setValue: setDateValue }
    },
    notificationStore
  } = useStores();

  const [temporaryAcidityAndSaltDescriptors, setTemporaryAcidityAndSaltDescriptors] = useState<any[]>([])
  const [temporaryBodyAndMouthfeelDescriptors, setTemporaryBodyAndMouthfeelDescriptors] = useState<any[]>([])
  const [temporaryFragranceDescriptors, setTemporaryFragranceDescriptors] = useState<any[]>([])
  const [temporaryFlavorAndAftertasteDescriptors, setTemporaryFlavorAndAftertasteDescriptors] = useState<any[]>([])
  const [temporaryDefectsDescriptors, setTemporaryDefectsDescriptors] = useState<any[]>([])
  const [temporaryListSearch, setTemporaryListSearch] = useState<any[]>([])
  const [openSearch, setOpenSearch] = useState(false)
  const [searchAdvanceData,setSearchAdvanceData] = useState<any[]>([])
  const [open, setOpen] = useState(false);
  const [selectDateCategory, setSelectDateCategory] = useState(dateCategory[0])
  const [isPrinting, setIsPrinting] = useState(false)
  const [openRejection, setOpenRejection] = useState(false);

  const toggle = () => setOpen(!open);

  const splitDataDes = ceil(descriptors.length/2)

  const getDescriptor = (descriptor) => {
      if (!descriptor.groups || !descriptor.groups.length) return descriptor

      return [descriptor, flatMapDeep(descriptor.groups, getDescriptor)]
    }

  const pickTextColorBasedOnBgColorSimple = (bgColor) => {
    if(bgColor === undefined) return

    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
      colors.text.primary : 'white';
  }

  const renderButtons = (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-start"
      spacing={2}
    >
      <FormControlLabel
        label={t('reportAnalytics.removeInternalScore')}
        control={
          <Switch
            checked={removeInternalScore === "0" ? true : false}
            onChange={(e) => {
              setFilterValue('removeInternalScore',  e.target.checked ? "0" : "1")
              getDataSample()
            }}
          />
        }
      />
      <Box className={classes.dateRange}>
        <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        >
          <Box sx={{minWidth: 50}}>
            <IconButton size="small" sx={{ color: "#FFFFFF" }}>
              <SvgIcon component={Event} inheritViewBox />{" "}
              <Typography sx={{ ml: 1 }}>{t(`reportAnalytics.${camelCase(selectDateCategory)}`)}: </Typography>{" "}
            </IconButton>
            </Box>
            <Box sx={{minWidth: 200}}>
              <Typography sx={{ ml: 1, color:"white" }}>{`${moment(sampleStartDate).format("DD-MMMM-YYYY")} - ${moment(sampleEndDate).format("DD-MMMM-YYYY")}`}</Typography>
            </Box>
            <Box sx={{width: 30}}>
            <IconButton size="small" sx={{ color: "#FFFFFF" }} onClick={()=> setOpen(true)}>
              <SvgIcon component={ExpandMore} inheritViewBox />{" "}
            </IconButton>
          </Box>
        </Stack>

      </Box>

      <Button
        size="small"
        variant="contained"
        onClick={()=> setOpenSearch(true)}
      >
        <IconButton size="small" sx={{ color: "#FFFFFF", mr: 0 }}>
          <SvgIcon component={FilterAlt} inheritViewBox />{" "}
        </IconButton>
        {t("reportAnalytics.addFilter")}
      </Button>
      <LoadingButton
        size='small'
        variant='contained'
        onClick={() => setIsPrinting(true)}
        loading={isPrinting}
      >
        <IconButton
          size='small'
          sx={{ mr: 0, color: "#FFFFFF"  }}><SvgIcon component={IconReportShare} inheritViewBox /> </IconButton>{t('reportAnalytics.button.export')}
      </LoadingButton>
    </Stack>
  );

  const descriptorColor = (data) => {
    const flatList = flatMapDeep(allDescriptor, getDescriptor)

    if (flatList.findIndex(x => upperFirst(x.label) === upperFirst(data)) >= 0) {
      return flatList.filter(x => upperFirst(x.label) === upperFirst(data))[0]['color']
    }
  }

  const descriptorTranslation = (language, data) => {
    const flatList = flatMapDeep(scaDescriptorList, getDescriptor)
    const label = `label_${language}`

    if (language === 'en') return upperFirst(data)

    const descriptor = flatList.filter(x => x.label === lowerCase(data))
    if (descriptor.length === 0) return upperFirst(data)

    return descriptor[0][label]
  }

  const renderDescription = (data) => {
    return (
      <Chip
        key={data.label}
        size="small"
        variant='outlined'
        sx={{ mr: 1, mt: 1, backgroundColor: descriptorColor(data.label), color: pickTextColorBasedOnBgColorSimple(descriptorColor(data.label)), borderColor: descriptorColor(data.label) }}
        label={`${descriptorTranslation(languagePreference, data.label)}`}
      />
    )
  }

  const renderSearchValue = (item) => {
    let label: string = item.label
    let resetValue: any = ''

    if (isString(item.value)) label += `: "${item.value}"`
    if (isArray(item.value)) label += `: "${textDescriptorsTranslation(languagePreference, item.value)}"`
    if (isBoolean(item.value)) resetValue = false

    return (
      <Chip
        key={item.id}
        size="medium"
        variant='filled'
        color='primary'
        sx={{ mr: 1, mb: 1, marginTop:1, backgroundColor: colors.primary.main, color:"white"}}
        label={label}
        onDelete={() => {
          setFilterValue(item.key || item.id, resetValue)
          getDataSample()
          setTemporaryListSearch(current =>
            current.filter(data => {
              return data !== item;
            }),
          );
        }}
      />
    )
  }

  const renderDateRange= () => {
   return(
    <DateRangePicker
      open={open}
      toggle={toggle}
      closeOnClickOutside={false}
      onChange={(range) => {
        setDateValue("sampleStartDate", range.startDate)
        setDateValue("sampleEndDate", range.endDate)
      }}
    />
   )
  }

  const getDataSample = async() => {
    try {
      notificationStore.setLoading(true);
      await getSample(moment(sampleStartDate).format('DD-MM-YYYY'), moment(sampleEndDate).format('DD-MM-YYYY'))
    } finally {
      notificationStore.setLoading(false);
    }
  }


  useEffect(() => {
    if (open) return

    getDataSample()
  }, [open]);

  useEffect(() => {
   flavorWheel(JSON.parse(topFlavorWheel),isEnterprisePlus && base64Logo)
  }, [topFlavorWheel])

  useEffect(() => {
    if (!isPrinting || !printElement.current) return

    (async () => {
      try {

        await printPage(printElement.current,true)
      } finally {
        setIsPrinting(false)

      }
    })()
  }, [isPrinting])

  return (
    <>
      <Box sx={{mt: 2}}>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <Stack
              direction="column"
              spacing={2}
            >
              <Typography variant="h6">{t("reportAnalytics.dashboard")}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={11}>
            {renderButtons}
          </Grid>
        </Grid>
      </Box>
      <Grid container sx={{p: 1 , display: isPrinting? "none": "flex"}}>
        <Grid item xs={10}>
        {Object.keys(filterValues).map(k => {
            const value = find(searchAdvanceData, v => {
                return (v.key || v.id) === k
            })
            if (value) return renderSearchValue(value)

            return null
        })}
        </Grid>
          <Grid item xs={2} sx={{ textAlign: 'right', mt: 1 }}>
            {Object.keys(filterValues).length > 1 &&
              <Button
              size='small'
              startIcon={<Clear sx={{color: '#E74C3C'}}/>}
              onClick={() => {
                resetSearch()
                getDataSample()
                setOpenSearch(false)
                window.scrollTo(0, 0)
              }}
            >
              <Typography variant='body2'> {t('reportAnalytics.clearAllFilters')} </Typography>
            </Button>
            }
          </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ display: isPrinting? "none": "flex"}} >
      <Grid item xs={3}>
          <Box className={classes.cardSummary}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6">{t("reportAnalytics.sampleReceived")}</Typography>
            </Box>

            <Stack direction="row">
              <Typography variant="h3" color={colors.text.primary}>
                {sampleReceived}
              </Typography>
            </Stack>
          </Box>
          <Box className={classes.cardSummary}>
            <Box sx={{mb: 2 }}>
              <Typography variant="h6">{t("reportAnalytics.cuppingSubmitted")}</Typography>
            </Box>

            <Stack direction="row">
              <Typography variant="h3" color={colors.text.primary}>
                {cuppingSubmitted}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.wrapper} height={243}>
            <Box sx={{mb: 2 }}>
              <Typography variant="h6">{t("reportAnalytics.averageScore")}</Typography>
            </Box>
            <Stack direction="column" justifyContent="center" textAlign="center">
              <Typography variant="h2" color="primary">
                {averageScore}
              </Typography>
              <Typography variant="h4" color={colors.primary.o50}>
               {`(${minScore} - ${maxScore})`}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.wrapper} height={243}>
          <Box sx={{mb: 2 }}>
              <Typography variant="h6">{t("reportAnalytics.topDescriptor")}</Typography>
            </Box>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box className={classes.wheelWrapper} sx={{minHeight: {md: 170}}}>
              {isEmpty(topFlavorWheel) ?
                <img src={emptyFlavorWheel} height="80%" /> :
                <div id="tastify-wheel" style={{width: '100%', height: '100%'}}></div>
              }
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={1}>
              {chunk(descriptors, splitDataDes).map((data , index) => {

                let number = index === 0 ? 0: index * splitDataDes
                return(
                  <Grid item xs={6}>
                    <Stack direction="column" spacing={1}>
                      {data.map((d: any) => {
                        number ++
                        return(
                          <Stack direction="row" spacing={1}>
                            <Typography variant="body1">
                            {number}
                            </Typography>
                            {renderDescription(d)}
                            <Typography variant="body1" sx={{ fontWeight: 600, color: colors.primary.main}} >
                              {d.qty} %
                            </Typography>
                          </Stack>
                        )})}

                      </Stack>
                  </Grid>
                )})}
              </Grid>
            </Grid>
            </Grid>

          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ display: isPrinting? "none": "flex"}}>
        <Grid item xs={3}>
          <Box className={classes.cardSummary}>
            <Box sx={{mb: 2 }}>
              <Typography variant="h6">{t("reportAnalytics.sampleShipmentCreated")}</Typography>
            </Box>
            <Stack direction="row">
              <Typography variant="h3" color={colors.text.primary}>
                {sampleShipmentCreated}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.cardSummary}>
            <Box sx={{mb: 2 }}>
              <Typography variant="h6">{t("reportAnalytics.approvedSample")}</Typography>
            </Box>
            <Stack direction="row">
              <Typography variant="h3" color={colors.success.primary}>
                {approvedSamples}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.cardSummary}>
            <Box sx={{ mb: 2 }}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
              >
              <Typography variant="h6">{t("reportAnalytics.rejectedSample")}</Typography>
              <Button variant="outlined" size="small" sx={{borderRadius: 5}} onClick={()=> setOpenRejection(true)}>
                {t("reportAnalytics.viewDetail")}
              </Button>
              </Stack>
            </Box>
            <Stack direction="row">
              <Typography variant="h3" color={colors.error.primary}>
                {rejectedSamples}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.cardSummary}>
            <Box sx={{mb: 2 }}>
              <Typography variant="h6">{t("reportAnalytics.sampleWithNoStatus")}</Typography>
            </Box>
            <Stack direction="row">
              <Typography variant="h3" color={colors.text.o75}>
                {noStatusSamples}
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        hideBackdrop={true}
      >
        <AppBar sx={{ position: 'relative', backgroundColor:"#FFFF" }} elevation={0}>
          <Toolbar>
            <Box>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <Typography variant="body1" color={colors.text.o75}>
              {t("reportAnalytics.dateCategory")} :
              </Typography>
              <Select
                size='small'
                defaultValue={dateCategory[0]}
                value={selectDateCategory}
                onChange={(event) => {
                  setSelectDateCategory(event.target.value)
                }}
              >
                {dateCategory.map(item =>
                  <MenuItem key={item} value={item}>
                    {t(`reportAnalytics.${camelCase(item)}`)}
                  </MenuItem>
                )}
              </Select>
            </Stack>
            </Box>
          </Toolbar>
        </AppBar>
        <Box>
           {renderDateRange()}
        </Box>
        <DialogActions>
          <Button variant="contained" onClick={()=> setOpen(false)}>
            {t("reportAnalytics.close")}
          </Button>
        </DialogActions>

      </Dialog>

      <RejectionDetail
        open={openRejection}
        onClose={()=> setOpenRejection(false)}
        dataRejection={JSON.parse(rejectedReasons)}
      />

      <ModalWrapper open={openSearch} maxWidth="lg">
      <AdvanceSearch
            isMobile= {false}
            expanded= {false}
            temporaryDefectsDescriptors = {temporaryDefectsDescriptors}
            temporarySearchAdvanceData = {searchAdvanceData}
            temporaryFlavorAndAftertasteDescriptors = {temporaryFlavorAndAftertasteDescriptors}
            temporaryFragranceDescriptors = {temporaryFragranceDescriptors}
            temporaryBodyAndMouthfeelDescriptors = {temporaryBodyAndMouthfeelDescriptors}
            temporaryAcidityAndSaltDescriptors = {temporaryAcidityAndSaltDescriptors}
            temporaryListSearch = {temporaryListSearch}
            addTemporarySearchAdvanceData={(item)=>{
              setSearchAdvanceData(item)
            }}
            addTemporaryDefectsDescriptors={(descriptors) => {
              setTemporaryDefectsDescriptors(descriptors)
            }}
            addTemporaryFlavorAndAftertasteDescriptors={(descriptors) => {
              setTemporaryFlavorAndAftertasteDescriptors(descriptors)
            }}
            addTemporaryFragranceDescriptors={(descriptors) => {
              setTemporaryFragranceDescriptors(descriptors)
            }}
            addTemporaryBodyAndMouthfeelDescriptors={(descriptors) => {
              setTemporaryBodyAndMouthfeelDescriptors(descriptors)
            }}
            addTemporaryAcidityAndSaltDescriptors={(descriptors) => {
              setTemporaryAcidityAndSaltDescriptors(descriptors)
            }}
            addTemporaryListSearch={(data) => {
              setTemporaryListSearch(data)
            }}
            onSearch={() => {
              getDataSample()
            }}
            onCancel={() => {
              getDataSample()
              setOpenSearch(false)
              window.scrollTo(0, 0)
              setTemporaryDefectsDescriptors([])
              setTemporaryFlavorAndAftertasteDescriptors([])
              setTemporaryFragranceDescriptors([])
              setTemporaryBodyAndMouthfeelDescriptors([])
              setTemporaryAcidityAndSaltDescriptors([])
              setTemporaryListSearch([])
            }}
          />
      </ModalWrapper>
      {isPrinting &&
          <ExportPdf
            title={t("reportAnalytics.sample")}
            nameFile={`export-pdf-${t("reportAnalytics.sample")}`}
            columnFilters={filterValues}
            dateValue={`${moment(sampleStartDate).format("DD-MMMM-YYYY")} - ${moment(sampleEndDate).format("DD-MMMM-YYYY")}`}
            approvedSamples={approvedSamples}
            cuppingSubmitted={cuppingSubmitted}
            rejectedSamples={rejectedSamples}
            sampleReceived={sampleReceived}
            sampleShipmentCreated={sampleShipmentCreated}
            topFlavorWheel={topFlavorWheel}
            descriptors={descriptors}
            averageScore={averageScore}
            isPrinting={isPrinting}
            isEnterprisePlus={isEnterprisePlus}
            base64Logo={base64Logo}
            maxScore = {maxScore}
            minScore = {minScore}
            noStatusSamples = {noStatusSamples}
            dataRejection = {JSON.parse(rejectedReasons)}
            languagePreference={languagePreference}
            onExportPdf={(data)=>{
              setIsPrinting(data)
            }}
          />
     }
    </>
  );
});
