import React from 'react'
import {every, isEmpty} from "lodash"
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {
  Card,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  TextField
} from '@mui/material'
import { isMobile } from "react-device-detect"

import { useStores } from 'models'
import { Descriptor } from "components"
import { solubleDescriptorList } from "constants/soluble-descriptors"
import { menuListSoluble, menuListSolubleBody, menuListSolubleAftertaste } from 'constants/menu-list-soluble'

import { useStyles } from './sucafina-soluble.styles'

export const SucafinaSoluble: React.FC = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { sampleStore: {
    selectedSample: { id, sampleId, selectedScore: {
      aroma, aftertaste, body, bitterness, consistency, acidity,
      nonUniform, defective, flavorDescriptors, notes, consistencyComment,
      setScoreValue
    }}
  },
  } = useStores()

  const renderDescriptor = (descriptor, descriptorList, scoreName) => {
    return (
      <Descriptor
        key={`fragrance${descriptor}`}
        sampleId={sampleId}
        title={t('descriptor.flavor')}
        descriptors={descriptor}
        descriptorList={descriptorList}
        addDescriptor={(values) => setScoreValue(scoreName, values)}
        isSca
      />
    )
  }

  const renderMenu = (label, value, scoreName, data) => {
    return (
      <Box className={classes.menuWrapper}>
        <Typography variant='h6' fontSize={isMobile ? '12px': '14px'}>{label.toUpperCase()}</Typography>
        <FormControl size="small" sx={{ m: 1, width: isMobile ? 175 : 225 }}>
          <Select value={value} onChange={(e) => setScoreValue(scoreName, e.target.value)}>
            {data.map((menu, index) =>
              <MenuItem key={index} value={menu.value}>
                <Typography variant="h6" color={menu.color} fontSize={isMobile ? '12px': '14px'}>
                  {`${menu.value} ${t(menu.label)}`}
                </Typography>
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>
    )
  }

  const renderNote = (value, scoreName, title) => {
    return (
      <Box>
        <Typography variant='body2' sx={{mb: 1}}>{title}</Typography>
        <TextField
          fullWidth
          multiline
          minRows={isMobile ? 2 : 4}
          value={value}
          inputProps={{ maxLength: 200 }}
          onChange={(e) => setScoreValue(scoreName, e.target.value)}
          placeholder={t('sampleScore.writeHere')}
        />
      </Box>
    )
  }

  React.useEffect(() => {
    setScoreValue('nonUniformScore', nonUniform.filter(Boolean).length * 2)
  }, [nonUniform])

  React.useEffect(() => {
    if (every(defective, e => e === false)) setScoreValue('defectsDescriptors', [])

    setScoreValue('defects', defective.filter(Boolean).length * 4)
  }, [defective])


  return (
    <Card sx={{p: isMobile ? 0 : 2}}>
      <Grid container spacing={isMobile ? 0 : 2}>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardStyle}>
            {renderMenu(t('descriptor.aroma'), aroma, 'aroma', menuListSoluble)}
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardStyle}>
            {renderMenu(t('descriptor.acidity'), acidity, 'acidity', menuListSoluble)}
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardStyle}>
            {renderMenu(t('descriptor.bitterness'), bitterness, 'bitterness', menuListSoluble)}
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardStyle}>
            {renderMenu(t('descriptor.body'), body, 'body', menuListSolubleBody)}
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardStyle}>
            {renderMenu(t('descriptor.aftertaste'), aftertaste, 'aftertaste', menuListSolubleAftertaste)}
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardStyle}>
            {renderMenu(t('descriptor.consistency'), consistency, 'consistency', menuListSoluble)}
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          {renderDescriptor(flavorDescriptors, solubleDescriptorList, 'flavorDescriptors')}
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardStyle}>
            {renderNote(consistencyComment, 'consistencyComment', t('descriptor.consistencyComment'))}
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardStyle}>
            {renderNote(notes, 'notes', t('descriptor.notesSoluble'))}
          </Card>
        </Grid>
      </Grid>
    </Card>
  )
})
