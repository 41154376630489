import { Box, Grid, Stack, Typography } from "@mui/material";
import { ModalWrapper } from "components";
import { ceil, chunk } from "lodash";
import { colors } from "assets";
import { useTranslation } from "react-i18next";
import { rejectedColors } from "constants/rejected-colors";


export const RejectionDetail = (props) => {
  const { t } = useTranslation();

  const { open, onClose, dataRejection } = props;

  const renderList = (data, key, index) => {
    const hasChildren = data[key]?.children
   
    return(
      <ul style={{marginLeft: -20}}>
        <li style={{listStyle: "square", fontSize: '21px', color: rejectedColors[key]}}>
          <Typography  color={index < 3 ? colors.text.primary : colors.text.o75} variant={index < 3 ? "h6": "body1"}>{data[key].count} {t(`shipment.rejectModal.${key}`)}</Typography>
        </li>
        {hasChildren && Object.keys(data[key].children).map( k => {
          return(
            renderList(data[key].children, k, 4)
          )
        })}
        
      </ul>
    )
}

  return (
    <ModalWrapper open={open} onClose={onClose} maxWidth="sm">
      <Typography variant="h6" sx={{ mt: -3.4, color : colors.text.o75 }}>
        {t("reportAnalytics.rejectionDetail")}
      </Typography>
      <Box sx={{backgroundColor: "#EBE8F2", borderRadius: 2, mt: 3}}>
        <Grid container sx={{pl: 3}}>
          {Object.keys(dataRejection).map((key, index) => {
            
            return( 
              <Grid item xs={4}>
                {renderList(dataRejection, key, index)}
                
              </Grid>
            )
          })}
        </Grid>
        </Box>
    </ModalWrapper>
  );
};
