import { concat } from 'lodash'

import { acidityDescriptorList } from './acidity-descriptors'
import { aftertasteDescriptorList } from './aftertaste-descriptors'
import { balanceDescriptorList } from './balance-descriptors'
import { bodyDescriptorList } from './body-descriptors'
import { flavorDescriptorList } from './flavor-descriptors'
import { defectDescriptorList } from './defect-descriptors'
import { solubleDescriptorList } from './soluble-descriptors'

export const allDescriptor =  concat(
                                acidityDescriptorList, aftertasteDescriptorList, balanceDescriptorList, 
                                bodyDescriptorList, flavorDescriptorList, defectDescriptorList, solubleDescriptorList
                              )