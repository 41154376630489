/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-04-17 22:38:39
 */

import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withSetValue,
  withReset,
} from "models/extensions"

import { defaultSampleProps } from "models/sample/default-sample.props"

/**
 * Model description here for TypeScript hints.
 */
export const NewSampleModel = types
  .model("NewSample")
  .props({
    ...defaultSampleProps
  })
  .extend(withSetValue)
  .extend(withReset)

type NewSampleType = Instance<typeof NewSampleModel>
export interface NewSample extends NewSampleType {}
type NewSampleSnapshotType = SnapshotOut<typeof NewSampleModel>
export interface NewSampleSnapshot extends NewSampleSnapshotType {}
