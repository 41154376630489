/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-03 15:23:27
 * @modify date 2022-02-03 15:23:27
 */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Typography, Tab } from '@mui/material'
import { TabContext, TabList } from '@mui/lab'

import { useStores } from "models"
import { ModalWrapper } from 'components'

import { Profile, Company, Subscription, Password, ApiToken, Webhooks, RedeemCode, ScaPolicy } from './parts'
import { useStyles } from './setting.styles'

export const Setting: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    userStore: { isCompanyAdmin, adminPrivileges },
    companyStore: { getCompany },
    modalStore: { setting, settingTab, setValue }
  } = useStores()

  React.useEffect(() => {
    if(setting) getCompany()
  }, [setting])

  return (
    <ModalWrapper
      open={setting}
      maxWidth='md'
      onClose={() => setValue('setting', false)}
    >
      <Typography variant="h6" sx={{mb: 2}}>
        {t('menu.myProfileSettings')}
      </Typography>

      <TabContext value={settingTab}>
        <TabList
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          onChange={(event, value) => setValue('settingTab', value)}
          aria-label="Profile settings"
        >
          <Tab value="profile" label={t('menu.profile')} className={classes.tab} />
          {isCompanyAdmin && <Tab value="company" label={t('menu.company')} className={classes.tab} />}
          {isCompanyAdmin && !(window as any).isRNWebview && <Tab value="subscription" label={t('menu.subscription')} className={classes.tab} />}
          <Tab value="redeem-code" label={t('redeemCode.title')} className={classes.tab} />
          <Tab value="password" label={t('menu.password')} className={classes.tab} />
          {adminPrivileges && <Tab value="apiToken" label={t('menu.apiToken')} className={classes.tab} />}
          {adminPrivileges && <Tab value="webhooks" label={t('menu.webhooks')} className={classes.tab} />}
          {isCompanyAdmin && <Tab value="scaPolicy" label={t('menu.scaDataTransfer')} className={classes.tab} />}
        </TabList>

        <Profile />
        <Company />
        <Subscription />
        <RedeemCode />
        <Password />
        <ApiToken />
        <Webhooks />
        <ScaPolicy />
      </TabContext>
    </ModalWrapper>
  )
})
