import React, {useState} from "react";
import {findIndex, toString} from "lodash";
import {useTranslation} from "react-i18next";
import {Box, IconButton, Stack, Typography} from "@mui/material";
import {ChevronLeft, ChevronRight, Home, Visibility, VisibilityOff} from "@mui/icons-material";

import {useStores} from "models";
import {colors} from "assets";
import {countryName, optionsTranslation} from "utils";
import {IconTotalScore} from "assets/images";

import {useStyles} from "./mobile.styles";

export const SampleScoreMobile = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { tab, setTab, renderRoastLevel, renderProtocol, isBlind } = props
  const {sampleStore: { orderedSamples }} = useStores()

  const [showSelectedSample, setShowSelectedSample] = useState(false)
  const [hideScore, setHideScore] = useState(true)

  const renderSampleInfo = (sample) => {
    if (isBlind) {
      return (
        <Box display="flex" alignItems="center">
          <Typography display="inline" color="primary" variant="body2" fontWeight={600}>
            {sample.sampleId}
          </Typography>
        </Box>
      )
    }

    return (
      <Stack spacing={0.5} >
        <Box>
          <Typography display="inline" color="primary" variant="body2" fontWeight={600}>
            {sample.sampleId}
          </Typography>&nbsp;
          <Typography display="inline" variant="body2" fontWeight={600}>
            {isBlind ? '-' : sample.name || '-'}
          </Typography>
        </Box>
        <Typography variant="caption">
          {sample.sampleUniqueNumber} - {optionsTranslation('cuppingProtocol', sample.cuppingProtocol)}
        </Typography>
        <Typography variant="caption" color={colors.text.caption}>
          {optionsTranslation('species', sample.species, true)} | {countryName(sample.countryCode) || '-'} | {optionsTranslation('sampleType', sample.sampleType, true)}
        </Typography>
      </Stack>
    )
  }

  const renderTotalScore = (sample) => {
    return (
      <Box className={classes.middleTitle}>
        <Box className={classes.totalScoreWrapper}>
          <Typography variant='body2'>{t('sampleScore.totalScore')}</Typography>
          <IconButton
            color='primary'
            sx={{p: 0, ml: 1}}
            onClick={() => setHideScore(!hideScore)}
          >
            {hideScore ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Box>
        <Box className={classes.totalScoreWrapper} sx={{ml: -1.5}}>
          <IconTotalScore className={classes.totalScoreIcon}/>
          <Typography variant='h5' color='primary'>{hideScore ? '---' : sample.selectedScore.totalScore}</Typography>
        </Box>
      </Box>
    )
  }


  if (showSelectedSample) {
    const index = findIndex(orderedSamples, { id: parseInt(tab) })
    const isSca = orderedSamples[index].cuppingProtocol.includes('sca')

    return (
      <Box pt={isSca ? 3 : 0}>
        <Box className={classes.nav}>
          <IconButton
            disabled={index === 0}
            className={classes.navButton}
            onClick={() => setTab(toString(orderedSamples[index - 1].id))}
          >
            <ChevronLeft />
          </IconButton>

          <IconButton
            className={classes.navButton}
            onClick={() => setShowSelectedSample(false)}
          >
            <Home />
          </IconButton>

          <IconButton
            disabled={index === orderedSamples.length - 1}
            className={classes.navButton}
            onClick={() => setTab(toString(orderedSamples[index + 1].id))}
          >
            <ChevronRight />
          </IconButton>
        </Box>

        <Box className={classes.contentScore}>
          {renderSampleInfo(orderedSamples[index])}
          {renderTotalScore(orderedSamples[index])}
        </Box>

        <Box className={classes.roastLevel}>
          {renderRoastLevel}
        </Box>

        <Box pb={7}>
          {renderProtocol()}
        </Box>
      </Box>
    )
  }

  return (
    <Box pb={7}>
      {orderedSamples.map((sample) => {
        return (
          <Box
            key={sample.id}
            className={classes.contentWrapper}
            onClick={() => {
              setTab(toString(sample.id))
              setShowSelectedSample(true)
            }}
          >
            {renderSampleInfo(sample)}

            <Box className={classes.score}>
              <Box textAlign="center">
                <Typography variant='body2'>{t('sampleScore.totalScore')}</Typography>
                <Typography variant="h5" color="primary">{sample.selectedScore.totalScore}</Typography>
              </Box>

              <ChevronRight />
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
