import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Tab, Tabs, Box, Typography} from "@mui/material";
import {Event} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import moment from "moment";

import { useStores } from "models"
import {emptyCupping, IconShippingFast, IconReviewSamples} from "assets/images";

import {useStyles} from "./reports-analytics.styles";
import {DailyArrivals} from "./components/daily-arrivals/daily-arrivals";
import { DailyCupping } from "./components/daily-cupping/daily-cupping";
import { Shipment } from "./components/shipment/shipment";
import { Sample } from "./components/sample/sample";

export const ReportsAnalytics: React.FC = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    companyStore: { isSucafinaFeature },
    reportsAnalyticsStore: { dateFilter }
  } = useStores()

  const [tab, setTab] = useState('sample')

  const renderTabContent = () => {
    switch (tab) {
      case 'sample':
        return <Sample />
      case 'shipment':
        return <Shipment />
      case 'daily-arrival':
        return <DailyArrivals />
      case 'daily-cuppings':
        return <DailyCupping />
      default:
        return (
          <Box py={4} textAlign="center">
            <img src={emptyCupping} />
          </Box>
        )
    }
  }

  useEffect(() => {
    dateFilter.setValue('sampleStartDate', moment().startOf('month').toDate())
    dateFilter.setValue('sampleEndDate', moment().endOf('month').toDate())
    dateFilter.setValue('shipmentStartDate', moment().startOf('month').toDate())
    dateFilter.setValue('shipmentEndDate', moment().endOf('month').toDate())
    dateFilter.setValue('cuppingDate', moment().format('DD-MMMM-YYYY'))
    dateFilter.setValue('arrivalDate', moment().format('DD-MMMM-YYYY'))
  }, [])

  return(
    <Box px={4} py={2.5}>
      <Typography variant='h4'>
        {`${t('reportAnalytics.title')}: ${tab}`}
      </Typography>

      <Box sx={{mt: 2}}>
        <Tabs
          value={tab}
          onChange={(e, v) => setTab(v)}
          textColor="secondary"
          indicatorColor="secondary"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabs}
          centered
        >
          <Tab value="sample" label="Sample" icon={<IconReviewSamples />} iconPosition="start" />
          <Tab value="shipment" label="Shipment" icon={<IconShippingFast />} iconPosition="start" />
          {isSucafinaFeature && <Tab value="daily-arrival" label="Daily Arrival" icon={<Event />} iconPosition="start" />}
          {isSucafinaFeature && <Tab value="daily-cuppings" label="Daily Cuppings" icon={<Event />} iconPosition="start" />}
        </Tabs>
        {renderTabContent()}
      </Box>

    </Box>
  )
})

export default ReportsAnalytics
