
import React, { useState } from "react";
import clsx from "clsx";
import {camelCase, find, isEmpty, isNull, isNumber, startCase} from "lodash";
import { useTranslation } from "react-i18next";
import {
  Card,
  Grid,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  Typography,
  Collapse,
  SvgIcon,
  Box,
} from "@mui/material";
import {
  MoreHoriz,
  ExpandMore,
  ExpandLess,
  Summarize,
  Archive,
  DeleteOutline,
  FileCopy,
  Visibility,
} from "@mui/icons-material";
import moment from "moment";

import { MenuIcon } from "components";
import { globalAction, optionsTranslation} from "utils";
import {
  IconEdit,
  IconTotalScore,
} from "assets/images";

import { SampleItemMobileProps } from "./sample-item-mobile.props";
import { useStyles } from "./sample-item-mobile.styles";
import { colors } from "assets";

import { getCode } from "country-list";
import { SampleShipmentApproval } from "constants/constant";
import { ApproveReject, ApproveRejectButton } from "modules";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import {useStores} from "models";
import {findRoute} from "constants/routes";

const RenderMenuItems = observer((props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    cuppingSessionStore: { getCuppingSession },
    sampleStore: { setValue: setSampleValue },
    reviewSampleStore: { samples, removeSample },
    notificationStore
  } = useStores()
  const {
    id, uniqueToken, canNotAddSampleInfo, cuppingSessionId, cuppingSessionUniqueToken, canSeeReport, canDeleteSample
  } = props.row
  const { isReview, setOpenConfirm, setConfirmAction, setDescription } = props

  const sampleInformationLink = findRoute('sampleInformation')
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorMenu)

  const onAction = (token: string, id: string, link: string) => {
    globalAction(notificationStore, {
      action: async () => await getCuppingSession(token),
      afterAction: () => {
        setSampleValue('selectedSample', id)
        navigate(link)
      }
    })
  }

  const onSeeReport = async (token: string, id: string) => {
    if (!isReview) {
      navigate(`/sample-report/${token}`)
      return
    }

    const sample = find(samples, ['id', id])
    if (!sample) return

    onAction(token, id, `/sample-report/${sample.uniqueToken}`)
  }

  const onRemoveSample = async (id: string, isFully = false) => {
    setOpenConfirm(false)
    globalAction(notificationStore, {
      action: async () => await removeSample(id, isFully)
    })
  }

  return (
    <>
      <Button
        size="medium"
        variant="contained"
        sx={{ ml: 1 }}
        onClick={(event) => setAnchorMenu(event.currentTarget)}
      >
        <MoreHoriz />
      </Button>

      <Menu
        anchorEl={anchorMenu}
        open={openMenu}
        onClose={() => setAnchorMenu(null)}
        onClick={() => setAnchorMenu(null)}
      >
        <MenuItem onClick={() => navigate(`/samples/${uniqueToken}/details`)}>
          <MenuIcon><Visibility /></MenuIcon>
          {t('sample.samples.button.detail')}
        </MenuItem>
        <MenuItem
          disabled={canNotAddSampleInfo}
          onClick={() => {
            cuppingSessionId ?
              onAction(cuppingSessionUniqueToken, id, sampleInformationLink) :
              navigate(`/samples/${uniqueToken}`)
          }}
        >
          <MenuIcon><SvgIcon component={IconEdit} inheritViewBox /></MenuIcon>
          {t('common.edit')}
        </MenuItem>
        <MenuItem
          disabled={!canSeeReport}
          onClick={() => {
            isReview ?
              onSeeReport(cuppingSessionUniqueToken, id) :
              onSeeReport(uniqueToken, id)
          }}
        >
          <MenuIcon><Summarize /></MenuIcon>
          {t('sample.report')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenConfirm(true)
            setConfirmAction(() => () => onRemoveSample(id))
            setDescription(t('sample.removeSampleDescription'))
          }}
        >
          <MenuIcon><Archive /></MenuIcon>
          {t('sample.archive')}
        </MenuItem>
        <MenuItem
          disabled={!canDeleteSample}
          onClick={() => {
            setOpenConfirm(true)
            setConfirmAction(() => () => onRemoveSample(id, true))
            setDescription(t('sample.removeSampleDescription'))
          }}
        >
          <MenuIcon><DeleteOutline /></MenuIcon>
          {t('common.delete')}
        </MenuItem>
      </Menu>
    </>
  )
})

export const SampleItemMobile: React.FC<SampleItemMobileProps> = (
  props: SampleItemMobileProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    uniqueToken,
    approvalStatus,
    id,
    name,
    species,
    countryCode,
    grade,
    sampleType,
    averageScore,
    processName,
    isReview,
    ids,
    purchaseContractReference,
    salesContractReference,
    createdAt,
    sampleUniqueNumber,
    onCheck,
    onGetSamples,
  } = props;

  const [expanded, setExpanded] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorMenu);
  const [sampleId, setSampleId] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false)
  const [confirmAction, setConfirmAction] = useState(() => () => {})
  const [description, setDescription] = useState('')

  const mobileSampleCards = [
    {
      title: t("sample.samples.createdOn"),
      subtitle: moment(createdAt).format("DD/MM/YYYY"),
    },
    {
      title: t("sample.samples.species"),
      subtitle: species,
    },
    {
      title: t("sample.process"),
      subtitle: processName,
    },
    {
      title: t("sample.samples.purchaseContract"),
      subtitle: purchaseContractReference,
    },
    {
      title: t("sample.samples.salesContract"),
      subtitle: salesContractReference,
    },
    {
      title: t("sample.sampleGrade"),
      subtitle: grade,
    },
  ];

  const isChecked = ids.includes(id.toString());
  return (
    <Card className={classes.mobileWrapper}>
      <Grid container rowSpacing={1} className={classes.mobileFirstRow}>
    
          <Grid item xs={12} md={isReview ? 2 : 1}>
            <Grid container spacing={1} className={classes.firstRow}>
              <Grid item xs={2} md={6}>
                <Checkbox
                  sx={{
                    mt: 1,
                    color: "#8D57B2",
                    "&.Mui-checked": {
                      color: "#8D57B2",
                    },
                  }}
                  checked={isChecked}
                  onChange={onCheck}
                />
              </Grid>

              <Grid item xs={10} md={10} textAlign="left" marginLeft={-3}>
                <Typography variant="body1">
                  {name}{" "}
                  {!isEmpty(sampleUniqueNumber) && ` - ${sampleUniqueNumber}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={isReview ? 2 : 1}>
            <Box className={classes.mobileRowDetail}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  ml: 1,
                }}
              >
                <Box>
                  <IconButton size="small" color="primary" sx={{ mr: 0 }}>
                    <SvgIcon component={IconTotalScore} inheritViewBox />
                    <Typography
                      variant="body1"
                      sx={{ color: colors.text.primary }}
                    >
                      {averageScore}
                    </Typography>
                  </IconButton>
                </Box>
                <Box
                  sx={{ borderLeft: 1, pl: 1, pr: 1, borderColor: colors.text.o50 }}
                >
                  <Typography variant="body1" sx={{ color: colors.text.o50 }}>
                    {optionsTranslation('sampleType', sampleType!, true)}
                  </Typography>
                </Box>
                <Box
                  sx={{ borderLeft: 1, pl: 1, borderColor: colors.text.o50 }}
                >
                  <Typography variant="body1" sx={{ color: colors.text.o50 }}>
                    {countryCode}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={1} className={classes.mobileButtonWrapper}>
            <Grid
              item
              xs={1}
              md={6}
              className={classes.mobileButtonWrapperExpand}
            >
              <IconButton
                size="medium"
                onClick={() => setExpanded(!expanded)}
                sx={{ p: 0, mt: 1 }}
              >
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Grid>
          </Grid>

        <Grid item xs={12}>
          <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ ml: 1 }}>
            <Card sx={{ borderColor: colors.border.primary, padding: 1 }}>
              {mobileSampleCards.map((sampleCard, index) => (
                <Grid container rowSpacing={1} md key={index}>
                  <Grid item xs={4}>
                    <Box sx={{ mb: 1 }}>
                      <Typography variant="subtitle1">
                        {sampleCard.title === "Sample Id"
                          ? "ID"
                          : sampleCard.title}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Box sx={{ mb: 1 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: colors.text.o75 }}
                      >
                        {sampleCard.subtitle}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Card>
          </Collapse>
        </Grid>
      </Grid>
    </Card>
  );
};
