import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyList: {
      textAlign: 'center',
      marginTop: 100,
      color: "#444F58",
      [theme.breakpoints.down('md')]: {
        marginTop: 50
      }
    },
    buttonWrapper: {
      width: '100%',
      justifyContent: 'end',
      [theme.breakpoints.up('md')]: {
        width: 'fit-content',
      }
    },
    buttonWrapperMobile: {
      backgroundColor: 'white',
      paddingBottom: 12,
      borderRadius: 5,
    },
    accordion: {
      marginBottom: 8,
      padding: 0,
      alignItems: 'center',

      '& .MuiAccordionSummary-content': {
        flexDirection: 'column',
      }
    },
    contentMobileWrapper: {
      '& > div': {
        padding: theme.spacing(2),
        borderBottom: '1px solid #EBE8F2',
        backgroundColor: 'white',

        '&:first-child': {
          borderTop: '1px solid #EBE8F2',
        }
      }
    },
    contentMobile: {
      border: '1px solid #EBE8F2',
      borderRadius: 4,

      '& td': {
        verticalAlign: 'start',
      },

      '& tr > td:first-child': {
        fontWeight: 500,
      }
    },
    buttonRounded: {
      '&:hover': {
        '& svg': {
          fill: theme.palette.primary.dark
        }
      }
    },
    iconRounded: {
      width: 20,
      height: 20,
      backgroundColor: 'white',
      '& svg': {
        height: 16,
        fill: theme.palette.primary.main,
      }
    }
  })
)
