export const rejectedColors = {
    "cup": "#C7141E",
    "profile": "#DF6E74",
    "defect" : "#D4454D",
    "age": "#FADFE2",
    "baggy": "#FCCBD0",
    "chemical": "#F4B0B7",
    "ferment": "#EE9EA6",
    "fungus": "#E88C95",
    "mold": "#E0707B",
    "phenol": "#D85562",
    "rio": "#CC3140",
    "potato": "#A22C37",
    "rubber": "#7D1E27",
    "physical": "#D34E00",
    "moistureContent": "#FACBAF",
    "foreignMatter": "#FF7D30",
    "defectCount": "#F6B690",
    "screenSize": "#FF5F00",
    "density": "#EE9058",
    "otherReason": "#CFA200",
    "containerDamage": "#FFF5CE",
    "ochratoxin": "#FFDB5A",
    "glyphosate": "#FFF0B9",
    "other": "#FFC800",
    "aflatoxin": "#FFE484"

}