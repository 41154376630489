/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-07 06:31:19
 * @modify date 2022-05-29 21:09:23
 */

import React, {useCallback, useEffect} from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  some, filter, isEmpty, head, last, map,
  toNumber, toString, includes, pull, clone, chain, debounce, assign, chunk
} from 'lodash'
import {
    Box, Container, Grid, TextField, InputAdornment, FormControlLabel, Checkbox, Typography,
    Button, FormGroup, IconButton, Popover, Paper, Divider, Autocomplete, CircularProgress, Switch
} from '@mui/material'
import { Create as CreateIcon, Search as SearchIcon, HelpOutline, Close as CloseIcon } from '@mui/icons-material'
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { useStores } from 'models'
import { verticalCentered } from 'assets'
import { DEBOUNCE_TIME } from 'config/env'
import { findRoute } from 'constants/routes'
import { CuppingProtocolForm } from "modules"
import {errorStatus, errorMessage, countryName} from 'utils'
import {HeaderTitle, AvatarProfile, ModalConfirm, FormInput, Pagination} from 'components'
import { cuppingProtocols, sampleIdStructures, descriptorSetDefault, descriptorSets } from 'constants/form'

import { useStyles } from './cupping-sessions-form.styles'

export const CuppingSessionsForm: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const homeLink = findRoute('home')
  const cuppingSessionsLink = findRoute('cuppingSessions')
  const sampleInformationLink = findRoute('sampleInformation')
  const forbiddenLink = findRoute('forbidden')
  const {
    userStore: { timeZone, email },
    companyStore: {
      canInviteCuppers, canInviteGuestCuppers, maximumGuestInvitation, isDormantAccount, showPublicCupping
    },
    cupperStore: { getCuppers, cuppers, records, setValue: setValueCupperStore },
    cuppingSessionFormStore,
    cuppingSessionFormStore: {
      name, startsAt, endsAt, location, description, sampleIdStructure, cuppingProtocol, descriptorSet,
      numberOfSamples, blind, scoreTarget, hideScoreTarget, invitedCuppersIds, guestInvitations, uniqueToken, isPublic,
      hasBeenScored, comboCuppingFlag, numberOfCupsFlag, cupsPerSample,
      setValue: setFormValue, reset: resetFormValue, setCuppingSession, getCuppingSession, saveCuppingSession,
    },
    locationStore: { locations, getLocations, search: searchLocation, setValue: setLocationValue, reset: resetLocationValue },
    sampleStore: { getSamples },
    reviewSampleStore: { cuppingSamples, setValue: setReviewSampleValue },
    guideStore: { guideCupping },
    notificationStore: { setLoading, setNotification }
  } = useStores()

  const [anchorBlind, setAnchorBlind] = React.useState<HTMLButtonElement | null>(null)
  const openBlind = Boolean(anchorBlind)
  const [anchorNumberOfSample, setAnchorNumberOfSample] = React.useState<HTMLDivElement | null>(null)
  const openNumberOfSample = Boolean(anchorNumberOfSample)
  const [anchorPublicCupping, setAnchorPublicCupping] = React.useState<HTMLButtonElement | null>(null)
  const openPublicCupping = Boolean(anchorPublicCupping)
  const [anchorComboCupping, setAnchorComboCupping] = React.useState<HTMLButtonElement | null>(null)
  const openComboCupping = Boolean(anchorComboCupping)
  const [anchorCustomCups, setAnchorCustomCups] = React.useState<HTMLButtonElement | null>(null)
  const openCustomCups = Boolean(anchorCustomCups)
  const [startNow, setStartNow] = React.useState(true)
  const [guestName, setGuestName] = React.useState('')
  const [guestEmail, setGuestEmail] = React.useState('')
  const [openGuestConfirm, setOpenGuestConfirm] = React.useState(false)
  const [openLocation, setOpenLocation] = React.useState(false)
  const [loadingLocation, setLoadingLocation] = React.useState(false)
  const [isSaved, setIsSaved] = React.useState(false)
  const [teamIds, setTeamIds] = React.useState<String[]>([])
  const [guestIds, setGuestIds] = React.useState<any[]>([])
  const [isGuideCupping] = React.useState(guideCupping)
  const [errors, setErrors] = React.useState<any>({})
  const [tempForm, setTempForm] = React.useState<any>()
  const [samplePage, setSamplePage] = React.useState(0)
  const [dateError, setDateError] = React.useState(false)

  const newForm = params.id === 'new'

  const onErrorDate = (reason) => {
    if (reason) {
      setDateError(true)
    } else {
      setDateError(false)
    }
  }

  const inviteCupper = (cupper) => {
    if (includes(teamIds, cupper.id)) return

    setTeamIds([...teamIds, cupper.id])
  }

  const removeInviteCupper = (cupper) => {
    const newInvitedCuppers = clone(teamIds)
    pull(newInvitedCuppers, cupper.id)

    setTeamIds(newInvitedCuppers)
  }

  const addGuest = () => {
    setOpenGuestConfirm(false)
    if (guestIds.length >= maximumGuestInvitation) {
      setNotification({
        severity: 'error',
        message: t('cuppingSession.error.guestInvitationLimitMessage', {max: maximumGuestInvitation})
      })

      return
    }

    const guest = {
      guestName: guestName,
      guestEmail: guestEmail
    }

    if (!some(guestIds, guest)) {
      setGuestIds([...guestIds, guest])
    }

    setGuestName('')
    setGuestEmail('')
  }

  const removeGuest = (guest) => {
    const newGuestInvitations = filter(guestIds, v => v.guestEmail !== guest.guestEmail)

    setGuestIds(newGuestInvitations)
  }

  const listGuest = () => {
    if (isEmpty(guestIds)) {
      return (
        <Box sx={{mt: 3}}>
          <Typography textAlign='center' paragraph>
            {t('cuppingSession.noGuestCuppersInvited')}
          </Typography>
          <Typography variant='caption' textAlign='center' paragraph sx={{fontStyle: 'italic'}}>
            {t('cuppingSession.invitationCupperDescription')}
          </Typography>
          <Divider variant='middle' sx={{mx: "40%"}}/>
        </Box>
      )
    }

    return (
      guestIds.filter( x => x.guestName !== "anonymous").map((guest, index) =>
        <Box sx={{my: 1}} key={index} className={classes.guestItem}>
          {!guest?.id &&
            <IconButton
              color='error'
              onClick={() => removeGuest(guest)}
            >
              <CloseIcon />
            </IconButton>
          }
          <Typography sx={{mr: 2}}>{guest.guestName}</Typography>
          <Typography variant='caption'>{guest.guestEmail}</Typography>
        </Box>
      )
    )
  }

  const startCuppingNow = () => {
    const start = new Date()
    setFormValue('startsAt', new Date())
    if (start > endsAt) setFormValue('endsAt', start.setDate(start.getDate() + 7))
  }

  const onEditSamples = async () => {
    try {
      setLoading(true)
      await getSamples(uniqueToken)
      navigate(sampleInformationLink)
    } finally {
      setLoading(false)
    }
  }

  const navigateAfterSave = async () => {
    if (!newForm) {
      navigate(cuppingSessionsLink)
      return
    }

    await onEditSamples()
  }

  const validations = () => {
    let validationErrors = {}

    if (!name) {
      assign(validationErrors, {
        "name": [{ message: t('cuppingSession.error.fieldIsEmpty') }]
      })
    }

    if (!location) {
      assign(validationErrors, {
        "location": [{ message: t('cuppingSession.error.fieldIsEmpty') }]
      })
    }

    if (numberOfSamples < 1 || numberOfSamples >= 52) {
      assign(validationErrors, {
        "numberOfSamples": [{ message: t('cuppingSession.error.numberOfSampleLimitMessage', { min: 1, max: 52 }) }]
      })
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors)
      return false
    }

    return true
  }

  const saveCuppingForm = async () => {
    setErrors({})
    if (!validations()) return

    try {
      setLoading(true)
      setFormValue('invitedCuppersIds', teamIds)
      setFormValue('guestInvitations', guestIds)
      await saveCuppingSession()
      setIsSaved(true)
    } finally {
      setLoading(false)
    }
  }

  const renderCupperInvitation = (
    <>
      <Typography variant='h6' sx={{mb: 1}}>{t('cuppingSession.inviteCuppers')}</Typography>
      <Button
        variant="contained"
        onClick={() => setTeamIds(
          chain(cuppers).filter(cupper => { return cupper.email !== email }).map('id').value()
        )}
        sx={{mr: 2}}
      >
        {t('cuppingSession.selectAll')}
      </Button>
      <Button
        variant="outlined"
        onClick={() => setTeamIds(clone(invitedCuppersIds))}
      >
        {t('cuppingSession.clearAll')}
      </Button>

      <Paper variant="outlined" className={classes.cupperWrapper}>
        {cuppers.map(cupper => {
          if (!cupper.active || cupper.email === email) return null

          const isActive = includes(teamIds, cupper.id)
          const canRemove = !includes(invitedCuppersIds, cupper.id)

          return (
            <Box
              key={cupper.id}
              className={clsx(classes.cupperItem, isActive && 'active')}
              {...(!isActive && {onClick: () => inviteCupper(cupper)})}
            >
              {isActive && canRemove &&
                <IconButton
                  color='error'
                  onClick={() => removeInviteCupper(cupper)}
                  className={classes.removeCupper}
                >
                  <CloseIcon />
                </IconButton>
              }
              <AvatarProfile
                name={cupper.name}
                email={cupper.email}
              />
            </Box>
          )
        }
        )}
      </Paper>

      <Typography variant='caption' textAlign='center' paragraph sx={{fontStyle: 'italic'}}>
        {t('cuppingSession.invitationCupperDescription')}
      </Typography>
      <Divider variant='middle' sx={{mx: "40%"}}/>
    </>
  )

  const renderGuestCupperInvitation = (
    <Box>
      <FormInput
        label={t('cuppingSession.inviteGuestToCuppingSession')}
        textFieldProps={{
          placeholder: t('cuppingSession.name'),
          value: guestName,
          onChange: (event) => setGuestName(event.target.value)
        }}
      />

      <TextField
        fullWidth
        type='email'
        size='small'
        placeholder={t('cuppingSession.email')}
        value={guestEmail}
        onChange={(event) => setGuestEmail(event.target.value)}
        sx={{mb: 2}}
      />

      <Button
        variant="contained"
        onClick={() => setOpenGuestConfirm(true)}
        disabled={!guestName || !guestEmail}
      >
        {t('cuppingSession.addGuest')}
      </Button>

      <ModalConfirm
        open={openGuestConfirm}
        description={t('cuppingSession.addGuestConfirmDescription')}
        onClose={() => setOpenGuestConfirm(false)}
        onConfirm={addGuest}
      />

      {listGuest()}
    </Box>
  )

  const renderCuppingSamples = () => {
    const chuckCuppingSamples = chunk(cuppingSamples, 5)

    return (
      <Box mt={4}>
        <Typography variant='h6' sx={{mb: 1}}>{t('cuppingSession.samplesToBeCupped')}</Typography>

        <Box className={clsx(classes.sample, 'header')}>
          <Box>{t('sample.samples.name')}</Box>
          <Box>{t('sample.samples.origin')}</Box>
          <Box>{t('sample.samples.processing')}</Box>
        </Box>

        {chuckCuppingSamples[samplePage].map((sample: any) => (
          <Box className={clsx(classes.sample, 'content')}>
            <Box>{sample.name}</Box>
            <Box>{countryName(sample.countryCode)}</Box>
            <Box>{sample.processName}</Box>
          </Box>
        ))}

        <Pagination
          page={samplePage + 1}
          count={chuckCuppingSamples.length}
          onChange={(e, p) => setSamplePage(p - 1)}
        />
      </Box>
    )
  }

  const renderButtons = (
    <Grid item xs={12} textAlign='center'>
      <Button
        variant="contained"
        sx={{mr: 2}}
        className='wide'
        onClick={saveCuppingForm}
        disabled={dateError}
      >
        {t('common.save')}
      </Button>
      <Button
        variant="outlined"
        className='wide'
        onClick={() => {
          setLocationValue('search', tempForm.location)
          setCuppingSession(tempForm)
          setTeamIds(clone(invitedCuppersIds))
          setGuestIds(map(guestInvitations, clone))
        }}
      >
        {t('common.discard')}
      </Button>
    </Grid>
  )

  const findLocations = useCallback(
    debounce(async () => {
      try {
        setLoadingLocation(true)
        await getLocations()
      } finally {
        setLoadingLocation(false)
      }
    }, DEBOUNCE_TIME), []
  )

  useEffect(() => {
    if (!guideCupping && isGuideCupping) navigate(homeLink)
  }, [guideCupping])

  useEffect(() => {
    if (!isSaved) return

    navigateAfterSave()
  }, [isSaved])

  useEffect(() => {
    if (!searchLocation) return

    setFormValue('location', searchLocation)
    findLocations()
  }, [searchLocation])

  useEffect(() => {
    setTeamIds(clone(invitedCuppersIds))
  }, [JSON.stringify(invitedCuppersIds)])

  useEffect(() => {
    setGuestIds(map(guestInvitations, clone))
  }, [JSON.stringify(guestInvitations)])

  useEffect(() => {
    if (endsAt < startsAt) setFormValue('endsAt', startsAt)
  }, [startsAt, endsAt])

  useEffect(() => {
    if (isDormantAccount) navigate(forbiddenLink)

    resetFormValue()
    resetLocationValue()
    setFormValue('cuppingProtocol', head(cuppingProtocols))
    setFormValue('sampleIdStructure', head(sampleIdStructures)?.value)
    setValueCupperStore('page', 1)
    setValueCupperStore('cuppers', [])
    startCuppingNow()
    getCuppers(false, {size: records > 0 ? records : 1000})

    if (newForm) {
      const defaultName = `${t('cuppingSession.title')} ${moment().format('DD-MMM-YYYY')}`
      setTempForm(clone(cuppingSessionFormStore))
      setFormValue('numberOfSamples', cuppingSamples.length > 0 ? cuppingSamples.length : 1)
      setFormValue('sampleIds', map(cuppingSamples, 'id'))
      setFormValue('name', defaultName)
      setFormValue('location', timeZone)
      setLocationValue('search', timeZone)
      setFormValue('description', defaultName)
      return
    } else {
      setReviewSampleValue('cuppingSamples', [])
    }

    (async () => {
      try {
        setLoadingLocation(true)
        await getCuppingSession(params.id)
        setTempForm(clone(cuppingSessionFormStore))
        setLocationValue('search', cuppingSessionFormStore.location)
      } finally {
        setLoadingLocation(false)
      }
    })()
  }, [params.id])

  return (
    <>
      <HeaderTitle
        backUrl={cuppingSessionsLink}
        breadcrumb={t('common.backTo', { menu: t('menu.cuppingSessions')})}
        title={t('cuppingSession.startYourCuppingSession')}
      />

      <Container sx={{px: {xs: 0, md: 3}}}>
        <Grid container spacing={3} className={classes.wrapper}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              placeholder={t('cuppingSession.addSessionName')}
              value={name}
              onChange={(e) => setFormValue('name', e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <CreateIcon />
                  </InputAdornment>
                ),
              }}
              error={errorStatus('name', name, errors)}
              helperText={errorMessage('name', name, errors)}
            />

            <FormControlLabel
              label={`${t('cuppingSession.startCuppingNow')}`}
              control={
                <Checkbox
                  checked={startNow}
                  onChange={(e) => {
                    setStartNow(e.target.checked)
                    startCuppingNow()
                  }}
                />
              }
            />

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormInput label={t('cuppingSession.startDate')}>
                    <DatePicker
                      disablePast={newForm}
                      mask='____/__/__'
                      inputFormat="yyyy/MM/DD"
                      disabled={startNow}
                      value={startsAt}
                      onChange={(value) => setFormValue('startsAt', value.valueOf())}
                      renderInput={(params) => <TextField {...params} size='small' className={classes.date}/>}
                      onError={onErrorDate}
                    />
                  </FormInput>
                </Grid>
                <Grid item xs={6}>
                  <FormInput label={t('cuppingSession.startTime')}>
                    <TimePicker
                      disabled={startNow}
                      value={startsAt}
                      onChange={(value) => setFormValue('startsAt', value.valueOf())}
                      renderInput={(params) => <TextField {...params} size='small' className={classes.date}/>}
                      onError={onErrorDate}
                    />
                  </FormInput>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormInput label={t('cuppingSession.endDate')}>
                    <DatePicker
                      disablePast={newForm}
                      mask='____/__/__'
                      inputFormat="yyyy/MM/DD"
                      minDate={moment(startsAt)}
                      value={endsAt}
                      onChange={(value) => setFormValue('endsAt', value?.valueOf())}
                      renderInput={(params) => <TextField {...params} size='small' className={classes.date}/>}
                      onError={onErrorDate}
                    />
                  </FormInput>
                </Grid>
                <Grid item xs={6}>
                  <FormInput label={t('cuppingSession.endTime')}>
                    <TimePicker
                      value={endsAt}
                      onChange={(value) => setFormValue('endsAt', value.valueOf())}
                      renderInput={(params) => <TextField {...params} size='small' className={classes.date}/>}
                      onError={onErrorDate}
                    />
                  </FormInput>
                </Grid>
              </Grid>
            </LocalizationProvider>

            <Typography variant='caption' paragraph>
              {timeZone}
            </Typography>

            <FormInput label={t('cuppingSession.location')}>
              <Autocomplete
                freeSolo
                open={openLocation}
                onOpen={() => setOpenLocation(true)}
                onClose={() => setOpenLocation(false)}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option: string) => option}
                options={locations}
                loading={loadingLocation}
                value={searchLocation}
                onChange={(event, value) => setLocationValue('search', toString(value))}
                className={classes.autocomplete}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size='small'
                    placeholder={t('cuppingSession.selectLocation')}
                    onChange={(e) => setLocationValue('search',  e.target.value)}
                    InputProps={{
                      ...params.InputProps,
                      inputProps: {
                        ...params.inputProps,
                        maxLength: 50
                    },
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <>
                          {loadingLocation ? <CircularProgress color="inherit" size={16} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    error={errorStatus('location', location, errors)}
                    helperText={errorMessage('location', location, errors)}
                  />
                )}
              />
            </FormInput>

            <FormInput
              label={t('cuppingSession.description')}
              textFieldProps={{
                multiline: true,
                rows: 3,
                placeholder: t('cuppingSession.description'),
                value: description,
                onChange: (e) => setFormValue('description', e.target.value),
                error: errorStatus('description', description, errors),
                helperText: errorMessage('description', description, errors),
                inputProps: { maxLength: 255 }
              }}
            />

            <FormInput label={t('cuppingSession.sampleIdStructure')}>
              <FormGroup row>
                {sampleIdStructures.map(structure =>
                  <Button
                    key={structure.value}
                    variant={structure.value === sampleIdStructure ? 'contained' : 'outlined'}
                    className={classes.toggleButton}
                    onClick={() => setFormValue('sampleIdStructure', structure.value)}
                  >
                    {t(structure.label)}
                  </Button>
                )}
              </FormGroup>
            </FormInput>
            <CuppingProtocolForm
              selectProps={{
                disabled: !newForm,
                value: cuppingProtocol,
                onChange: (e) => setFormValue('cuppingProtocol', e.target.value)
              }}
            />

            <Box fontSize="small" color="#999999">
              <sup>1</sup>&nbsp;
              <span>{t('cuppingSession.scaFormBetaDescription')}</span>
            </Box>

            <FormInput label={t('cuppingSession.numberOfSamples')}>
              <FormGroup row>
                <TextField
                  type='tel'
                  size='small'
                  inputMode='numeric'
                  inputProps={{ min: "1", max: "52", pattern: '[0-9]*', size: "1" }}
                  value={numberOfSamples}
                  onChange={(e) => setFormValue('numberOfSamples', toNumber(e.target.value) || 0)}
                  onMouseEnter={(event) => setAnchorNumberOfSample(event.currentTarget)}
                  disabled={!newForm || cuppingSamples.length > 0}
                  sx={{minWidth: 50, maxWidth: 50, mr:1}}
                />
                {!newForm &&
                  <Popover
                    open={openNumberOfSample}
                    anchorEl={anchorNumberOfSample}
                    onClose={() => setAnchorNumberOfSample(null)}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    PaperProps={{
                      sx: {width: 300}
                    }}
                  >
                    <Typography sx={{p: 2}}>
                      {t(`cuppingSession.${hasBeenScored ? 'numberOfSamplePopoverScoredDescription' : 'numberOfSamplePopoverDescription'}`)}
                    </Typography>
                  </Popover>
                }

                <FormControlLabel
                  label={`${t('cuppingSession.blind')}`}
                  control={
                    <Checkbox
                      checked={blind}
                      onChange={(e) => setFormValue('blind', e.target.checked)}
                    />
                  }
                  sx={{mr: 0}}
                />

                <IconButton
                  color='primary'
                  onClick={(event) => setAnchorBlind(event.currentTarget)}
                >
                  <HelpOutline />
                </IconButton>
                <Popover
                  open={openBlind}
                  anchorEl={anchorBlind}
                  onClose={() => setAnchorBlind(null)}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    sx: {width: 300}
                  }}
                >
                  <Typography sx={{ p: 2 }}>{t('cuppingSession.blindDescription')}</Typography>
                </Popover>
              </FormGroup>
              {errorStatus('numberOfSamples', numberOfSamples, errors) &&
                <Typography variant='caption' color='error'>
                  {errorMessage('numberOfSamples', numberOfSamples, errors)}
                </Typography>
              }
            </FormInput>

            {cuppingProtocol === descriptorSetDefault && descriptorSet === last(descriptorSets)?.value &&
              <FormInput>
                <Box sx={{...verticalCentered}}>
                  <Typography variant="subtitle1">
                    {t('cuppingSession.cuppingScoreTarget')}
                  </Typography>
                  <IconButton
                    color='primary'
                    onClick={(event) => setAnchorBlind(event.currentTarget)}
                  >
                    <HelpOutline />
                  </IconButton>
                  <Popover
                    open={openBlind}
                    anchorEl={anchorBlind}
                    onClose={() => setAnchorBlind(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    PaperProps={{
                      sx: {width: 300}
                    }}
                  >
                    <Typography sx={{ p: 2 }}>{t('cuppingSession.blindDescription')}</Typography>
                  </Popover>
                </Box>

                <FormGroup>
                  <FormControlLabel
                    label={`${t('cuppingSession.addCuppingScoreTarget')}`}
                    control={
                      <Checkbox
                        checked={scoreTarget}
                        onChange={(e) => {
                          setFormValue('scoreTarget', e.target.checked)
                          setFormValue('hideScoreTarget', false)
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label={`${t('cuppingSession.hideCuppingScoreTarget')}`}
                    control={
                      <Checkbox
                        disabled={!scoreTarget}
                        checked={hideScoreTarget}
                        onChange={(e) => setFormValue('hideScoreTarget', e.target.checked)}
                      />
                    }
                  />
                </FormGroup>
              </FormInput>
            }

            {showPublicCupping && (
              <FormInput formControlProps={{sx: {mt: 0}}}>
                <Typography variant="subtitle1">
                  {t('cuppingSession.publicCupping')}
                </Typography>
                <FormGroup>
                  <Box sx={{ ...verticalCentered }}>
                    <FormControlLabel
                      label={t('cuppingSession.enablePublicCupping')}
                      control={
                        <Switch
                          checked={isPublic}
                          onChange={(e) => setFormValue('isPublic', e.target.checked)}
                        />
                      }
                    />
                    <IconButton
                      sx={{ml: -2}}
                      color='primary'
                      onClick={(event) => setAnchorPublicCupping(event.currentTarget)}
                    >
                      <HelpOutline />
                    </IconButton>

                    <Popover
                      open={openPublicCupping}
                      anchorEl={anchorPublicCupping}
                      onClose={() => setAnchorPublicCupping(null)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      PaperProps={{
                        sx: { width: 300 }
                      }}
                    >
                      <Typography sx={{ p: 2 }}>{t('cuppingSession.publicCuppingDescription')}</Typography>
                    </Popover>
                  </Box>
                </FormGroup>
              </FormInput>
            )}

            <FormInput formControlProps={{sx: {mt: 0}}}>
              <Typography variant="subtitle1">
                {t('cuppingSession.comboCupping')}
              </Typography>
              <FormGroup>
                <Box sx={{ ...verticalCentered }}>
                  <FormControlLabel
                    label={t('cuppingSession.enableComboCupping')}
                    control={
                      <Switch
                        checked={comboCuppingFlag}
                        onChange={(e) => setFormValue('comboCuppingFlag', e.target.checked)}
                      />
                    }
                  />
                  <IconButton
                    sx={{ml: -2}}
                    color='primary'
                    onClick={(event) => setAnchorComboCupping(event.currentTarget)}
                  >
                    <HelpOutline />
                  </IconButton>

                  <Popover
                    open={openComboCupping}
                    anchorEl={anchorComboCupping}
                    onClose={() => setAnchorComboCupping(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    PaperProps={{
                      sx: { width: 300 }
                    }}
                  >
                    <Typography sx={{ p: 2 }}>{t('cuppingSession.comboCuppingDescription')}</Typography>
                  </Popover>
                </Box>
              </FormGroup>
            </FormInput>

            <FormInput formControlProps={{sx: {mt: 0}}}>
              <Typography variant="subtitle1">
                {t('cuppingSession.customNumberOfCups')}
              </Typography>
              <FormGroup>
                <Box sx={{ ...verticalCentered }}>
                  <FormControlLabel
                    label={t('cuppingSession.enableCustomNumberOfCups')}
                    control={
                      <Switch
                        checked={numberOfCupsFlag}
                        onChange={(e) => {
                          setFormValue('numberOfCupsFlag', e.target.checked)
                          if (e.target.checked) setFormValue('cupsPerSample', 5)
                        }}
                      />
                    }
                  />
                  <IconButton
                    sx={{ml: -2}}
                    color='primary'
                    onClick={(event) => setAnchorCustomCups(event.currentTarget)}
                  >
                    <HelpOutline />
                  </IconButton>

                  <Popover
                    open={openCustomCups}
                    anchorEl={anchorCustomCups}
                    onClose={() => setAnchorCustomCups(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    PaperProps={{
                      sx: { width: 300 }
                    }}
                  >
                    <Typography sx={{ p: 2 }}>{t('cuppingSession.enableCustomNumberOfCups')}</Typography>
                  </Popover>
                </Box>
              </FormGroup>
            </FormInput>

            {numberOfCupsFlag && (
              <FormInput label={t('cuppingSession.cupsPerSample')} formControlProps={{sx: {mt: 0}}}>
                <FormGroup row>
                  <TextField
                    type='tel'
                    size='small'
                    inputMode='numeric'
                    inputProps={{ min: "1", max: "100", pattern: '[0-9]*', size: "1" }}
                    value={cupsPerSample}
                    onChange={(e) => setFormValue('cupsPerSample', toNumber(e.target.value) || 0)}
                    onMouseEnter={(event) => setAnchorNumberOfSample(event.currentTarget)}
                    sx={{minWidth: 50, maxWidth: 50, mr:1}}
                  />
                </FormGroup>
              </FormInput>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            {(canInviteCuppers || guideCupping) && renderCupperInvitation}
            {(canInviteGuestCuppers || guideCupping) && renderGuestCupperInvitation}

            {cuppingSamples.length > 0 && (
              renderCuppingSamples()
            )}
          </Grid>

          {renderButtons}
        </Grid>
      </Container>
    </>
  )
})

export default CuppingSessionsForm
