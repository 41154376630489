/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-03 20:13:36
 * @modify date 2022-02-04 19:30:45
 */

import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { startCase, upperCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import { TabPanel } from '@mui/lab'
import { CheckCircle, Cancel } from '@mui/icons-material'
import {
  Box, Grid, Typography, Button, Chip,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell
} from '@mui/material'

import { useStores } from 'models'
import { findRoute } from 'constants/routes'
import {DormantModal, ModalConfirm} from "components"
import { globalAction } from "utils";

import { useStyles } from './subscription.styles'

export const Subscription: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    companyStore: {
      cardNumber, subscriptionId, subscriptionPlanId, subscriptionPlanName, subscriptionStatus, trialDaysLeft, licensesNumber, variableNumberOfLicenses,
      freeLicensesNumber, freeGuestsNumber, hasGuestUsers, hasAccessToApi, hasGreenGradingAccess, isDormantAccount, initialPlanIdSelection
    },
    subscriptionStore: { paymentHistories, updateSubscription },
    modalStore: { setValue: setModalValue },
    guideStore: { guideLicense },
    notificationStore,
  } = useStores()

  const [openCancelSubscription, setOpenCancelSubscription] = React.useState(false)
  const [openDormantModal, setOpenDormantModal] = React.useState(false)
  const [openDormant, setOpenDormant] = React.useState(false)

  const planLink = findRoute('plan')
  const licenseLink = findRoute('license')
  const cardLink = findRoute('card')
  const showUpdateLisence = guideLicense || (variableNumberOfLicenses && subscriptionPlanId)

  const iconState = (status) => {
    if (status) return <CheckCircle color='primary' />

    return <Cancel color='error' />
  }

  const onRestorePlan = () => {
    globalAction(notificationStore, {
      action: async () => updateSubscription(initialPlanIdSelection),
    })
  }

  const contents = [
    {
      leftContent: t('subscription.plan'),
      rightContent:
        <>
          <Box sx={{display: 'flex'}}>
            <Typography sx={{mr: 1}}>{startCase(subscriptionPlanName)}</Typography>
            {subscriptionStatus === 'trialing' &&
              <Typography variant='caption'>{t('subscription.trialPeriodEnds', {days: trialDaysLeft})}</Typography>
            }
          </Box>
          {isDormantAccount &&
            <Button className='btn-text' onClick={onRestorePlan}>
              {t('subscription.restorePlan')}
            </Button>
          }
          {!isDormantAccount &&
            <Link to={planLink} onClick={() => setModalValue('setting', false)}>
              <Button className='btn-text'>{t('subscription.changePlan')}</Button>
            </Link>
          }
        </>
    },
    {
      leftContent: t('subscription.card'),
      rightContent:
        <>
          {cardNumber || '-'}
          {subscriptionPlanId ?
            <Link to={cardLink} onClick={() => setModalValue('setting', false)}>
              <Button className='btn-text'>{t('subscription.changeCard')}</Button>
            </Link> :
            <Button className='btn-text' onClick={() => setModalValue('addCard', true)}>
              {t('subscription.addCard')}
            </Button>
          }
        </>
    },
    ...( showUpdateLisence ? [{
        leftContent: t('subscription.numberOfLicenses'),
        rightContent:
          <>
            {licensesNumber}
            <Link to={licenseLink} onClick={() => setModalValue('setting', false)}>
              <Button className='btn-text'>{t('subscription.updateNumberOfLicenses')}</Button>
            </Link>
          </>
      }] : []
    ),
    ...(freeLicensesNumber > 0 ?
      [{
        leftContent: t('subscription.numberOfFreeLicenses'),
        rightContent: freeLicensesNumber
      }] : []
    ),
    ...(freeGuestsNumber > 0 ?
      [{
        leftContent: t('subscription.numberOfFreeGuest'),
        rightContent: freeGuestsNumber
      }] : []
    ),
    {
      leftContent: t('subscription.guestUsers'),
      rightContent: iconState(hasGuestUsers)
    },
    {
      leftContent: t('subscription.apiAccess'),
      rightContent: iconState(hasAccessToApi || false)
    },
    {
      leftContent: t('subscription.greenGradingAccess'),
      rightContent: iconState(hasGreenGradingAccess || false)
    }
  ]

  const gridWrapper = (index, leftContent, rightContent) => {
    return (
      <Grid item key={index} xs={12} className={classes.gridItem}>
        <Grid container>
          <Grid item xs={4} className={classes.leftContent}>
            {leftContent}
          </Grid>
          <Grid item xs={8} className={classes.rightContent}>
            {rightContent}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const status = (status) => {
    const label = t(`subscription.${status}`)
    switch (status) {
      case "paid":
        return <Chip label={label} color="success" />
      default:
        return <Chip label={label} color="warning" />
    }
  }

  const tableContent = (history, index) => {
    return (
      <TableRow key={index}>
        <TableCell>{moment(history.date).format('DD MMM YYYY')}</TableCell>
        <TableCell>{startCase(history.name)}</TableCell>
        <TableCell>{history.card}</TableCell>
        <TableCell align='center'>{upperCase(history.currency)} {history.amount / 100}</TableCell>
        <TableCell align='center'>{status(history.status)}</TableCell>
      </TableRow>
    )
  }

  return (
    <TabPanel value="subscription">
      <Grid container rowSpacing={3} sx={{mt: 1, mb: 3}}>
        {contents.map((content, index) => gridWrapper(index, content.leftContent, content.rightContent))}
      </Grid>

      {subscriptionId && !isDormantAccount &&
        <Box sx={{textAlign: 'right'}}>
          <Button
            variant='outlined'
            size='small'
            onClick={() => setOpenCancelSubscription(true)}
          >
            {t('subscription.cancelSubscription')}
          </Button>
        </Box>
      }

      <Typography variant="h6" sx={{mt: 5}}>
        {t('subscription.paymentHistory')}
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('subscription.date')}</TableCell>
              <TableCell>{t('subscription.plan')}</TableCell>
              <TableCell>{t('subscription.card')}</TableCell>
              <TableCell align='center'>{t('subscription.amount')}</TableCell>
              <TableCell align='center'>{t('subscription.status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentHistories.map((history, index) => tableContent(history, index))}
          </TableBody>
        </Table>
      </TableContainer>

      <DormantModal
        rootStore={useStores()}
        open={openDormantModal}
        setOpen={(status) => {
          setOpenDormantModal(status)
        }}
        openDormant={openDormant}
        setOpenDormant={(status) => {
          setOpenDormant(status)
          setOpenCancelSubscription(!status)
        }}
        isSubscription
      />

      <ModalConfirm
        align="left"
        open={openCancelSubscription}
        onClose={() => {
          setOpenDormantModal(true)
          setOpenCancelSubscription(false)
        }}
        onConfirm={() => {
          setOpenDormant(true)
          setOpenCancelSubscription(false)
        }}
        onCloseIcon={() => {
          setOpenDormant(false)
          setOpenCancelSubscription(false)
        }}
        title={t('subscription.cancelModal.title')}
        description={t('subscription.cancelModal.description')}
        confirm={t('subscription.cancelModal.button.dormantMode')}
        cancel={t('subscription.cancelModal.button.noThanks')}
      />
    </TabPanel>
  )
})
