import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import {cloneDeep, find} from "lodash";
import {useTranslation} from "react-i18next";
import {Box, Checkbox, FormControlLabel} from "@mui/material";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";

import {optionsTranslation} from "utils";
import {BasicTable} from "components";
import {colors} from "assets";

const columnHelper = createColumnHelper<any>()

export const SubComponent = ({token, childSamples, renderValue, rowSelectionChild, setRowSelectionChild}) => {
  const { t } = useTranslation()
  const [rowSelection, setRowSelection] = useState(rowSelectionChild[token] ?? {})

  const styles = {
    backgroundColor: colors.primary.o25,
    padding: '8px',
    borderRadius: '4px',
    '& table.TableGrid': {
      '& tr': {
        '& td': {
          borderBottom: 'none !important',

          '&:first-child': {
            borderTopLeftRadius: '8px',
          },

          '&:last-child': {
            borderTopRightRadius: '8px',
          }
        }
      }
    }
  }

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: ({ table }) =>
        <Box textAlign="left">
          <FormControlLabel
            label={t('sample.samples.name')}
            control={
              <Checkbox
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
            }
          />
        </Box>,
      cell: info =>
        <Box textAlign="left">
          <FormControlLabel
            label={info.renderValue()}
            control={
              <Checkbox
                checked={info.row.getIsSelected()}
                onChange={info.row.getToggleSelectedHandler()}
              />
            }
          />
        </Box>,
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => <Box textAlign="left">{t('sample.samples.createdOn')}</Box>,
      cell: info => moment(info.renderValue()).format('DD/MM/YYYY'),
    }),
    columnHelper.accessor('species', {
      id: 'species',
      header: () => <Box textAlign="left">{t('sample.samples.species')}</Box>,
      cell: info => optionsTranslation('cuppingProtocol', info.renderValue(), true),
    }),
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left">{t('sample.samples.type')}</Box>,
      cell: info => optionsTranslation('sampleType', info.renderValue(), true),
    }),

    columnHelper.accessor('salesContractReference', {
      id: 'salesContractReference',
      header: () => <Box textAlign="left">{t('sample.samples.salesContract')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('averageScore', {
      id: 'averageScore',
      header: () => t('sample.samples.averageScore'),
      cell: info => <Box textAlign="center">{info.renderValue()}</Box>,
    }),
  ]

  const table = useReactTable({
    data: cloneDeep(childSamples),
    columns,
    state: { rowSelection },
    enableRowSelection: true,
    manualPagination: true,
    autoResetPageIndex: false,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.uniqueToken,
  })

  useEffect(() => {
    const tokens = Object.keys(rowSelection)
    const selectedSamples = tokens.map(token => {
      let sample = find(childSamples, ['uniqueToken', token])

      return cloneDeep(sample)
    })

    setRowSelectionChild(token, rowSelection, selectedSamples)
  }, [rowSelection])

  return (
    <Box sx={styles}>
      <BasicTable table={table} />
    </Box>
  )
}
