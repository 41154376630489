import { Api } from "./api"

export class GreenGradingApi extends Api {
  constructor() {
    super('green_gradings', [
      'color', 'weight', 'smell', 'defects', 'sampleId', 'greenGradingTypeId',
      'roastColor', 'ovenTest', 'otaTest', 'glyphosateTest', 'density', 'moisture', 'waterActivity',
      'size_10', 'size_11', 'size_12', 'size_13', 'size_14', 'size_15', 'size_16', 'size_17', 'size_18', 'size_19', 'size_20',
      'picture', 'picture_2', 'quaker_number', 'yield_factor'
    ])
  }
}
