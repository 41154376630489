/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-06 18:36:35
 * @modify date 2022-03-06 18:36:35
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { centered, colors, horizontalCentered, verticalCentered } from 'assets'

export const heightCarousel = 250

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoundedCalibration: {
      borderRadius: 5,
    },
    buttonRounded: {
      borderRadius: 99,
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    profile: {
      ...verticalCentered,
      width: 'fit-content',
      maxHeight: 50,
      justifyContent: 'space-between',
      border: `1px solid ${colors.border.primary}`,
      borderRadius: 99,
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
      cursor: 'pointer',
      '&.active': {
        borderColor: colors.primary.main
      }
    },
    profileScore: {
      fontWeight: 600,
      fontSize: 14,
      color: 'white',
    },
    table: {
      borderCollapse: 'collapse',
      fontSize: '0.75rem',
      marginBottom: theme.spacing(1),
      '& td': {
        border: `1px solid ${colors.border.primary}`,
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

        '&:first-child': {
          fontWeight: 500
        },

        '&:last-child': {
          color: colors.primary.main
        }
      },
    },
    carouselWrapper: {
      display: 'flex'
    },
    carousel: {
      flex: 1,
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
      }
    },
    noImageWrapper: {
      ...centered,
      height: heightCarousel,
      textTransform: 'uppercase',
      fontWeight: 700,
      border: `1px solid ${colors.text.o25}`,
      '& svg': {
        fontSize: 50,
        width: '100%',
        marginRight: theme.spacing(1)
      }
    },
    imageWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%'
    },
    imageDelete: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 'auto',
      marginBottom: 'auto',
      width: 20,
      height: 20,
      backgroundColor: 'white !important'
    },
    boxWrapper: {
      borderRadius: 5,
      padding: theme.spacing(1),
      backgroundColor: colors.primary.main,
      color: "white",
      fontSize: 'smaller',
    },
    aggregateForm: {
      marginBottom: theme.spacing(2),

      [theme.breakpoints.up('md')]: {
        width: '60%',
      }
    },
    tableSyncSummary: {
      marginTop: theme.spacing(2),

      '& thead': {
        backgroundColor: colors.primary.o25,
      }
    },
    sampleNavWrapper: {
      overflowY: 'auto',
      height: 'calc(100vh - 200px)',
    },
    sampleNav: {
      cursor: 'pointer',
      padding: theme.spacing(2),
      paddingTop: 12,
      paddingBottom: 12,
      flex: 1,

      [theme.breakpoints.up('md')]: {
        backgroundColor: colors.brightGray,
        border: `1px solid ${colors.primary.o25}`,
      },

      '&:nth-child(n+2)': {
        borderTop: 'none'
      },

      '&.active': {
        backgroundColor: 'white',
        borderRight: 'none'
      }
    },
    tabPanelWrapper: {
      padding: theme.spacing(2),
      border: `1px solid ${colors.primary.o25}`,
      overflowY: 'auto',
      height: 'calc(100vh - 180px)',

      [theme.breakpoints.up('sm')]: {
        backgroundColor: 'white',
        borderLeft: 'none',
      }
    },
  })
)

export const indicatorContainer = {
  display: 'flex',
  overflowX: 'auto' as const,
  overflowY: 'hidden' as const
}

export const carouselIndicator = {
  width: 50,
  height: 50,
  margin: 4,
  borderRadius: 5,
  border: `1px solid ${colors.border.primary}`
}
