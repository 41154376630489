import { Api } from "./api"

export class SampleTransactionGuestApi extends Api {
  constructor() {
    super('sample_transactions', [
      "uniqueToken", "approvalStatus", "reason", "reasonJson", "qcReason", 'itemIds',
      'flavorDescriptors', 'defectsDescriptors', 'defects', 'totalScore', 'notes'
    ], { prefix: 'counter_party' })
  }
}
