import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {cloneDeep, isEmpty} from "lodash";
import {observer} from "mobx-react-lite";
import {useParams, useNavigate, useSearchParams} from "react-router-dom";
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
  } from '@tanstack/react-table'
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography
} from "@mui/material";

import {useStores} from "models";
import {HeaderTitle} from "components";
import {findRoute} from "constants/routes";
import {countryName, flavorWheel, globalAction, optionsTranslation} from "utils";
import {
  emptyFlavorWheel,
  Logo,
} from "assets/images";

import {useStyles} from "./sales-contract-details.styles";
import {colors} from "assets";
import {BasicTable} from "components";
import clsx from "clsx";

const columnHelper = createColumnHelper<any>()

export const SalesContractDetails = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const params = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const {
    companyStore: { base64Logo, isEnterprisePlus },
    contractStore: {getSalesContractDetail, getSalesContractSample,samples,
      contractDetails: { averageScore, species, cropYear, sampleGrade, producer,
        numberOfUnit, weight, coffeeProcessing, country, certification, aggregatedFlavorWheelGroups,
        aggregatedFlavorWheelOnlyFlavor, aggregatedFlavorWheelOnlyFragrance }
    },
    notificationStore
  } = useStores()

  const [tab, setTab] = useState('samples')
  const [ids, setIds] = React.useState<String[]>([])
  const [cuppingProtocols, setCuppingProtocols] = React.useState<String[]>([])
  const [disableCompare, setDisableCompare] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [isFragranceWheel, setIsFragranceWheel] = React.useState(true)
  const [isFlavorWheel, setIsFlavorWheel] = React.useState(true)

  const homeLink = findRoute('home')
  const compareLInk = findRoute('compare')


  const data = cloneDeep(samples)

  const poweredImage = (absolute = false) => {
    return (
      <Box className={clsx(classes.powered, absolute && 'absolute')}>
        <Typography variant='body3' sx={{mr: 0.5}}>{t('report.poweredBy')}</Typography>
        <Logo width={50} height={25} />
      </Box>
    )
  }

  const contractDetails = [
    {
      label: t('contracts.detail.species'),
      value: optionsTranslation('species', species)
    },
    {
      label: t('contracts.detail.cropYear'),
      value: cropYear
    },
    {
      label: t('contracts.detail.sampleGrade'),
      value: optionsTranslation('grades', sampleGrade)
    },
    {
      label: t('contracts.detail.producer'),
      value: producer
    },
    {
      label: t('contracts.detail.numberOfUnit'),
      value: numberOfUnit
    },
    {
      label: t('contracts.detail.weightUnit'),
      value: weight
    },
    {
      label: t('contracts.detail.coffeeProcessing'),
      value: coffeeProcessing
    },
    {
      label: t('contracts.detail.country'),
      value: countryName(country)
    },
    {
      label: t('contracts.detail.certifications'),
      value: certification
    },
  ]


  const columns = [
    columnHelper.accessor('sampleUniqueNumber', {
      id: 'sampleUniqueNumber',
      header: () => <Box textAlign="left" ml="30px">{t('sample.samples.id')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left">{t('contracts.samples.table.sampleType')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('sampleName', {
      id: 'sampleName',
      header: () => <Box textAlign="left">{t('contracts.samples.table.sampleName')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('customer', {
      id: 'customer',
      header: () => <Box textAlign="left">{t('contracts.samples.table.customer')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('averageScore', {
      id: 'averageScore',
      header: () => <Box textAlign="left">{t('contracts.samples.table.averageScore')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('topThreeDescriptors', {
      id: 'topThreeDescriptors',
      header: () => <Box textAlign="left">{t('contracts.samples.table.topThreeDescriptor')}</Box>,
      cell: info => info.renderValue(),
    })
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),

  })

  const getContractDetail = () => {
    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        await getSalesContractDetail(params.id, true)
        await getSalesContractSample(params.id, true)
      },
    })
  }


  React.useEffect(() => {
    setDisableCompare(true)

    if (ids.length < 2 || ids.length > 4) return

    const sameProtocol = cuppingProtocols.every( (val, i, arr) => val === arr[0] )
    setDisableCompare(!sameProtocol)
  }, [ids.length])

  React.useEffect(() => {

    if (isFragranceWheel && isFlavorWheel) {
      flavorWheel(JSON.parse(aggregatedFlavorWheelGroups), isEnterprisePlus && base64Logo)
      return
    }

    if (isFragranceWheel) {
      flavorWheel(JSON.parse(aggregatedFlavorWheelOnlyFragrance), isEnterprisePlus && base64Logo)
      return
    }

    flavorWheel(JSON.parse(aggregatedFlavorWheelOnlyFlavor), isEnterprisePlus && base64Logo)

  }, [isFragranceWheel, isFlavorWheel])

  useEffect(() => {

    getContractDetail()

  }, [params.id])

  useEffect(() => {
    if (searchParams.has('tab')) {
      setTab(searchParams.get('tab') || 'samples')
    }
  }, [])

  return (
    <Box>
      <HeaderTitle
        backUrl={homeLink}
        breadcrumb={t('common.backTo', { menu: t('menu.home')})}
  
      />

      <Container>
        <Box sx={{mb: 1}}>
        <Typography variant='h4'>{t('contracts.title')} {params.id}</Typography>
        </Box>
        <Box className={classes.wrapper} sx={{ px: 3, py: 5 }}>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={5}>
          <Typography variant='subtitle1'>{t('contracts.detail.contractDetail')}</Typography>
          <Box className={classes.sampleDetail}>
            <Grid container spacing={2} columns={10} mt={2}>
              {contractDetails.map((item, index) => (
                <Grid item md={5} key={index}>
                  {item.label && <Typography variant="subtitle1">{item.label}:</Typography>}
                  {item.value && <Typography variant="body2" fontWeight={600}  color={colors.primary.main}>{item.value}</Typography>}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Typography variant='subtitle1'>{t('contracts.detail.sampleProfile')}</Typography>

          {isEmpty(aggregatedFlavorWheelGroups) ?
            <img src={emptyFlavorWheel} height="90%" /> :
            <div id="tastify-wheel" style={{width: '90%', height: '90%'}}></div>
          }

          <Box sx={{textAlign: 'center', flexDirection: 'row', p: 1}}>
            {poweredImage()}
          <FormControlLabel
              label={`${t('descriptor.fragranceOrAroma')}`}
              control={
                <Checkbox
                  size="small"
                  disabled={!isFlavorWheel}
                  checked={isFragranceWheel}
                  onChange={() => setIsFragranceWheel(!isFragranceWheel)}
                />
              }
            />

            <FormControlLabel
              label={`${t('descriptor.flavorOrAftertaste')}`}
              control={
                <Checkbox
                  size="small"
                  defaultChecked={true}
                  disabled={!isFragranceWheel}
                  checked={isFlavorWheel}
                  onChange={() => setIsFlavorWheel(!isFlavorWheel)}
                />
              }
            />

          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box sx={{
            flexWrap: 'wrap',
            alignContent: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'row',
            height:'100%'
          }}>
            <Typography variant='h4'>{t('contracts.detail.averageScore')}</Typography>
            <Typography variant='h3'>{averageScore}</Typography>
            {poweredImage()}
          </Box>
        </Grid>
      </Grid>
        </Box>
        <Typography variant='h4'>Samples</Typography>
        <Box className={classes.wrapper}>
        <BasicTable
            table={table}
            isFetching={loading}
            tableProps={{ className: classes.table }}
        />
        </Box>

      </Container>

    </Box>
  )
})

export default SalesContractDetails
