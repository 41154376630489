/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-28 20:22:31
 * @modify date 2022-02-28 20:22:31
 */

import React from 'react'
import {every, includes, isEmpty, without} from "lodash"
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {
  Tab,
  Card,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  TextField
} from '@mui/material'
import { TabContext, TabList } from '@mui/lab'
import { isMobile } from "react-device-detect"
import ReactHtmlParser from 'react-html-parser'

import { useStores } from 'models'
import { ScaSlide, Descriptor } from "components"
import { scaDescriptorList} from "constants/sca-descriptors"
import { menuList } from 'constants/form'
import { CuppingProtocol } from "constants/constant"

import { useStyles } from './sca.styles'
import { Cup } from "../index"

const reachMaxCheckboxSelection = (items: any[], maxSelection = 2) => {
  const count = items.filter(Boolean).length

  return count >= maxSelection;
}

export const Sca: React.FC = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { sampleStore: {
    selectedSample: { id, sampleId, cupsPerSample, tab, setValue: setSampleValue, selectedScore: {
      fragrance, fragranceIntensity, fragranceAromaDescriptors, aroma, aromaIntensity, fragranceAromaNote,
      flavor, flavorIntensity, flavorAftertasteDescriptors, aftertaste, aftertasteIntensity, flavorAftertasteNote,
      saltyTaste, sourTaste, sweetTaste, bitterTaste, umamiTaste,
      acidity, acidityIntensity, dryAcidity, mediumAcidity, sweetAcidity, acidityNote, mouthfeelNote,
      mouthfeel, mouthfeelIntensity, roughMouthfeel, creamyMouthfeel, smoothMouthfeel, mouthDryingMouthfeel, metallicMouthfeel,
      sweetnessScore, sweetnessIntensity, sweetnessNote, overall, overallNote, nonUniform, nonUniformScore, defective, defects, defectsDescriptors,
      setScoreValue, onClickCup, cuppingProtocol, loadedLocalStorage
    }}
  },
  } = useStores()

  const fiveCups = cupsPerSample === 5
  const isSca = cuppingProtocol === CuppingProtocol.SCA
  const isScaDescriptive = cuppingProtocol === CuppingProtocol.SCA_DESCRIPTIVE
  const isScaAffective = cuppingProtocol === CuppingProtocol.SCA_AFFECTIVE
  const hideTab = !isSca && (isScaDescriptive || isScaAffective)

  const mainTastes = [
    {
      label: t('descriptor.saltyTaste'),
      value: saltyTaste,
      onClick: () => setScoreValue('saltyTaste', !saltyTaste)
    },
    {
      label: t('descriptor.sourTaste'),
      value: sourTaste,
      onClick: () => setScoreValue('sourTaste', !sourTaste)
    },
    {
      label: t('descriptor.sweetTaste'),
      value: sweetTaste,
      onClick: () => setScoreValue('sweetTaste', !sweetTaste)
    },
    {
      label: t('descriptor.bitterTaste'),
      value: bitterTaste,
      onClick: () => setScoreValue('bitterTaste', !bitterTaste)
    },
    {
      label: t('descriptor.umamiTaste'),
      value: umamiTaste,
      onClick: () => setScoreValue('umamiTaste', !umamiTaste)
    }
  ]
  const acidityTastes = [
    {
      label: t('descriptor.dryAcidity'),
      value: dryAcidity,
      onClick: () => setScoreValue('dryAcidity', !dryAcidity)
    },
    {
      label: t('descriptor.sweetAcidity'),
      value: sweetAcidity,
      onClick: () => setScoreValue('sweetAcidity', !sweetAcidity)
    }
  ]
  const mouthfeelTastes = [
    {
      label: t('descriptor.roughMouthfeel'),
      value: roughMouthfeel,
      onClick: () => setScoreValue('roughMouthfeel', !roughMouthfeel)
    },
    {
      label: t('descriptor.creamyMouthfeel'),
      value: creamyMouthfeel,
      onClick: () => setScoreValue('creamyMouthfeel', !creamyMouthfeel)
    },
    {
      label: t('descriptor.smoothMouthfeel'),
      value: smoothMouthfeel,
      onClick: () => setScoreValue('smoothMouthfeel', !smoothMouthfeel)
    },
    {
      label: t('descriptor.mouthDryingMouthfeel'),
      value: mouthDryingMouthfeel,
      onClick: () => setScoreValue('mouthDryingMouthfeel', !mouthDryingMouthfeel)
    },
    {
      label: t('descriptor.metallicMouthfeel'),
      value: metallicMouthfeel,
      onClick: () => setScoreValue('metallicMouthfeel', !metallicMouthfeel)
    }
  ]

  const reachMaxFlavorAftertasteTastes = reachMaxCheckboxSelection([saltyTaste, sourTaste, sweetTaste, bitterTaste, umamiTaste])
  const reachMaxAcidityTastes = reachMaxCheckboxSelection([dryAcidity, mediumAcidity, sweetAcidity], 1)
  const reachMaxMouthfeelTastes = reachMaxCheckboxSelection([roughMouthfeel, creamyMouthfeel, smoothMouthfeel, mouthDryingMouthfeel, metallicMouthfeel])

  const selectDefectDescriptor = (e, checked) => {
    if (checked) {
      setScoreValue('defectsDescriptors', [...defectsDescriptors, e.target.value])
      return
    }

    setScoreValue('defectsDescriptors', without(defectsDescriptors, e.target.value))
  }

  const renderDescriptor = (descriptor, descriptorList, scoreName, tastes: any[] = [], maxSelection = false) => {
    const emptyTastes = isEmpty(tastes)

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={emptyTastes ? 12 : 6}>
          <Box className={classes.descriptorWrapper}>
            <Typography variant="body2" className={classes.tasteText}>
              {ReactHtmlParser(t('descriptor.selectUpTo', { count: 5 }))}
            </Typography>
            <Descriptor
              key={`fragrance${descriptor}`}
              sampleId={sampleId}
              descriptors={descriptor}
              descriptorList={descriptorList}
              addDescriptor={(values) => setScoreValue(scoreName, values)}
              isSca
            />
          </Box>
        </Grid>
        {!emptyTastes &&
          <Grid item xs={12} md={6}>
            <Box className={classes.descriptorWrapper}>
              <Typography variant="body2" className={classes.tasteText}>
                {ReactHtmlParser(t('descriptor.mainTastesUpTo', { count: 2 }))}
              </Typography>
              <Card className={classes.tasteWrapper}>
                {tastes.map(taste => (
                  <FormControlLabel
                    key={taste.label}
                    label={taste.label}
                    control={<Checkbox checked={taste.value} onClick={taste.onClick} disabled={!taste.value && maxSelection}/>}
                    className={classes.tasteForm}
                  />
                ))}
              </Card>
            </Box>
          </Grid>
        }
      </Grid>
    )
  }

  const renderMenu = (label, value, scoreName) => {
    return (
      <Box className={classes.menuWrapper}>
        <Typography variant='h6' fontSize={isMobile ? '12px': '14px'}>{label.toUpperCase()}</Typography>
        <FormControl size="small" sx={{ m: 1, width: isMobile ? 175 : 225 }}>
          <Select value={value} onChange={(e) => setScoreValue(scoreName, e.target.value)}>
            {menuList.map((menu, index) =>
              <MenuItem key={index} value={menu.value}>
                <Typography variant="h6" color={menu.color} fontSize={isMobile ? '12px': '14px'}>
                  {`${menu.value} ${t(menu.label)}`}
                </Typography>
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>
    )
  }

  const renderNote = (value, scoreName) => {
    return (
      <Box sx={{mt: 2}}>
        <Typography variant='body2' sx={{mb: 2}}>{t('descriptor.note')}</Typography>
        <TextField
          fullWidth
          multiline
          minRows={isMobile ? 2 : 4}
          value={value}
          inputProps={{ maxLength: 200 }}
          onChange={(e) => setScoreValue(scoreName, e.target.value)}
          placeholder={t('sampleScore.notePlaceholder')}
        />
      </Box>
    )
  }

  const renderDescriptive = () => {
    return (
      <Card sx={{p: isMobile ? 0 : 2}}>
        <Grid container spacing={isMobile ? 0 : 2}>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <ScaSlide
                label={t('descriptor.fragrance')}
                value={fragranceIntensity}
                onChange={(value) => setScoreValue('fragranceIntensity', value)}
              />
              <ScaSlide
                label={t('descriptor.aroma')}
                value={aromaIntensity}
                onChange={(value) => setScoreValue('aromaIntensity', value)}
              />

              {renderDescriptor(fragranceAromaDescriptors, scaDescriptorList, 'fragranceAromaDescriptors')}
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <ScaSlide
                label={t('descriptor.flavor')}
                value={flavorIntensity}
                onChange={(value) => setScoreValue('flavorIntensity', value)}
              />
              <ScaSlide
                label={t('descriptor.aftertaste')}
                value={aftertasteIntensity}
                onChange={(value) => setScoreValue('aftertasteIntensity', value)}
              />

              {renderDescriptor(flavorAftertasteDescriptors, scaDescriptorList, 'flavorAftertasteDescriptors', mainTastes, reachMaxFlavorAftertasteTastes)}
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <ScaSlide
                label={t('descriptor.acidity')}
                value={acidityIntensity}
                onChange={(value) => setScoreValue('acidityIntensity', value)}
              />

              <Box className={classes.descriptorWrapper}>
                <Typography variant="body2" className={classes.tasteText}>
                  {ReactHtmlParser(t('descriptor.mainTastes', { count: 1 }))}
                </Typography>
                <Card className={classes.tasteWrapper}>
                  {acidityTastes.map(taste => (
                    <FormControlLabel
                      key={taste.label}
                      label={taste.label}
                      control={<Checkbox checked={taste.value} onClick={taste.onClick} disabled={!taste.value && reachMaxAcidityTastes} />}
                      className={classes.tasteForm}
                    />
                  ))}
                </Card>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <ScaSlide
                label={t('descriptor.sweetness')}
                value={sweetnessIntensity}
                onChange={(value) => setScoreValue('sweetnessIntensity', value)}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <ScaSlide
                label={t('descriptor.mouthfeel')}
                value={mouthfeelIntensity}
                onChange={(value) => setScoreValue('mouthfeelIntensity', value)}
              />

              <Box className={classes.descriptorWrapper}>
                <Typography variant="body2" className={classes.tasteText}>
                  {ReactHtmlParser(t('descriptor.mainTastesUpTo', { count: 2 }))}
                </Typography>
                <Card className={classes.tasteWrapper}>
                  {mouthfeelTastes.map(taste => (
                    <FormControlLabel
                      key={taste.label}
                      label={taste.label}
                      control={<Checkbox checked={taste.value} onClick={taste.onClick} disabled={!taste.value && reachMaxMouthfeelTastes} />}
                      className={classes.tasteForm}
                    />
                  ))}
                </Card>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Card>
    )
  }

  const renderAffective = () => {
    return (
      <Card sx={{p: isMobile ? 0 : 2}}>
        <Grid container spacing={isMobile ? 0 : 2}>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              {renderMenu(t('descriptor.fragrance'), fragrance, 'fragrance')}
              {renderMenu(t('descriptor.aroma'), aroma, 'aroma')}
              {renderNote(fragranceAromaNote, 'fragranceAromaNote')}
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              {renderMenu(t('descriptor.flavor'), flavor, 'flavor')}
              {renderMenu(t('descriptor.aftertaste'), aftertaste, 'aftertaste')}
              {renderNote(flavorAftertasteNote, 'flavorAftertasteNote')}
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              {renderMenu(t('descriptor.acidity'), acidity, 'acidity')}
              {renderNote(acidityNote, 'acidityNote')}
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              {renderMenu(t('descriptor.sweetness'), sweetnessScore, 'sweetnessScore')}
              {renderNote(sweetnessNote, 'sweetnessNote')}
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              {renderMenu(t('descriptor.mouthfeel'), mouthfeel, 'mouthfeel')}
              {renderNote(mouthfeelNote, 'mouthfeelNote')}
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              {renderMenu(t('descriptor.overall'), overall, 'overall')}
              {renderNote(overallNote, 'overallNote')}
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <Cup
                key={`non-uniform-cups${id}-${loadedLocalStorage}`}
                isSca
                label={t('descriptor.nonUniformCups')}
                cups={nonUniform}
                score={nonUniformScore}
                onClick={(index) => onClickCup(index, nonUniform, 'nonUniform')}
                fiveCups={fiveCups}
                onChange={(value) => setScoreValue('nonUniformScore', value)}
              />
              <Cup
                key={`defective-cups${id}-${loadedLocalStorage}`}
                isScaDefect
                label={t('descriptor.defectiveCups')}
                cups={defective}
                score={defects}
                onClick={(index) => onClickCup(index, defective, 'defective')}
                fiveCups={fiveCups}
                onChange={(value) => setScoreValue('defects', value)}
              />

              {defects > 0 &&
                <Grid container spacing={2} alignItems="center" sx={{mt: 0}}>
                  <Grid item xs={12} md={5}>
                    <Typography variant="h6">Defect Name</Typography>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <FormControlLabel
                      label={t('descriptor.mold')}
                      control={<Checkbox value='mold' checked={includes(defectsDescriptors, 'mold')} onChange={selectDefectDescriptor} />}
                    />
                    <FormControlLabel
                      label={t('descriptor.phenol')}
                      control={<Checkbox value='phenol' checked={includes(defectsDescriptors, 'phenol')} onChange={selectDefectDescriptor} />}
                    />
                    <FormControlLabel
                      label={t('descriptor.potato')}
                      control={<Checkbox value='potato' checked={includes(defectsDescriptors, 'potato')} onChange={selectDefectDescriptor} />}
                    />
                  </Grid>
                </Grid>
              }
            </Card>
          </Grid>
        </Grid>
      </Card>
    )
  }

  React.useEffect(() => {
    setScoreValue('nonUniformScore', nonUniform.filter(Boolean).length * 2)
  }, [nonUniform])

  React.useEffect(() => {
    if (every(defective, e => e === false)) setScoreValue('defectsDescriptors', [])

    setScoreValue('defects', defective.filter(Boolean).length * 4)
  }, [defective])

  React.useEffect(() => {
    if (isScaDescriptive) {
      setSampleValue('tab', 'descriptive')
    }

    if (isScaAffective) {
      setSampleValue('tab', 'affective')
    }
  }, [cuppingProtocol])

  return (
    <TabContext value={tab}>
      <TabList
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        onChange={(event, value) => {
          setSampleValue('tab', value)
        }}
        sx={{
          display: hideTab ? 'none' : 'flex',
        }}
        className={classes.tab}
      >
        {(isScaDescriptive || isSca) &&
          <Tab value={'descriptive'} label={t('descriptor.descriptive')} />
        }
        {(isScaAffective || isSca) &&
          <Tab value={'affective'} label={t('descriptor.affective')} />
        }
      </TabList>

      {tab === 'descriptive' && renderDescriptive()}
      {tab === 'affective' && renderAffective()}
    </TabContext>
  )
})
