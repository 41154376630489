import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { verticalCentered } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expandWrapper: {
      ...verticalCentered,
      cursor: 'pointer',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1)
    }
  })
)