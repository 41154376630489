import React from 'react';
import {Link} from "react-router-dom";
import {Button, CssBaseline, Stack, Typography} from "@mui/material";
import {ArrowCircleLeftOutlined} from "@mui/icons-material";
import { ThemeProvider } from '@mui/material/styles'
import i18n from 'i18n/i18n'
import Rollbar from "rollbar";

import { ROLLBAR, PRODUCTION_MODE } from "config/env"
import {centered, dashboardTheme} from "assets";
import {underDevelopment} from "assets/images";

const rollbar = new Rollbar(ROLLBAR)

class ErrorBoundary extends React.Component<{logout}, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  stopError() {
    this.setState({hasError: false})
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    if (PRODUCTION_MODE) rollbar.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ThemeProvider theme={dashboardTheme}>
          <CssBaseline />
          <Stack sx={{...centered, flexDirection: 'column', flex: 1, p: 3}} height='100vh'>
            <img src={underDevelopment} alt='not-found' style={{maxWidth: 600, width: '100%'}} />
            <Typography variant='h5' sx={{mt: 3}} textAlign="center">
              {i18n.t('error.somethingWrongDescription')}
            </Typography>
            <Typography variant='h6' sx={{mt: 2}}>
              {i18n.t('error.youCanTryTo')}:
            </Typography>

            <Stack direction='row' spacing={2} my={2}>
              <Button
                variant='contained'
                className='wide'
                onClick={() => window.location.reload()}
                sx={{mt: 3}}
              >
                {i18n.t('error.button.refresh')}
              </Button>
              <Button
                variant='contained'
                className='wide'
                onClick={() => {
                  this.stopError()
                  this.props.logout()
                  window.location.href = '/'
                }}
                sx={{mt: 3}}
              >
                {i18n.t('error.button.logout')}
              </Button>
            </Stack>

            <Link to={'/'} onClick={() => this.stopError()}>
              <Stack direction='row' spacing={1} sx={centered}>
                <ArrowCircleLeftOutlined />
                <Typography variant='body1'>
                  {i18n.t('error.button.backToDashboard')}
                </Typography>
              </Stack>
            </Link>
          </Stack>
        </ThemeProvider>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary
