import React, { useState } from 'react'
import {useTranslation} from "react-i18next"
import {LoadingButton} from "@mui/lab"
import {Box, Button, Stack, TextField, Typography} from "@mui/material"

import {useStores} from "models"
import {ModalWrapper} from "components"
import {SampleShipmentApproval} from "constants/constant"

interface DeclineModalProps {
  isOpen: boolean
  isMultipleDecline : boolean
  onClose: () => void
  id: string | undefined
  transactionItemId?: string
  uniqueTokenData?: any
  onSuccess: () => void
}

export const DeclineModal: React.FC<DeclineModalProps> = ({ isOpen, onClose, id, transactionItemId, onSuccess, uniqueTokenData, isMultipleDecline }) => {
  const { t } = useTranslation()
  const { sampleOrderStore: { updateApprovalStatus, bulkDeclineSampleOrders }, notificationStore } = useStores()
  const [reason, setReason] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async () => {
    try {
      setLoading(true)

      if(isMultipleDecline){
        await bulkDeclineSampleOrders({
          itemIds: uniqueTokenData,
          approvalStatus: SampleShipmentApproval.DECLINED,
          reason: reason
        })

      } else {
        await updateApprovalStatus(id, {
          uniqueToken: transactionItemId,
          approvalStatus: SampleShipmentApproval.DECLINED,
          reason: reason
        })
      }


      setReason('')
      onSuccess()
    } catch (e: any) {
      e.errors.base?.map(message => notificationStore.setNotification(message))
    } finally {
      setLoading(false)
    }
  }

  return (
    <ModalWrapper
      open={isOpen}
      containerProps={{
        maxWidth: 'sm'
      }}
    >
      <Box textAlign="center">
        <Typography variant="h5" mb={2}>
          {t('order.declineModal.title')}
        </Typography>
        <Typography variant="body2" mb={2}  >
          {t('order.declineModal.description')}
        </Typography>

        <Typography variant="body2">
          {t('order.declineModal.pleaseWriteReason')}:
        </Typography>
        <TextField
          multiline
          fullWidth
          minRows={3}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />

        <Stack direction="row" spacing={2} mt={2} justifyContent="center">
          <LoadingButton
            variant="contained"
            sx={{width: 200}}
            loading={loading}
            disabled={!reason}
            onClick={onSubmit}
          >
            {t(`order.button.decline`)}
          </LoadingButton>
          <Button
            variant="outlined"
            sx={{width: 200}}
            onClick={onClose}
          >
            {t("common.cancel")}
          </Button>
        </Stack>
      </Box>
    </ModalWrapper>
  )
}
