import React from "react";
import {useTranslation} from "react-i18next";
import {Button, ButtonProps, Menu, MenuItem} from "@mui/material";
import {KeyboardArrowDown} from "@mui/icons-material";

import {optionsTranslation} from "utils";
import {SampleShipmentApproval} from "constants/constant";

interface ApproveRejectButtonProps {
  approvalStatus: any
  onApprove: (hasApproval: boolean) => void
  onReject: (hasApproval: boolean) => void
  approveChildren?: React.ReactNode
  rejectChildren?: React.ReactNode
  buttonProps?: ButtonProps
}

export const ApproveRejectButton = (props: ApproveRejectButtonProps) => {
  const { t } = useTranslation()
  const {approvalStatus, approveChildren, onApprove, rejectChildren, onReject, buttonProps} = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const color = () => {
    if (approvalStatus === SampleShipmentApproval.APPROVED) {
      return 'success'
    }
    if (approvalStatus === SampleShipmentApproval.REJECTED) {
      return 'error'
    }

    return 'secondary'
  }

  if (approvalStatus) {
    return (
      <>
        <Button
          variant="contained"
          size="small"
          color={color()}
          endIcon={<KeyboardArrowDown />}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          {...buttonProps}
        >
          {optionsTranslation('approvalStatus', approvalStatus)}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              onApprove(!!approvalStatus)
            }}
          >
            {t('shipment.button.approve')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              onReject(!!approvalStatus)
            }}
          >
            {t('shipment.button.reject')}
          </MenuItem>
        </Menu>
      </>
    )
  }

  return (
    <>
      <Button
        variant="contained"
        size="small"
        color="success"
        onClick={() => onApprove(!!approvalStatus)}
        sx={{minWidth: 'fit-content'}}
        {...buttonProps}
      >
        {approveChildren ?? t('shipment.button.approve')}
      </Button>
      <Button
        variant="contained"
        size="small"
        color="error"
        onClick={() => onReject(!!approvalStatus)}
        sx={{minWidth: 'fit-content'}}
        {...buttonProps}
      >
        {rejectChildren ?? t('shipment.button.reject')}
      </Button>
    </>
  )
}
