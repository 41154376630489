import React, {useState} from "react"
import {useTranslation} from "react-i18next"
import {createSearchParams} from "react-router-dom"
import {ExpandMore} from '@mui/icons-material'
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography} from "@mui/material"

import {ModalWrapper} from "components"
import {CuppingResults, GreenGradings} from "../index"

export const GenerateReport = (props) => {
  const { t } = useTranslation()
  const [selectedCuppingResult, setSelectedCuppingResult] = useState('')
  const [selectedGreenGrading, setSelectedGreenGrading] = useState('')

  const onGenerate = () => {
    const searchParams = createSearchParams({
      sample_score_id: selectedCuppingResult,
      green_grading_id: selectedGreenGrading
    })

    window.open(`/combined-report?${searchParams.toString()}`, '_blank')
  }

  return (
    <ModalWrapper
      open={props.open}
      containerProps={{
        sx: {'& .MuiAccordion-root': { boxShadow: 'none', '&:before': { display: 'none' } }}
      }}
    >
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box>
            <Typography variant="h5">{t('generateReport.cuppingResult.title')}</Typography>
            <Typography variant="body2">{t('generateReport.cuppingResult.description')}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <CuppingResults isReport selectedCuppingResult={selectedCuppingResult} setSelectedCuppingResult={setSelectedCuppingResult} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box>
            <Typography variant="h5">{t('generateReport.greenGrading.title')}</Typography>
            <Typography variant="body2">{t('generateReport.greenGrading.description')}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <GreenGradings isReport selectedGreenGrading={selectedGreenGrading} setSelectedGreenGrading={setSelectedGreenGrading} />
        </AccordionDetails>
      </Accordion>

      <Stack direction="row" justifyContent="end" spacing={2} mt={4}>
        <Button
          size="small"
          variant="contained"
          onClick={onGenerate}
        >
          {t('generateReport.generate')}
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={props.onClose}
        >
          {t('common.cancel')}
        </Button>
      </Stack>
    </ModalWrapper>
  )
}
