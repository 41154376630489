import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {centered, colors} from "assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      borderRadius: 9,
      border: `1px solid ${colors.primary.o25}`,
    },
    tableHeader: {
      borderRight: `1px solid ${colors.primary.o25}`,
      borderBottom: `0px solid`,
    },
    tableRow: {
      borderLeft: `1px solid ${colors.primary.o25}`,
      borderRight: `1px solid ${colors.primary.o25}`,
      borderBottom: `0px solid`,
      borderTop: `1px solid ${colors.primary.o25}`,
      '&.row': {
        borderTop: `0px solid`,
      }
    }
  })
)