import React, {useEffect, useRef, useState} from "react"
import {cloneDeep, has, isEmpty, uniq} from "lodash";
import { useTranslation } from "react-i18next"
import {useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {isMobile} from "react-device-detect";
import {
	Box,
	Container,
	Typography,
	Stack,
	FormControlLabel,
	Checkbox,
	Button, IconButton, Select, MenuItem, TextField, InputAdornment,
	Accordion, AccordionSummary, AccordionDetails, Divider, Autocomplete
} from "@mui/material"
import {Add, Delete, Edit, ExpandLess, ExpandMore, Feed} from "@mui/icons-material"
import {
  createColumnHelper,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from "@tanstack/react-table";
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'

import {useStores} from "models";
import { findRoute } from 'constants/routes'
import {
	BasicTable, FormInput,
	HeaderTitleWide,
	LightTooltip,
	ModalConfirm,
	ModalMessage,
	ShipmentOrderForm
} from "components"
import {countries, countryName, globalAction, optionsTranslation, str2bool} from "utils"
import {fulfillmentTypes} from "constants/form"
import {GenerateLabel, PrintLabel, MultipleGenerateLabel} from "pages/modal";
import {SampleShipmentFulfillmentStatus} from "constants/constant";
import { SampleInformationModal } from "modules"
import {colors} from "assets";

import { useStyles } from "./new.styles"
import {AddSamplesModal, ShipmentTable} from "../index";

const columnHelper = createColumnHelper<any>()

export const NewShipmentForm = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()
  const params = useParams()

  const {
    companyStore: { name: companyName },
    sampleShipmentStore: {
      samplesToBeShipment, shipmentForm, groupSamplesToBeShipment, isLabelComplete, isFormComplete, labelIncomplete, sampleShipmentCounter,
      setSamplesToBeShipment, setThirdPartyEmails, saveSampleShipment, removeSampleShipments, resetShipmentForm,
      setSamplesToBeShipmentSample, setSamplesToBeShipmentGenerateLabel, setSamplesToBeShipmentMultiGenerateLabel, generateUncompletedLabels
    },
    notificationStore
  } = useStores()

  const homeLink = findRoute('home')
  const [rowSelection, setRowSelection] = useState({})
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState(cloneDeep(samplesToBeShipment))
  const [openAddSampleModal, setOpenAddSampleModal] = useState(false)
  const [openSuccessAddedSample, setOpenSuccessAddedSample] = useState(false)
  const [openSuccessEditedSample, setOpenSuccessEditedSample] = useState(false)
  const [openConfirmShipment, setOpenConfirmShipment] = useState(false)
  const [openSuccessCreatedShipment, setOpenSuccessCreatedShipment] = useState(false)
  const [openConfirmRemove, setOpenConfirmRemove] = useState(false)
  const [openSuccessRemovedSample, setOpenSuccessRemovedSample] = useState(false)
	const refInput = useRef('')
  const [openGenerateLabel, setOpenGenerateLabel] = useState(false)
  const [openMultipleGenerateLabel, setMultipleOpenGenerateLabel] = useState(false)
  const [openPrintLabel, setOpenPrintLabel] = useState(false)
  const [openSampleModal, setOpenSampleModal] = useState(false)
  const [indexSamples, setIndexSamples] = useState(-1)
  const [openLabelMessage, setOpenLabelMessage] = useState(false)

  const isRequestNewSample = params.id === SampleShipmentFulfillmentStatus.NEW_REQUEST

  const resetRefInput = () => {
	  refInput.current = ''
  }

	const onSetDataValue = (rowIndex, key, value) => {
		setData(old => {
			const newData = [...old]
			let currentData = newData[rowIndex]

			if (has(currentData, key)) currentData[key] = value
			if (has(currentData, `sample.${key}`)) currentData.sample[key] = value
			if (key === 'sampleFulfillmentType' && value === fulfillmentTypes[0]) {
				currentData.sampleLocation = companyName
			}

			newData[rowIndex] = currentData
			return newData
		})
	}

	const renderTextField = (info, field) => {
		const key = field + info.row.index

		return (
			<TextField
				fullWidth
				size='small'
				autoFocus={key === refInput.current}
				value={info.renderValue()}
				onChange={(e) => {
					refInput.current = key
					onSetDataValue(info.row.index, field, e.target.value)
				}}
				sx={{minWidth: 150}}
			/>
		)
	}

  const renderEditSampleWeight = (info) => {
    if (!isEdit) {
      return (
        <Box textAlign="center">
          {info.renderValue() ? `${info.renderValue()} gr` : '-' }
        </Box>
      )
    }

    const key = 'sampleWeight' + info.row.index
    return (
      <CurrencyTextField
        fullWidth
        size='small'
        type='text'
        inputMode='numeric'
        variant='outlined'
        textAlign='left'
        currencySymbol=''
        value={info.renderValue()}
        outputFormat="string"
        decimalCharacter="."
        digitGroupSeparator=","
        minimumValue="0"
        decimalPlaces={0}
        autoFocus={key === refInput.current}
        onChange={(event, value)=> {
	        refInput.current = key
          onSetDataValue(info.row.index,'sampleWeight', value)
        }}
        InputProps={{
	        sx: {minWidth: 125},
          endAdornment: (<InputAdornment position="end">gr</InputAdornment>)
        }}
      />
    )
  }

  const renderSubItem = (label, value) => {
    const val = value ? value : '-'
    return (
      <Box>
        <Typography variant="subtitle1">{label}</Typography>
        <Typography variant="body2">{val}</Typography>
      </Box>
    )
  }

  const renderSubComponent = ({ row }) => {
	  const { isRoasted, sample, deliveryInformation } = row.original

    const items = [
      { label: t('shipment.table.producer'), value: sample.producerName },
      { label: t('shipment.table.salesContract'), value: sample.salesContractReference },
      { label: t('shipment.table.processing'), value: sample.processName },
    ]

    return (
	    <Box className={classes.collapseWrapper}>
		    <Stack direction="row" spacing={2} justifyContent="space-between">
			    {isEdit ?
				    <FormInput
					    label={t('shipment.table.roasted')}
					    formControlProps={{
						    sx: {
							    backgroundColor: 'transparent',
							    width: 'auto',
							    '& .MuiInputBase-formControl': {
								    backgroundColor: 'white'
							    }
						    }
					    }}
				    >
					    <Select
						    size='small'
						    value={isRoasted}
						    onChange={(e) => {
							    resetRefInput()
							    onSetDataValue(row.index, 'isRoasted', str2bool(e.target.value))
						    }}
					    >
						    {[true, false].map(bool =>
							    <MenuItem key={bool.toString()} value={bool.toString()}>
								    {optionsTranslation('boolean', bool.toString())}
							    </MenuItem>
						    )}
					    </Select>
				    </FormInput> :
				    <Box>
					    <Typography variant="subtitle1">{t('shipment.table.roasted')}</Typography>
					    <Typography variant="body2">{optionsTranslation('boolean', isRoasted.toString())}</Typography>
				    </Box>
			    }
	        {items.map(item => renderSubItem(item.label, item.value))}
	      </Stack>

		    {isEdit ?
			    <Box mt={2}>
				    <FormInput
					    label={t('shipment.table.deliveryNotes')}
					    textFieldProps={{
						    value: deliveryInformation,
						    onChange: (e) => onSetDataValue(row.index, 'deliveryInformation', e.target.value),
						    multiline: true,
						    rows: 2,
					    }}
					    formControlProps={{
						    sx: {
							    backgroundColor: 'transparent',
							    '& .MuiInputBase-formControl': {
								    backgroundColor: 'white'
							    }
						    }
					    }}
				    />
			    </Box> :
			    <Box mt={2}>
				    <Typography variant="subtitle1">{t('shipment.table.deliveryNotes')}</Typography>
				    <Typography variant="body2">{deliveryInformation ?? '-'}</Typography>
			    </Box>
		    }
	    </Box>
    )
  }

  const renderActionRowTable = (index) => (
    <Stack direction="row" spacing={1} className={classes.buttonWrapper}>
      <Button
        fullWidth={isMobile}
        variant="contained"
        size="small"
        disabled={isEdit}
        onClick={() => {
          setIndexSamples(parseInt(index))
          setOpenGenerateLabel(true)
        }}
        startIcon={<Feed />}
        sx={{backgroundColor: isEmpty(samplesToBeShipment[index].label) ? colors.secondary.main : colors.primary.main,}}
      >
        {isMobile && t('shipment.table.generateLabel')}
      </Button>
      <Button
        fullWidth={isMobile}
        variant="contained"
        size="small"
        disabled={isEdit}
        onClick={() => {
          setIndexSamples(parseInt(index))
          setOpenSampleModal(true)
        }}
        startIcon={<Edit />}
      >
        {isMobile && t('common.edit')}
      </Button>
    </Stack>
  )

  const renderEditActionButtons = (
    <Stack direction="row" spacing={2} justifyContent="end" mt={3}>
      <Button
        variant="contained"
        sx={{width: 200}}
        onClick={() => {
          setSamplesToBeShipment(data)
          setIsEdit(false)
          setOpenSuccessEditedSample(true)
        }}
      >
        {t(`shipment.button.saveChanges`)}
      </Button>
      <Button
        variant="outlined"
        sx={{width: 200}}
        onClick={() => {
          setData(cloneDeep(samplesToBeShipment))
          setIsEdit(false)
        }}
      >
        {t("common.cancel")}
      </Button>
    </Stack>
  )

  const renderGroupSamplesToBeShipment = () => {
    return Object.keys(groupSamplesToBeShipment).map(group => {
      return (
        <ShipmentTable
          key={group}
          groupSamplesToBeShipment={groupSamplesToBeShipment}
          group={group}
          onSubmitEmail={setThirdPartyEmails}
        />
      )
    })
  }

  const columns = [
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: ({ table }) =>
        <Box textAlign="left">
          <FormControlLabel
            label={t('shipment.table.type')}
            control={
              <Checkbox
	              disabled={isEdit}
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
            }
          />
        </Box>,
      cell: info =>
        <Box textAlign="left">
          <FormControlLabel
            label={optionsTranslation('sampleType', info.renderValue(), true)}
            control={
              <Checkbox
	              disabled={isEdit}
                checked={info.row.getIsSelected()}
                onChange={info.row.getToggleSelectedHandler()}
              />
            }
          />
        </Box>,
    }),
	  columnHelper.accessor('sample.purchaseContractReference', {
		  id: 'sample.purchaseContractReference',
		  header: () => <Box textAlign="left">{t('shipment.table.purchaseContract')}</Box>,
		  cell: info => info.renderValue(),
	  }),
	  columnHelper.accessor('sample.purchaseGrade', {
		  id: 'sample.purchaseGrade',
		  header: () => <Box textAlign="left">{t('shipment.table.purchaseGrade')}</Box>,
		  cell: info => info.renderValue()
	  }),
    columnHelper.accessor('sampleLocation', {
      id: 'sampleLocation',
      header: () => <Box textAlign="left">{t('shipment.table.sampleLocation')}</Box>,
	    cell: info => isEdit ? renderTextField(info, 'sampleLocation') : info.renderValue()
    }),
	  columnHelper.accessor('sample.countryCode', {
		  id: 'sample.countryCode',
		  header: () => <Box textAlign="left">{t('shipment.table.country')}</Box>,
		  cell: info => countryName(info.renderValue()),
	  }),
	  columnHelper.accessor('sample.warehouseReference', {
		  id: 'sample.warehouseReference',
		  header: () => <Box textAlign="left">{t('shipment.table.warehouse')}</Box>,
		  cell: info => info.renderValue()
	  }),
    columnHelper.accessor('sampleFulfillmentType', {
      id: 'sampleFulfillmentType',
      header: () => <Box textAlign="left">{t('shipment.table.fulfillmentType')}</Box>,
      cell: info => isEdit ?
        <Select
          size='small'
          value={info.renderValue()}
          onChange={(e) => {
            resetRefInput()
            onSetDataValue(info.row.index,'sampleFulfillmentType', e.target.value)
          }}
        >
          {fulfillmentTypes.map(item =>
            <MenuItem key={item} value={item}>{optionsTranslation('fulfillmentType', item)}</MenuItem>
          )}
        </Select> :
        optionsTranslation('fulfillmentType', info.renderValue()),
    }),
    columnHelper.accessor('isApprovalNeeded', {
      id: 'isApprovalNeeded',
      header: () => t('shipment.table.approvalNeeded'),
      cell: info => isEdit ?
        <Select
          size='small'
          value={info.renderValue()}
          onChange={(e) => {
            resetRefInput()
            onSetDataValue(info.row.index,'isApprovalNeeded', str2bool(e.target.value))
          }}
        >
          {[true, false].map(bool =>
            <MenuItem key={bool.toString()} value={bool.toString()}>
              {optionsTranslation('boolean', bool.toString())}
            </MenuItem>
          )}
        </Select> :
        <Box textAlign="center">
          {optionsTranslation('boolean', info.renderValue()?.toString())}
        </Box>,
    }),
    columnHelper.accessor('sampleWeight', {
      id: 'sampleWeight',
      header: () => t('shipment.table.weight'),
      cell: renderEditSampleWeight,
    }),
    columnHelper.accessor('isRoasted', {
      id: 'isRoasted',
      header: () => t('shipment.table.roasted'),
      cell: info => isEdit ?
        <Select
          size='small'
          value={info.renderValue()}
          onChange={(e) => {
            resetRefInput()
            onSetDataValue(info.row.index,'isRoasted', str2bool(e.target.value))
          }}
        >
          {[true, false].map(bool =>
            <MenuItem key={bool.toString()} value={bool.toString()}>
              {optionsTranslation('boolean', bool.toString())}
            </MenuItem>
          )}
        </Select> :
        <Box textAlign="center">
          {optionsTranslation('boolean', info.renderValue().toString())}
        </Box>,
    }),
    columnHelper.accessor('label', {
      id: 'label',
      header: () => t('shipment.table.action'),
      cell: info => renderActionRowTable(info.row.index),
    }),
    columnHelper.accessor('expand', {
      id: 'expand',
      header: () => '',
      cell: info =>
        <Box textAlign="center">
          <IconButton
            size="small"
            color="primary"
            onClick={info.row.getToggleExpandedHandler()}
          >
            {info.row.getIsExpanded() ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>,
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    state: { rowSelection, expanded },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    manualExpanding: true,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: row => row.sampleId,
    getCoreRowModel: getCoreRowModel(),
  })

  const onSaveSampleShipment = async () => {
    setOpenConfirmShipment(false)

    globalAction(notificationStore, {
      action: saveSampleShipment,
      afterAction: () => setOpenSuccessCreatedShipment(true)
    })
  }

  const onRemoveSamples = () => {
    const selections = uniq(Object.keys(rowSelection).map(key => parseInt(key)))
    const samples = removeSampleShipments(selections)
    setRowSelection({})
    setData(samples)
    setOpenConfirmRemove(false)
    setOpenSuccessRemovedSample(true)
  }

  const onGenerateLabels = () => {
    generateUncompletedLabels()
    setOpenLabelMessage(false)
    setOpenConfirmShipment(true)
  }

  const renderShipmentForm = (
    <Box className={classes.wrapper}>
      <ShipmentOrderForm {...shipmentForm} setValue={shipmentForm.setValue} />
    </Box>
  )

  const renderSampleInformationMobile = (
    <Box className={classes.wrapper}>
      <Stack direction="row" spacing={1} mb={1}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<Add />}
          onClick={() => setOpenAddSampleModal(true)}
        >
          {t("shipment.button.addSample")}
        </Button>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<Delete />}
          onClick={() => setOpenConfirmRemove(true)}
          disabled={isEmpty(rowSelection)}
        >
          {t("shipment.button.removeSample")}
        </Button>
      </Stack>

      <Button
        fullWidth
        variant="contained"
        startIcon={<Edit />}
        onClick={() => setIsEdit(true)}
        disabled={isEdit}
        sx={{mb: 2}}
      >
        {t("shipment.button.edit")}
      </Button>

      {data.map((row, index) => (
        <Accordion key={row.uniqueToken} className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Stack direction="row" spacing={1}>
              <Checkbox
                checked={rowSelection[index] ?? false}
                onChange={(e) => {
                  e.stopPropagation()

                  setRowSelection({
                    ...rowSelection,
                    [index]: !rowSelection[index]
                  })
                }}
              />

              <Box>
                <Typography variant='h6'>{row.sampleName}</Typography>
                <Stack
                  direction="row"
                  spacing={0.5}
                  divider={<Divider orientation="vertical" flexItem sx={{borderColor: "#999FA4"}} />}
                >
                  <Typography variant="body2" color="#999FA4">{optionsTranslation('sampleType', row.sampleType, true)}</Typography>
                  <Typography variant="body2" color="#999FA4">{optionsTranslation('fulfillmentType', row.sampleFulfillmentType)}</Typography>
                </Stack>
              </Box>
            </Stack>
          </AccordionSummary>
          <AccordionDetails className="content">
            <table cellPadding={2} className={classes.table}>
              <tbody>
                <tr>
                  <td>{t('shipment.table.sampleGrade')}</td>
                  <td>{optionsTranslation('grades', row.sampleGrade, true)}</td>
                </tr>
                <tr>
                  <td>{t('shipment.table.sampleLocation')}</td>
                  <td>
                    {isEdit ?
                      <TextField
                        fullWidth
                        size='small'
                        value={row.sampleLocation}
                        onChange={(e) => {
                          onSetDataValue(index,'sampleLocation', e.target.value)
                        }}
                      /> :
                      <Box>{row.sampleLocation || '-'}</Box>
                    }
                  </td>
                </tr>
                <tr>
                  <td>{t('shipment.table.fulfillmentType')}</td>
                  <td>
                    {isEdit ?
                      <Select
                        fullWidth
                        size='small'
                        value={row.sampleFulfillmentType}
                        onChange={(e) => {
                          onSetDataValue(index,'sampleFulfillmentType', e.target.value)
                        }}
                      >
                        {fulfillmentTypes.map(item =>
                          <MenuItem key={item} value={item}>{optionsTranslation('fulfillmentType', item)}</MenuItem>
                        )}
                      </Select> :
                      <Box>{optionsTranslation('fulfillmentType', row.sampleFulfillmentType, true)}</Box>
                    }
                  </td>
                </tr>
                <tr>
                  <td>{t('shipment.table.approvalNeeded')}</td>
                  <td>
                    {isEdit ?
                      <Select
                        fullWidth
                        size='small'
                        value={row.isApprovalNeeded}
                        onChange={(e) => {
                          onSetDataValue(index,'isApprovalNeeded', str2bool(e.target.value))
                        }}
                      >
                        {[true, false].map(bool =>
                          <MenuItem key={bool.toString()} value={bool.toString()}>
                            {optionsTranslation('boolean', bool.toString())}
                          </MenuItem>
                        )}
                      </Select> :
                      <Box>
                        {optionsTranslation('boolean', row.isApprovalNeeded?.toString(), true)}
                      </Box>
                    }
                  </td>
                </tr>
                <tr>
                  <td>{t('shipment.table.weight')}</td>
                  <td>
                    {isEdit ?
                      <CurrencyTextField
                        fullWidth
                        size='small'
                        type='text'
                        inputMode='numeric'
                        variant='outlined'
                        textAlign='left'
                        currencySymbol=''
                        value={row.sampleWeight}
                        outputFormat="string"
                        decimalCharacter="."
                        digitGroupSeparator=","
                        minimumValue="0"
                        decimalPlaces={0}
                        autoFocus={`sampleWeight${index}` === refInput.current}
                        onChange={(event, value) => {
                          onSetDataValue(index, 'sampleWeight', value)
                        }}
                        InputProps={{
                          endAdornment: (<InputAdornment position="end">gr</InputAdornment>)
                        }}
                      /> :
                      <Box>
                        {row.sampleWeight ? `${row.sampleWeight} gr` : '-'}
                      </Box>
                    }
                  </td>
                </tr>
                <tr>
                  <td>{t('shipment.table.roasted')}</td>
                  <td>
                    {isEdit ?
                      <Select
                        fullWidth
                        size='small'
                        value={row.isRoasted}
                        onChange={(e) => {
                          onSetDataValue(index,'isRoasted', str2bool(e.target.value))
                        }}
                      >
                        {[true, false].map(bool =>
                          <MenuItem key={bool.toString()} value={bool.toString()}>
                            {optionsTranslation('boolean', bool.toString())}
                          </MenuItem>
                        )}
                      </Select> :
                      <Box>
                        {optionsTranslation('boolean', row.isRoasted.toString(), true)}
                      </Box>
                    }
                  </td>
                </tr>
                <tr>
                  <td>{t('shipment.table.producer')}</td>
                  <td>{row.sample.producerName || '-'}</td>
                </tr>
                <tr>
                  <td>{t('shipment.table.purchaseContract')}</td>
                  <td>{row.sample.purchaseContractReference || '-'}</td>
                </tr>
                <tr>
                  <td>{t('shipment.table.salesContract')}</td>
                  <td>{row.sample.salesContractReference || '-'}</td>
                </tr>
                <tr>
                  <td>{t('shipment.table.processing')}</td>
                  <td>{row.sample.processName || '-'}</td>
                </tr>
              </tbody>
            </table>

            {renderActionRowTable(index)}
          </AccordionDetails>
        </Accordion>
      ))}

      {isEdit && renderEditActionButtons}
    </Box>
  )


  useEffect(() => {
    if(params.id !==  SampleShipmentFulfillmentStatus.NEW_REQUEST) resetShipmentForm()
  }, [])

  return (
	  <Box px={4} py={2.5}>
		  <HeaderTitleWide
        backUrl={homeLink}
        breadcrumb={t('common.backTo', { menu: t('menu.home')})}
        title={t(`shipment.createSampleShipment`)}
      />

      {isMobile ? (
        <Accordion className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant='h5'>{t('shipment.generalInformationForm')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderShipmentForm}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Box sx={{mb: 2}}>
          <Typography variant='h5' mb={2}>{t('shipment.generalInformationForm')}</Typography>

          {renderShipmentForm}
        </Box>
      )}
      {isMobile ? (
        <Accordion className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant='h5'>{t('shipment.sampleInformation')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderSampleInformationMobile}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Box sx={{mb: 2}}>
          <Typography variant='h5' mb={2}>{t('shipment.sampleInformation')}</Typography>

          <Box className={classes.wrapper}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant='h6'>
                {t('shipment.table.sample', { count: samplesToBeShipment.length })}
              </Typography>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<Add />}
                  onClick={() => setOpenAddSampleModal(true)}
                >
                  {t("shipment.button.addSample")}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<Delete />}
                  onClick={() => setOpenConfirmRemove(true)}
                  disabled={isEmpty(rowSelection)}
                >
                  {t("shipment.button.removeSample")}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<Feed />}
                  onClick={() => setMultipleOpenGenerateLabel(true)}
                  disabled={isEdit || uniq(Object.keys(rowSelection).map(key => parseInt(key))).length === 0}
                >
                  {t('shipment.table.generateLabel')}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<Edit />}
                  onClick={() => setIsEdit(true)}
                  disabled={isEdit}
                >
                  {t("shipment.button.edit")}
                </Button>
              </Stack>
            </Stack>

            <BasicTable
	            table={table}
	            renderSubComponent={renderSubComponent}
	            containerProps={{sx: {overflowX: 'auto'}}}
            />

            {isEdit && renderEditActionButtons}
          </Box>
        </Box>
      )}

      {isMobile ? (
        <Accordion className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant='h5'>{t('shipment.shipmentsToBeCreated')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderGroupSamplesToBeShipment()}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Box sx={{my: 2}}>
          <Typography variant='h5' mb={2}>{t('shipment.shipmentsToBeCreated')}</Typography>

          {renderGroupSamplesToBeShipment()}
        </Box>
      )}

      <Box sx={{mb: 4}}>
        <Stack direction="row" spacing={2} justifyContent="end" mt={3}>
          <LightTooltip title={!isFormComplete ? t('shipment.button.createShipmentTooltip') : ''} arrow>
            <span>
              <Button
                variant="contained"
                sx={{width: 200}}
                onClick={() => isLabelComplete ? setOpenConfirmShipment(true) : setOpenLabelMessage(true)}
                disabled={!isFormComplete}
              >
                {isRequestNewSample ? t(`shipment.button.shipmentRequest`) : t(`shipment.button.createShipment`)}
              </Button>
            </span>
          </LightTooltip>
          <Button
            variant="outlined"
            sx={{width: 200}}
            onClick={() => navigate('/samples')}
          >
            {t("common.cancel")}
          </Button>
        </Stack>
      </Box>

      {openAddSampleModal &&
        <AddSamplesModal
          open={openAddSampleModal}
          onOpen={() => setOpenAddSampleModal(true)}
          onClose={() => setOpenAddSampleModal(false)}
          onSuccess={(samples) => {
            setData([...data, ...samples])
            setSamplesToBeShipment([...data, ...samples])
            setOpenAddSampleModal(false)
            setOpenSuccessAddedSample(true)
          }}
        />
      }

      <ModalMessage
        open={openSuccessAddedSample}
        description={t('shipment.success.successfullyAddedSamples')}
        onSubmit={() => setOpenSuccessAddedSample(false)}
      />

      <ModalMessage
        open={openSuccessEditedSample}
        description={t('shipment.success.successfullyEditedSamples')}
        onSubmit={() => setOpenSuccessEditedSample(false)}
      />

      <ModalMessage
        open={openSuccessCreatedShipment}
        description={t('shipment.success.successfullyCreatedShipment')}
        onSubmit={() => {
          setOpenSuccessCreatedShipment(false)
          navigate('/sample-shipments')
        }}
        buttonText={t('shipment.button.goToShipmentPage')}
      />

      <ModalConfirm
        open={openLabelMessage}
        onClose={() => setOpenLabelMessage(false)}
        title={t('shipment.modal.labelMessageTitle')}
        description={t('shipment.modal.labelMessageDescription', {count: labelIncomplete.length})}
        confirm={t('common.continue')}
        onConfirm={onGenerateLabels}
      />

      <ModalConfirm
        open={openConfirmShipment}
        onClose={() => setOpenConfirmShipment(false)}
        onConfirm={onSaveSampleShipment}
        confirm={isRequestNewSample ? t('shipment.button.request') : t('shipment.button.confirm')}
        title={isRequestNewSample ? t('shipment.requestShipment') : t('shipment.createSampleShipment')}
        description={isRequestNewSample ? `${t('shipment.modal.requestShipmentConfirmation')}`: `
          ${t('shipment.modal.localShipment', {count: sampleShipmentCounter.local})} 
          ${t('shipment.modal.thirdPartyShipment', {count: sampleShipmentCounter.thirdParty})}
          <br /><br />
          ${t('shipment.modal.createShipmentConfirmation')}
        `}
      />

      <ModalConfirm
        open={openConfirmRemove}
        onClose={() => setOpenConfirmRemove(false)}
        confirm={t('common.remove')}
        onConfirm={onRemoveSamples}
        title={t('shipment.modal.removeSample')}
        description={t('shipment.modal.removeSampleDescription')}
      />

      <ModalMessage
        open={openSuccessRemovedSample}
        description={t('shipment.success.successfullyRemovedSamples')}
        onSubmit={() => setOpenSuccessRemovedSample(false)}
      />

      {indexSamples >= 0 && (
        <>
          <GenerateLabel
            index={indexSamples}
            samplesToBeShipment={samplesToBeShipment}
            open={openGenerateLabel}
            onClose={() => setOpenGenerateLabel(false)}
            onSubmit={(index, data) => {
              setSamplesToBeShipmentGenerateLabel(index, data)
            }}
          />

          <SampleInformationModal
            open={openSampleModal}
            onClose={() => setOpenSampleModal(false)}
            sample={samplesToBeShipment[indexSamples].sample}
            setSample={(sample) => {
              setSamplesToBeShipmentSample(indexSamples, sample)
              const newData = data.map((item, index) => {
                if (index === indexSamples) {
                  return {
                    ...item,
                    sampleName: sample.name,
                    sampleGrade: sample.grade,
	                  sampleLocation: sample.sampleLocation,
                    sample: sample
                  }
                }

                return item
              })
              setData(newData)
              setSamplesToBeShipment(newData)
              setIndexSamples(-1)
            }}
          />
        </>
      )}
      <MultipleGenerateLabel
        index={0}
        samplesToBePrintLabel={
          uniq(Object.keys(rowSelection).map(key => parseInt(key))).length === 0 ? samplesToBeShipment :
          samplesToBeShipment.filter((item,index) => uniq(Object.keys(rowSelection).map(key => parseInt(key))).includes(index))
        }
        open={openMultipleGenerateLabel}
        onClose={() => setMultipleOpenGenerateLabel(false)}
        onSubmit={(data) => {
          data.map(d => {
            setSamplesToBeShipmentMultiGenerateLabel(d)
          })
        }}
      />
    </Box>
  )
})
