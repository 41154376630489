import React, {useEffect} from "react";
import {flatMap, isEmpty} from "lodash";
import {useTranslation} from "react-i18next";
import {Box, Button, Checkbox, FormControlLabel, Stack, Typography} from "@mui/material";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";

import {optionsTranslation} from "utils";
import {BasicTable, ModalWrapper} from "components";

const columnHelper = createColumnHelper<any>()

export const BulkApprove = (props) => {
  const { t } = useTranslation()
  const {open, onClose, shipments, onApprove} = props
  const [rowSelection, setRowSelection] = React.useState<any>({})
  const [dataApproval] = React.useState<any>([])
  const data = shipments.flatMap(shipment => shipment.sampleTransactionItems).filter( x => isEmpty(x.approvalStatus))

  const hendlerCheckbox = (): void  => {
    const keys = Object.keys(rowSelection);

    dataApproval.length = 0
    keys.map( x => {
      dataApproval.push(data[x].uniqueToken)
    })

    onApprove(dataApproval)
  }

  const columns = [
    columnHelper.accessor('sampleName', {
      id: 'sampleName',
      header: ({ table }) =>
        <Box textAlign="left">
          <FormControlLabel
            label={t('shipment.table.sampleName')}
            control={
              <Checkbox
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
            }
          />
        </Box>,
      cell: info =>
        <Box textAlign="left">
          <FormControlLabel
            label={info.renderValue()}
            control={
              <Checkbox
                checked={info.row.getIsSelected()}
                onChange={info.row.getToggleSelectedHandler()}
              />
            }
          />
        </Box>,
    }),
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left" fontSize="16px">{t('shipment.table.sampleType')}</Box>,
      cell: info =>optionsTranslation('sampleType', info.renderValue()),
    }),
    columnHelper.accessor('sampleGrade', {
      id: 'sampleGrade',
      header: () => <Box textAlign="left" fontSize="16px">{t('shipment.table.sampleGrade')}</Box>,
      cell: info => optionsTranslation('grades', info.renderValue()),
    }),
    columnHelper.accessor('salesContract', {
      id: 'salesContract',
      header: () => <Box textAlign="left" fontSize="16px">{t('shipment.table.salesContract')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('purchaseContract', {
      id: 'purchaseContract',
      header: () => <Box textAlign="left" fontSize="16px">{t('shipment.table.purchaseContract')}</Box>,
      cell: info => info.renderValue(),
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    state: { rowSelection },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <ModalWrapper open={open}>
      <Typography variant="h4" textAlign="center" mt={2}>{t('shipment.modal.bulkApprove')}</Typography>

      <BasicTable table={table} tableProps={{ sx: {'& th': { verticalAlign: 'middle !important' }}}} />
      <Stack direction="row" spacing={2} justifyContent="center" my={2}>
        <Button
          variant="contained"
          sx={{width: 200}}
          disabled={isEmpty(rowSelection)}
          onClick={hendlerCheckbox}
        >
          {t(`shipment.button.approve`)}
        </Button>
        <Button
          variant="outlined"
          sx={{width: 200}}
          onClick={onClose}
        >
          {t("common.cancel")}
        </Button>
      </Stack>
    </ModalWrapper>
  )
}
