/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-14 23:27:39
 * @modify date 2022-04-13 17:47:07
 */

import React from 'react'
import { find } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { AppBar, Box, Toolbar, MenuItem, Select, Container } from '@mui/material'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import i18n from 'i18n/i18n'
import { useStores } from 'models'
import { Logo } from 'assets/images'
import { RECAPTCHA_API_KEY, WEB_URL} from 'config/env'
import { LANGUANGES } from 'constants/languanges'
import { colors } from 'assets'
import {authenticationRoutes, isPublicRoutes } from 'constants/routes'
import {MaintenanceBanner} from "components"

import { useStyles } from './authentication-navigation.styles'

export const AuthenticationNavigation: React.FC = observer(() => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    userStore: { isAuthenticated, registrationCompleted, languagePreference, setValue },
  } = useStores()

  React.useEffect(() => {
    i18n.changeLanguage(languagePreference)
  }, [languagePreference])

  React.useEffect(() => {
    if (!isAuthenticated || !registrationCompleted) {
      document.body.style.backgroundColor = colors.body.primary
      return
    }

    if (find(authenticationRoutes, ['path', location.pathname])) navigate(location.pathname, { replace: true })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  if ((isAuthenticated && registrationCompleted) || isPublicRoutes(location.pathname)) return null

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_API_KEY}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="sticky" className={classes.appBar}>
          <MaintenanceBanner />
          <Container>
            <Toolbar className={classes.toolbar}>
              <a href={WEB_URL}>
                <Logo width={130} height={35} />
              </a>

              <Select
                autoWidth
                size='small'
                value={languagePreference}
                onChange={event => setValue('languagePreference', event.target.value)}
              >
                {Object.keys(LANGUANGES).map((key: string) =>
                  <MenuItem value={key} key={key}>{LANGUANGES[key]}</MenuItem>
                )}
              </Select>
            </Toolbar>
          </Container>
        </AppBar>
        <Routes>
          {authenticationRoutes.map((route) =>
            <Route key={route.name} path={route.path} element={route.element} />
          )}
        </Routes>
      </Box>
    </GoogleReCaptchaProvider>
  )
})
