import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: '1px solid #E0D1EA',
      borderRadius: 8,
    },
    table: {
      whiteSpace: 'nowrap',

      '& .MuiTableCell-root': {
        borderRight: '1px solid #E0D1EA',
        borderBottom: 'none',
        minWidth: 125,
        maxWidth: 250,
      },

      '& th': {
        padding: 8
      },

      '& td.MuiTableCell-root': {
        padding: '4px 8px',
				overflow: 'hidden',
	      textOverflow: 'ellipsis',
	      height: 'inherit',
      }
    },
    filter: {
      '& th': {
        backgroundColor: '#EBE8F2',
      }
    }
  })
)
