import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {colors} from "assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      borderRadius: 4,
      border: `1px solid ${colors.text.o25}`,
    }
  })
)
