import React from 'react'
import {AppBar, Box, Container, MenuItem, Select, Toolbar} from "@mui/material"
import {Routes, Route, useLocation} from 'react-router-dom'
import {observer} from "mobx-react-lite"

import i18n from 'i18n/i18n'
import { useStores } from 'models'
import {isPublicRoutes, publicRoutes} from "constants/routes"
import { Logo } from 'assets/images'
import { WEB_URL} from 'config/env'
import { LANGUANGES } from 'constants/languanges'
import {MaintenanceBanner} from "components"

import { useStyles } from './public-navigation.styles'

export const PublicNavigation = observer(() => {
  const classes = useStyles()
  const location = useLocation()
  const {
    userStore: { languagePreference, setValue },
  } = useStores()

  React.useEffect(() => {
    i18n.changeLanguage(languagePreference)
  }, [languagePreference])

  if (!isPublicRoutes(location.pathname)) return null

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky" className={classes.appBar}>
        <MaintenanceBanner />
        <Container>
          <Toolbar className={classes.toolbar}>
            <a href={WEB_URL}>
              <Logo width={130} height={35} />
            </a>

            <Select
              autoWidth
              size='small'
              value={languagePreference}
              onChange={event => setValue('languagePreference', event.target.value)}
            >
              {Object.keys(LANGUANGES).map((key: string) =>
                <MenuItem value={key} key={key}>{LANGUANGES[key]}</MenuItem>
              )}
            </Select>
          </Toolbar>
        </Container>
      </AppBar>

      <Routes>
        {publicRoutes.map((route) =>
          <Route key={route.name} path={route.path} element={route.element} />
        )}
      </Routes>
    </Box>
  )
})
