import React, {useState, useRef, useEffect} from "react"
import {useTranslation} from "react-i18next"
import Draggable from 'react-draggable'
import { styled } from '@mui/material/styles'
import { isMobile } from "react-device-detect"
import {Box, Card, IconButton, Stack, Tooltip, TooltipProps, tooltipClasses, Typography, SvgIcon} from "@mui/material"
import {ChevronRight} from "@mui/icons-material"

import { IconPlayCircle, IconPauseCircle, IconStopCircle, IconResetCircle, IconStopwatch } from 'assets/images'
import {ScoreTimerProps} from "./score-timer.props"

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Draggable bounds="body">
    <Box sx={{maxWidth: 200, zIndex: 9999, position: 'fixed', right: isMobile ? 0 : 25, ...(isMobile && {top: 50})}}>
      <Tooltip {...props} classes={{ popper: className }} arrow  placement="left" />
    </Box>
  </Draggable>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
  },
}));

export const ScoreTimer: React.FC<ScoreTimerProps> = (props: ScoreTimerProps) => {
  const { t } = useTranslation()
  const { closeTooltip } = props

  const [time, setTime] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const countRef: { current: NodeJS.Timeout | null } = useRef(null)
  const [useIcon, setUseIcon] = useState(true)
  const [open, setOpen] = React.useState(true)

  const openTooltip = !closeTooltip && (!isActive && open)

  const handleResume = () => {
    if (isPaused) {
      clearInterval(countRef.current as NodeJS.Timeout)
      setIsPaused(false)
      return
    }

    countRef.current = setInterval(() => {
      setTime((time) => time + 1)
    }, 1000)
    setIsPaused(true)
    setIsActive(true)
  }

  const handleStop = () => {
    clearInterval(countRef.current as NodeJS.Timeout)
    setIsActive(false)
    setIsPaused(false)
  }

  const handleReset = () => {
    clearInterval(countRef.current as NodeJS.Timeout)
    setIsActive(false)
    setIsPaused(false)
    setTime(0)
  }

  const formatTime = () => {
    const getSeconds = `0${(time % 60)}`.slice(-2)
    const minutes = Math.floor(time / 60)
    const getMinutes = `0${minutes % 60}`.slice(-2)
    const getHours = `0${Math.floor(time / 3600)}`.slice(-2)

    return `${getHours} : ${getMinutes} : ${getSeconds}`
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleIcon = () => {
    setUseIcon(!useIcon)
  }

  useEffect(() => {
    setTimeout(handleClose, 5000)

    window.addEventListener('click', handleClose)
    return () => window.removeEventListener("click", handleClose)
  }, [])

  if (useIcon) {
    return (
      <LightTooltip title={`${t('sampleScore.timerTooltip')}`} open={openTooltip} onClose={handleClose}>
        <IconButton size='small' color='primary' onClick={handleIcon} onTouchStart={handleIcon}>
          <SvgIcon component={IconStopwatch} sx={{fontSize: {md: 100, xs: 64}}} inheritViewBox />
        </IconButton>
      </LightTooltip>

    )
  }

  return (
    <LightTooltip title={`${t('sampleScore.timerTooltip')}`} open={openTooltip} onClose={handleClose}>
      <Card sx={{p: 1, width: {xs: 170, md: 190}}}>
        <Typography variant='h3' color='primary' textAlign='center'>
          {formatTime()}
        </Typography>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <IconButton size='small' onClick={handleIcon} onTouchStart={handleIcon} sx={{pl: 0}}>
            <ChevronRight />
          </IconButton>

          <Stack direction="row" spacing={0.2}>
            <IconButton size='small' onClick={handleResume} onTouchStart={handleResume}>
              { isPaused ?
                <SvgIcon component={IconPauseCircle} color='primary' inheritViewBox /> :
                <SvgIcon component={IconPlayCircle} color='primary' inheritViewBox />
              }
            </IconButton>
            <IconButton size='small' onClick={handleStop} onTouchStart={handleStop} disabled={!isActive}>
              <SvgIcon component={IconStopCircle} color={isActive ? 'primary' : 'disabled'} inheritViewBox />
            </IconButton>
            <IconButton size='small' onClick={handleReset} onTouchStart={handleReset}>
              <SvgIcon component={IconResetCircle} color='primary' inheritViewBox />
            </IconButton>
          </Stack>
        </Box>
      </Card>
    </LightTooltip>
  )
}
