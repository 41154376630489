import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {centered, colors} from "assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      borderBottom: `1px solid ${colors.border.primary}`,
      cursor: 'pointer',

      '&:first-child': {
        borderTop: `1px solid ${colors.border.primary}`
      }
    },
    score: {
      display: 'flex',
      alignItems: 'center',

      '& > div': {
        textAlign: 'center',
      },

      '& > svg': {
        width: 24,
        height: 24,
      }
    },
    nav: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      paddingTop: 0,
      marginTop: theme.spacing(-2),
    },
    navButton: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },

      '& svg': {
        width: 24,
        height: 24,
      },

      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.grey[500],
      }
    },
    contentScore: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `8px 16px`,
      borderTop: `1px solid #E0D1EA`,
      borderBottom: `1px solid #E0D1EA`,
    },
    roastLevel: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      backgroundColor: 'white',
      borderBottom: `1px solid ${colors.border.primary}`,
    },
    totalScoreWrapper: {
      ...centered
    },
    totalScoreIcon: {
      width: 20,
      height: 20,
      fill: colors.primary.main,
      marginRight: theme.spacing(1)
    },
    middleTitle: {
      textAlign: 'center',
    },
  })
)
