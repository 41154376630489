/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withGeneralError, withReset, withRootStore, withEnvironment, withSetValue
} from "models/extensions"
import {
  ShipmentOrderFormModel,
  shipmentOrderFormPreProcessor,
  shipmentOrderFormProps
} from "models/shipment-order-form";

import {
  sampleItemProps,
  sampleProps,
  sampleTransactionItemProps,
  shipmentInformationProps,
} from "./sample-shipment.props"
import {withSampleShipmentActions} from "./sample-shipment-action";
import {withSampleShipmentViews} from "./sample-shipment.view";
import {filterModel} from "./filter-model";
import {SampleShipmentScoreModel} from "./shipment-score";

/**
 * Model description here for TypeScript hints.
 */
export const SampleShipmentStoreModel = types
  .model("SampleShipmentStore")
  .props({
    samplesToBeShipment: types.optional(
      types.array(types.model(sampleProps)),
      []
    ),
    shipmentForm: types.optional(ShipmentOrderFormModel, shipmentOrderFormPreProcessor({})),
    shipmentInformation: types.optional(types.model(shipmentInformationProps), shipmentOrderFormPreProcessor({})),
    sampleShipments: types.optional(types.array(types.model({
      ...shipmentOrderFormProps,
      tastifyOrderNumber: types.string,
      fulfillment: types.string,
      fulfillmentStatus: types.string,
      sampleTransactionItems: types.optional(
        types.array(types.model(sampleTransactionItemProps
        )),
        []
      ),
      numberOfSamples: types.number,
      uniqueToken: types.string,
      createdAt: types.string,
      isSubmitted: types.boolean,
      shipmentNumber: types.maybeNull(types.string),
      sampleLocation: types.maybeNull(types.string),
      isRequestedNewSample: types.boolean,
      country: types.maybeNull(types.string),
      customer: types.maybeNull(types.string),
      purchaseContractReference: types.maybeNull(types.string),
      salesContractReference: types.maybeNull(types.string),
      cantBeCancelled: types.boolean,
      sampleOrderNumber: types.maybeNull(types.string)
    })), []),
    thirdPartyName: types.optional(types.string, ''),
    thirdPartyEmail: types.optional(types.string, ''),
    archivedAt: types.optional(types.string, ''),
    fulfillmentStatus: types.optional(types.string, ''),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0),
    sort: types.optional(types.string, ''),
    filter: types.optional(filterModel, {}),
    score: types.optional(SampleShipmentScoreModel, {}),
  })
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withEnvironment)
  .extend(withSampleShipmentActions)
  .extend(withSampleShipmentViews)

type SampleShipmentStoreType = Instance<typeof SampleShipmentStoreModel>
export interface SampleShipmentStore extends SampleShipmentStoreType {}
type SampleShipmentStoreSnapshotType = SnapshotOut<typeof SampleShipmentStoreModel>
export interface SampleShipmentStoreSnapshot extends SampleShipmentStoreSnapshotType {}
