import React, {useEffect} from 'react'
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {Box, Button, Typography} from "@mui/material";
import {DataGrid, GridColDef, useGridApiRef} from "@mui/x-data-grid";

import {useStores} from "models";
import {DataGridSelect, ModalWrapper} from "components";
import {sampleTypes} from "constants/form";
import {optionsTranslation} from "utils";

import {useStyles} from "./simplified-common.styles";
import {LoadingButton} from "@mui/lab";

export const EditSampleModal = observer((props: any) => {
	const { t } = useTranslation()
	const apiRef = useGridApiRef();
	const classes = useStyles()
	const {
		sampleStore: { samples, bulkSaveSamples }
	} = useStores()

	const { open, setOpen, updateSampleReference } = props
	const [rows, setRows] = React.useState<any[]>(() => [])
	const [isLoading, setIsLoading] = React.useState(false)

	const columns: GridColDef[] = [
		{ field: 'id' },
		{
			field: 'name',
			headerName: t('sample.sampleName'),
			editable: true,
			width: 200,
		},
		{
			field: 'customer',
			headerName: t('sample.customer'),
			editable: true,
			width: 200,
		},
		{
			field: 'supplierName',
			headerName: t('sample.supplier'),
			editable: true,
			width: 200,
		},
		{
			field: 'externalIdentification',
			headerName: t('sample.externalIdentification'),
			editable: true,
			width: 200,
		},
		{
			field: 'sampleReference',
			headerName: t('sample.sampleReference'),
			editable: true,
			width: 200,
		},
		{
			field: 'purchaseContractReference',
			headerName: t('sample.purchaseContractReference'),
			editable: true,
			width: 200,
		},
		{
			field: 'salesContractReference',
			headerName: t('sample.salesContractReference'),
			editable: true,
			width: 200,
		},
		{
			field: 'shipmentDate',
			headerName: t('sample.shipmentDate'),
			type: 'date',
			editable: true,
			width: 200,
		},
		{
			field: 'sampleType',
			headerName: t('sample.sampleType'),
			type: 'singleSelect',
			getOptionLabel: (value) => optionsTranslation('sampleType', value as string),
			valueOptions: sampleTypes,
			width: 200,
			editable: true,
			renderEditCell: (params) => <DataGridSelect {...params} />,
		},
	]

	const processRowUpdate = (newRow) => {
		setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));

		const sample = samples.find(s => s.id === newRow.id)
		if (!sample) return

		Object.keys(newRow).forEach(key => {
			if (key === 'id') return

			sample.setValue(key, newRow[key])
		})
	};

	const handleCellKeyDown = (params, event) => {
		if (event.key === 'Tab') {
			event.preventDefault(); // Prevent the default tab behavior

			const { id: rowId, field } = params; // Current cell's row and field
			const columnIndex = columns.findIndex((col) => col.field === field);

			let nextColumnIndex;
			let nextRowId = rowId;

			if (event.shiftKey) {
				// Shift + Tab: Move to the previous cell
				nextColumnIndex = (columnIndex - 1 + columns.length) % columns.length;
				if (nextColumnIndex === columns.length - 1) {
					// Move to the previous row if we are at the first column
					nextRowId = rowId - 1 < rows[0].id ? rows[rows.length - 1].id : rowId - 1;
				}
			} else {
				// Tab: Move to the next cell
				nextColumnIndex = (columnIndex + 1) % columns.length;
				if (nextColumnIndex === 0) {
					// Move to the next row if we are at the last column
					nextRowId = rowId + 1 > rows.length ? rows[0].id : rowId + 1;
				}
			}

			const nextField = columns[nextColumnIndex].field;
			apiRef.current.setCellFocus(nextRowId, nextField);
		}
	};

	const updateSamples = async () => {
		try {
			setIsLoading(true)
			await bulkSaveSamples()
			updateSampleReference()
			setOpen(false)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		setRows(samples.map(sample => ({
			id: sample.id,
			name: sample.name,
			customer: sample.customer,
			supplierName: sample.supplierName,
			externalIdentification: sample.externalIdentification,
			sampleReference: sample.sampleReference,
			purchaseContractReference: sample.purchaseContractReference,
			salesContractReference: sample.salesContractReference,
			shipmentDate: sample.shipmentDate,
			sampleType: sample.sampleType,
		})))
	}, [])

	return (
		<ModalWrapper open={open}>
			<Typography variant="h5">
				{t('sample.editSampleTitle')}
			</Typography>
			<Typography variant="body2" sx={{mb: 2}}>
				{t('sample.editSamplesDescription')}
			</Typography>

			<DataGrid
				autoHeight
				rows={rows}
				columns={columns}
				processRowUpdate={processRowUpdate}
				hideFooter
				disableColumnMenu
				disableColumnSorting
				rowHeight={55}
				initialState={{
					columns: {
						columnVisibilityModel: {
							id: false,
						},
					},
				}}
				autosizeOptions={{
					includeHeaders: true,
					includeOutliers: true,
				}}
				className={classes.dataGrid}
				onProcessRowUpdateError={() => null}
				apiRef={apiRef}
				onCellKeyDown={handleCellKeyDown}
			/>

			<Box className={classes.buttonWrapper}>
				<Button variant='outlined' onClick={() => setOpen(false)}>
					{t('common.cancel')}
				</Button>
				<LoadingButton
					loading={isLoading}
					variant='contained'
					onClick={() => {
						new KeyboardEvent('keydown', {
							key: 'Enter',
							code: 'Enter',
							keyCode: 13,
							which: 13,
							bubbles: true,
							cancelable: true,
						})

						updateSamples()
					}}
				>
					{t('common.submit')}
				</LoadingButton>
			</Box>
		</ModalWrapper>
	)
})
