/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-19 02:57:43
 * @modify date 2022-04-19 02:57:43
 */

import { toNumber, camelCase } from 'lodash'

export const preProcessorSampleScore = (sn: any) => {
  return {
    ...sn,
    fragrance: toNumber(sn.fragrance) || 0,
    fragranceIntensity: toNumber(sn.fragranceIntensity) || 0,
    fragranceDry: toNumber(sn.fragranceDry) || 0,
    fragranceCrust: toNumber(sn.fragranceCrust) || 0,
    fragranceBreak: toNumber(sn.fragranceBreak) || 0,
    acidity: toNumber(sn.acidity) || 0,
    acidityIntensity: toNumber(sn.acidityIntensity) || 0,
    dryAcidity: sn.dryAcidity || false,
    mediumAcidity: sn.mediumAcidity || false,
    sweetAcidity: sn.sweetAcidity || false,
    body: toNumber(sn.body) || 0,
    bodyIntensity: toNumber(sn.bodyIntensity) || 0,
    flavor: toNumber(sn.flavor) || 0,
    flavorIntensity: toNumber(sn.flavorIntensity) || 0,
    aftertaste: toNumber(sn.aftertaste) || 0,
    aftertasteIntensity: toNumber(sn.aftertasteIntensity) || 0,
    aftertasteBrackishToSavory: toNumber(sn.aftertasteBrackishToSavory) || 0,
    balance: toNumber(sn.balance) || 0,
    salt: toNumber(sn.salt) || 0,
    saltLowSalt: toNumber(sn.saltLowSalt) || 0,
    saltHiAcid: toNumber(sn.saltHiAcid) || 0,
    bittersweet: toNumber(sn.bittersweet) || 0,
    bittersweetLowBitter: toNumber(sn.bittersweetLowBitter) || 0,
    bittersweetHiSweet: toNumber(sn.bittersweetHiSweet) || 0,
    mouthfeel: toNumber(sn.mouthfeel) || 0,
    mouthfeelIntensity: toNumber(sn.mouthfeelIntensity) || 0,
    mouthfeelRoughToSmooth: toNumber(sn.mouthfeelRoughToSmooth) || 0,
    roughMouthfeel: sn.roughMouthfeel || false,
    creamyMouthfeel: sn.creamyMouthfeel || false,
    smoothMouthfeel: sn.smoothMouthfeel || false,
    mouthDryingMouthfeel: sn.mouthDryingMouthfeel || false,
    metallicMouthfeel: sn.metallicMouthfeel || false,
    uniformityScore: toNumber(sn.cleanCupScore) || 0,
    cleanCupScore: toNumber(sn.cleanCupScore) || 0,
    sweetnessScore: toNumber(sn.sweetnessScore) || 0,
    overall: toNumber(sn.overall) || 0,
    defects: toNumber(sn.defects) || 0,
    cupperTitle: camelCase(sn.cupperTitle) || '',

    roastLevel: toNumber(sn.roastLevel) || 0,
    aroma: toNumber(sn.aroma) || 0,
    aromaIntensity: toNumber(sn.aromaIntensity) || 0,
    sweetnessIntensity: toNumber(sn.sweetnessIntensity) || 0,
    nonUniformScore: toNumber(sn.nonUniformScore) || 0,

    bitterness: toNumber(sn.bitterness) || 0,
    consistency: toNumber(sn.consistency) || 0,

    fragranceAromaNote: sn.fragranceAromaNote || '',
    acidityNote: sn.acidityNote || '',
    flavorAftertasteNote: sn.flavorAftertasteNote || '',
    mouthfeelNote: sn.mouthfeelNote || '',
    sweetnessNote: sn.sweetnessNote || '',
    overallNote: sn.overallNote || '',

    saltyTaste: sn.saltyTaste || false,
    sourTaste: sn.sourTaste || false,
    sweetTaste: sn.sweetTaste || false,
    bitterTaste: sn.bitterTaste || false,
    umamiTaste: sn.umamiTaste || false,

    uniformity1: sn.uniformity1 || false,
    uniformity2: sn.uniformity2 || false,
    uniformity3: sn.uniformity3 || false,
    uniformity4: sn.uniformity4 || false,
    uniformity5: sn.uniformity5 || false,

    nonUniform1: sn.nonUniform1 || false,
    nonUniform2: sn.nonUniform2 || false,
    nonUniform3: sn.nonUniform3 || false,
    nonUniform4: sn.nonUniform4 || false,
    nonUniform5: sn.nonUniform5 || false,

    cleanCup1: sn.cleanCup1 || false,
    cleanCup2: sn.cleanCup2 || false,
    cleanCup3: sn.cleanCup3 || false,
    cleanCup4: sn.cleanCup4 || false,
    cleanCup5: sn.cleanCup5 || false,

    sweetness1: sn.sweetness1 || false,
    sweetness2: sn.sweetness2 || false,
    sweetness3: sn.sweetness3 || false,
    sweetness4: sn.sweetness4 || false,
    sweetness5: sn.sweetness5 || false,

    defective1: sn.defective1 || false,
    defective2: sn.defective2 || false,
    defective3: sn.defective3 || false,
    defective4: sn.defective4 || false,
    defective5: sn.defective5 || false,

    totalScore: toNumber(sn.totalScore) || 0,

    tastingScore: toNumber(sn.totalScore) || 0,
    tastingDescriptors: sn.tastingDescriptors || [],
  }
}
