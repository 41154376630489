/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-07 22:34:56
 * @modify date 2022-02-07 22:34:56
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Typography,
  IconButton, Stack,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { Close as CloseIcon } from '@mui/icons-material'
import ReactHtmlParser from 'react-html-parser'

import { ModalConfirmProps } from './modal-confirm.props'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalConfirm: React.FC<ModalConfirmProps> = (props: ModalConfirmProps) => {
  const { t } = useTranslation()

  const {
    open, title, description, icon, onClose, onConfirm, onCancel,
    confirm = t('common.confirm'),
    cancel = t('common.cancel'),
    align = 'center', alignButton = 'center',
    rtl, onCloseIcon,
  } = props

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogContent sx={{pt: 4, pb: 2, overflow: 'hidden'}}>
        {onCloseIcon &&
          <IconButton
            aria-label="close"
            onClick={onCloseIcon}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        }

        {icon}
        <Typography variant='h5' align={align} sx={{mb: 0.5}}>{title}</Typography>
        <Typography align={align} variant='body2' whiteSpace="normal">
            {ReactHtmlParser(description)}
        </Typography>
      </DialogContent>
      <Stack direction={rtl ? "row-reverse" : "row"} spacing={2} justifyContent={alignButton} alignItems="center" sx={{mb: 4, p: 1}}>
        <Button variant='contained' onClick={onConfirm}>{confirm}</Button>
        <Button variant='outlined' onClick={onCancel || onClose}>{cancel}</Button>
      </Stack>
    </Dialog>
  )
}
