import React from "react";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {Box, Button, ButtonProps, BoxProps} from "@mui/material";

import {optionsTranslation} from "utils";
import {useStyles} from "./approve-reject-button.styles";

interface ApproveRejectButtonProps {
  row: any
  onApprove: (hasApproval: boolean) => void
  onReject: (hasApproval: boolean) => void
  approveChildren?: React.ReactNode
  rejectChildren?: React.ReactNode
  edited?: boolean
  buttonProps?: ButtonProps
  boxProps?: BoxProps
}

export const ApproveRejectButton = (props: ApproveRejectButtonProps) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {row, approveChildren, onApprove, rejectChildren, onReject, edited = false, buttonProps, boxProps} = props
  const { approvalStatus } = row

  if (approvalStatus && !edited) {
    return (
      <Box className={clsx(classes.approvalStatus, approvalStatus)} {...boxProps}>
        {optionsTranslation('approvalStatus', approvalStatus)}
      </Box>
    )
  }

  return (
    <>
      <Button
        variant="contained"
        size="small"
        color="success"
        onClick={() => onApprove(!!approvalStatus)}
        sx={{minWidth: 'fit-content'}}
        {...buttonProps}
      >
        {approveChildren ?? t('shipment.button.approve')}
      </Button>
      <Button
        variant="contained"
        size="small"
        color="error"
        onClick={() => onReject(!!approvalStatus)}
        sx={{minWidth: 'fit-content'}}
        {...buttonProps}
      >
        {rejectChildren ?? t('shipment.button.reject')}
      </Button>
    </>
  )
}
