export const scaDescriptorList = [{
  "id": 1,
  "label": "floral",
  "color": "#DA0D68",
  "parent_id": null,
  "label_es": "floral",
  "open": 1,
  "zoomed": 1,
  "selected": 1,
  "flavor_type": "lexicon",
  "positive": 1,
  "label_pt": "floral",
  "label_zh-CN": "花",
  "label_fr-CA": "florale",
  "label_ko": "꽃향기",
  "label_id": "Bunga",
  "label_ja": "フローラル、花のような",
  "weights": 1,
  "groups": []
}, {
  "id": 2,
  "label": "fruity",
  "color": "#DA1E23",
  "parent_id": null,
  "label_es": "frutal",
  "open": 1,
  "zoomed": 1,
  "selected": 1,
  "flavor_type": "lexicon",
  "positive": 1,
  "label_ko": "과일",
  "label_id": "Buah",
  "label_pt": "frutado",
  "label_zh-CN": "水果",
  "label_fr-CA": "fruité",
  "label_ja": "フルーツ、果物系",
  "weights": 1,
  "groups": [{
    "id": 20,
    "label": "citrus fruit",
    "color": "#F7A128",
    "parent_id": 2,
    "label_es": "cítricos",
    "open": 1,
    "zoomed": 1,
    "selected": 1,
    "flavor_type": "lexicon",
    "positive": 1,
    "label_pt": "frutas cítricas",
    "label_zh-CN": "柑橘类水果",
    "label_fr-CA": "fruits agrumes",
    "label_ko": "감귤류",
    "label_id": "Buah citrus",
    "label_ja": "柑橘類",
    "weights": 1,
    "groups": []
  }, {
    "id": 22,
    "label": "dried fruit",
    "color": "#F2674C",
    "parent_id": 2,
    "label_es": "frutas secas",
    "open": 1,
    "zoomed": 1,
    "selected": 1,
    "flavor_type": "lexicon",
    "positive": 1,
    "label_pt": "frutas secas",
    "label_zh-CN": "干果",
    "label_fr-CA": "fruits séchés",
    "label_ko": "말린 과일",
    "label_id": "Buah kering",
    "label_ja": "乾燥果物",
    "weights": 1,
    "groups": []
  }, {
    "id": 23,
    "label": "berry",
    "color": "#EF2D35",
    "parent_id": 2,
    "label_es": "moras",
    "open": 1,
    "zoomed": 1,
    "selected": 1,
    "flavor_type": "lexicon",
    "positive": 1,
    "label_pt": "baga",
    "label_zh-CN": "浆果",
    "label_fr-CA": "baies",
    "label_ko": "베리",
    "label_id": "Buah beri",
    "label_ja": "ベリー",
    "weights": 1,
    "groups": []
  }]
}, {
  "id": 3,
  "label": "sour/fermented",
  "color": "#EAB40E",
  "parent_id": null,
  "label_es": "agrio/fermentado",
  "open": 1,
  "zoomed": 1,
  "selected": 1,
  "flavor_type": "lexicon",
  "positive": 1,
  "label_pt": "azedo/fermentado",
  "label_zh-CN": "酸/发酵",
  "label_fr-CA": "aigre/fermenté",
  "label_ko": "신/발효된",
  "label_id": "Kecut/fermentasi",
  "label_ja": "酸味/発酵",
  "weights": 1,
  "groups": [{
    "id": 30,
    "label": "fermented",
    "color": "#B09732",
    "parent_id": 3,
    "label_es": "fermentado",
    "open": 1,
    "zoomed": 1,
    "selected": 1,
    "flavor_type": "lexicon",
    "positive": 1,
    "label_pt": "fermentado",
    "label_zh-CN": "发酵",
    "label_fr-CA": "fermenté",
    "label_ko": "발효된",
    "label_id": "Fermentasi",
    "label_ja": "発酵",
    "weights": 1,
    "groups": []
  }, {
    "id": 31,
    "label": "sour",
    "color": "#E1C315",
    "parent_id": 3,
    "label_es": "agrio",
    "open": 1,
    "zoomed": 1,
    "selected": 1,
    "flavor_type": "lexicon",
    "positive": 1,
    "label_pt": "azedo",
    "label_zh-CN": "酸",
    "label_fr-CA": "aigre",
    "label_ko": "신맛",
    "label_id": "Kecut",
    "label_ja": "酸味",
    "weights": 1,
    "groups": []
  }]
},
  {
    "id": 4,
    "label": "green/vegetative",
    "color": "#3AA255",
    "parent_id": null,
    "label_es": "verde/vegetal",
    "open": 1,
    "zoomed": 1,
    "selected": 1,
    "flavor_type": "lexicon",
    "positive": 1,
    "label_pt": "verde/vegetal",
    "label_zh-CN": "青/植物",
    "label_fr-CA": "vert/végétal",
    "label_ko": "녹색채소/식물성",
    "label_id": "Sayur/Daun hijau",
    "label_ja": "緑/植物",
    "weights": 1,
  }, {
    "id": 5,
    "label": "other",
    "color": "#07A2B4",
    "parent_id": null,
    "label_es": "otro",
    "open": 1,
    "zoomed": 1,
    "selected": 1,
    "flavor_type": "lexicon",
    "positive": 1,
    "label_pt": "outro",
    "label_zh-CN": "其他",
    "label_fr-CA": "autre",
    "label_ko": "기타",
    "label_id": "Lainnya",
    "label_ja": "その他",
    "weights": 1,
    "groups": [{
      "id": 50,
      "label": "chemical",
      "color": "#76C0CB",
      "parent_id": 5,
      "label_es": "químico",
      "open": 1,
      "zoomed": 1,
      "selected": 1,
      "flavor_type": "lexicon",
      "positive": 1,
      "label_pt": "químico",
      "label_zh-CN": "化学",
      "label_fr-CA": "chimique",
      "label_ko": "화학적인",
      "label_id": "Bahan kimia",
      "label_ja": "化学的",
      "weights": 1,
      "groups": []
    }, {
      "id": 51,
      "label": "musty/earthy",
      "color": "#9DB1B7",
      "parent_id": 5,
      "label_es": "mohoso/terroso",
      "open": 1,
      "zoomed": 1,
      "selected": 1,
      "flavor_type": "lexicon",
      "positive": 1,
      "label_pt": "bolorento/terroso",
      "label_zh-CN": "霉味/土味",
      "label_fr-CA": "moisi/terreux",
      "label_ko": "퀴퀴한/흙냄새",
      "label_id": "Bau apek/tanah",
      "label_ja": "カビ臭い/土のような",
      "weights": 1,
      "groups": []
    }, {
      "id": 52,
      "label": "papery",
      "color": "#9DB1B7",
      "parent_id": 5,
      "label_es": "papel",
      "open": 1,
      "zoomed": 1,
      "selected": 1,
      "flavor_type": "lexicon",
      "positive": 1,
      "label_pt": "papel",
      "label_zh-CN": "纸",
      "label_fr-CA": "papier",
      "label_ko": "종이같은",
      "label_id": "Kertas",
      "label_ja": "紙のような",
      "weights": 1,
      "groups": []
    }]
  }, {
    "id": 6,
    "label": "roasted",
    "color": "#C94930",
    "parent_id": null,
    "label_es": "tostado",
    "open": 1,
    "zoomed": 1,
    "selected": 1,
    "flavor_type": "lexicon",
    "positive": 1,
    "label_pt": "torrado",
    "label_zh-CN": "烘焙",
    "label_fr-CA": "rôti",
    "label_ko": "구운",
    "label_id": "Hasil panggang",
    "label_ja": "焙煎された",
    "weights": 1,
    "groups": []
  }, {
    "id": 7,
    "label": "spicy",
    "color": "#AD213D",
    "parent_id": null,
    "label_es": "especiado",
    "open": 1,
    "zoomed": 1,
    "selected": 1,
    "flavor_type": "lexicon",
    "positive": 1,
    "label_pt": "picante",
    "label_zh-CN": "辛辣",
    "label_fr-CA": "épicé",
    "label_ko": "향신료",
    "label_id": "Pedas",
    "label_ja": "スパイシー",
    "weights": 1,
    "groups": []
  }, {
    "id": 8,
    "label": "nutty/cocoa",
    "color": "#A87B64",
    "parent_id": null,
    "label_es": "nueces/cocoa",
    "open": 1,
    "zoomed": 1,
    "selected": 1,
    "flavor_type": "lexicon",
    "positive": 1,
    "label_pt": "noz/cacau",
    "label_zh-CN": "坚果/可可",
    "label_fr-CA": "noisette/cacao",
    "label_ko": "견과류/코코아",
    "label_id": "Kacang/Coklat/Kokoa",
    "label_ja": "ナッツ/ココア",
    "weights": 1,
    "groups": [{
      "id": 80,
      "label": "cocoa",
      "color": "#BB764B",
      "parent_id": 8,
      "label_es": "cocoa",
      "open": 1,
      "zoomed": 1,
      "selected": 1,
      "flavor_type": "lexicon",
      "positive": 1,
      "label_pt": "cacau",
      "label_zh-CN": "可可",
      "label_fr-CA": "cacao",
      "label_ko": "코코아",
      "label_id": "Coklat/Kokoa",
      "label_ja": "ココア",
      "weights": 1,
      "groups": []
    }, {
      "id": 81,
      "label": "nutty",
      "color": "#C78869",
      "parent_id": 8,
      "label_es": "nueces",
      "open": 1,
      "zoomed": 1,
      "selected": 1,
      "flavor_type": "lexicon",
      "positive": 1,
      "label_pt": "noz",
      "label_zh-CN": "坚果",
      "label_fr-CA": "noisette",
      "label_ko": "견과류",
      "label_id": "Kacang",
      "label_ja": "ナッツ",
      "weights": 1,
      "groups": []
    }]
  }, {
    "id": 9,
    "label": "sweet",
    "color": "#E55732",
    "parent_id": null,
    "label_es": "dulce",
    "open": 1,
    "zoomed": 1,
    "selected": 1,
    "flavor_type": "lexicon",
    "positive": 1,
    "label_pt": "doce",
    "label_zh-CN": "甜的",
    "label_fr-CA": "sucré",
    "label_ko": "달콤한",
    "label_id": "Manis",
    "label_ja": "甘い",
    "weights": 1,
    "groups": [{
      "id": 92,
      "label": "vainilla/vainillina",
      "color": "#F37674",
      "parent_id": 9,
      "label_es": "vanilina",
      "open": 1,
      "zoomed": 1,
      "selected": 1,
      "flavor_type": "lexicon",
      "positive": 1,
      "label_pt": "baunilha/vanilina",
      "label_zh-CN": "香草/香草素",
      "label_fr-CA": "vanille/vanilline",
      "label_ko": "바닐라/바닐린",
      "label_id": "Vanila/Vanilin",
      "label_ja": "バニラ/バニリン",
      "weights": 1,
      "groups": []
    }, {
      "id": 94,
      "label": "brown sugar",
      "color": "#D45959",
      "parent_id": 9,
      "label_es": "azúcar morena",
      "open": 1,
      "zoomed": 1,
      "selected": 1,
      "flavor_type": "lexicon",
      "positive": 1,
      "label_pt": "açúcar mascavo",
      "label_zh-CN": "红糖",
      "label_fr-CA": "sucre brun",
      "label_ko": "황설탕(브라운슈거)",
      "label_id": "Gula merah",
      "label_ja": "黒糖",
      "weights": 1,
      "groups": []
    }]
  }]
