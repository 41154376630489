import { applySnapshot, flow, getSnapshot } from "mobx-state-tree"
import { ApiResult } from "services/api"
import { ceil, chain, isEmpty, snakeCase } from "lodash";

import {DATA_PER_PAGE} from "config/env";

export const withReportsAnalyticsActions = (self: any) => ({
  actions: {
    getDailyArrivals: flow(function * (date) {
      try {
        const params: any = { date }
        const additionalPath = `daily_arrivals`
        const res: ApiResult = yield self.environment.sampleAnalyticReportsApi.all(params, additionalPath)

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            reportAnalyticsDailyArrivals: res.data
          })
        }
      } catch (error: any) {

        yield self.checkForGeneralError(error)
      }
    }),

    getDailyCuppings: flow(function * (date) {
      try {
        const params: any = {
          "date": date
        }
        const additionalPath = `daily_cuppings`
        const res: ApiResult = yield self.environment.sampleAnalyticReportsApi.all(params, additionalPath)

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            reportAnalyticsDailyCuppings: res.data
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),

    getSampleShipments: flow(function * (fromDate, toDate) {
      try {
        let filter = {}

        chain(self.filter)
          .omitBy(isEmpty)
          .forEach((value, key) => { filter[`q[${snakeCase(key)}]`] = value})
          .value()

        const params = {
          ...filter,
          "start_date": fromDate,
          "end_date": toDate,
        }
        const additionalPath = 'daily_shipments'
        const res: ApiResult = yield self.environment.sampleAnalyticReportsApi.all(params, additionalPath)

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            reportAnalyticsShipments: res.data,
            totalShipments:res.meta.summary.total_shipments,
            summaryLocalShipments:res.meta.summary.local_shipments,
            summaryThirdPartyShipments: res.meta.summary.third_party_shipments,
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),

    getSample: flow(function * (fromDate, toDate) {
      try {
        let filter = {}

        chain(self.filter)
          .omitBy(isEmpty)
          .forEach((value, key) => { filter[`search[${snakeCase(key)}]`] = value})
          .value()

        const params = {
          ...filter,
          "start_date": fromDate,
          "end_date": toDate,
        }
        const additionalPath = 'daily_samples'
        const res: ApiResult = yield self.environment.sampleAnalyticReportsApi.all(params, additionalPath)

        if (res.kind === "ok") {

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            approvedSamples: res.data.approvedSamples,
            cuppingSubmitted: res.data.cuppingSubmitted,
            rejectedSamples: res.data.rejectedSamples,
            sampleReceived: res.data.sampleReceived,
            sampleShipmentCreated: res.data.sampleShipmentCreated,
            topFlavorWheel: JSON.stringify(res.data.topFlavorWheel),
            rejectedReasons: JSON.stringify(res.data.rejectedReasons),
            descriptors: res.data.descriptors,
            averageScore: res.data.averageScore,
            maxScore: res.data.maxScore,
            minScore: res.data.minScore,
            noStatusSamples: res.data.noStatusSamples,
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),

    sendEmail: flow(function * (payload) {
      try {
        const additionalPath = `send_mail`
        const res: ApiResult = yield self.environment.sampleAnalyticReportsApi.save(payload, {}, additionalPath)

        if (res.kind === "ok") {
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }
})
