import React from 'react'
import {useLocation} from "react-router-dom"

import { APP_VERSION } from 'config/env'
import { loadString, saveString } from 'utils/storage'
import {isAuthenticateRoutes, isPublicRoutes} from "constants/routes"

export const useAppVersion = (rootStore) => {
  const location = useLocation()

  React.useEffect(() => {
    if (!rootStore) return

    loadString('app_version').then(async (version) => {
      if (APP_VERSION === version || isPublicRoutes(location.pathname) || isAuthenticateRoutes(location.pathname)) return

      rootStore.resetStoreCache()
      await saveString('app_version', APP_VERSION)
      window.location.href = '/'
    })
  }, [rootStore])
}
