import React from 'react'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {Typography, CssBaseline, Stack} from '@mui/material'

import {centered, dashboardTheme} from 'assets'
import {underDevelopment} from 'assets/images'
import {ThemeProvider} from "@mui/material/styles"

export const NotFound: React.FC = () => {
  const { t } = useTranslation()
  const locations = useLocation()
  const searchParams = new URLSearchParams(locations.search)

  const [description, setDescription] = React.useState<string>(t('error.somethingWrongDescription'))

  React.useEffect(() => {
    if (searchParams.has('message')) {
      setDescription(searchParams.get('message') || '')
    }
  }, [])

  return (
    <ThemeProvider theme={dashboardTheme}>
      <CssBaseline />
      <Stack sx={{...centered, flexDirection: 'column', flex: 1, p: 3}}>
        <img src={underDevelopment} alt='not-found' style={{maxWidth: 600, width: '100%'}} />
        <Typography variant='h6' sx={{mt: 2}}>
          {description}
        </Typography>
      </Stack>
    </ThemeProvider>
  )
}

export default NotFound
