/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-14 23:27:39
 * @modify date 2022-02-01 16:21:11
 */

import { createStyles, makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() =>
  createStyles({
    toolbar: {
      justifyContent: 'space-between'
    },
    appBar: {
      backgroundColor: 'white',
      boxShadow: '0px -4px 15px rgba(0, 0, 0, 0.25)',

      '@media print': {
        display: 'none !important'
      }
    },
  })
)
