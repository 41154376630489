import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    approvalStatus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 96,
      padding: '4px 8px',
      color: 'white',
      fontSize: 14,
      fontWeight: 600,
      borderRadius: 4,
      '&.approved': {
        backgroundColor: '#94DF78'
      },
      '&.rejected': {
        backgroundColor: '#FF848A'
      },
      '&.declined': {
        backgroundColor: '#FF848A'
      }
    },
  })
)
