import React from "react";
import {Box, LinearProgress} from "@mui/material";
import {Logo} from "assets/images";
import {centered} from "assets";

export const LoadingPage = () => {
  return (
    <Box sx={{...centered, height: 'calc(100vh - 1rem)', flexDirection: 'column'}}>
      <Logo width={200} height={75} />

      <Box sx={{ width: 200 }}>
        <LinearProgress color="primary" sx={{borderRadius: 10}} />
      </Box>
    </Box>
  )
}
