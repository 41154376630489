import {max, min} from "lodash";

export const minMaxGraph = (data: number[]) => {
  if (!data) {
    return {
      min: 0,
      max: 10
    }
  }

  let minimum = (min(data) || 0) - 1 // -1 to make the graph look better
  if (minimum < 0) minimum = 0

  let maximum = (max(data) || 10) + 1 // +1 to make the graph look better
  if (maximum > 10) maximum = 10

  return {
    min: minimum,
    max: maximum
  }

}
