import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { centered, colors } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridReport: {
      flexGrow: 1,
    },
    totalScoreWrapper: {
      ...centered,
      height: '100%',
      textAlign: 'center',
      flexDirection: 'column',
      margin: `${theme.spacing(2)} 0`
    },
    legend: {
      fontStyle: 'italic',
      '&.bold': {
        fontWeight: 500
      }
    },
    approved: {
      color: colors.tertiary.main,
      textTransform: 'uppercase'
    },
    rejected: {
      color: colors.error.primary,
      textTransform: 'uppercase'
    },
    powered: {
      ...centered,
      '&.absolute': {
        position: 'absolute',
        right: 10,
        bottom: 5
      }
    },
    headerCardWheel: {
      padding: 16,
      height: 260,
      minWidth: 260,
      border: "none",
      boxShadow: "none",
      [theme.breakpoints.up('md')]: {
        minWidth: 'auto'
      }
    },
    wheelWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    wheel: {
      width: '100%',
      height: '100%',
      flex: 1,
      position: 'relative'
    },
    tableDefect: {
      '& th, td': {
        fontSize: '0.75rem'
      },
      '& tfoot > tr > td': {
        borderBottom: 'none'
      }
    },
    primary: {
      color: colors.primary.main
    },
    socialIcon: {
      padding: `${theme.spacing(1)} !important`
    },
    cellMobile: {
      whiteSpace: 'nowrap',
      padding: theme.spacing(0.5),
    },
    infoWrapper: {
      color: colors.primary.main,
      textAlign: 'left',
      fontSize: "small",
      border: '1px solid #8347AD',
      borderRadius: 8,
      padding: 8,
      height: 'fit-content'
    },
    checkbox: {
      '& span': {
        fontSize: 'small'
      }
    },
    totalCupper: {
      padding: '4px 12px',
      color: colors.primary.main,
      backgroundColor: colors.primary.o25,
      width: 'fit-content',
      borderRadius: 50,
      margin: '0 auto',
      marginBottom: theme.spacing(2.5),

      '& svg': {
        fill: colors.primary.main,
        width: 20,
        height: 20
      },

      '& .MuiTypography-root': {
        fontWeight: 600,
      }
    }
  })
)
