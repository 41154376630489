import React from 'react'
import {isEmpty} from "lodash";
import {Box, Divider, InputAdornment, Typography} from "@mui/material";
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'

import { FormInput } from 'components/form-input/form-input';
import { GreenGradingFormProps } from './green-grading-form.props'
import {useStyles} from "./green-grading-form.styles";
import {colors as colorAsset} from "../../assets";
import {WarningOutlined} from "@mui/icons-material";

export const GreenGradingForm: React.FC<GreenGradingFormProps> = (props: GreenGradingFormProps) => {
  const classes = useStyles()
  const {
    id,
    formInputLabel,
    formInputProps,
    value,
    size,
    label,
    type,
    showLabel = true,
    onChange
  } = props

  const renderIcon = () => {
    if (type === 'primary') {
      return (
        <WarningOutlined
          sx={{
            color: colorAsset.secondary.main,
            ml: 0.7,
            mt: 0.5,
          }}
        />
      )
    }

    return (
      <WarningOutlined sx={{ ml: 0.7, mt: 0.5 }} />
    )
  }

  return (
      <FormInput label={formInputLabel} formControlProps={formInputProps}>
        <CurrencyTextField
          keys={id}
          fullWidth
          size={size}
          type="text"
          inputMode="numeric"
          variant="outlined"
          textAlign="left"
          currencySymbol=""
          value={value === 0 ? '' : value}
          outputFormat="string"
          decimalCharacter="."
          digitGroupSeparator=","
          minimumValue="0"
          onChange={onChange}
          {...showLabel && {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <Divider sx={{ height: 35 }} orientation="vertical" />
                  <Box className={classes.wrapperIconField}>
                    {isEmpty(label) ?
                      renderIcon() : (
                        <Typography sx={{ ml: 0.7, mt: 0.5 }}>{label}</Typography>
                      )}
                  </Box>
                </InputAdornment>
              ),
            }
          }}
        />
      </FormInput>
  );
}
