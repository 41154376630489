/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-28 20:21:36
 * @modify date 2022-02-28 20:21:36
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { centered, colors, verticalCentered } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabWrapper: {
      '& > .Mui-disabled': {
        display: 'none'
      }
    },
    tabHeader: {
      minWidth: 0,
      border: `1px solid ${colors.border.primary}`,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      color: colors.primary.o50,
      '&.Mui-selected': {
        backgroundColor: 'white'
      }
    },
    titleWrapper: {
      padding: theme.spacing(2)
    },
    totalScoreWrapper: {
      ...centered
    },
    totalScoreIcon: {
      width: 30,
      height: 30,
      fill: colors.primary.main,
      marginRight: theme.spacing(1)
    },
    middleTitle: {
      textAlign: 'center',
    },
    roastLevelWrapper: {
      ...verticalCentered,
      height: 48
    },
    roastLevelItem: {
      ...centered,
      textAlign: 'center',
      minHeight: 110,
      cursor: 'pointer',
      flexDirection: 'column',
      backgroundColor: 'white',
      border: `1px dashed ${colors.border.primary}`,
      '&.selected': {
        backgroundColor: '#FFF7F3'
      },
      '&.review': {
        cursor: 'default',
        minHeight: 1,
        border: 'none',
        backgroundColor: 'transparent',
        flexDirection: 'row'
      },

      [theme.breakpoints.down('md')]: {
        minHeight: 'unset',
        padding: theme.spacing(0.5),
        height: 'fit-content',
        borderStyle: 'solid',
      }
    },
    roastLevelIcon: {
      width: 30,
      height: 30,
      marginBottom: theme.spacing(1),
      '&.color-0': { fill: '#A5715B' },
      '&.color-25': { fill: '#8C4E34' },
      '&.color-50': { fill: '#8C3B19' },
      '&.color-75': { fill: '#652C14' },
      '&.color-100': { fill: '#451400' },
      '&.review': {
        marginBottom: 0,
        marginRight: theme.spacing(1)
      }
    },
    panelWrapper: {
      padding: 0,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2)
    },
    scoreReviewTitle: {
      color: 'white',
      textAlign: 'center',
      backgroundColor: colors.primary.main,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    scoreReviewContent: {
      ...centered,
    },
    borderDashed: {
      border: `1px dashed ${colors.border.primary}`
    },
    tableHeader: {
      border: '1px solid #EAE7F0',
      borderBottom: 'none',
      padding: '6px 0 0',
      '& .th-wrapper': {
        borderTop: '1px solid #EAE7F0',
        display: 'flex',
      },
    },
    verticalDivider: {
      border: 'none',
      borderLeft: '1px dashed #EAE7F0',
    },
    buttonMobileWrapper: {
      position: 'fixed',
      textAlign: 'center',
      bottom: 0,
      padding: '12px 16px',
      marginBottom: 0,
      backgroundColor: 'white',
      boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.1)',
      zIndex: 1,
      width: '100%',
      '& button': {
        width: '100%'
      }
    },
    iconViewScore: {
      color: "white",
      position: "absolute",
      bottom: 15,
      fontSize: 'xxx-large'
    },
    iconButtonHideScore: {
      size:'medium',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    messageWarning: {
      display: 'flex',
      flexDirection: 'column',
      alignItems : 'center',
      justifyContent: 'center'
    }
  })
)
