import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
  Popover,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  IconReportShare,
} from "assets/images";
import { Event, ExpandMore, HelpOutline, Visibility } from "@mui/icons-material";
import { cloneDeep, includes, isEmpty } from "lodash";
import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  createColumnHelper,
} from "@tanstack/react-table";
import {
  rankItem,
} from "@tanstack/match-sorter-utils";
import { DateRangePicker, DateRange } from "mui-daterange-picker";

import { colors } from "assets";
import { useStyles } from "./shipment.styles";
import { useStores } from "models";
import { FilterTable } from "components";
import moment from "moment";

import {fulfillmentStatus, fulfillmentTypes} from "constants/form";
import { printPage } from 'utils'
import { LoadingButton } from "@mui/lab";
import { ExportPdf } from "../export-pdf/export-pdf";

const columnHelper = createColumnHelper<any>();

export const Shipment: React.FC = observer(() => {
  const { t } = useTranslation();
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate()

  const printElement = React.createRef<Element | undefined>()

  const {
    reportsAnalyticsStore: {
      reportAnalyticsShipments,
      totalShipments,
      summaryLocalShipments,
      summaryThirdPartyShipments,
      getSampleShipments,
      filter: {setValue: setFilterValue},
      dateFilter: { shipmentStartDate, shipmentEndDate, setValue: setDateValue }
    },
    notificationStore
  } = useStores();

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [tab, setTab] = useState("");
  const [data, setData] = React.useState(reportAnalyticsShipments)
  const [isPrinting, setIsPrinting] = useState(false)
  const [tmpData] = useState<any[]>([])
  const [anchor3rdPartyShipment, setAnchor3rdPartyShipment] = React.useState<HTMLButtonElement | null>(null)
  const openAnchor3rdPartyShipment = Boolean(anchor3rdPartyShipment)
  const [anchorLocalShipment, setLocalShipment] = React.useState<HTMLButtonElement | null>(null)
  const openLocalShipment = Boolean(anchorLocalShipment)
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const filterClear = () => {
      setTab("")
      setFilterValue("third_party_email_blank","")
      setFilterValue("fulfillment_status","")
  }

  const renderDateRange= () => {
    return(
     <DateRangePicker
       open={open}
       toggle={toggle}
       closeOnClickOutside={false}
       onChange={(range) => {
          setDateValue("shipmentStartDate", range.startDate)
          setDateValue("shipmentEndDate", range.endDate)
       }}
     />
    )
   }

  const renderButtons = (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-start"
      spacing={2}
    >
       <Box className={classes.dateRange}>
        <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        >
          <Box sx={{minWidth: 50}}>
            <IconButton size="small" sx={{ color: "#FFFFFF" }}>
              <SvgIcon component={Event} inheritViewBox />{" "}
              <Typography sx={{ ml: 1 }}>Date : </Typography>{" "}
            </IconButton>
            </Box>
            <Box sx={{minWidth: 200}}>
              <Typography sx={{ ml: 1, color:"white" }}>
                {`${moment(shipmentStartDate).format("DD-MMMM-YYYY")} - ${moment(shipmentEndDate).format("DD-MMMM-YYYY")}`}
              </Typography>
            </Box>
            <Box sx={{width: 30}}>
            <IconButton size="small" sx={{ color: "#FFFFFF" }} onClick={()=> setOpen(true)}>
              <SvgIcon component={ExpandMore} inheritViewBox />{" "}
            </IconButton>
          </Box>
        </Stack>

      </Box>
      <LoadingButton
        size='small'
        variant='contained'
        onClick={() => setIsPrinting(true)}
        loading={isPrinting}
      >
        <IconButton
          size='small'
          style={{ color: "#FFFFFF" }}

          sx={{ mr: 0 }}><SvgIcon component={IconReportShare} inheritViewBox /> </IconButton>{t('reportAnalytics.button.export')}
      </LoadingButton>
    </Stack>
  );

  const renderTab = (summaryData, types) => {
      const tabSelect = types === fulfillmentTypes[0] ? "local-" : ""

      return(
      <Tabs
        value={tab}
        onChange={(e, v) => {
          setFilterValue("third_party_email_blank",types === fulfillmentTypes[0] ? "1":"0")
          setFilterValue("fulfillment_status",types === fulfillmentTypes[0] ? v.slice(6) : v)
          setTab(v)
        }}
        textColor="secondary"
        indicatorColor="secondary"
        variant="fullWidth"
        aria-label="full width tabs example"
        className={classes.tabs}
      >
         {fulfillmentStatus.map( data => {

          if(types === fulfillmentTypes[0] && data === fulfillmentStatus[1]) return
          return(
            <Tab
            value={types === fulfillmentTypes[0] ? `local-${data}` : data}
            label={
              <Stack
                direction="column"
                justifyContent="center"
                textAlign="center"
              >
                <Typography
                  variant="subtitle1"
                  color={tab === `${tabSelect}${data}` ? "white" : colors.text.o75}
                >
                  {t(`reportAnalytics.${data}`)}
                </Typography>
                <Typography
                  variant="h4"
                  color={tab === `${tabSelect}${data}` ? "white" : "primary"}
                >
                  {summaryData[data]}
                </Typography>
              </Stack>
            }
          />
          )

         })}
      </Tabs>
    )
  }

  const renderButtonTabel = (info) => {
    const { uniqueToken } = info.row.original

    return(
      <Button
          size='small'
          variant='contained'
          color={'primary'}
          onClick={() => navigate(`/sample-shipments/${uniqueToken}`)}
          sx={{p: 0.5, minWidth: 0}}
        >
         <SvgIcon component={Visibility} inheritViewBox sx={{ fontSize: 25}}/>
        </Button>
    )
  }

  const columns = [
    columnHelper.accessor("customer", {
      id: "customer",
      header: () => <Box textAlign="left">{t("reportAnalytics.customer")}</Box>,
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("createdDate", {
      id: "createdDate",
      header: () => <Box textAlign="left">{t("reportAnalytics.shippingDate")}</Box>,
      cell: (info) => moment(info.renderValue()).format('DD-MM-YYYY'),
    }),
    columnHelper.accessor("numberOfSamples", {
      id: "numberOfSamples",
      header: () => <Box textAlign="left">{t("reportAnalytics.numberOfSamples")}</Box>,
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("numberOfContractedSamples", {
      id: "numberOfContractedSamples",
      header: () => <Box textAlign="left">{t("reportAnalytics.numberOfContractedSamples")}</Box>,
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("numberOfApprovalSamples", {
      id: "numberOfApprovalSamples",
      header: () => <Box textAlign="left">{t("reportAnalytics.numberOfApprovalSamples")}</Box>,
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("numberOfRejectedSamples", {
      id: "numberOfRejectedSamples",
      header: () => <Box textAlign="left">{t("reportAnalytics.numberOfRejectedSamples")}</Box>,
      cell: (info) => info.renderValue(),
    }),
    ...(isPrinting ? []:[
      columnHelper.accessor("review", {
      id: "review",
      enableColumnFilter: false,
      header: () => <Box textAlign="left">{t("reportAnalytics.review")}</Box>,
      cell: (info) => renderButtonTabel(info),
    }),])

  ];

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  useEffect(() => {
    if (open) return

    (async () => {
      try {
        notificationStore.setLoading(true)
        await getSampleShipments(moment(shipmentStartDate).format('DD-MM-YYYY'), moment(shipmentEndDate ).format('DD-MM-YYYY'))
        setData(cloneDeep(reportAnalyticsShipments))
      } finally {
        notificationStore.setLoading(false)
      }
    })();
  }, [tab, open]);

  useEffect(() => {
    if (!isPrinting || !printElement.current) return

    (async () => {
      try {

        tmpData.length=0
        table.getRowModel().rows.map(row => {
           tmpData.push(row.original)
        })

        await printPage(printElement.current,true)
      } finally {
        setIsPrinting(false)

      }
    })()
  }, [isPrinting])


  return (
    <>
      <Box className={classes.wrapper} sx={{ display: isPrinting? "none": "flex"}} >
        <Grid container spacing={2} sx={{p: 1}}>
          <Grid item xs={2}>
            <Stack
              direction="column"
              spacing={2}
              justifyContent="center"
              textAlign="center"
            >
              <Typography variant="h6">{t("reportAnalytics.shipments")}</Typography>
              <Typography variant="h4" color="primary">
                {totalShipments}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={10}>
            {renderButtons}
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2} sx={{ display: isPrinting? "none": "flex"}} >
        <Grid item xs={6}>
          <Box className={classes.wrapper} sx={{p: 1}}>
          <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              alignItems="center"
              sx={{mb: 2}}
            >
              <Typography variant="h6">{t("reportAnalytics.3rdPartyShipment")}</Typography>
              <IconButton
                color='primary'
                size="small"
                onClick={(event) => setAnchor3rdPartyShipment(event.currentTarget)}
              >
                <HelpOutline fontSize="small"/>
              </IconButton>
            </Stack>

            <Popover
              open={openAnchor3rdPartyShipment}
              anchorEl={anchor3rdPartyShipment}
              onClose={() => setAnchor3rdPartyShipment(null)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              PaperProps={{
                sx: {width: 300}
              }}
            >
              <Typography sx={{ p: 2 }}>{t('reportAnalytics.filterDescription')}</Typography>
            </Popover>

            <Stack direction="row" justifyContent="center" textAlign="center">
              <Typography variant="h4" color="primary">
                {summaryThirdPartyShipments.total}
              </Typography>
              <Typography variant="h4" color={colors.text.o50}>
                /{totalShipments}
              </Typography>
            </Stack>

            <Box>
              <Stack direction="row" justifyContent="space-between">
                <Box height={35} p={0.5}>
                  <Typography>{t("reportAnalytics.detail")}</Typography>
                </Box>
              {(!isEmpty(tab) && !includes(tab, 'local')) &&  <Button onClick={()=> filterClear()} variant="text">{t("reportAnalytics.clearFilter")}</Button>}
              </Stack>
              {renderTab(summaryThirdPartyShipments, fulfillmentTypes[1])}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.wrapper} sx={{p: 1}}>
          <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              alignItems="center"
              sx={{mb: 2}}
            >
              <Typography variant="h6">{t("reportAnalytics.localShipment")}</Typography>
              <IconButton
                color='primary'
                size="small"
                onClick={(event) => setLocalShipment(event.currentTarget)}
              >
                <HelpOutline fontSize="small"/>
              </IconButton>
            </Stack>
            <Popover
              open={openLocalShipment}
              anchorEl={anchorLocalShipment}
              onClose={() => setLocalShipment(null)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              PaperProps={{
                sx: {width: 300}
              }}
            >
              <Typography sx={{ p: 2 }}>{t('reportAnalytics.filterDescription')}</Typography>
          </Popover>
            <Stack direction="row" justifyContent="center" textAlign="center">
              <Typography variant="h4" color="primary">
                {summaryLocalShipments.total}
              </Typography>
              <Typography variant="h4" color={colors.text.o50}>
                /{totalShipments}
              </Typography>
            </Stack>

            <Box>
              <Stack direction="row" justifyContent="space-between">
                <Box height={35} p={0.5}>
                  <Typography>{t("reportAnalytics.detail")}</Typography>
                </Box>
              {(!isEmpty(tab) && includes(tab, 'local')) &&  <Button onClick={()=> filterClear()} variant="text">{t("reportAnalytics.clearFilter")}</Button>}
              </Stack>
              {renderTab(summaryLocalShipments, fulfillmentTypes[0])}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.wrapper}>
        <FilterTable table={table} paginationEnable={true}/>
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        hideBackdrop={true}
      >
        <Box>
           {renderDateRange()}
        </Box>
        <DialogActions>
          <Button variant="contained" onClick={()=> setOpen(false)}>
            {t("reportAnalytics.close")}
          </Button>
        </DialogActions>

      </Dialog>

      {isPrinting &&
        <ExportPdf
          title={t("reportAnalytics.shipment")}
          nameFile={`export-pdf-${t("reportAnalytics.shipment")}`}
          columnFilters={columnFilters}
          dateValue={`${moment(shipmentStartDate).format('DD-MM-YYYY')} - ${moment(shipmentEndDate ).format('DD-MM-YYYY')}`}
          titleCountData={t("reportAnalytics.shipment")}
          summaryLocalShipments={summaryLocalShipments}
          localShipmentsType={fulfillmentTypes[0]}
          summaryThirdPartyShipments={summaryThirdPartyShipments}
          thirdPartyShipmentsType={fulfillmentTypes[1]}
          totalShipments={totalShipments}
          tabValue={tab}
          countData={table.getRowModel().rows.length}
          table={table}
          isPrinting={isPrinting}
          onExportPdf={(data)=>{
            setIsPrinting(data)
          }}
        />
       }
    </>
  );
});
