/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-01 03:40:36
 * @modify date 2022-04-19 02:18:52
 */

import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { isMobile } from "react-device-detect"
import {Card, Grid, Typography, Avatar, InputAdornment} from '@mui/material'
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'

import { verticalCentered } from 'assets'
import { IconStartCupping } from 'assets/images'

import { CupProps } from './cup.props'
import { useStyles } from './cup.styles'

export const Cup: React.FC<CupProps> = (props: CupProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { label, cups, score, onClick, isSca, isScaDefect, fiveCups, onChange } = props

  const forSca = isSca || isScaDefect

  if (!fiveCups) {
    return (
      <Card className={clsx(classes.wrapper, forSca && 'sca')}>
        <Grid container spacing={2}>
          <Grid item xs={isMobile ? 6 : 12} md={6} sx={{...verticalCentered}}>
            <Typography variant='subtitle1'>{label}</Typography>
          </Grid>
          <Grid item xs={isMobile ? 6 : 12} md={6} className={classes.scoreWrapper}>
            <Typography variant='body3' mr={1}>{t('descriptor.score')}</Typography>
            <CurrencyTextField
              type="text"
              size="small"
              inputMode="numeric"
              variant="outlined"
              textAlign="left"
              currencySymbol=""
              value={score}
              outputFormat="number"
              decimalCharacter="."
              digitGroupSeparator=","
              minimumValue="0"
              decimalPlaces={0}
              onChange={(e, value) => onChange && onChange(value)}
              style={{width: '60px'}}
              {...(forSca && {InputProps: {startAdornment: (<InputAdornment position="start">-</InputAdornment>)}})}
            />
          </Grid>
        </Grid>
      </Card>
    )
  }

  return (
    <Card className={clsx(classes.wrapper, forSca && 'sca')}>
      <Grid container spacing={isMobile ? 0 : 2}>
        <Grid item xs={forSca ? 4: 2} md={forSca ? 5 : 3} sx={{...verticalCentered}}>
          <Typography variant='subtitle1'>{label}</Typography>
        </Grid>
        <Grid item xs={8} md={forSca ? 7 : 6} className={classes.cupWrapper}>
          {cups.map((item, index) =>
            <Avatar key={index} className={clsx(classes.cup, item && 'selected', item && isSca && 'sca-selected', item && isScaDefect && 'sca-defect-selected')} onClick={() => onClick(index)}>
              <IconStartCupping className={clsx(classes.cupIcon, item && 'selected')} />
            </Avatar>
          )}
        </Grid>
        {!forSca &&
          <Grid item xs={2} md={3} className={classes.scoreWrapper}>
            {!isMobile && <Typography variant='body3'>{t('descriptor.score')}</Typography>}
            <Card className={classes.score}>{score}</Card>
          </Grid>
        }
      </Grid>
    </Card>
  )
}
