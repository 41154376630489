/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-16 21:10:51
 * @modify date 2022-02-16 21:10:51
 */

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Card } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'

import { findRoute } from 'constants/routes'
import { HeaderTitle, StripePayment } from 'components'
import { STRIPE_PROMISE } from 'config/env'

export const CardStripe: React.FC = () => {
  const { t } = useTranslation()
  const homeLink = findRoute('home')

  return (
    <>
      <HeaderTitle
        backUrl={homeLink}
        breadcrumb={t('common.backTo', { menu: t('menu.home')})}
        title={t('subscription.updateCardDetails')}
      />

      <Container>
        <Card sx={{p: 4, maxWidth: 360}}>
          <Elements stripe={STRIPE_PROMISE}>
            <StripePayment />
          </Elements>
        </Card>
      </Container>
    </>
  )
}

export default CardStripe
