/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-09 02:54:03
 * @modify date 2022-03-09 02:54:03
 */

import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, horizontalCentered } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      ...horizontalCentered,
      marginTop: theme.spacing(4)
    },
    primary: {
      color: colors.primary.main
    },
    img: {
      marginBottom: theme.spacing(2),
      width: 250,
      height: 175,
      display: 'flex',
      '&:hover': {
        cursor: 'pointer'
      },
    }
  })
)