import {types} from "mobx-state-tree";

export const cuppingResultProps = {
  id: types.maybeNull(types.string),
  cupperName: types.maybeNull(types.string),
  cuppingDate: types.maybeNull(types.string),
  sampleType: types.maybeNull(types.string),
  salesContract: types.maybeNull(types.string),
  cuppingProtocol: types.maybeNull(types.string),
  totalDefect: types.maybeNull(types.union(types.string, types.number)),
  totalScore: types.maybeNull(types.union(types.string, types.number)),
  uniqueToken: types.maybeNull(types.string),
  sampleUniqueToken: types.maybeNull(types.string),
  masterSampleUniqueToken: types.maybeNull(types.string),
  sampleTransactionUniqueToken: types.maybeNull(types.string),
  sampleTransactionItemUniqueToken: types.maybeNull(types.string),
  approvalStatus: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
}
